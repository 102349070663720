import React, { Component, useMemo } from 'react';
import '../../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import Alert from '@material-ui/lab/Alert';
// import Modal from '@material-ui/core/Modal';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';
import LinkIcon from '@material-ui/icons/Link';
import OfflineHeader from '../../components/OfflineHeader';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import TimelineIcon from '@material-ui/icons/Timeline';
import GoogleLogin from 'react-google-login';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Chart } from 'react-charts'
import Snackbar from '@material-ui/core/Snackbar';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Helmet} from 'react-helmet';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import RichTextEditor from 'react-rte';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import {
  isMobile,
  BrowserView,
  MobileView
} from "react-device-detect";

import { Redirect } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';


const theme = createMuiTheme({
  overrides: {
    MuiGridListTile: {
      // Name of the rule
      size: {
        // Some CSS
        width: 130,
        height: '100%'
      },
    },
  },
});

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ]
  };

class EditCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      package: {},
      title: '',
      secondaryTitle: '',
      text: RichTextEditor.createEmptyValue(),
      phoneNumber: '',
      facebookLink: '',
      instagramLink: '',
      address: '',
      businessName: '',
      loading: false,
      button_1: '',
      button_1_icon: '',
      button_1_link: '',
      button_2: '',
      button_2_icon: '',
      button_2_link: '',
      button_3: '',
      button_3_icon: '',
      button_3_link: '',
      button_4: '',
      button_4_icon: '',
      button_4_link: '',
      button_5: '',
      button_5_icon: '',
      button_5_link: '',
      button_6: '',
      button_6_icon: '',
      button_6_link: '',
      button_7: '',
      button_7_icon: '',
      button_7_link: '',
      button_8: '',
      button_8_icon: '',
      button_8_link: '',
      button_9: '',
      button_9_icon: '',
      button_9_link: '',
      buttonShownIndex: 3,
      openingHours: {
        'A': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'B': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'C': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'D': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'E': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'F': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'G': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
      },
      close: {
        'A': false,
        'B': false,
        'C': false,
        'D': false,
        'E': false,
        'F': false,
        'G': false,
      },
      open24h: false,
      slug: '',
      slugOriginal: '',
      slugAvailable: true,
      templateId: '',
      templates: [],
      uploadLogoModal: false,
      uploadLogoModalSuccess: false,
      uploadCoverModal: false,
      uploadCoverModalSuccess: false,
      changeTemplateModal: false,
      uploadGalleryModal: false,
      uploadGalleryModalSuccess: false,
      template: {
        name: '',
        _id: '',
        description: '',
        coverImage: ''
      },
      gallery: [],
      original_gallery: [],
      search_gallery: '',
      gallerySelectedCoverImage: '',
      mobileNavLevel: 1,
      templateChosen: '',
      categories: [],
      categoryChosen: 'all',
      trialEnded: false,
      trialUntil: 0,
      slug: '',
      areYouSure: false,
      chosenId: ''
    };
  }

  componentDidMount() {
    this.getMyCard();
    this.getTemplates();
    this.getGalleries();
    this.getPackage();
  }

  getPackage = () => {
    this.setState({loading: true});
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'profile', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          if (response.data.data.card.slug === null || response.data.data.card.slug === undefined || response.data.data.card.slug === '') {
            window.location.href='/signup'
          }
          //console.log(response);
          this.setState({loading: false});
          if (response.data.status === "ok") {
            this.setState({
              package: response.data.data.package,
              trialEnded: response.data.data.trialEnded,
              trialUntil: response.data.data.trialUntil,
              slug: response.data.data.card.slug
            });
          }
        }).catch(err => {
          console.log(err);
          this.setState({loading: false});
        });
      } catch (err) {
        console.log(err);
        this.setState({loading: false});
      }
    }
  }


  onChangeText = (value) => {
    this.setState({text: value});
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(
        value.toString('html')
      );
    }
  };

  searchGallery = () => {
    let gallery = this.state.gallery;
    let original_gallery = this.state.original_gallery;
    if (original_gallery.length === 0) this.setState({original_gallery: gallery});

    let query = this.state.search_gallery;

    let output = [];
    gallery.forEach((item, i) => {
      if (item.tagEnglish.includes(query) || item.name.includes(query)) output.push(item);
    });

    if (output.length >= 1) {
      this.setState({gallery: output});
    }
  }

  resetGallery = () => {
    let original_gallery = this.state.original_gallery;
    if (original_gallery.length >= 1) {
      this.setState({gallery: original_gallery});
    }
  }

  updateCoverFromGallery = () => {
    let gallerySelectedCoverImage = this.state.gallerySelectedCoverImage;

    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.put(API + 'card/cover-by-gallery?id='+this.state.gallerySelectedCoverImage, {}, {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          this.setState({loading: false,uploadLogoModal: false, uploadCoverModal: false, uploadCoverModalSuccess: true, coverImageShowGalleryBool: false, cover: response.data.data.card.cover});
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
          console.log(err);
      }
    }
  }

  updateLogoFromGallery = () => {
    let gallerySelectedLogoImage = this.state.gallerySelectedLogoImage;

    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.put(API + 'card/logo-by-gallery?id='+this.state.gallerySelectedLogoImage, {}, {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          this.setState({loading: false, uploadLogoModal: false, uploadCoverModal: false, uploadLogoModalSuccess: true, logoImageShowGalleryBool: false, logo: response.data.data.card.logo});
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
          console.log(err);
      }
    }
  }

  getTemplates = () => {
    this.setState({loading: true});
    axios.get(API + 'templates').then(response => {
      if (response.data.status === "ok") {
        this.setState({templates: response.data.data.templates});
      }
      this.setState({loading: false});
    }).catch(err => {
      console.log(err);
      this.setState({loading: false});
    });
  }

  checkSlug = (slug) => {
    slug = slug.replace(' ', '');
    if (slug.match("^[a-zA-Z0-9]+$") !== null) {
      this.setState({slug: slug}, () => {
        axios.get(API + 'card/slug?slug='+slug).then(response => {
          if (response.data.status === "ok") {
            this.setState({slugAvailable: response.data.available});
          }
        }).catch(err => {
          console.log(err);
        });
      });
    } else {
      this.setState({slug: slug, slugAvailable: false});
    }
  }

  is24hours = () => {
    this.setState({loading: true});
    let openingHours = this.state.openingHours;

    let a1 = new Date(openingHours['A'][0]).getHours() + ':' + new Date(openingHours['A'][0]).getMinutes();
    let a2 = new Date(openingHours['A'][1]).getHours() + ':' + new Date(openingHours['A'][1]).getMinutes();

    let b1 = new Date(openingHours['B'][0]).getHours() + ':' + new Date(openingHours['B'][0]).getMinutes();
    let b2 = new Date(openingHours['B'][1]).getHours() + ':' + new Date(openingHours['B'][1]).getMinutes();

    let c1 = new Date(openingHours['C'][0]).getHours() + ':' + new Date(openingHours['C'][0]).getMinutes();
    let c2 = new Date(openingHours['C'][1]).getHours() + ':' + new Date(openingHours['C'][1]).getMinutes();

    let d1 = new Date(openingHours['D'][0]).getHours() + ':' + new Date(openingHours['D'][0]).getMinutes();
    let d2 = new Date(openingHours['D'][1]).getHours() + ':' + new Date(openingHours['D'][1]).getMinutes();

    let e1 = new Date(openingHours['E'][0]).getHours() + ':' + new Date(openingHours['E'][0]).getMinutes();
    let e2 = new Date(openingHours['E'][1]).getHours() + ':' + new Date(openingHours['E'][1]).getMinutes();

    let f1 = new Date(openingHours['F'][0]).getHours() + ':' + new Date(openingHours['F'][0]).getMinutes();
    let f2 = new Date(openingHours['F'][1]).getHours() + ':' + new Date(openingHours['F'][1]).getMinutes();

    let g1 = new Date(openingHours['G'][0]).getHours() + ':' + new Date(openingHours['G'][0]).getMinutes();
    let g2 = new Date(openingHours['G'][1]).getHours() + ':' + new Date(openingHours['G'][1]).getMinutes();
    if (
      ((a1 === '0:0' || a1 === '00:00') && (a2 === '0:0' || a2 === '00:00')) &&
      ((b1 === '0:0' || b1 === '00:00') && (b2 === '0:0' || b2 === '00:00')) &&
      ((c1 === '0:0' || c1 === '00:00') && (c2 === '0:0' || c2 === '00:00')) &&
      ((d1 === '0:0' || d1 === '00:00') && (d2 === '0:0' || d2 === '00:00')) &&
      ((e1 === '0:0' || e1 === '00:00') && (e2 === '0:0' || e2 === '00:00')) &&
      ((f1 === '0:0' || f1 === '00:00') && (f2 === '0:0' || f2 === '00:00')) &&
      ((g1 === '0:0' || g1 === '00:00') && (g2 === '0:0' || g2 === '00:00'))
    ) {
      this.setState({open24h: true, loading: false});
    } else {
      this.setState({open24h: false, loading: false});
    }
  }

  remove_button = (btn) => {
    let index = this.state.buttonShownIndex;

    if (index === 4) this.setState({button_4: ''});
    if (index === 5) this.setState({button_5: ''});
    if (index === 6) this.setState({button_6: ''});
    if (index === 7) this.setState({button_7: ''});
    if (index === 8) this.setState({button_8: ''});
    if (index === 9) this.setState({button_9: ''});

    index -= 1;

    this.setState({buttonShownIndex: index});
  }

  setOpen24H = () => {
    if (this.state.open24h) {
      this.setState({open24h: false});
    } else {
      this.setState({
        openingHours: {
          'A': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'B': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'C': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'D': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'E': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'F': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'G': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
        },
        open24h: true
      });
    }
  }

  getOpeningHours = (day, type = 'from') => {
    let days = this.state.openingHours;

    if (days === null || days === undefined || days['A'] === null || days['A'] === undefined) {
      return new Date(Date.now());
    } else {
      if (type === 'from') {
        return new Date(days[day][0]);
      } else {
        return new Date(days[day][1]);
      }
    }
  }

  setOpeningHours = (day, type = 'from', time) => {
    let days = this.state.openingHours;
    let dayTimes = days[day];

    if (type === 'from') {
      dayTimes[0] = time;
    } else {
      dayTimes[1] = time;
    }

    days[day] = dayTimes;

    this.setState({openingHours: days});
  }

  getGalleries = () => {
    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'galleries', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          this.setState({gallery: response.data.data.gallery, original_gallery: response.data.data.gallery});
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
          console.log(err);
      }
    }
  }

  getMyCard = () => {
    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'card', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          if (response.data.status === "ok") {
            this.setState({
              categories: response.data.data.categories,
              title: response.data.data.card.title,
              secondaryTitle: response.data.data.card.secondaryTitle,
              text: RichTextEditor.createValueFromString(response.data.data.card.text, 'html'),
              phoneNumber: response.data.data.card.phoneNumber,
              facebookLink: response.data.data.card.facebookLink,
              instagramLink: response.data.data.card.instagramLink,
              address: response.data.data.card.address,
              video: response.data.data.card.video,
              logo: response.data.data.card.logo,
              cover: response.data.data.card.cover,
              button_1: response.data.data.card.button_1,
              button_1_icon: response.data.data.card.button_1_icon,
              button_1_link: response.data.data.card.button_1_link,
              button_2: response.data.data.card.button_2,
              button_2_icon: response.data.data.card.button_2_icon,
              button_2_link: response.data.data.card.button_2_link,
              button_3: response.data.data.card.button_3,
              button_3_icon: response.data.data.card.button_3_icon,
              button_3_link: response.data.data.card.button_3_link,
              button_4: response.data.data.card.button_4,
              button_4_icon: response.data.data.card.button_4_icon,
              button_4_link: response.data.data.card.button_4_link,
              button_5: response.data.data.card.button_5,
              button_5_icon: response.data.data.card.button_5_icon,
              button_5_link: response.data.data.card.button_5_link,
              button_6: response.data.data.card.button_6,
              button_6_icon: response.data.data.card.button_6_icon,
              button_6_link: response.data.data.card.button_6_link,
              button_7: response.data.data.card.button_7,
              button_7_icon: response.data.data.card.button_7_icon,
              button_7_link: response.data.data.card.button_7_link,
              button_8: response.data.data.card.button_8,
              button_8_icon: response.data.data.card.button_8_icon,
              button_8_link: response.data.data.card.button_8_link,
              button_9: response.data.data.card.button_9,
              button_9_icon: response.data.data.card.button_9_icon,
              button_9_link: response.data.data.card.button_9_link,
              businessName: response.data.data.card.businessName,
              openingHours: response.data.data.card.openingHours,
              slug: response.data.data.card.slug,
              slugOriginal: response.data.data.card.slug,
              templateId: response.data.data.card.templateId,
              templateChosen: response.data.data.card.templateId,
              template: response.data.data.template
            }, () => {
              this.setState({loading: false});
              let c = this.state;
              if (c.button_9 !== '' && c.button_9 !== undefined && c.button_9 !== null) this.setState({buttonShownIndex: 9});
              else if (c.button_8 !== '' && c.button_8 !== undefined && c.button_8 !== null) this.setState({buttonShownIndex: 8});
              else if (c.button_7 !== '' && c.button_7 !== undefined && c.button_7 !== null) this.setState({buttonShownIndex: 7});
              else if (c.button_6 !== '' && c.button_6 !== undefined && c.button_6 !== null) this.setState({buttonShownIndex: 6});
              else if (c.button_5 !== '' && c.button_5 !== undefined && c.button_5 !== null) this.setState({buttonShownIndex: 5});
              else if (c.button_4 !== '' && c.button_4 !== undefined && c.button_4 !== null) this.setState({buttonShownIndex: 4});
              else this.setState({buttonShownIndex: 3});

              let openingHours = this.state.openingHours;

              if (openingHours === null || openingHours === undefined || openingHours['A'] === null || openingHours['A'] === undefined) {

              } else {
                if (openingHours['A'][0] === '' || openingHours['A'][0].length === 0) this.close('A');
                if (openingHours['B'][0] === '' || openingHours['B'][0].length === 0) this.close('B');
                if (openingHours['C'][0] === '' || openingHours['C'][0].length === 0) this.close('C');
                if (openingHours['D'][0] === '' || openingHours['D'][0].length === 0) this.close('D');
                if (openingHours['E'][0] === '' || openingHours['E'][0].length === 0) this.close('E');
                if (openingHours['F'][0] === '' || openingHours['F'][0].length === 0) this.close('F');
                if (openingHours['G'][0] === '' || openingHours['G'][0].length === 0) this.close('G');

                this.is24hours()
              }
            });
          }
        }).catch(err => {
          this.setState({loading: true});
          console.log(err);
          alert('שגיאה בקבלת נתוני הכרטיס, אנא נסו שוב');
        });
      } catch (err) {
        this.setState({loading: true});
        console.log(err);
        alert('שגיאה בקבלת נתוני הכרטיס, אנא נסו שוב');
      }
    }
  }

  updateCard = () => {
    this.setState({errorAlert: false, error: ''});

    let payload = {
      title: this.state.title,
      secondaryTitle: this.state.secondaryTitle,
      text: this.state.text.toString('html'),
      phoneNumber: this.state.phoneNumber,
      facebookLink: this.state.facebookLink,
      instagramLink: this.state.instagramLink,
      address: this.state.address,
      video: this.state.video,
      button_1: this.state.button_1,
      button_1_icon: this.state.button_1_icon,
      button_1_link: this.state.button_1_link,
      button_2: this.state.button_2,
      button_2_icon: this.state.button_2_icon,
      button_2_link: this.state.button_2_link,
      button_3: this.state.button_3,
      button_3_icon: this.state.button_3_icon,
      button_3_link: this.state.button_3_link,
      button_4: this.state.button_4,
      button_4_icon: this.state.button_4_icon,
      button_4_link: this.state.button_4_link,
      button_5: this.state.button_5,
      button_5_icon: this.state.button_5_icon,
      button_5_link: this.state.button_5_link,
      button_6: this.state.button_6,
      button_6_icon: this.state.button_6_icon,
      button_6_link: this.state.button_6_link,
      button_7: this.state.button_7,
      button_7_icon: this.state.button_7_icon,
      button_7_link: this.state.button_7_link,
      button_8: this.state.button_8,
      button_8_icon: this.state.button_8_icon,
      button_8_link: this.state.button_8_link,
      button_9: this.state.button_9,
      button_9_icon: this.state.button_9_icon,
      button_9_link: this.state.button_9_link,
      businessName: this.state.businessName,
      openingHours: this.state.openingHours,
      slug: this.state.slug,
      templateId: this.state.templateId,

      gallerySelectedCoverImage: '',
      gallerySelectedLogoImage: '',
    }

    if (
      this.state.title !== '' &&
      this.state.secondaryTitle !== '' &&
      this.state.text !== '' &&
      this.state.phoneNumber !== '' &&
      this.state.facebookLink !== '' &&
      this.state.instagramLink !== '' &&
      this.state.address !== '' &&
      this.state.businessName !== '' &&
      this.state.templateId !== ''
    ) {
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          axios.put(API + 'card', payload, {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {
            if (response.data.status === "ok") {
              alert('הכרטיס עודכן בהצלחה');
              window.location.reload();
            } else {
              alert('חלה שגיאה, אנא נסו שוב.')
            }
          }).catch(err => {
            console.log(err);
            alert('חלה שגיאה, אנא נסו שוב.')
          });
        } catch (err) {
          console.log(err);
          alert('חלה שגיאה, אנא נסו שוב.')
        }
      }
    } else {
      this.setState({errorAlert: true, error: 'יש למלא את כל השדות על מנת ליצור את הכרטיס'});

      if (this.state.title === '') this.setState({errortitle: true});
      if (this.state.secondaryTitle === '') this.setState({errorsecondarytitle: true});
      if (this.state.text === '') this.setState({errortext: true});
      if (this.state.phoneNumber === '') this.setState({errorphoneNumber: true});
      if (this.state.facebookLink === '') this.setState({errorfacebookLink: true});
      if (this.state.instagramLink === '') this.setState({errorinstagramLink: true});
      if (this.state.address === '') this.setState({erroraddress: true});
      if (this.state.businessName === '') this.setState({errorbusinessName: true});

    }
  }

  addButtonIndex = () => {
    let index = this.state.buttonShownIndex;

    index += 1;

    this.setState({buttonShownIndex: index});
  }



  updateTemplate = () => {
    let id = this.state.chosenId;
    this.setState({areYouSure: false});
    if (id !== '' && id !== null && id !== undefined) {
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {

        axios.put(API + 'card/template?id='+id, {}, {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          this.setState({changeTemplateModal: false, changeTemplateModalSuccess: true, areYouSure: false});
            this.getMyCard();

        }).catch((error) => {
          this.setState({changeTemplateModal: false, areYouSure: false});
        });
      }
    }
  }

  getMyCardTemplate = () => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      axios.get(API + 'card', {
        headers: {
           Authorization: `Bearer ${token}`
        }
      }).then(response => {
          this.setState({templateId: response.data.data.card.templateId, template: response.data.data.template});
      }).catch((error) => {
        this.setState({changeTemplateModal: false});
      });
    }
  }


  getMyCardImages = () => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      axios.get(API + 'card', {
        headers: {
           Authorization: `Bearer ${token}`
        }
      }).then(response => {
        //console.log(response);
          this.setState({logo: response.data.data.card.logo, cover: response.data.data.cover});
      }).catch((error) => {

      });
    }
  }



  onFormSubmitCover = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.file);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'card/cover',formData,config)
          .then((response) => {
            this.setState({file: null, uploadCoverModal: false, uploadCoverModalSuccess: true})
            // window.location.reload();
            this.getMyCardImages();
          }).catch((error) => {
            this.setState({file: null, uploadCoverModal: false})
      });
    }
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.file);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'card/logo',formData,config)
          .then((response) => {
            this.setState({file: null, uploadLogoModal: false, uploadLogoModalSuccess: true})
            // window.location.reload();
            this.getMyCardImages();
          }).catch((error) => {
            this.setState({file: null, uploadLogoModal: false})
      });
    }
  }

  onChange = (e) => {
      this.setState({file:e.target.files[0]});
  }

  close = (t) => {
    let dict = this.state.close;

    if (dict[t]) {
      dict[t] = false
    } else {
      dict[t] = true
    }

    let openingHours = this.state.openingHours;

    openingHours[t] = ['', ''];

    this.setState({close: dict, openingHours: openingHours});
  }


    helmet = () => {
      const description = 'דיביט | תבניות';

      return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>דיביט | תבניות</title>
            <link rel="canonical" href={window.location.href} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta property="og:description" content={description} />
            <meta property="og:title" content={description} />
            <meta property="og:site_name" content="דיביט" />
            <meta property="og:see_also" content="דיביט" />
            <meta itemprop="name" content={description} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta name="description" content={description}/>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
      );
    }

    areYouSure = (id) => {
      this.setState({chosenId: id, areYouSure: true});
    }

  render() {
    return (
      <div>
        {this.helmet()}
        {this.state.trialEnded && (<Redirect to='/' />)}

        {this.state.loading ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OfflineHeader slug={this.state.slug} package={this.state.package} trialUntil={this.state.trialUntil}/>
            </Grid>
            <Grid item xs={12}>
              <center>
                <div style={{textAlign: 'center', marginTop: '5%'}}>
                  <CircularProgress />
                  <h3 style={{direciton: 'rtl'}}>..טוען</h3>
                </div>
              </center>
            </Grid>
          </Grid>
        ) : (
          <React.Fragment>
          <Dialog
           open={this.state.areYouSure}
           keepMounted
           onClose={() => this.setState({areYouSure: false})}
           aria-labelledby="alert-dialog-slide-title"
           aria-describedby="alert-dialog-slide-description"
           style={{
             direction: 'rtl',
             textAlign: 'right',
             whiteSpace: 'normal'
           }}
         >
           <DialogTitle id="alert-dialog-slide-title">{"החלפת תבנית"}</DialogTitle>
           <DialogContent>
             <DialogContentText id="alert-dialog-slide-description" style={{whiteSpace: 'normal'}}>
                האם אתם בטוחים שברצונכם להחליף תבנית לתבנית זו?
             </DialogContentText>
           </DialogContent>
           <DialogActions>
             <Button onClick={() => this.setState({areYouSure: false})} color="orange" variant={'contained'} style={{marginRight: '1%', marginLeft: '1%'}}>
               לא! חזור אחורה
             </Button>
             <Button onClick={() => this.updateTemplate(this.state.chosenId)} color="primary" variant={'contained'} style={{marginRight: '1%', marginLeft: '1%'}}>
               אני בטוח\ה
             </Button>
           </DialogActions>
         </Dialog>

            <BrowserView>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <OfflineHeader slug={this.state.slug} mobile={isMobile} title={'עריכת התבנית'} package={this.state.package} trialUntil={this.state.trialUntil}/>
              </Grid>

              <Grid item xs={12} style={{textAlign: 'right', marginRight: '5%'}}>
                <p style={{fontSize: '1.5em'}}>בחר תבנית ממאגר התבניות של דיביט</p>
              </Grid>
              <br /><br />
              <Grid item xs={12} style={{textAlign: 'right', marginRight: '5%'}}>
              <div style={{textAlign: 'center', width: '100%', marginTop: '-5%'}}>
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                  {this.state.categories.reverse().map((c_) => {
                    let category = c_.name;
                    return (
                      <Button onClick={(e) => this.setState({categoryChosen: category})} style={{fontSize: '1em', backgroundColor: this.state.categoryChosen === category ? yellow : blue, color: 'white', borderRadius: 50, marginRight: '0.5%', marginLeft: '0.5%', marginBottom: '0.5%'}}>{category}</Button>
                    )
                  })}
                  <Button onClick={(e) => this.setState({categoryChosen: 'all'})} style={{fontSize: '1em', backgroundColor: this.state.categoryChosen === 'all' ? yellow : blue, color: 'white', borderRadius: 50, marginRight: '0.5%', marginLeft: '0.5%', marginBottom: '0.5%'}}>הכל</Button>


                </div>
              </div>
              </Grid>
              {this.state.templates.map((template) => {
                if (this.state.categoryChosen === 'all' || this.state.categoryChosen === '') {
                  if (this.state.templateChosen === template._id.toString()) {
                    return (
                      <Grid item xs={4} style={{textAlign: 'center'}}>
                        <p>{template.name}</p>
                        <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={4} style={{textAlign: 'center'}} onClick={(e) => this.areYouSure(template._id)}>
                        <p>{template.name}</p>
                        <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', }} />
                      </Grid>
                    );
                  }
                } else {
                  if (this.state.categoryChosen === template.category) {
                    if (this.state.templateChosen === template._id.toString()) {
                      return (
                        <Grid item xs={4} style={{textAlign: 'center'}}>
                          <p>{template.name}</p>
                          <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid item xs={4} style={{textAlign: 'center'}} onClick={(e) => this.areYouSure(template._id)}>
                          <p>{template.name}</p>
                          <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', }} />
                        </Grid>
                      );
                    }
                  }
                }

              })}

              <Snackbar open={this.state.changeTemplateModalSuccess} autoHideDuration={6000} onClose={(e) => this.setState({changeTemplateModalSuccess: false})}>
                <Alert variant="filled" severity="success" style={{fontSize: '1.5em'}}>!התבנית שלך התעדכנה</Alert>
              </Snackbar>

              <Grid item xs={12}>
                <div>
                  <center><p style={{direction:'rtl', color: blue, fontSize: '1em', fontWeight: 100}}>
                    כל הזכויות שמורות לדיביט בע"מ, 2020
                  </p></center>
                </div>
              </Grid>
            </Grid>
            </BrowserView>
            {/*
              ████████▄     ▄████████  ▄█    █▄   ▄█   ▄████████    ▄████████         ▄████████    ▄████████    ▄███████▄    ▄████████    ▄████████
              ███   ▀███   ███    ███ ███    ███ ███  ███    ███   ███    ███        ███    ███   ███    ███   ███    ███   ███    ███   ███    ███
              ███    ███   ███    █▀  ███    ███ ███▌ ███    █▀    ███    █▀         ███    █▀    ███    █▀    ███    ███   ███    █▀    ███    ███
              ███    ███  ▄███▄▄▄     ███    ███ ███▌ ███         ▄███▄▄▄            ███         ▄███▄▄▄       ███    ███  ▄███▄▄▄      ▄███▄▄▄▄██▀
              ███    ███ ▀▀███▀▀▀     ███    ███ ███▌ ███        ▀▀███▀▀▀          ▀███████████ ▀▀███▀▀▀     ▀█████████▀  ▀▀███▀▀▀     ▀▀███▀▀▀▀▀
              ███    ███   ███    █▄  ███    ███ ███  ███    █▄    ███    █▄                ███   ███    █▄    ███          ███    █▄  ▀███████████
              ███   ▄███   ███    ███ ███    ███ ███  ███    ███   ███    ███         ▄█    ███   ███    ███   ███          ███    ███   ███    ███
              ████████▀    ██████████  ▀██████▀  █▀   ████████▀    ██████████       ▄████████▀    ██████████  ▄████▀        ██████████   ███    ███
            */}
            <MobileView>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <OfflineHeader slug={this.state.slug} mobile={isMobile} title={'עריכת התבנית'} package={this.state.package} trialUntil={this.state.trialUntil}/>
              </Grid>

              <Grid item xs={12} style={{textAlign: 'right', marginRight: '5%'}}>
                <p style={{fontSize: '1.5em', whiteSpace: 'normal'}}>בחר תבנית ממאגר התבניות של דיביט</p>
              </Grid>

              <div style={{textAlign: 'center', width: '100%', marginTop: '-5%'}}>
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                  {this.state.categories.reverse().map((c_) => {
                    let category = c_.name;
                    return (
                      <Button onClick={(e) => this.setState({categoryChosen: category})} style={{fontSize: '1em', backgroundColor: this.state.categoryChosen === category ? yellow : blue, color: 'white', borderRadius: 50, marginRight: '0.5%', marginLeft: '0.5%', marginBottom: '0.5%'}}>{category}</Button>
                    )
                  })}
                  <Button onClick={(e) => this.setState({categoryChosen: 'all'})} style={{fontSize: '1em', backgroundColor: this.state.categoryChosen === 'all' ? yellow : blue, color: 'white', borderRadius: 50, marginRight: '0.5%', marginLeft: '0.5%', marginBottom: '0.5%'}}>הכל</Button>

                </div>
              </div>

              {this.state.templates.map((template) => {
                if (this.state.categoryChosen === 'all' || this.state.categoryChosen === '') {
                  if (this.state.templateChosen === template._id.toString()) {
                    return (
                      <Grid item xs={6} style={{textAlign: 'center'}}>
                        <p>{template.name}</p>
                        <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={6} style={{textAlign: 'center'}} onClick={(e) => this.areYouSure(template._id)}>
                        <p>{template.name}</p>
                        <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', }} />
                      </Grid>
                    );
                  }
                } else {
                  if (this.state.categoryChosen === template.category) {
                    if (this.state.templateChosen === template._id.toString()) {
                      return (
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                          <p>{template.name}</p>
                          <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid item xs={6} style={{textAlign: 'center'}} onClick={(e) => this.areYouSure(template._id)}>
                          <p>{template.name}</p>
                          <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', }} />
                        </Grid>
                      );
                    }
                  }
                }

              })}

              <Snackbar open={this.state.changeTemplateModalSuccess} autoHideDuration={6000} onClose={(e) => this.setState({changeTemplateModalSuccess: false})}>
                <Alert variant="filled" severity="success" style={{fontSize: '1.5em'}}>!התבנית שלך התעדכנה</Alert>
              </Snackbar>

              <Grid item xs={12}>
                <div>
                  <center><p style={{direction:'rtl', color: blue, fontSize: '1em', fontWeight: 100}}>
                    כל הזכויות שמורות לדיביט בע"מ, 2020
                  </p></center>
                </div>
              </Grid>
            </Grid>
            </MobileView>
          </React.Fragment>
        )}

      </div>
    );
  }
}

export default EditCard;
