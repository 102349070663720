import React, { Component } from 'react';
import '../../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {Helmet} from 'react-helmet';
import { SliderPicker, ChromePicker } from 'react-color'

import {
  BrowserView,
  MobileView,
  isMobile
} from "react-device-detect";

import Switch from '@material-ui/core/Switch';
import LinkIcon from '@material-ui/icons/Link';
import OfflineHeader from '../../components/OfflineHeader';
import OfflineHeaderLogin from '../../components/OfflineHeaderLogin';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import TimelineIcon from '@material-ui/icons/Timeline';
import GoogleLogin from 'react-google-login';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Chart } from 'react-charts'

import {
  Redirect
} from 'react-router-dom'

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';


class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: {},
      package: {},
      trialEnded: false,
      trialUntil: 0,
      slug: '',
      loading: false
    };
  }

  componentDidMount () {
    this.getData();
  }

  getData = () => {
    this.setState({loading: true});
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'profile', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          if (response.data.data.card.slug === null || response.data.data.card.slug === undefined || response.data.data.card.slug === '') {
            window.location.href='/signup'
          }
          // //console.log(response);
          if (response.data.status === "ok") {
            this.setState({
              package: response.data.data.package,
              trialUntil: response.data.data.trialUntil,
              slug: response.data.data.card.slug,
              firstName: response.data.data.profile.firstName,
              lastName: response.data.data.profile.lastName,
              card: response.data.data.card,
              package: response.data.data.package,
              trialEnded: response.data.data.trialEnded,

            }, () => {
              this.setState({loading: false})
            });
          } else {
            this.setState({loading: false})
          }
        }).catch(err => {
          console.log(err);
          this.setState({loading: false});
        });
      } catch (err) {
        console.log(err);
        this.setState({loading: false});
      }
    }
  }



    helmet = () => {
      const description = 'דיביט | אנליטיקות';

      return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>דיביט | אנליטיקות</title>
            <link rel="canonical" href={window.location.href} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta property="og:description" content={description} />
            <meta property="og:title" content={description} />
            <meta property="og:site_name" content="דיביט" />
            <meta property="og:see_also" content="דיביט" />
            <meta itemprop="name" content={description} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta name="description" content={description}/>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
      );
    }


  render() {
    return (
      <React.Fragment>
      {this.state.loading ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OfflineHeaderLogin slug={this.state.slug}  package={this.state.package} trialUntil={'0'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{textAlign: 'center', marginTop: '5%'}}>
                <CircularProgress />
                <h3 style={{direction: 'rtl'}}>..טוען</h3>
              </div>
            </center>
          </Grid>
        </Grid>
      ) : (
        <div>
          {this.state.trialEnded && (<Redirect to='/' />)}
          <BrowserView>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OfflineHeader slug={this.state.slug} mobile={isMobile} title={'אנליטיקות'} package={this.state.package} trialUntil={this.state.trialUntil}/>
            </Grid>
            <Grid item xs={12} style={{marginBottom: '2%'}}>
              <div style={{marginRight: '4%'}}>
                <p style={{direction:'rtl', fontSize: '2em', fontWeight: 700, color: yellow, marginBottom: -50}}>
                  אנליטיקות הכרטיס שלי
                </p>
              </div>
            </Grid>

            <Grid item xs={3}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.watches === undefined ? 0 : this.state.card.watches}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                  צפיות בכרטיס שלי
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.whatsappClicks === undefined ? 0 : this.state.card.whatsappClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                  לחצו על הווטצאפ
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.callmeClicks === undefined ? 0 : this.state.card.callmeClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                  התקשרו אליכם
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.instagramClicks === undefined ? 0 : this.state.card.instagramClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                  לחצו על האינסטגרם
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.wazeClicks === undefined ? 0 : this.state.card.wazeClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                  ניווטו לעסק שלך
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.facebookClicks === undefined ? 0 : this.state.card.facebookClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                  לחצו על הפייסבוק
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.websiteClicks === undefined ? 0 : this.state.card.websiteClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                  לחצו על האתר שלכם
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.portfolioClicks === undefined ? 0 : this.state.card.portfolioClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                  לחצו על תיק העבודות שלכם
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={12}>
              <footer>
                <center><p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 100}}>
                  כל הזכויות שמורות לדיביט בע"מ, 2020
                </p></center>
              </footer>
            </Grid>
          </Grid>
          </BrowserView>
          <MobileView>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OfflineHeader slug={this.state.slug}  mobile={isMobile} title={'אנליטיקות'} package={this.state.package} trialUntil={this.state.trialUntil}/>
            </Grid>
            <Grid item xs={6}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2.2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.watches === undefined ? 0 : this.state.card.watches}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.3em', fontWeight: 700, marginBottom: 0}}>
                  צפיות בכרטיס שלי
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2.2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.whatsappClicks === undefined ? 0 : this.state.card.whatsappClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.3em', fontWeight: 700, marginBottom: 0}}>
                  לחצו על הווטצאפ
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2.2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.callmeClicks === undefined ? 0 : this.state.card.callmeClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.3em', fontWeight: 700, marginBottom: 0}}>
                  התקשרו אליכם
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2.2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.instagramClicks === undefined ? 0 : this.state.card.instagramClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.3em', fontWeight: 700, marginBottom: 0}}>
                  לחצו על האינסטגרם
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2.2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.wazeClicks === undefined ? 0 : this.state.card.wazeClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.3em', fontWeight: 700, marginBottom: 0}}>
                  ניווטו לעסק שלך
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2.2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.facebookClicks === undefined ? 0 : this.state.card.facebookClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.3em', fontWeight: 700, marginBottom: 0}}>
                  לחצו על הפייסבוק
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2.2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.websiteClicks === undefined ? 0 : this.state.card.websiteClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.3em', fontWeight: 700, marginBottom: 0}}>
                  לחצו על האתר
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <center>
                <p style={{direction:'rtl', color: blue, fontSize: '2.2em', fontWeight: 700, marginBottom: 0}}>
                  {this.state.card.portfolioClicks === undefined ? 0 : this.state.card.portfolioClicks}
                </p>
                <Divider style={{backgroundColor: yellow, width: '50%'}}/>
                <p style={{direction:'rtl', color: blue, fontSize: '1.3em', fontWeight: 700, marginBottom: 0}}>
                  לחצו על תיק העבודות
                </p>
                </center>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <center><p style={{direction:'rtl', color: blue, fontSize: '1em', fontWeight: 100}}>
                  כל הזכויות שמורות לדיביט בע"מ, 2020
                </p></center>
              </div>
            </Grid>
          </Grid>
          </MobileView>
        </div>
      )}

      </React.Fragment>
    );
  }
}

export default Analytics;
