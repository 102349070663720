import React, { Component } from 'react';
import '../../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';
import LinkIcon from '@material-ui/icons/Link';
import OfflineHeader from '../../components/OfflineHeader';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import TimelineIcon from '@material-ui/icons/Timeline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import GoogleLogin from 'react-google-login';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Chart } from 'react-charts'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CancelIcon from '@material-ui/icons/Cancel';
import ImageGallery from 'react-image-gallery';
import {Helmet} from 'react-helmet';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import {
  BrowserView,
  MobileView,
  isMobile
} from "react-device-detect";
import {
 Link,
 Redirect
} from "react-router-dom";
import ImageUploader from "react-images-upload";
import "react-image-gallery/styles/css/image-gallery.css";

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';


class Gallery extends Component {
  componentDidMount () {
    this.getMyCard();
    this.getPackage();
    this.getGalleries();
  }


  constructor(props) {
    super(props);
    this.state = {
      pictures: [],
      gallery: [
        {
          original: 'https://picsum.photos/id/1018/1000/600/',
          thumbnail: 'https://picsum.photos/id/1018/250/150/',
        },
        {
          original: 'https://picsum.photos/id/1015/1000/600/',
          thumbnail: 'https://picsum.photos/id/1015/250/150/',
        },
        {
          original: 'https://picsum.photos/id/1019/1000/600/',
          thumbnail: 'https://picsum.photos/id/1019/250/150/',
        },
      ],
      site_gallery: [],
      site_original_gallery: [],
      site_search_gallery: '',
      gallerySelectedImage: '',
      galleryModal: false,
      deleteImages: false,
      package: {},
      trialEnded: false,
      trialUntil: 0,
      slug: ''
    };
  }

  getPackage = () => {
    this.setState({loading: true});
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'profile', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          if (response.data.data.card.slug === null || response.data.data.card.slug === undefined || response.data.data.card.slug === '') {
            window.location.href='/signup'
          }
          //console.log(response);
          this.setState({loading: false});
          if (response.data.status === "ok") {
            this.setState({
              package: response.data.data.package,
              trialEnded: response.data.data.trialEnded,
              trialUntil: response.data.data.trialUntil,
              slug: response.data.data.card.slug
            });
          }
        }).catch(err => {
          console.log(err);
          this.setState({loading: false});
        });
      } catch (err) {
        console.log(err);
        this.setState({loading: false});
      }
    }
  }

  searchGallery = () => {
    let gallery = this.state.site_gallery;
    let original_gallery = this.state.site_original_gallery;
    if (original_gallery.length === 0) this.setState({site_original_gallery: gallery});

    let query = this.state.site_search_gallery;

    let output = [];
    gallery.forEach((item, i) => {
      if (item.tagEnglish.includes(query) || item.name.includes(query)) output.push(item);
    });

    if (output.length >= 1) {
      this.setState({site_gallery: output});
    }
  }

  resetGallery = () => {
    let original_gallery = this.state.site_original_gallery;
    if (original_gallery.length >= 1) {
      this.setState({site_gallery: original_gallery});
    }
  }

  getGalleries = () => {
    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'galleries', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          this.setState({site_gallery: response.data.data.gallery, site_original_gallery: response.data.data.gallery});
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
          console.log(err);
      }
    }
  }

  getMyCard = () => {
    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'card', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          if (response.data.status === "ok") {
            this.setState({
              gallery: response.data.data.card.gallery,
            }, () => {
              let g = this.state.gallery;
              let o = [];

              g.forEach((item, i) => {
                o.push({
                  original: 'https://app.dibit.co.il/gallery/'+item,
                  thumbnail: 'https://app.dibit.co.il/gallery/'+item,
                  sizes: '250x250'
                })
              });

              this.setState({gallery: o});
            });
          }
        }).catch(err => {
          this.setState({loading: true});
          console.log(err);
          alert('שגיאה בקבלת נתוני הכרטיס, אנא נסו שוב');
        });
      } catch (err) {
        this.setState({loading: true});
        console.log(err);
        alert('שגיאה בקבלת נתוני הכרטיס, אנא נסו שוב');
      }
    }
  }

  onDrop = (pictureFiles, pictureDataURLs) => {
      this.setState({
          pictures: pictureFiles
      });
  }

  updateImageFromGallery = () => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      axios.put(API + 'card/gallery-from-gallery?id='+this.state.gallerySelectedImage, {}, {
        headers: {
           Authorization: `Bearer ${token}`
        }
      })
          .then((response) => {
            // this.setState({pictures: []})
            // window.location.reload();
            this.setState({galleryModal: false, gallerySelectedImage: '', site_search_gallery: ''}, () => {
              this.getMyCard();
            });
          }).catch((error) => {
            // this.setState({pictures: []})
      });
    }
  }

  uploadGalleryFiles = () => {
    if (this.state.pictures.length === 0) {
      alert('אנא בחרו תמונות');
    } else {
      this.setState({loadingImage: true});
      this.state.pictures.forEach((file, i) => {
        console.log(file);
        let formData = new FormData();
        formData.append('file',file);
        let token = cookies.get('__dato__');

        if (token !== null && token !== undefined && token !== '') {

          const config = {
              headers: {
                  'content-type': 'multipart/form-data',
                  Authorization: `Bearer ${token}`
              }
          };
          axios.put(API + 'card/gallery-files',formData,config)
              .then((response) => {
                // this.setState({pictures: []})
                // window.location.reload();
                if (i + 1 === this.state.pictures.length) {
                  this.setState({loadingImage: false});
                  window.location.reload();
                }
              }).catch((error) => {
                // this.setState({pictures: []})
                this.setState({loadingImage: false});
          });
        }
      });

      // this.setState({loadingImage: false});
    }
  }

  removeImage = (image) => {
    let i = image.replace('https://app.dibit.co.il/gallery/', '').trim();

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      axios.put(API + 'card/gallery-remove?image='+i, {}, {
        headers: {
           Authorization: `Bearer ${token}`
        }
      })
          .then((response) => {
            // this.setState({pictures: []})
            // window.location.reload();
            this.getMyCard();
          }).catch((error) => {
            // this.setState({pictures: []})
      });
    }
  }


    helmet = () => {
      const description = 'דיביט | גלרייה';

      return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>דיביט | גלרייה</title>
            <link rel="canonical" href={window.location.href} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta property="og:description" content={description} />
            <meta property="og:title" content={description} />
            <meta property="og:site_name" content="דיביט" />
            <meta property="og:see_also" content="דיביט" />
            <meta itemprop="name" content={description} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta name="description" content={description}/>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
      );
    }


  render() {
    return (
      <div>
        {this.helmet()}

        {this.state.trialEnded && (<Redirect to='/' />)}

        <BrowserView>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OfflineHeader slug={this.state.slug}  mobile={isMobile} title={'גלרייה'} package={this.state.package} trialUntil={this.state.trialUntil}/>
          </Grid>

          <Grid item xs={12} style={{marginBottom: '2%', textAlign: 'center'}}>
            <center>

            {this.state.deleteImages ? (
              <Button onClick={(e) => this.setState({deleteImages: false})} style={{color: 'white', backgroundColor: blue, fontSize: '1.2em', width: '30%'}}>
                <DeleteForeverIcon style={{width: 32, height: 32, color: 'white'}}/>  סגירת המחיקה
              </Button>
            ) : (
              <Button onClick={(e) => this.setState({deleteImages: true})} style={{color: 'white', backgroundColor: 'red', fontSize: '1.2em', width: '30%'}}>
                <DeleteForeverIcon style={{width: 32, height: 32, color: 'white'}}/>  מחיקת תמונות
              </Button>
            )}


            {this.state.deleteImages && (
              <React.Fragment>
                <br />
                <p style={{color: yellow, fontSize: '1.6em'}}>לחץ על התמונה על מנת להסירה מהגלרייה</p>
                <div style={{width: '100%'}}>
                  {this.state.gallery.map((image, i) => {
                    if (i % 4 === 0 && i !== 0) {
                      return (
                        <React.Fragment>
                          <img src={image.original} style={{display: 'inline-block', width: 150, height: 150, objectFit: 'cover', marginLeft: '1%', marginRight: '1%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}} onClick={(e) => this.removeImage(image.original)}/>
                          <br /><br />
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <img src={image.original} style={{display: 'inline-block', width: 150, height: 150, objectFit: 'cover', marginLeft: '1%', marginRight: '1%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}} onClick={(e) => this.removeImage(image.original)}/>
                      );
                    }
                  })}
                </div>
              </React.Fragment>
            )}

            {this.state.loadingImage && (
              <React.Fragment>
                <center>
                  <p>מעלה את התמונה/ות, אנא המתן</p>
                  <CircularProgress />
                </center>
              </React.Fragment>
            )}

            <br />
            <p style={{fontSize: '1.7em', color: blue}}>העלאת תמונות חדשות</p>
            <div style={{width: '70%', textAlign: 'center'}}>
              <ImageUploader
                    withIcon={true}
                    buttonText='בחר תמונות לגלרייה'
                    onChange={this.onDrop}
                    imgExtension={['.jpg', '.jpeg', '.png', '.JPG', '.PNG', '.JPEG']}
                    maxFileSize={10042880}
                    withPreview={true}
                    fileSizeError={'התמונה גדולה מדי, אנא בחר תמונה קטנה יותר'}
                    fileTypeError={'סוג קובץ זה אינו נתמך, אנא בחר תמונה סטנדרטית'}
                    withLabel={false}
                />
            </div>

            <Button onClick={(e) => this.uploadGalleryFiles()} style={{color: blue, backgroundColor: yellow, fontSize: '1.3em', width: '70%'}}>
              העלאת התמונות שנבחרו
            </Button>
            <p style={{fontSize: '1.4em', color: blue}}>- או -</p>
            <Button onClick={(e) => this.setState({galleryModal: true})} style={{color: 'white', backgroundColor: blue, fontSize: '1.3em', width: '70%'}}>
              בחר תמונה מהגלרייה המדהימה שלנו
            </Button>

            <Modal
              open={this.state.galleryModal}
              onClose={(e) => this.setState({galleryModal: false})}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: isMobile ? 'auto' : 500,
                minHeight: isMobile ? 'auto' : 400
              }}
            >
            <div style={{
              backgroundColor: 'white',
              border: '2px solid white',
              padding: '5px 8px 6px',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
              minWidth: isMobile ? 'auto' : 500,
              minHeight: isMobile ? 'auto' : 400
            }}>
              <div style={{textAlign: 'right'}}>
                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700,}}>
                בחרו תמונה מהגלרייה המדהימה שלנו
                </p>

                <div>
                  <p>חפש תמונה על פי מילה המגדירה אותה</p>

                  <button onClick={(e) => this.resetGallery()} style={{backgroundColor: 'white', color: blue, border: '1px solid ' + blue, fontSize: '1.5em'}}>איפוס</button>
                  &nbsp;
                  <button onClick={(e) => this.searchGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>חפש</button>
                  &nbsp;
                  <TextField
                    value={this.state.site_search_gallery}
                    onChange={(e) => this.setState({site_search_gallery: e.target.value})}
                    style={{width: '70%',
                    direction: 'rtl',
                    textAlign: 'right',}}
                    inputProps={{
                      style: {
                        direction: 'rtl',
                        textAlign: 'right',
                      }
                    }}
                  />
                  <br />
                  <br />
                </div>
              </div>
              <Card style={{minWidth: '100%', height: 350, overflowY: 'auto'}}>
              {this.state.site_gallery.map((image, i) => {
                if (this.state.gallerySelectedImage !== '') {
                  if (i % 3 !== 0 || i === 0) {
                    if (image._id.toString() === this.state.gallerySelectedImage) {
                      return (
                        <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                      )
                    } else {
                      return (
                        <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                      )
                    }
                  } else {
                    if (image._id.toString() === this.state.gallerySelectedImage) {
                      return (
                        <React.Fragment>
                          <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                          <br />
                        </React.Fragment>
                      )
                    } else {
                      return (
                        <React.Fragment>
                          <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                          <br />
                        </React.Fragment>
                      )
                    }
                  }
                } else {
                  if (i % 3 !== 0 || i === 0) {
                    if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                      return (
                        <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                      )
                    } else {
                      return (
                        <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                      )
                    }
                  } else {
                    if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                      return (
                        <React.Fragment>
                          <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                          <br />
                        </React.Fragment>
                      )
                    } else {
                      return (
                        <React.Fragment>
                          <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                          <br />
                        </React.Fragment>
                      )
                    }
                  }
                }
              })}
              </Card>
              <br />
              <div style={{textAlign: 'right'}}>
                <button onClick={(e) => this.updateImageFromGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>בחר ועדכן</button>
              </div>
              </div>
            </Modal>


            <center>
            <p style={{fontSize: '1.7em', color: blue}}>תצוגת הגלרייה שלי</p>

            {this.state.gallery.length === 0 && (
              <p style={{fontSize: '1.2em', color: blue, marginTop: 0}}>אין תמונות בגלרייה שלך</p>
            )}

            <div style={{maxWidth: '40%', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
              <div style={{textAlign: 'center'}}><ImageGallery
                items={this.state.gallery}
                lazyLoad={true}
                showThumbnails={false}
                useTranslate3D={false}
                showPlayButton={false}
                showIndex={false}
                showFullscreenButton={false}
                autoPlay={true}
                style={{width: '40%'}}
              /></div>
            </div>
            </center>

            </center>
          </Grid>
          <Grid item xs={12}>
            <div>
              <center><p style={{direction:'rtl', color: blue, fontSize: '1.1em', fontWeight: 100}}>
                כל הזכויות שמורות לדיביט בע"מ, 2020
              </p></center>
            </div>
          </Grid>
        </Grid>
        </BrowserView>

        <MobileView>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OfflineHeader slug={this.state.slug} mobile={isMobile} title={'הגלרייה שלי'}  package={this.state.package} trialUntil={this.state.trialUntil}/>
          </Grid>

          <Grid item xs={12} style={{marginBottom: '2%'}}>
          <center>
          {this.state.deleteImages ? (
            <Button onClick={(e) => this.setState({deleteImages: false})} style={{color: 'white', backgroundColor: blue, fontSize: '1.3em', width: '90%'}}>
              <DeleteForeverIcon style={{width: 32, height: 32, color: 'white'}}/>  סגירת המחיקה
            </Button>
          ) : (
            <Button onClick={(e) => this.setState({deleteImages: true})} style={{color: 'white', backgroundColor: 'red', fontSize: '1.3em', width: '90%'}}>
              <DeleteForeverIcon style={{width: 32, height: 32, color: 'white'}}/>  מחיקת תמונות
            </Button>
          )}


          {this.state.deleteImages && (
            <React.Fragment>
              <br />
              <p style={{color: yellow, fontSize: '1.3em'}}>לחץ על התמונה על מנת להסירה מהגלרייה</p>
              <div style={{width: '100%'}}>
                {this.state.gallery.map((image, i) => {
                  if (i % 2 === 0) {
                    return (
                      <React.Fragment>
                        <img src={image.original} style={{display: 'inline-block', width: 150, height: 150, objectFit: 'cover', marginLeft: '1%', marginRight: '1%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}} onClick={(e) => this.removeImage(image.original)}/>
                        <br /><br />
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <img src={image.original} style={{display: 'inline-block', width: 150, height: 150, objectFit: 'cover', marginLeft: '1%', marginRight: '1%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}} onClick={(e) => this.removeImage(image.original)}/>
                    );
                  }
                })}
              </div>
            </React.Fragment>
          )}

          {this.state.loadingImage && (
            <React.Fragment>
              <center>
                <p>מעלה את התמונה/ות, אנא המתן</p>
                <CircularProgress />
              </center>
            </React.Fragment>
          )}

          <br />
          <p style={{fontSize: '1.7em', color: blue}}>העלאת תמונות חדשות</p>
          <div style={{width: '90%', textAlign: 'center'}}>
            <ImageUploader
                  withIcon={true}
                  buttonText='בחר תמונות לגלרייה'
                  onChange={this.onDrop}
                  imgExtension={['.jpg', '.jpeg', '.png', '.JPG', '.PNG', '.JPEG']}
                  maxFileSize={10042880}
                  withPreview={true}
                  fileSizeError={'התמונה גדולה מדי, אנא בחר תמונה קטנה יותר'}
                  fileTypeError={'סוג קובץ זה אינו נתמך, אנא בחר תמונה סטנדרטית'}
                  withLabel={false}
              />
          </div>

          <Button onClick={(e) => this.uploadGalleryFiles()} style={{color: blue, backgroundColor: yellow, fontSize: '1.5em', width: '90%'}}>
            העלאת התמונות שנבחרו
          </Button>
          <p style={{fontSize: '1.3em', color: blue}}>- או -</p>
          <Button onClick={(e) => this.setState({galleryModal: true})} style={{color: 'white', backgroundColor: blue, fontSize: '1.3em', width: '90%'}}>
            בחר תמונה מהגלרייה המדהימה שלנו
          </Button>

          <Modal
            open={this.state.galleryModal}
            onClose={(e) => this.setState({galleryModal: false})}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '100%'
            }}
            contentStyle={{maxWidth: '100%'}}
          >
              <div style={{backgroundColor: 'white', width: '95%'}}>
              <div style={{textAlign: 'right', backgroundColor: 'white'}}>
                <p style={{direction:'rtl', color: blue, fontSize: '1.3em', fontWeight: 700, color: yellow}}>
                בחרו תמונה מהגלרייה המדהימה שלנו
                </p>

                <div>
                  <p style={{fontSize:'1.3em', marginBottom: 0}}>חפש תמונה על פי מילה המגדירה אותה</p>

                  <TextField
                    value={this.state.site_search_gallery}
                    onChange={(e) => this.setState({site_search_gallery: e.target.value})}
                    style={{
                      width: '95%',
                      direction: 'rtl',
                      textAlign: 'right',
                      color: blue,
                      backgroundColor: 'white',
                      marginRight: '1%'
                    }}
                    inputProps={{
                      style: {
                        direction: 'rtl',
                        textAlign: 'right',
                        fontSize: '1.3em',
                        color: blue,
                        backgroundColor: 'white'
                      }
                    }}
                    placeholder={'חפשו מילות מפתח'}
                  />
                  <br /><br />
                  <button onClick={(e) => this.resetGallery()} style={{width: '45%', backgroundColor: 'white', color: blue, border: '1px solid ' + blue, fontSize: '1.3em'}}>איפוס</button>
                  &nbsp;
                  <button onClick={(e) => this.searchGallery()} style={{width: '45%', backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.3em'}}>חפש</button>
                  &nbsp;

                  <br />
                  <br />
                </div>
              </div>
              <Card style={{minWidth: '100%', height: 350, overflowY: 'auto', overflowX: 'auto'}}>
              {this.state.site_gallery.map((image, i) => {
                if (this.state.gallerySelectedImage !== '') {
                  if (i % 2 !== 0 || i === 0) {
                    if (image._id.toString() === this.state.gallerySelectedImage) {
                      return (
                        <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                      )
                    } else {
                      return (
                        <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                      )
                    }
                  } else {
                    if (image._id.toString() === this.state.gallerySelectedImage) {
                      return (
                        <React.Fragment>
                          <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                          <br />
                        </React.Fragment>
                      )
                    } else {
                      return (
                        <React.Fragment>
                          <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                          <br />
                        </React.Fragment>
                      )
                    }
                  }
                } else {
                  if (i % 2 !== 0 || i === 0) {
                    if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                      return (
                        <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                      )
                    } else {
                      return (
                        <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                      )
                    }
                  } else {
                    if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                      return (
                        <React.Fragment>
                          <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                          <br />
                        </React.Fragment>
                      )
                    } else {
                      return (
                        <React.Fragment>
                          <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                          <br />
                        </React.Fragment>
                      )
                    }
                  }
                }
              })}
              </Card>
              <br />
              <div style={{textAlign: 'right'}}>
                <button onClick={(e) => this.updateImageFromGallery()} style={{width: '100%', backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>בחר ועדכן</button>
              </div>
              <br /><br />
              </div>
          </Modal>

          {/*
            <Modal
              open={this.state.galleryModal}
              onClose={(e) => this.setState({galleryModal: false})}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: isMobile ? 'auto' : 500,
                minHeight: isMobile ? 'auto' : 400
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '90%'
              }}
              contentStyle={{maxWidth: '90%'}}
            >
            <div style={{
              backgroundColor: 'white',
              border: '2px solid white',
              padding: '5px 8px 6px',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
              minWidth: isMobile ? 'auto' : 500,
              minHeight: isMobile ? 'auto' : 400
            }}>
              <div style={{textAlign: 'right'}}>
                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700,}}>
                בחרו תמונה מהגלרייה המדהימה שלנו
                </p>

                <div>
                  <p>חפש תמונה על פי מילה המגדירה אותה</p>

                  <button onClick={(e) => this.resetGallery()} style={{backgroundColor: 'white', color: blue, border: '1px solid ' + blue, fontSize: '1.5em'}}>איפוס</button>
                  &nbsp;
                  <button onClick={(e) => this.searchGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>חפש</button>
                  &nbsp;
                  <TextField
                    value={this.state.site_search_gallery}
                    onChange={(e) => this.setState({site_search_gallery: e.target.value})}
                    style={{width: '70%',
                    direction: 'rtl',
                    textAlign: 'right',}}
                    inputProps={{
                      style: {
                        direction: 'rtl',
                        textAlign: 'right',
                      }
                    }}
                  />
                  <br />
                  <br />
                </div>
              </div>
              <Card style={{minWidth: '100%', height: 350, overflowY: 'auto'}}>
              {this.state.site_gallery.map((image, i) => {
                if (this.state.gallerySelectedImage !== '') {
                  if (i % 3 !== 0 || i === 0) {
                    if (image._id.toString() === this.state.gallerySelectedImage) {
                      return (
                        <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                      )
                    } else {
                      return (
                        <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                      )
                    }
                  } else {
                    if (image._id.toString() === this.state.gallerySelectedImage) {
                      return (
                        <React.Fragment>
                          <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                          <br />
                        </React.Fragment>
                      )
                    } else {
                      return (
                        <React.Fragment>
                          <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                          <br />
                        </React.Fragment>
                      )
                    }
                  }
                } else {
                  if (i % 3 !== 0 || i === 0) {
                    if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                      return (
                        <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                      )
                    } else {
                      return (
                        <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                      )
                    }
                  } else {
                    if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                      return (
                        <React.Fragment>
                          <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                          <br />
                        </React.Fragment>
                      )
                    } else {
                      return (
                        <React.Fragment>
                          <img onClick={(e) => this.setState({gallerySelectedImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                          <br />
                        </React.Fragment>
                      )
                    }
                  }
                }
              })}
              </Card>
              <br />
              <div style={{textAlign: 'right'}}>
                <button onClick={(e) => this.updateImageFromGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>בחר ועדכן</button>
              </div>
              </div>
            </Modal>
            */}


          <center>
          <p style={{fontSize: '1.7em', color: blue}}>תצוגת הגלרייה שלי</p>

          {this.state.gallery.length === 0 && (
            <p style={{fontSize: '1.4em', color: blue}}>אין תמונות בגלרייה שלכם</p>
          )}

          <div style={{maxWidth: '90%', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{textAlign: 'center'}}><ImageGallery
              items={this.state.gallery}
              lazyLoad={true}
              showThumbnails={false}
              useTranslate3D={false}
              showPlayButton={false}
              showIndex={false}
              showFullscreenButton={false}
              autoPlay={true}
              style={{width: '90%'}}
            /></div>
          </div>
          </center>
          </center>
          </Grid>
          <Grid item xs={12}>
            <div>
              <center><p style={{direction:'rtl', color: blue, fontSize: '1.4em', fontWeight: 100}}>
                כל הזכויות שמורות לדיביט בע"מ, 2020
              </p></center>
            </div>
          </Grid>
        </Grid>
        </MobileView>
      </div>
    );
  }
}

export default Gallery;
