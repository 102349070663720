import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {
 BrowserRouter as Router,
 Switch,
 Route,
 Link
} from "react-router-dom";

// components
import Loading from './components/Loading';

// pages:
// -----

// global
import Login from './pages/Login';
import LoginPWD from './pages/LoginPWD';
import GlobalSignup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import ContactUs from './pages/ContactUs';

// user
import Dashboard from './pages/user/Dashboard';
import Analytics from './pages/user/Analytics';
import EditCard from './pages/user/EditCard';
import Messages from './pages/user/Messages';
import MessagesReplyed from './pages/user/MessagesReplyed';
import Settings from './pages/user/Settings';
import NewCard from './pages/user/NewCard';
import Signup from './pages/user/Signup';
import Packages from './pages/user/Packages';
import Templates from './pages/user/Templates';
import Gallery from './pages/user/Gallery';
import Payments from './pages/user/Payments';


import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      loading: false,
      signup: false
    };
  }

  componentDidMount() {
    this.validate();
  }

  setConnectionStatus = (status = 'not_connected', signup = false) => {
    this.setState({loading: true});
    if (status === 'connected') {
      this.setState({connected: true, loading: false, signup: signup});
    } else {
      this.setState({connected: false, loading: false, signup: signup})
    }
  }

  validate = () => {
    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'validate', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          // //console.log(response);
          if (response.data.status === "ok") {
            this.setConnectionStatus('connected', response.data.signup);
          } else {
            this.setConnectionStatus();
          }
        }).catch(err => {
          console.log(err);
          this.setConnectionStatus();
        });
      } catch (err) {
        console.log(err);
        this.setConnectionStatus();
      }
    } else {
      this.setState({loading: false})
    }
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div>
            {this.state.connected ? (
              <div>
                {this.state.signup ? (
                  <Router>
                    <Switch>
                      <Route path="/">
                        <Signup />
                      </Route>
                    </Switch>
                  </Router>
                ) : (
                  <Router>
                    <Switch>
                      <Route exact path="/payments">
                        <Payments />
                      </Route>
                      <Route exact path="/contact-us">
                        <ContactUs />
                      </Route>
                      <Route exact path="/gallery">
                        <Gallery />
                      </Route>
                      <Route exact path="/templates">
                        <Templates />
                      </Route>
                      <Route exact path="/packages">
                        <Packages />
                      </Route>
                      <Route exact path="/messages">
                        <Messages />
                      </Route>
                      <Route exact path="/messages/history">
                        <MessagesReplyed />
                      </Route>
                      <Route exact path="/card/edit">
                        <EditCard />
                      </Route>
                      <Route path="/analytics">
                        <Analytics />
                      </Route>
                      <Route path="/settings">
                        <Settings />
                      </Route>
                      <Route path="/signup">
                        <Signup />
                      </Route>
                      <Route path="/su">
                        <Signup />
                      </Route>
                      <Route path="/">
                        <Dashboard />
                      </Route>
                    </Switch>
                  </Router>
                )}
              </div>
            ) : (
              <Router>
                <Switch>
                  <Route exact path="/contact-us">
                    <ContactUs />
                  </Route>
                  <Route exact path="/forgot-password">
                    <ForgotPassword />
                  </Route>
                  <Route exact path="/signup">
                    <GlobalSignup />
                  </Route>
                  <Route exact path="/e-sign">
                    <LoginPWD />
                  </Route>
                  <Route path="/">
                    <Login />
                  </Route>
                </Switch>
              </Router>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default App;
