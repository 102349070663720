import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import {
 Link
} from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import Cookies from 'universal-cookie';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import {
  isMobile,
  MobileView,
  BrowserView
} from "react-device-detect";

// Drawer
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
// Drawer
import CloseIcon from '@material-ui/icons/Close';

const cookies = new Cookies();

const blue = '#07132D';
const yellow = '#FEBE36';

class OfflineHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerIsOpen: false
    };
  }

  logout = () => {
    cookies.remove('__dato__');
    cookies.remove('__dato__', { path: '/' });

    alert('התנתק בהצלחה, מקווים לראותכם שוב בקרוב!');

    window.location.reload();
  }

  handleDrawerOpen = () => {
    this.setState({ drawerIsOpen: true });
  };

  closeDrawer = () => {
    this.setState({ drawerIsOpen: false });
  };

  render() {
    return (
      <React.Fragment>
      <MobileView>
      <AppBar position="static" style={{backgroundColor: blue}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>


          {((!this.props.mobile || this.props.mobile === undefined || this.props.mobile === null) && (this.props.logged !== false)) ? (
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}>
              {this.props.logged !== false && (
                <React.Fragment>
                  <Link onClick={(e) => this.logout()} style={{textDecoration: 'none', color: 'white', marginTop: '2.5%'}}><p style={{}}>התנתקות</p></Link>&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to='/contact-us' target='_blank' style={{textDecoration: 'none', color: 'white', marginTop: '2.5%'}}><p style={{}}>צור קשר</p></Link>&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to='/analytics' style={{textDecoration: 'none', color: 'white', marginTop: '2.5%'}}><p style={{}}>סטטיסטיקות</p></Link>&nbsp;&nbsp;&nbsp;&nbsp;
                  {this.props.package === undefined ? (
                    <p style={{color: 'grey'}}>הודעות</p>
                  ) : (
                    <React.Fragment>
                    {this.props.package.messages ? (
                      <Link to='/messages' style={{textDecoration: 'none', color: 'white', marginTop: '2.5%'}}><p style={{}}>הודעות</p></Link>
                    ) : (
                      <p style={{color: 'grey'}}>הודעות</p>
                    )}
                    </React.Fragment>
                  )}&nbsp;&nbsp;&nbsp;&nbsp;
                  {this.props.package === undefined ? (
                      <p style={{color: 'grey'}}>עריכת כרטיס</p>
                  ) : (
                    <React.Fragment>
                    {this.props.package.editCard ? (
                      <Link to='/card/edit' style={{textDecoration: 'none', color: 'white', marginTop: '2.5%'}}><p style={{}}>עריכת כרטיס</p></Link>
                    ) : (
                      <p style={{color: 'grey'}}>עריכת כרטיס</p>
                    )}
                    </React.Fragment>
                  )}&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to='/' style={{textDecoration: 'none', color: 'white', marginTop: '2.5%'}}><p style={{}}>ניהול ראשי</p></Link>&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to='/'><Button raised color="accent">
                    <img src={'https://app.dibit.co.il/icon.png'} style={{width: 50}}/>
                  </Button></Link>
                </React.Fragment>
              )}

            </div>
          ) : (
            <div>
              <Link to='/'><Button raised color="accent">
                <img src={'https://app.dibit.co.il/icon.png'} style={{width: 50}}/>
              </Button></Link>
            </div>
          )}

          {(this.props.title !== '' && this.props.title !== undefined && this.props.title !== null) && (
            <span style={{fontSize: '1.35em'}}>{this.props.title}</span>
          )}

          {(this.props.logged !== false && this.props.mobile) && (
            <div>
                <React.Fragment>
                  <Link to='/messages'><IconButton>
                    <NotificationsNoneIcon style={{width: 22, height: 22, color: 'white'}}/>
                  </IconButton></Link>
                  <IconButton>
                    <MenuIcon style={{width: 25, height: 25, color: 'white', marginRight: '50%'}} onClick={(e) => this.handleDrawerOpen()}/>
                  </IconButton>
                </React.Fragment>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <React.Fragment>

      {this.state.logged !== false && (
        <Drawer
          open={this.state.drawerIsOpen}
          onClose={() => this.closeDrawer()}
          docked={false}
          onRequestChange={() => this.closeDrawer()}
          anchor={'right'}
        >
          <div style={{
            minWidth: 300
          }}>
            <div style={{textAlign: 'right'}}>
              <CloseIcon style={{marginTop: '5%', textAlign: 'right', marginRight: '5%', width: 35, height: 35}} onClick={(e) => this.closeDrawer()}/>
            </div>
            <div>
              {this.props.package === undefined ? (
                <p style={{
                  fontSize: '1.7em',
                  color: 'grey',
                  textAlign: 'right',
                  marginBottom: 0,
                  marginRight: '5%',
                  marginTop: 0
                }}>עריכת כרטיס</p>
              ) : (
                <React.Fragment>
                {this.props.package.editCard ? (
                  <Link to='/card/edit' style={{textDecoration: 'none'}}><p style={{
                    fontSize: '1.7em',
                    color: blue,
                    textAlign: 'right',
                    marginBottom: 0,
                    marginRight: '5%',
                    marginTop: 0
                  }}>עריכת כרטיס</p></Link>
                ) : (
                  <p style={{
                    fontSize: '1.7em',
                    color: 'grey',
                    textAlign: 'right',
                    marginBottom: 0,
                    marginRight: '5%',
                    marginTop: 0
                  }}>עריכת כרטיס</p>
                )}
                </React.Fragment>
              )}
              <hr style={{backgroundColor: yellow, height: 1, border: '1px solid '+yellow}} />
            </div>
            <div>
            {this.props.package === undefined ? (
              <p style={{
                fontSize: '1.7em',
                color: 'grey',
                textAlign: 'right',
                marginBottom: 0,
                marginRight: '5%',
                marginTop: 0
              }}>תבניות</p>
            ) : (
              <React.Fragment>
              {this.props.package.editCard ? (
                <Link to='/templates' style={{textDecoration: 'none'}}><p style={{
                  fontSize: '1.7em',
                  color: blue,
                  textAlign: 'right',
                  marginBottom: 0,
                  marginRight: '5%',
                  marginTop: 0
                }}>תבניות</p></Link>
              ) : (
                <p style={{
                  fontSize: '1.7em',
                  color: 'grey',
                  textAlign: 'right',
                  marginBottom: 0,
                  marginRight: '5%',
                  marginTop: 0
                }}>תבניות</p>
              )}
              </React.Fragment>
            )}

              <hr style={{backgroundColor: yellow, height: 1, border: '1px solid '+yellow}} />
            </div>
            <div>
              {this.props.package === undefined ? (
                <p style={{
                  fontSize: '1.7em',
                  color: 'grey',
                  textAlign: 'right',
                  marginBottom: 0,
                  marginRight: '5%',
                  marginTop: 0
                }}>גלריית תמונות</p>
              ) : (
                <React.Fragment>
                {this.props.package.editCard ? (
                  <Link to='/gallery' style={{textDecoration: 'none'}}><p style={{
                    fontSize: '1.7em',
                    color: blue,
                    textAlign: 'right',
                    marginBottom: 0,
                    marginRight: '5%',
                    marginTop: 0
                  }}>גלריית תמונות</p></Link>
                ) : (
                  <p style={{
                    fontSize: '1.7em',
                    color: 'grey',
                    textAlign: 'right',
                    marginBottom: 0,
                    marginRight: '5%',
                    marginTop: 0
                  }}>גלריית תמונות</p>
                )}

                </React.Fragment>
              )}
              <hr style={{backgroundColor: yellow, height: 1, border: '1px solid '+yellow}} />
            </div>
            <div>
              {this.props.package === undefined ? (
                <p style={{
                  fontSize: '1.7em',
                  color: 'grey',
                  textAlign: 'right',
                  marginBottom: 0,
                  marginRight: '5%',
                  marginTop: 0
                }}>הודעות</p>
              ) : (
                <React.Fragment>
                {this.props.package.messages ? (
                  <Link to='/messages' style={{textDecoration: 'none'}}><p style={{
                    fontSize: '1.7em',
                    color: blue,
                    textAlign: 'right',
                    marginBottom: 0,
                    marginRight: '5%',
                    marginTop: 0
                  }}>הודעות</p></Link>
                ) : (
                  <p style={{
                    fontSize: '1.7em',
                    color: 'grey',
                    textAlign: 'right',
                    marginBottom: 0,
                    marginRight: '5%',
                    marginTop: 0
                  }}>הודעות</p>
                )}
                </React.Fragment>
              )}

              <hr style={{backgroundColor: yellow, height: 1, border: '1px solid '+yellow}} />
            </div>
            <div>
              <Link to='/analytics' style={{textDecoration: 'none'}}><p style={{
                fontSize: '1.7em',
                color: blue,
                textAlign: 'right',
                marginBottom: 0,
                marginRight: '5%',
                marginTop: 0
              }}>אנליטיקות</p></Link>
              <hr style={{backgroundColor: yellow, height: 1, border: '1px solid '+yellow}} />
            </div>
            <div>
              <Link onClick={(e) => window.open('https://dibit.co.il/'+this.props.slug, '_blank')} style={{textDecoration: 'none'}}><p style={{
                fontSize: '1.7em',
                color: blue,
                textAlign: 'right',
                marginBottom: 0,
                marginRight: '5%',
                marginTop: 0
              }}>כניסה לכרטיס</p></Link>
              <hr style={{backgroundColor: yellow, height: 1, border: '1px solid '+yellow}} />
            </div>
            <div>
              <Link to='/contact-us' style={{textDecoration: 'none'}}><p style={{
                fontSize: '1.7em',
                color: blue,
                textAlign: 'right',
                marginBottom: 0,
                marginRight: '5%',
                marginTop: 0
              }}>יצירת קשר</p></Link>
              <hr style={{backgroundColor: yellow, height: 1, border: '1px solid '+yellow}} />
            </div>
            <div>
              <Link onClick={(e) => this.logout()} style={{textDecoration: 'none'}}><p style={{
                fontSize: '1.7em',
                color: blue,
                textAlign: 'right',
                marginBottom: 0,
                marginRight: '5%',
                marginTop: 0
              }}>התנתקות</p></Link>
              <hr style={{backgroundColor: yellow, height: 1, border: '1px solid '+yellow}} />
            </div>
            {this.props.package._id === '5f8817dec059ec3188f5de86' && (
              <div>
                <br />
                <p style={{fontSize: '1.2em',
                color: blue,
                textAlign: 'center',
                marginBottom: 0,
                marginTop: 0}}>נותרו עוד <span style={{color: yellow, fontSize: '1.4em'}}>{(this.props.trialUntil === undefined || this.props.trialUntil === null || this.props.trialUntil === '') ? '0' : this.props.trialUntil}</span> לתוכנית הנסיון</p>
                <Link to='/packages' style={{textDecoration: 'none'}}><div style={{
                  backgroundColor: blue,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: 'white',
                  padding: 5,
                  fontSize: '1.4em'
                }}>!קנה חבילה</div></Link>
              </div>
            )}
          </div>
        </Drawer>
      )}
      </React.Fragment>
      </MobileView>
      <BrowserView>
      <AppBar position="static" style={{backgroundColor: blue,}}>
        <Toolbar style={{textAlign: 'right',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}>
          {this.state.logged !== false && (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginRight: '3%'}}>
              <Link onClick={(e) => this.logout()} style={{textDecoration: 'none', color: 'white', marginTop: '1.5%', fontSize:'1.2em', paddingRight: '1%'}}><p style={{}}>התנתקות</p></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to='/contact-us' target='_blank' style={{textDecoration: 'none', color: 'white', marginTop: '1.5%', fontSize:'1.2em', paddingRight: '1%'}}><p style={{}}>צור קשר</p></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to='/analytics' style={{textDecoration: 'none', color: 'white', marginTop: '1.5%', fontSize:'1.2em', paddingRight: '1%'}}><p style={{}}>סטטיסטיקות</p></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {this.props.package === undefined ? (
                <Link style={{textDecoration: 'none', color: 'grey', marginTop: '1.5%', fontSize:'1.2em', paddingRight: '1%'}}><p style={{color: 'grey'}}>הודעות</p></Link>
              ) : (
                <React.Fragment>
                {this.props.package.messages ? (<Link to='/messages' style={{textDecoration: 'none', color: 'white', marginTop: '1.5%', fontSize:'1.2em', paddingRight: '1%'}}><p style={{}}>הודעות</p></Link>) : (<Link style={{textDecoration: 'none', color: 'grey', marginTop: '1.5%', fontSize: '1.2em'}}><p style={{color: 'grey'}}>הודעות</p></Link>)}
                </React.Fragment>
              )}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to='/gallery' style={{textDecoration: 'none', color: 'white', marginTop: '1.5%', fontSize:'1.2em', paddingRight: '1%'}}><p style={{}}>גלריה</p></Link>&nbsp;&nbsp;&nbsp;&nbsp;
              {this.props.package.editCard ? (<Link to='/templates' style={{textDecoration: 'none', color: 'white', marginTop: '1.5%', fontSize:'1.2em', paddingRight: '1%'}}><p style={{}}>תבניות</p></Link>) : (<Link style={{textDecoration: 'none', color: 'grey', marginTop: '1.5%', fontSize: '1.2em'}}><p style={{color:'grey'}}>תבניות</p></Link>)}&nbsp;&nbsp;&nbsp;&nbsp;
              {this.props.package.editCard ? (<Link to='/card/edit' style={{textDecoration: 'none', color: 'white', marginTop: '1.5%', fontSize:'1.2em', paddingRight: '1%'}}><p style={{}}>עריכת כרטיס</p></Link>) : (<Link style={{textDecoration: 'none', color: 'grey', marginTop: '1.5%', fontSize: '1.2em'}}><p style={{color:'grey'}}>עריכת כרטיס</p></Link>)}&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to='/' style={{textDecoration: 'none', color: 'white', marginTop: '1.5%', fontSize:'1.2em', paddingRight: '1%'}}><p style={{}}>ניהול ראשי</p></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          )}

          <Link to='/'><Button raised color="accent">
            <img src={'https://app.dibit.co.il/icon.png'} style={{width: 50}}/>
          </Button></Link>
        </Toolbar>
      </AppBar>
      </BrowserView>
      </React.Fragment>
    );
  }
}

export default OfflineHeader;
