import React, { Component } from 'react';
import '../../App.css';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import CircularProgress from '@material-ui/core/CircularProgress';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';

import { SliderPicker, ChromePicker } from 'react-color'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';

import {
 Link,
 Redirect
} from "react-router-dom";

import {
  isMobile,
  BrowserView,
  MobileView,
} from "react-device-detect";

import Switch from '@material-ui/core/Switch';
import LinkIcon from '@material-ui/icons/Link';
import OfflineHeader from '../../components/OfflineHeader';
import OfflineHeaderLogin from '../../components/OfflineHeaderLogin';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import TimelineIcon from '@material-ui/icons/Timeline';
import GoogleLogin from 'react-google-login';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CreateIcon from '@material-ui/icons/Create';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';

import FBLogo from '../../resources/fb_logo.png';
// import InstagramLogo from '../../resources/instagram_logo.png';
// import WhatsappLogo from '../../resources/whatsapp_logo.png';
// import WazeLogo from '../../resources/waze_logo.png';
// import WhatsappSVG from '../../resources/whatsapp.svg';
// import Paperplane from '../../resources/paperplane.png';
// import LinkIconI from '../../resources/broken-link.png';
// import Phone from '../../resources/phone.png';
import InstagramLogo from '../../resources/icons/instagram.svg';
import WhatsappLogo from '../../resources/icons/whatsapp.svg';
import WazeLogo from '../../resources/icons/waze.svg';
import WhatsappSVG from '../../resources/icons/whatsapp.svg';
import Paperplane from '../../resources/icons/send.svg';
import LinkIconI from '../../resources/broken-link.png';
import CallIcon from '../../resources/icons/call.svg';
import WatchIcon from '../../resources/icons/view.svg';
import Trend from '../../resources/icons/trend.svg';
import EditIcon from '../../resources/icons/edit.svg';
import SettingsIcon from '../../resources/settings.svg';
import FacebookLogo from '../../resources/icons/facebook.svg';
import MessageIcon from '../../resources/icons/message.svg';
import WebIcon from '../../resources/icons/web.svg';
import Temp from '../../resources/temp.png';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';
const Parser = require('rss-parser');

class Dashboard extends Component {
    constructor(props) {
      super(props);
      this.state = {
        data: {},
        redirectSignUp: false,
        firstName: '',
        lastName: '',
        loading: false,
        card: {
          completed: true
        },
        package: {
          name: ''
        },
        error: '',

        title: '',
        secondaryTitle: '',
        text: '',
        phoneNumber: '',
        facebookLink: '',
        instagramLink: '',
        address: '',
        businessName: '',
        loading: false,
        button_1: '',
        button_1_icon: '',
        button_1_link: '',
        button_2: '',
        button_2_icon: '',
        button_2_link: '',
        button_3: '',
        button_3_icon: '',
        button_3_link: '',
        button_4: '',
        button_4_icon: '',
        button_4_link: '',
        button_5: '',
        button_5_icon: '',
        button_5_link: '',
        button_6: '',
        button_6_icon: '',
        button_6_link: '',
        button_7: '',
        button_7_icon: '',
        button_7_link: '',
        button_8: '',
        button_8_icon: '',
        button_8_link: '',
        button_9: '',
        button_9_icon: '',
        button_9_link: '',
        buttonShownIndex: 3,
        openingHours: {
          'A': [new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 8:30', new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 19:30'],
          'B': [new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 8:30', new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 19:30'],
          'C': [new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 8:30', new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 19:30'],
          'D': [new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 8:30', new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 19:30'],
          'E': [new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 8:30', new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 19:30'],
          'F': [new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 8:30', new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 19:30'],
          'G': [new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 8:30', new Date(Date.now()).getMonth() + '-' + new Date(Date.now()).getDate() + '-' + new Date(Date.now()).getFullYear() + ' 19:30'],
        },
        open24h: false,
        messages: [],
        close: {
          'A': false,
          'B': false,
          'C': false,
          'D': false,
          'E': false,
          'F': false,
          'G': false
        },
        errortitle: false,
        errorsecondaryTitle: false,
        errortext: false,
        errorphoneNumber: false,
        errorfacebookLink: false,
        errorinstagramLink: false,
        erroraddress: false,
        products: '',
        slug: '',
        templateId: '',
        templates: [],
        trialUntil: 0,
        trialEnded: false,
        rss: []
      };
    }

    componentDidMount() {
      this.getData();
      this.getRSS();
      // this.getMessages();
    }


    getData = () => {
      this.setState({loading: true});
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          return axios.get(API + 'profile', {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {
            if (response.data.data.card.slug === null || response.data.data.card.slug === undefined || response.data.data.card.slug === '') {
              window.location.href='/signup'
            } else {
            //console.log(response);
              if (response.data.status === "ok") {
                this.setState({
                  firstName: response.data.data.profile.firstName,
                  lastName: response.data.data.profile.lastName,
                  card: response.data.data.card,
                  package: response.data.data.package,
                  trialUntil: response.data.data.trialUntil,
                  trialEnded: response.data.data.trialEnded,
                  slug: response.data.data.card.slug
                }, () => {

                  if (this.state.package === '' || this.state.package === undefined || this.state.package === null) {
                    this.setState({package: {
                      name: ''
                    }})
                  }

                  if (this.state.card.slug === null || this.state.card.slug === undefined || this.state.card.slug === '') {
                    this.setState({redirectSignUp: true, loading: false});
                  } else {
                    this.getMessages();
                    this.setState({loading: false})
                  }
                });
              }
            }
          }).catch(err => {
            console.log(err);
            this.setState({loading: false});
          });
        } catch (err) {
          console.log(err);
          this.setState({loading: false});
        }
      }
    }

    getMessages = () => {
      this.setState({loading: true});
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          return axios.get(API + 'messages', {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {
            //console.log(response);
            if (response.data.status === "ok") {
              this.setState({
                messages: response.data.data.messages,
              }, () => {
                let messages = this.state.messages;
                let read_count = 0;
                messages.forEach((item, i) => {
                  if (!item.read) read_count += 1;
                });

                this.setState({messagesRead: read_count}, () => {
                  this.setState({loading: false});
                });
              });
            }
          }).catch(err => {
            console.log(err);
            this.setState({loading: false});
          });
        } catch (err) {
          console.log(err);
          this.setState({loading: false});
        }
      }
    }


      copyText = (text) => {
        function selectElementText(element) {
          if (document.selection) {
            var range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
          } else if (window.getSelection) {
            var range = document.createRange();
            range.selectNode(element);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
          }
        }
        var element = document.createElement('DIV');
        element.textContent = text;
        document.body.appendChild(element);
        selectElementText(element);
        document.execCommand('copy');
        element.remove();
        alert('הקישור הועתק בהצלחה');
      }

      getRSS = async () => {
        axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://dibit.co.il/feed/').then(res => {
          this.setState({rss: res.data.items});
        }).catch(err => {
          console.log(err);
        })
      }


  render() {
    return (
      <div>
        {(this.state.redirectSignUp) && (<Redirect to='/signup' />)}

        {this.state.loading ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OfflineHeader package={this.state.package}/>
            </Grid>
            <Grid item xs={12}>
              <center>
                <div style={{textAlign: 'center', marginTop: '5%'}}>
                  <CircularProgress />
                  <h3 style={{direction: 'rtl', fontWeight: 'bold'}}>טוען..</h3>
                </div>
              </center>
            </Grid>
          </Grid>
        ) : (
          <React.Fragment>
          <BrowserView>
            {this.state.loading ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <OfflineHeader slug={this.state.slug} package={this.state.package} trialUntil={'0'}/>
                </Grid>
                <Grid item xs={12}>
                  <center>
                    <div style={{textAlign: 'center', marginTop: '5%'}}>
                      <CircularProgress />
                      <h3 style={{direction: 'rtl'}}>..טוען</h3>
                    </div>
                  </center>
                </Grid>
              </Grid>
            ) : (
              <React.Fragment>
                {(!this.state.card.completed) && (<Redirect to='/signup' />)}

                  {this.state.trialEnded ? (
                    <OfflineHeaderLogin package={this.state.package} logged={!this.state.trialEnded}  trialUntil={this.state.trialUntil} />
                  ) : (
                    <OfflineHeader slug={this.state.slug}  package={this.state.package} logged={!this.state.trialEnded}  trialUntil={this.state.trialUntil} />
                  )}
                  <GridList cols={12}>

                  </GridList>
                  {this.state.trialEnded ? (
                    <GridList cols={12} style={{marginTop: '2%'}}>
                      <GridListTile style={{height: '90vh'}} cols={12}>
                        <center>
                        <h1 style={{direction: 'rtl', marginTop: 0, marginBottom: 0}}>לקוח יקר, תקופת הניסיון הסתיימה,</h1>
                        <h2 style={{direction: 'rtl', marginTop: 0, marginBottom: 0}}>לחידוש המנוי <a href='/packages'>לחץ כאן</a></h2>
                        </center>
                      </GridListTile>
                    </GridList>
                  ) : (
                    <GridList cols={12} style={{marginTop: '2%'}}>
                      <GridListTile style={{height: '90vh'}} cols={1}>

                      </GridListTile>
                      <GridListTile style={{height: '90vh'}} cols={7}>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end'
                        }}>
                            <div style={{textAlign: 'right', marginRight: '2%'}}>
                              <p style={{fontSize: '1.5em', marginBottom: -15, marginTop: 0}}>{this.state.card.businessName}</p>
                              <p style={{fontSize: '1.3em', marginBottom: 0, marginTop: 0}}>{this.state.card.title}</p>
                              {this.state.package._id === '5f8817dec059ec3188f5de86' && (
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center'
                                }}>
                                  <Link to='/packages' style={{textDecoration: 'none'}}>
                                    <div style={{
                                      backgroundColor: blue,
                                      fontWeight: 'bold',
                                      textAlign: 'center',
                                      color: 'white',
                                      padding: 5,
                                      marginRight: 5,
                                      whiteSpace: 'nowrap',
                                      fontSize: '1.3em'
                                    }}>!קנה חבילה</div>
                                  </Link>
                                  <div style={{
                                    backgroundColor: yellow,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    color: blue,
                                    padding: 5,
                                    whiteSpace: 'nowrap',
                                    fontSize: '1.3em'
                                  }}>
                                    נותרו עוד {this.state.trialUntil} יום לתוכנית הנסיון
                                  </div>
                                </div>
                              )}
                              </div>
                            <div>
                              {(this.state.card.logo !== '' && this.state.card.logo !== undefined && this.state.card.logo !== null) && (
                                <React.Fragment>
                                  {(this.state.card.logo.includes('/gallery/') || this.state.card.logo.includes('/templates/')) ? (
                                    <React.Fragment>
                                      {this.state.package.editCard ? (
                                        <Link to='/card/edit' style={{textDecoration: 'none'}}><img src={this.state.card.logo} style={{
                                          marginTop: 2,
                                          width: '100%',
                                          height: 'auto',
                                          maxWidth: 100,
                                          minWidth: 100,
                                          borderRadius: '100%',
                                          padding: 0,
                                          maxHeight: 100,
                                          minHeight: 100,
                                          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                          objectFit: 'cover'
                                        }}/></Link>
                                      ) : (
                                        <Link onClick={(e) => this.setState({messageCannotEditCard: true})} style={{textDecoration: 'none'}}><img src={this.state.card.logo} style={{
                                          marginTop: 2,
                                          width: '100%',
                                          height: 'auto',
                                          maxWidth: 100,
                                          minWidth: 100,
                                          borderRadius: '100%',
                                          padding: 0,
                                          maxHeight: 100,
                                          minHeight: 100,
                                          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                          objectFit: 'cover'
                                        }}/></Link>
                                      )}
                                    </React.Fragment>

                                  ) : (
                                    <React.Fragment>
                                      {this.state.package.editCard ? (
                                        <React.Fragment>
                                          <Link to='/card/edit' style={{textDecoration: 'none'}}><img src={'https://app.dibit.co.il/logos/'+this.state.card.logo} style={{
                                            marginTop: 2,
                                            width: '100%',
                                            height: 'auto',
                                            maxWidth: 100,
                                            borderRadius: '100%',
                                            padding: 0,
                                            maxHeight: 100,
                                            minWidth: 100,
                                            minHeight: 100,
                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                            objectFit: 'cover'
                                          }}/></Link>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          <Link onClick={(e) => this.setState({messageCannotEditCard: true})} style={{textDecoration: 'none'}}><img src={'https://app.dibit.co.il/logos/'+this.state.card.logo} style={{
                                            marginTop: 2,
                                            width: '100%',
                                            height: 'auto',
                                            maxWidth: 100,
                                            borderRadius: '100%',
                                            padding: 0,
                                            maxHeight: 100,
                                            minWidth: 100,
                                            minHeight: 100,
                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                            objectFit: 'cover'
                                          }}/></Link>
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '3%', width: '100%', flexFlow: 'wrap'}}>
                          <div style={{
                            backgroundColor: 'white',
                            width: '25%',
                            minHeight: 70,
                            maxHeight: 100,
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px',
                          display: 'inline'}} >
                            <div style={{padding: '2%'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}>
                              <img src={Paperplane} style={{color: yellow, fontSize: '3em', width: 45, height: 45, marginRight: '3%'}}/>
                              <div style={{
                                textAlign: 'center', width: 45, height: 45,
                                fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>
                                <span>{this.state.card.sentClicks}</span>
                                </div>
                            </div>
                            <div style={{textAlign: 'center'}}>שלחו את הכרטיס</div>
                            </div>
                          </div>
                          <div style={{
                            backgroundColor: 'white',
                            width: '25%',
                            minHeight: 70,
                            maxHeight: 100,
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px', display: 'inline'}} >
                            <div style={{padding: '2%'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}>
                              <img src={WatchIcon} style={{color: yellow, fontSize: '3em', width: 45, height: 45, marginRight: '3%'}}/>
                              <div style={{
                                textAlign: 'center', width: 45, height: 45,
                                fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>
                                <span style={{fontSize: parseInt(this.state.card.watches) >= 100 ? '0.8em' : '1em'}}>{this.state.card.watches}</span>
                                </div>
                            </div>
                            <div style={{textAlign: 'center'}}>צפו בכרטיס שלכם</div>
                            </div>
                          </div>
                          <div style={{
                            backgroundColor: 'white',
                            width: '25%',
                            minHeight: 70,
                            maxHeight: 100,
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px', display: 'inline'}} >
                            <div style={{padding: '2%'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}>
                              <img src={WhatsappSVG} style={{color: yellow, fontSize: '3em', width: 42, height: 42, marginRight: '3%'}}/>
                              <div style={{
                                textAlign: 'center', width: 45, height: 45,
                                fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>
                                <span style={{fontSize: parseInt(this.state.card.whatsappClicks) >= 100 ? '1.5em' : '1em'}}>{this.state.card.whatsappClicks}</span>
                                </div>
                            </div>
                            <div style={{textAlign: 'center'}}>לחצו על הווצאפ</div>
                            </div>
                          </div>
                          <div style={{
                            backgroundColor: 'white',
                            width: '25%',
                            minHeight: 70,
                            maxHeight: 100,
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px', display: 'inline'}} >
                            <div style={{padding: '2%'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}>
                              <img src={CallIcon} style={{color: yellow, fontSize: '3em', width: 42, height: 42, marginRight: '3%'}}/>
                              <div style={{
                                textAlign: 'center', width: 45, height: 45,
                                fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>
                                <span style={{fontSize: parseInt(this.state.card.callmeClicks) >= 100 ? '1.5em' : '1em'}}>{this.state.card.callmeClicks}</span>
                                </div>
                            </div>
                            <div style={{textAlign: 'center'}}>התקשרו אליכם</div>
                            </div>
                          </div>
                          <div style={{
                            backgroundColor: 'white',
                            width: '25%',
                            minHeight: 70,
                            maxHeight: 100,
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px', display: 'inline'}} >
                            <div style={{padding: '2%'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}>
                              <img src={InstagramLogo} style={{color: yellow, fontSize: '3em', width: 42, height: 45, marginRight: '3%'}}/>
                              <div style={{
                                textAlign: 'center', width: 45, height: 45,
                                fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>
                                <span style={{fontSize: parseInt(this.state.card.instagramClicks) >= 100 ? '1.5em' : '1em'}}>{this.state.card.instagramClicks}</span>
                                </div>
                            </div>
                            <div style={{textAlign: 'center'}}>לחצו על האינסטגרם</div>
                            </div>
                          </div>
                          <div style={{
                            backgroundColor: 'white',
                            width: '25%',
                            minHeight: 70,
                            maxHeight: 100,
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px', display: 'inline'}} >
                            <div style={{padding: '2%'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}>
                              <img src={WazeLogo} style={{color: yellow, fontSize: '3em', width: 45, height: 45, marginRight: '3%'}}/>
                              <div style={{
                                textAlign: 'center', width: 45, height: 45,
                                fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>
                                <span style={{fontSize: parseInt(this.state.card.wazeClicks) >= 100 ? '1.5em' : '1em'}}>{this.state.card.wazeClicks}</span>
                                </div>
                            </div>
                            <div style={{textAlign: 'center'}}>ניווטו לעסק שלכם</div>
                            </div>
                          </div>
                          <div style={{
                            backgroundColor: 'white',
                            width: '25%',
                            minHeight: 70,
                            maxHeight: 100,
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px', display: 'inline'}} >
                            <div style={{padding: '2%'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}>
                              <img src={FacebookLogo} style={{color: yellow, fontSize: '3em', width: 45, height: 45, marginRight: '3%'}}/>
                              <div style={{
                                textAlign: 'center', width: 45, height: 45,
                                fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>
                                <span style={{fontSize: parseInt(this.state.card.facebookClicks) >= 100 ? '1.5em' : '1em'}}>{this.state.card.facebookClicks}</span>
                                </div>
                            </div>
                            <div style={{textAlign: 'center'}}>לחצו על הפייסבוק</div>
                            </div>
                          </div>
                          <div style={{
                            backgroundColor: 'white',
                            width: '25%',
                            minHeight: 70,
                            maxHeight: 100,
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px', display: 'inline'}} >
                            <div style={{padding: '2%'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}>
                              <img src={WebIcon} style={{color: yellow, fontSize: '3em', width: 45, height: 45, marginRight: '3%'}}/>
                              <div style={{
                                textAlign: 'center', width: 45, height: 45,
                                fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>
                                <span style={{fontSize: parseInt(this.state.card.websiteClicks) >= 100 ? '1.5em' : '1em'}}>{this.state.card.websiteClicks}</span>
                                </div>
                            </div>
                            <div style={{textAlign: 'center'}}>לחצו על האתר שלכם</div>
                            </div>
                          </div>
                        </div>
                        {/*<div style={{display: 'flex', flexDirection: 'row', marginTop: '3%'}}>
                          <div style={{backgroundColor: 'white', width: 430, height: 100, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px'}} >
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: '-20%',
                              marginTop: '-10%',
                              paddingRight: '10%',
                              paddingLeft: '10%'
                            }}>
                              <img src={Paperplane} style={{color: yellow, fontSize: '3em', marginTop: '30%', width: 50, height: 50}}/>
                              <p style={{textAlign: 'center', width: 45, height: 45, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>{this.state.card.sentClicks}</p>
                            </div>
                            <div style={{textAlign: 'center'}}>שלחו את הכרטיס</div>
                          </div>


                          <div style={{backgroundColor: 'white', width: 430, height: 100, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: '-20%',
                              marginTop: '-10%',
                              paddingRight: '15%',
                              paddingLeft: '10%'
                            }}>
                              <img src={WatchIcon} style={{color: yellow, fontSize: '3em', marginTop: '32%', width: 47, height: 47}}/>
                              <p style={{textAlign: 'center', width: 45, height: 45, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>{this.state.card.watches}</p>
                            </div>
                            <div style={{textAlign: 'center'}}>צפו בכרטיס שלכם</div>
                          </div>


                          <div style={{backgroundColor: 'white', width: 430, height: 100, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: '-20%',
                              marginTop: '-10%',
                              paddingRight: '15%',
                              paddingLeft: '10%'
                            }}>
                              <img src={WhatsappSVG} style={{color: yellow, fontSize: '3em', marginTop: '32%', width: 45, height: 45}}/>
                              <p style={{textAlign: 'center', width: 45, height: 45, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>{this.state.card.whatsappClicks}</p>
                            </div>
                            <div style={{textAlign: 'center'}}>לחצו על הווטצאפ</div>
                          </div>

                          <div style={{backgroundColor: 'white', width: 430, height: 100, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: '-20%',
                              marginTop: '-10%',
                              paddingRight: '15%',
                              paddingLeft: '10%'
                            }}>
                              <img src={CallIcon} style={{color: yellow, fontSize: '3em', marginTop: '30%', width: 47, height: 47}}/>
                              <p style={{textAlign: 'center', width: 45, height: 45, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>{this.state.card.callmeClicks}</p>
                            </div>
                            <div style={{textAlign: 'center'}}>התקשרו אליכם</div>
                          </div>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '1%'}}>
                          <div style={{backgroundColor: 'white', width: 430, height: 100, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: '-20%',
                              marginTop: '-10%',
                              paddingRight: '10%',
                              paddingLeft: '10%'
                            }}>
                              <img src={Paperplane} style={{color: yellow, fontSize: '3em', marginTop: '30%', width: 50, height: 50}}/>
                              <p style={{textAlign: 'center', width: 45, height: 45, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>{this.state.card.sentClicks}</p>
                            </div>
                            <div style={{textAlign: 'center'}}>שלחו את הכרטיס</div>
                          </div>


                          <div style={{backgroundColor: 'white', width: 430, height: 100, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: '-20%',
                              marginTop: '-10%',
                              paddingRight: '15%',
                              paddingLeft: '10%'
                            }}>
                            <img src={WatchIcon} style={{color: yellow, fontSize: '3em', marginTop: '32%', width: 47, height: 47}}/>
                              <p style={{textAlign: 'center', width: 45, height: 45, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>{this.state.card.watches}</p>
                            </div>
                            <div style={{textAlign: 'center'}}>צפו בכרטיס שלכם</div>
                          </div>

                          <div style={{backgroundColor: 'white', width: 430, height: 100, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: '-20%',
                              marginTop: '-10%',
                              paddingRight: '15%',
                              paddingLeft: '10%'
                            }}>
                              <img src={InstagramLogo} style={{color: yellow, fontSize: '3em', marginTop: '35%', width: 40, height: 40}}/>
                              <p style={{textAlign: 'center', width: 45, height: 45, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>{this.state.card.instagramClicks}</p>
                            </div>
                            <div style={{textAlign: 'center'}}>לחצו על האינטסגרם</div>
                          </div>

                          <div style={{backgroundColor: 'white', width: 430, height: 100, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px'}}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: '-20%',
                              marginTop: '-10%',
                              paddingRight: '13%',
                              paddingLeft: '10%'
                            }}>
                              <img src={WazeLogo} style={{color: yellow, fontSize: '3em', marginTop: '32%', width: 47, height: 47}}/>
                              <p style={{textAlign: 'center', width: 45, height: 45, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50}}>{this.state.card.wazeClicks}</p>
                            </div>
                            <div style={{textAlign: 'center'}}>ניווטו לעסק שלכם</div>
                          </div>
                        </div>*/}
                        <div onClick={(e) => this.copyText('https://dibit.co.il/'+this.state.card.slug)} style={{backgroundColor: yellow, width: '100%', height: 40, marginTop: '3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '3px solid black'}}>
                          <img src={LinkIconI} style={{width: 25, height: 25, marginTop: '1%', marginLeft: '2%'}}/>

                          <p style={{direction: 'rtl', marginTop: '1.1%', fontSize: '1.2em', marginRight: '2%', whiteSpace: 'nowrap'}}>
                          להעתקת הקישור לכרטיס שלך, לחץ/י עליי!
                          </p>
                        </div>
                        <div style={{width: '100%', marginTop: '2%', borderBottom: '3px solid '+yellow}}>
                          {this.state.package.messages ? (
                            <Link to='/messages' style={{textDecoration: 'none'}}><div style={{
                              backgroundColor: 'white',
                              fontWeight: 'bold',
                              color: blue,
                              textAlign: 'center',
                              fontSize: '1em',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              paddingBottom: '1%'
                            }}>
                              <img src={MessageIcon} style={{width: 25, height: 25, marginTop: '1%', marginLeft: '2%'}}/>
                              {(this.state.messagesRead === 0 || this.state.messagesRead === '0') ? (
                                  <div style={{fontSize: '1.4em', marginTop: '1.1%', fontSize: '1.2em', marginRight: '2%', whiteSpace: 'nowrap'}}>אין לך הודעות חדשות</div>
                              ) : (
                                  <div style={{fontSize: '1.4em', marginTop: '1.1%', fontSize: '1.2em', marginRight: '2%', whiteSpace: 'nowrap'}}>יש {this.state.messagesRead} הודעות</div>
                              )}
                            </div></Link>
                          ) : (
                            <div style={{
                              backgroundColor: 'grey',
                              fontWeight: 'bold',
                              color: blue,
                              textAlign: 'center',
                              fontSize: '1em',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              paddingBottom: '1%'
                            }} onClick={(e) => this.setState({messageCannotSendMessages: true})}>
                              <img src={MessageIcon} style={{width: 25, height: 25, marginTop: '1%', marginLeft: '2%'}}/>
                              {(this.state.messagesRead === 0 || this.state.messagesRead === '0') ? (
                                  <div style={{fontSize: '1.4em', marginTop: '1.1%', fontSize: '1.2em', marginRight: '2%', whiteSpace: 'nowrap'}}>אין לך הודעות חדשות</div>
                              ) : (
                                  <div style={{fontSize: '1.4em', marginTop: '1.1%', fontSize: '1.2em', marginRight: '2%', whiteSpace: 'nowrap'}}>יש {this.state.messagesRead} הודעות</div>
                              )}
                            </div>
                          )}
                          <Snackbar open={this.state.messageCannotSendMessages} autoHideDuration={6000} onClose={(e) => this.setState({messageCannotSendMessages: false})}>
                            <Alert variant="filled" severity="warning" style={{fontSize: '1.2em'}}>על מנת לקבל ושלוח הודעות עליכם לשדרג חבילה</Alert>
                          </Snackbar>
                        </div>
                      </GridListTile>
                      <GridListTile style={{height: '90vh'}} cols={3}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <div style={{width: '80%'}}>
                            <div style={{
                              backgroundColor: blue,
                              padding: 10,
                              fontWeight: 'bold',
                              marginTop: '3%',
                              color: 'white',
                              textAlign: 'center',
                              marginRight: '5%',
                              marginLeft: '5%',
                            }} onClick={(e) => window.open('https://dibit.co.il/'+this.state.card.slug, '_blank')}>
                              <div style={{fontSize: '1.4em', textAlign: 'center'}}>
                                <span>לכרטיס</span>
                              </div>
                            </div>
                          </div>

                          <div style={{width: '80%'}}>
                            <Link to='/packages' style={{textDecoration: 'none'}}><div style={{
                              backgroundColor: yellow,
                              padding: 10.5,
                              fontWeight: 'bold',
                              marginTop: '3%',
                              color: blue,
                              textAlign: 'center',
                              marginRight: '5%',
                              marginLeft: '5%',
                              // letterSpacing: 1,
                              fontSize: '1.2em'
                            }}>{this.state.package.name}</div></Link>
                          </div>


                        </div>
                        <div>
                          {this.state.package.editCard ? (
                            <Link to='/card/edit' style={{textDecoration: 'none'}}><div style={{
                              backgroundColor: 'white',
                              padding: 15,
                              fontWeight: 'bold',
                              marginTop: '3%',
                              color: blue,
                              textAlign: 'center',
                              marginRight: '3%',
                              marginLeft: '3%',

                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              whiteSpace: 'nowrap'
                            }}>
                              <img src={EditIcon} style={{marginTop: '2%', width: 30, height: 30}}/>
                              <div style={{fontSize: '1.3em'}}>לעריכת הכרטיס <span style={{fontSize: '1.2em', color: 'black'}}>לחצו כאן</span></div>
                            </div></Link>
                          ) : (
                            <div style={{
                              backgroundColor: 'grey',
                              padding: 15,
                              fontWeight: 'bold',
                              marginTop: '3%',
                              color: blue,
                              textAlign: 'center',
                              marginRight: '3%',
                              marginLeft: '3%',

                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              whiteSpace: 'nowrap'
                            }} onClick={(e) => this.setState({messageCannotEditCard: true})}>
                              <img src={EditIcon} style={{marginTop: '2%', width: 30, height: 30}}/>
                              <div style={{fontSize: '1.3em'}}>לעריכת הכרטיס <span style={{fontSize: '1.2em', color: 'black'}}>לחצו כאן</span></div>
                            </div>
                          )}
                        </div>
                        <Snackbar open={this.state.messageCannotEditCard} autoHideDuration={6000} onClose={(e) => this.setState({messageCannotEditCard: false})}>
                          <Alert variant="filled" severity="warning">על מנת לערוך את הכרטיס עליכם לשדרג חבילה</Alert>
                        </Snackbar>
                        {/*<div>
                          {this.state.package.messages ? (
                            <Link to='/messages' style={{textDecoration: 'none'}}><div style={{
                              backgroundColor: 'white',
                              padding: 15,
                              fontWeight: 'bold',
                              marginTop: '3%',
                              color: blue,
                              textAlign: 'center',
                              marginRight: '3%',
                              marginLeft: '3%',
                              fontSize: '1em',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between'
                            }}>
                              <img src={MessageIcon} style={{marginTop: '2%', width: 30, height: 30}}/>
                              {(this.state.messagesRead === 0 || this.state.messagesRead === '0') ? (
                                  <div style={{fontSize: '1.4em'}}>אין לך הודעות חדשות</div>
                              ) : (
                                  <div style={{fontSize: '1.4em'}}>יש {this.state.messagesRead} הודעות</div>
                              )}
                            </div></Link>
                          ) : (
                            <div style={{
                              backgroundColor: 'grey',
                              padding: 15,
                              fontWeight: 'bold',
                              marginTop: '3%',
                              color: blue,
                              textAlign: 'center',
                              marginRight: '3%',
                              marginLeft: '3%',
                              fontSize: '1em',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between'
                            }} onClick={(e) => this.setState({messageCannotSendMessages: true})}>
                              <img src={MessageIcon} style={{marginTop: '2%', width: 30, height: 30}}/>
                              {(this.state.messagesRead === 0 || this.state.messagesRead === '0') ? (
                                  <div style={{fontSize: '1.4em'}}>אין לך הודעות חדשות</div>
                              ) : (
                                  <div style={{fontSize: '1.4em'}}>יש {this.state.messagesRead} הודעות</div>
                              )}
                            </div>
                          )}
                          <Snackbar open={this.state.messageCannotSendMessages} autoHideDuration={6000} onClose={(e) => this.setState({messageCannotSendMessages: false})}>
                            <Alert variant="filled" severity="warning" style={{fontSize: '1.2em'}}>על מנת לקבל ושלוח הודעות עליכם לשדרג חבילה</Alert>
                          </Snackbar>
                        </div> */}

                        {this.state.package._id !== '5f8817dec059ec3188f5de86' && (
                          <div>
                              <Link to='/payments' style={{textDecoration: 'none'}}><center><div style={{
                                backgroundColor: 'white',
                                padding: 15,
                                fontWeight: 'bold',
                                marginTop: '3%',
                                color: blue,
                                textAlign: 'center',
                                marginRight: '3%',
                                marginLeft: '3%',

                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                              }}>
                                <img src={SettingsIcon} style={{marginTop: '1%', width: 30, height: 30}}/>
                                <div style={{fontSize: '1.4em'}}>הגדרות תשלום</div>
                              </div></center></Link>
                          </div>
                        )}

                        <div style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}} className="phone_emulator">
                          {/*<iframe seamless seamless="seamless" src="https://dibit.co.il/aviv"></iframe>*/}
                          {/*<img src={Temp} style={{height: '70vh', width: 'auto'}} />*/}
                          <div>
                            <div style={{
                              backgroundColor: 'white',
                              padding: 4,
                              fontWeight: 'bold',
                              marginTop: '3%',
                              color: blue,
                              textAlign: 'center',
                              marginRight: '3%',
                              marginLeft: '3%',
                              fontSize: '1em'
                            }}>
                              <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '2%',
                                whiteSpace: 'nowrap'
                              }}>
                                <AssignmentOutlinedIcon style={{fontSize: '2.5em', marginTop: 'inherit'}} />

                                <div style={{fontSize: '1.3em'}}>חדש בדיביט (כתבות)</div>
                              </div>
                              {this.state.rss.length === 0 && (
                                <div>
                                  <div style={{direction: 'rtl'}}>טוען כתבות..</div>
                                </div>
                              )}
                              {this.state.rss.map((item) => {
                                return (
                                  <a href={item.link} target='_blank' style={{textDecoration: 'none', color: 'black'}}><div>
                                    <div style={{direction: 'rtl'}}>{item.title}</div>
                                    <hr style={{color: '#f2f2f2', backgroundColor: '#f2f2f2'}}/>
                                  </div></a>
                                )
                              })}
                            </div>
                          </div>
                        </div>

                      </GridListTile>
                      <GridListTile style={{height: '90vh'}} cols={1}>

                      </GridListTile>
                    </GridList>
                  )}

              </React.Fragment>
            )}
          </BrowserView>

          <MobileView>
          {this.state.trialEnded ? (
            <GridList cols={12} style={{marginTop: '2%'}}>
              <GridListTile style={{height: '90vh'}} cols={12}>
                <center>
                <h1 style={{direction: 'rtl', marginTop: 0, marginBottom: 0, fontSize: '1.2em'}}>לקוח יקר, תקופת הניסיון הסתיימה,</h1>
                <h2 style={{direction: 'rtl', marginTop: 0, marginBottom: 0, fontSize: '1.2em'}}>לחידוש המנוי <a href='/packages'>לחץ כאן</a></h2>
                </center>
              </GridListTile>
            </GridList>
          ) : (
            <Grid container spaceing={2}>
              {(!this.state.card.completed) && (<Redirect to='/signup' />)}

              <Grid item xs={12}>
                <OfflineHeader slug={this.state.slug} mobile={true} package={this.state.package} trialUntil={this.state.trialUntil} />
              </Grid>

              <Grid item xs={12} style={{marginTop: '5%'}}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginRight: '5%',
                width: '100%'
              }}>
                  <div style={{textAlign: 'right', direction: 'rtl', marginRight: '2%', width: '65%'}}>
                    <p style={{fontSize: '1.5em', marginBottom: -15, marginTop: 0}}>{this.state.card.businessName}</p>
                    <p style={{fontSize: '1.3em', marginBottom: 0, marginTop: 0}}>{this.state.card.title}</p>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      width: '100%'
                    }}>
                      <div style={{
                        backgroundColor: blue,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: 'white',
                        padding: 0,
                        marginRight: 5,
                        fontSize: '1.1em',
                        width: '50%',
                        height: '100%',
                        marginLeft: '1%',
                        marginRight: '1%',

                        paddingTop: '2%',
                        paddingBottom: '2%',
                      }}>
                       <span style={{textAlign: 'center'}} onClick={(e) => window.open('https://dibit.co.il/'+this.state.card.slug, '_blank')}> לכרטיס</span>
                      </div>
                      <div style={{
                        backgroundColor: yellow,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: blue,
                        padding: 0,
                        fontSize: '1.1em',
                        width: '50%',
                        height: '100%',
                        marginLeft: '1%',
                        marginRight: '1%',
                        paddingTop: '2%',
                        paddingBottom: '2%',
                      }}>
                        <Link to='/packages' style={{textDecoration: 'none', color: 'white'}}>{this.state.package.name}</Link>
                      </div>
                    </div>
                  </div>
                  <div style={{width: '30%'}}>
                    {(this.state.card.logo !== '' && this.state.card.logo !== undefined && this.state.card.logo !== null) && (
                      <React.Fragment>
                        {(this.state.card.logo.includes('/gallery/') || this.state.card.logo.includes('/templates/')) ? (
                          <React.Fragment>
                            {this.state.package.editCard ? (
                              <React.Fragment>
                              <Link to='/card/edit' style={{textDecoration: 'none'}}>
                              <img src={this.state.card.logo} style={{
                                marginTop: 2,
                                width: '100%',
                                height: 'aut',
                                maxWidth: 105,
                                minWidth: 105,
                                minHeight: 105,
                                maxHeight: 105,
                                borderRadius: '100%',
                                padding: 0,
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                objectFit: 'cover'
                              }}/>
                              </Link>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <Link onClick={(e) => this.setState({messageCannotEditCard: true})} style={{textDecoration: 'none'}}>
                                <img src={this.state.card.logo} style={{
                                  marginTop: 2,
                                  width: '100%',
                                  height: 'aut',
                                  maxWidth: 105,
                                  minWidth: 105,
                                  minHeight: 105,
                                  maxHeight: 105,
                                  borderRadius: '100%',
                                  padding: 0,
                                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                  objectFit: 'cover'
                                }}/>
                                </Link>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {this.state.package.editCard ? (
                              <React.Fragment>
                                <Link to='/card/edit' style={{textDecoration: 'none'}}>
                                <img src={'https://app.dibit.co.il/logos/'+this.state.card.logo} style={{
                                  marginTop: 2,
                                  width: '100%',
                                  height: 'aut',
                                  maxWidth: 105,
                                  minWidth: 105,
                                  minHeight: 105,
                                  maxHeight: 105,
                                  borderRadius: '100%',
                                  padding: 0,
                                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                  objectFit: 'cover'
                                }}/>
                                </Link>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <Link onClick={(e) => this.setState({messageCannotEditCard: true})} style={{textDecoration: 'none'}}>
                                <img src={'https://app.dibit.co.il/logos/'+this.state.card.logo} style={{
                                  marginTop: 2,
                                  width: '100%',
                                  height: 'aut',
                                  maxWidth: 105,
                                  minWidth: 105,
                                  minHeight: 105,
                                  maxHeight: 105,
                                  borderRadius: '100%',
                                  padding: 0,
                                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                  objectFit: 'cover'
                                }}/>
                                </Link>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </div>
              </div>
              </Grid>

              <Grid item xs={12}>
              <center><div onClick={(e) => this.copyText('https://dibit.co.il/'+this.state.card.slug)} style={{backgroundColor: yellow, width: '95%', height: 40, marginTop: '3%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '3px solid black'}}>
                <img src={LinkIconI} style={{width: 23, height: 23, marginTop: '2%', marginLeft: '1%'}}/>

                <p style={{direction: 'rtl', marginTop: '2%', fontSize: '1.1em', marginRight: '1%', whiteSpace: 'nowrap'}}>
                להעתקת הקישור לכרטיס שלך, לחץ/י עליי!
                </p>
              </div></center>
              </Grid>

              <Grid item xs={12}>
                {this.state.package.editCard ? (
                  <Link to='/card/edit' style={{textDecoration: 'none'}}><center><div style={{
                    backgroundColor: 'white',
                    padding: 15,
                    fontWeight: 'bold',
                    marginTop: '3%',
                    color: blue,
                    textAlign: 'center',
                    marginRight: '3%',
                    marginLeft: '3%',

                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}>
                    <img src={EditIcon} style={{marginTop: '-1%', width: 40, height: 40}}/>
                    <div style={{fontSize: '1.3em'}}>לעריכת הכרטיס <span style={{fontSize: '1.2em', color: 'black'}}>לחצו כאן</span></div>
                  </div></center></Link>
                ) : (
                  <center><div style={{
                    backgroundColor: 'grey',
                    padding: 15,
                    fontWeight: 'bold',
                    marginTop: '3%',
                    color: blue,
                    textAlign: 'center',
                    marginRight: '3%',
                    marginLeft: '3%',

                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }} onClick={(e) => this.setState({messageCannotEditCard: true})}>
                    <img src={EditIcon} style={{marginTop: '-1%', width: 40, height: 40}}/>
                    <div style={{fontSize: '1.3em'}}>לעריכת הכרטיס <span style={{fontSize: '1.2em', color: 'black'}}>לחצו כאן</span></div>
                  </div></center>
                )}

                <Snackbar open={this.state.messageCannotEditCard} autoHideDuration={6000} onClose={(e) => this.setState({messageCannotEditCard: false})}>
                  <Alert variant="filled" severity="warning" style={{fontSize: '1.2em'}}>על מנת לערוך את הכרטיס עליכם לשדרג חבילה</Alert>
                </Snackbar>
              </Grid>

               <Grid item xs={12}>
                {this.state.package.messages ? (
                  <Link to='/messages' style={{textDecoration: 'none'}}><center><div style={{
                    backgroundColor: 'white',
                    padding: 0,
                    fontWeight: 'bold',
                    marginTop: '3%',
                    color: blue,
                    textAlign: 'center',
                    marginRight: '3%',
                    marginLeft: '3%',
                    fontSize: '1em',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: '2%'
                  }}>
                    <img src={MessageIcon} style={{marginTop: 'inherit', width: 40, height: 40, marginTop: '2%', marginLeft: '5%'}}/>
                    {(this.state.messagesRead === 0 || this.state.messagesRead === '0') ? (
                        <div style={{fontSize: '1.4em', marginRight: '5%', marginTop: '3%'}}>אין לך הודעות חדשות</div>
                    ) : (
                        <div style={{fontSize: '1.3em', marginRight: '5%', marginTop: '3%'}}>יש {this.state.messagesRead} הודעות</div>
                    )}
                  </div></center></Link>
                ) : (
                  <center><div style={{
                    backgroundColor: 'grey',
                    padding: 0,
                    fontWeight: 'bold',
                    marginTop: '3%',
                    color: blue,
                    textAlign: 'center',
                    marginRight: '3%',
                    marginLeft: '3%',
                    fontSize: '1em',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: '2%'
                  }} onClick={(e) => this.setState({messageCannotSendMessages: true})}>
                    <img src={MessageIcon} style={{marginTop: 'inherit', width: 40, height: 40, marginTop: '2%', marginLeft: '5%'}}/>
                    {(this.state.messagesRead === 0 || this.state.messagesRead === '0') ? (
                        <div style={{fontSize: '1.4em', marginRight: '5%', marginTop: '3%'}}>אין לך הודעות חדשות</div>
                    ) : (
                        <div style={{fontSize: '1.2em', marginRight: '5%', marginTop: '3%'}}>יש {this.state.messagesRead} הודעות</div>
                    )}
                  </div></center>
                )}
                <Snackbar open={this.state.messageCannotSendMessages} autoHideDuration={6000} onClose={(e) => this.setState({messageCannotSendMessages: false})}>
                  <Alert variant="filled" severity="warning" style={{fontSize: '1.2em'}}>על מנת לקבל ושלוח הודעות עליכם לשדרג חבילה</Alert>
                </Snackbar>
               </Grid>

               {this.state.package._id !== '5f8817dec059ec3188f5de86' && (
                 <Grid item xs={12}>
                     <Link to='/payments' style={{textDecoration: 'none'}}><center><div style={{
                       backgroundColor: 'white',
                       padding: 15,
                       fontWeight: 'bold',
                       marginTop: '3%',
                       color: blue,
                       textAlign: 'center',
                       marginRight: '3%',
                       marginLeft: '3%',

                       display: 'flex',
                       flexDirection: 'row',
                       justifyContent: 'space-between'
                     }}>
                       <img src={SettingsIcon} style={{marginTop: '-1%', width: 40, height: 40}}/>
                       <div style={{fontSize: '1.4em'}}>הגדרות תשלום</div>
                     </div></center></Link>
                 </Grid>
               )}

               <Grid item xs={12} style={{marginTop: '5%'}}></Grid>

               {/*----*/}
               <Grid item xs={12} style={{marginBottom: '0%'}}>
                  <center><div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    maxWidth: '93%'
                  }}>
                    <div style={{
                       backgroundColor: 'white',
                       background: `linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(242,242,242,1) 65%)`,
                       width: '100%',
                       height: '100%',
                       maxHeight: 160
                     }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingLeft: '9%',
                        paddingRight: '9%',
                        marginBottom: parseInt(this.state.card.whatsappClicks) >= 100 ? '28%' : 0
                      }}>
                        <img src={WhatsappSVG} style={{color: yellow, fontSize: '3em', width: 70, height: 70, marginTop: '10%'}}/>
                        {/*<p style={{textAlign: 'center', width: 70, height: 70, fontSize: '3em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}>{this.state.card.whatsappClicks}</p>*/}
                        {parseInt(this.state.card.whatsappClicks) >= 100 ? (
                          <div style={{textAlign: 'center', width: 70, height: 70, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}><p style={{marginTop: '15%'}}>{this.state.card.whatsappClicks}</p></div>
                        ) : (
                          <p style={{textAlign: 'center', width: 70, height: 70, fontSize: '3em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}>{this.state.card.whatsappClicks}</p>
                        )}
                      </div>
                      <p style={{textAlign: 'center', fontSize: '1.2em', marginTop: '-15%'}}>לחצו על הווטצאפ</p>
                    </div>
                    <div style={{
                      backgroundColor: 'white',
                      width: '100%',
                      borderLeft: '1px solid #eee'
                     }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingLeft: '9%',
                        paddingRight: '9%',
                        marginBottom: parseInt(this.state.card.callmeClicks) >= 100 ? '28%' : 0
                      }}>
                        <img src={CallIcon} style={{color: yellow, fontSize: '3em', width: 70, height: 70, marginTop: '10%'}}/>
                        {/*<p style={{textAlign: 'center', width: 70, height: 70, fontSize: '3em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}>{this.state.card.callmeClicks}</p>*/}
                        {parseInt(this.state.card.callmeClicks) >= 100 ? (
                          <div style={{textAlign: 'center', width: 70, height: 70, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}><p style={{marginTop: '15%'}}>{this.state.card.callmeClicks}</p></div>
                        ) : (
                          <p style={{textAlign: 'center', width: 70, height: 70, fontSize: '3em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}>{this.state.card.callmeClicks}</p>
                        )}
                      </div>
                      <div style={{textAlign: 'center', fontSize: '1.2em', marginTop: '-15%'}}>התקשרו אליכם</div>
                    </div>
                  </div></center>
               </Grid>
               {/*----*/}

               <Grid item xs={12} style={{marginTop: '3%'}}>
                  <center><div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    maxWidth: '93%',
                    height: '100%',
                    maxHeight: 160
                  }}>
                    <div style={{
                      backgroundColor: 'white',
                      background: `linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(242,242,242,1) 65%)`,
                      width: '100%'
                    }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingLeft: '9%',
                        paddingRight: '9%',
                        marginBottom: parseInt(this.state.card.instagramClicks) >= 100 ? '28%' : 0
                      }}>
                        <img src={InstagramLogo} style={{color: yellow, fontSize: '3em', width: 70, height: 70, marginTop: '10%'}}/>
                        {/*<p style={{textAlign: 'center', width: 70, height: 70, fontSize: '3em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}>{this.state.card.instagramClicks}</p>*/}
                        {parseInt(this.state.card.instagramClicks) >= 100 ? (
                          <div style={{textAlign: 'center', width: 70, height: 70, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}><p style={{marginTop: '15%'}}>{this.state.card.instagramClicks}</p></div>
                        ) : (
                          <p style={{textAlign: 'center', width: 70, height: 70, fontSize: '3em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}>{this.state.card.instagramClicks}</p>
                        )}
                      </div>
                      <div style={{textAlign: 'center', fontSize: '1.2em', marginTop: '-15%'}}>לחצו על האינסטגרם</div>
                    </div>
                    <div style={{
                      backgroundColor: 'white',
                      borderLeft: '1px solid #eee',
                      width: '100%'
                     }}>
                       <div style={{
                         display: 'flex',
                         flexDirection: 'row',
                         justifyContent: 'space-between',
                         paddingLeft: '9%',
                         paddingRight: '9%',
                         marginBottom: parseInt(this.state.card.wazeClicks) >= 100 ? '28%' : 0
                       }}>
                         <img src={WazeLogo} style={{color: yellow, fontSize: '3em', width: 70, height: 70, marginTop: '10%'}}/>
                         {/*<p style={{textAlign: 'center', width: 70, height: 70, fontSize: '3em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}>{this.state.card.wazeClicks}</p>*/}
                         {parseInt(this.state.card.wazeClicks) >= 100 ? (
                           <div style={{textAlign: 'center', width: 70, height: 70, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}><p style={{marginTop: '15%'}}>{this.state.card.wazeClicks}</p></div>
                         ) : (
                           <p style={{textAlign: 'center', width: 70, height: 70, fontSize: '3em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}>{this.state.card.wazeClicks}</p>
                         )}
                       </div>
                       <div style={{textAlign: 'center', fontSize: '1.2em', marginTop: '-15%', paddingBottom: '10%'}}>ניווטו לעסק שלכם</div>
                    </div>
                  </div></center>
               </Grid>


               <Grid item xs={12} style={{marginTop: '3%'}}>
                  <center><div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    maxWidth: '93%',
                    height: '100%',
                    maxHeight: 160
                  }}>
                    <div style={{
                      backgroundColor: 'white',
                      background: `linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(242,242,242,1) 65%)`,
                      width: '100%',
                      paddingBottom: '5%'
                    }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingLeft: '9%',
                        paddingRight: '9%',
                        marginBottom: parseInt(this.state.card.sentClicks) >= 100 ? '28%' : 0
                      }}>
                        <img src={Paperplane} style={{color: yellow, fontSize: '3em', width: 70, height: 70, marginTop: '10%'}}/>
                        {/*<p style={{textAlign: 'center', width: 70, height: 70, fontSize: '3em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}>{this.state.card.sentClicks}</p>*/}
                        {parseInt(this.state.card.sentClicks) >= 100 ? (
                          <div style={{textAlign: 'center', width: 70, height: 70, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}><p style={{marginTop: '15%'}}>{this.state.card.sentClicks}</p></div>
                        ) : (
                          <p style={{textAlign: 'center', width: 70, height: 70, fontSize: '3em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}>{this.state.card.sentClicks}</p>
                        )}
                      </div>
                      <div style={{textAlign: 'center', fontSize: '1.2em', marginTop: '-15%'}}>שלחו את הכרטיס</div>
                    </div>
                    <div style={{
                      backgroundColor: 'white',
                      borderLeft: '1px solid #eee',
                      width: '100%'
                     }}>
                       <div style={{
                         display: 'flex',
                         flexDirection: 'row',
                         justifyContent: 'space-between',
                         paddingLeft: '9%',
                         paddingRight: '9%',
                         marginBottom: parseInt(this.state.card.watches) >= 100 ? '28%' : 0
                       }}>
                         <img src={WatchIcon} style={{color: yellow, fontSize: '3em', marginTop: '10%', width: 70, height: 70}}/>
                         {parseInt(this.state.card.watches) >= 100 ? (
                           <div style={{textAlign: 'center', width: 70, height: 70, fontSize: '2em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}><p style={{marginTop: '15%'}}>{this.state.card.watches}</p></div>
                         ) : (
                           <p style={{textAlign: 'center', width: 70, height: 70, fontSize: '3em', backgroundColor: yellow, color: blue, borderRadius: 50, marginTop: '10%', marginRight: '3%'}}>{this.state.card.watches}</p>
                         )}
                       </div>
                       <div style={{textAlign: 'center', fontSize: '1.2em', marginTop: '-15%'}}>צפו בכרטיס שלכם</div>
                    </div>
                  </div></center>
               </Grid>


               <Grid item xs={12} style={{marginTop: '5%'}}></Grid>
            </Grid>
          )}

          </MobileView>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Dashboard;
