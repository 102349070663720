import React, { Component, useMemo } from 'react';
import '../../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import Alert from '@material-ui/lab/Alert';
// import Modal from '@material-ui/core/Modal';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';
import LinkIcon from '@material-ui/icons/Link';
import OfflineHeader from '../../components/OfflineHeader';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import TimelineIcon from '@material-ui/icons/Timeline';
import GoogleLogin from 'react-google-login';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Chart } from 'react-charts'
import Snackbar from '@material-ui/core/Snackbar';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import RichTextEditor from 'react-rte';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { Link } from 'react-router-dom';

import {
  isMobile,
  BrowserView,
  MobileView
} from "react-device-detect";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import imageCompression from 'browser-image-compression';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';

const theme = createMuiTheme({
  overrides: {
    MuiGridListTile: {
      // Name of the rule
      size: {
        // Some CSS
        width: 130,
        height: '100%'
      },
    },
  },
});

class EditCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      package: {},
      title: '',
      secondaryTitle: '',
      text: '',
      // text: RichTextEditor.createEmptyValue(),
      phoneNumber: '',
      facebookLink: '',
      instagramLink: '',
      address: '',
      businessName: '',
      loading: false,
      button_1: '',
      button_1_icon: '',
      button_1_link: '',
      button_2: '',
      button_2_icon: '',
      button_2_link: '',
      button_3: '',
      button_3_icon: '',
      button_3_link: '',
      button_4: '',
      button_4_icon: '',
      button_4_link: '',
      button_5: '',
      button_5_icon: '',
      button_5_link: '',
      button_6: '',
      button_6_icon: '',
      button_6_link: '',
      button_7: '',
      button_7_icon: '',
      button_7_link: '',
      button_8: '',
      button_8_icon: '',
      button_8_link: '',
      button_9: '',
      button_9_icon: '',
      button_9_link: '',
      buttonShownIndex: 3,
      openingHours: {
        'A': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'B': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'C': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'D': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'E': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'F': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'G': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
      },
      close: {
        'A': false,
        'B': false,
        'C': false,
        'D': false,
        'E': false,
        'F': false,
        'G': false,
      },
      open24h: false,
      slug: '',
      slugOriginal: '',
      slugAvailable: true,
      templateId: '',
      templates: [],
      uploadLogoModal: false,
      uploadLogoModalSuccess: false,
      uploadCoverModal: false,
      uploadCoverModalSuccess: false,
      changeTemplateModal: false,
      uploadGalleryModal: false,
      uploadGalleryModalSuccess: false,
      template: {
        name: '',
        _id: '',
        description: '',
        coverImage: ''
      },
      gallery: [],
      original_gallery: [],
      search_gallery: '',
      gallerySelectedCoverImage: '',
      mobileNavLevel: 1,
      templateChosen: '',
      category: '',
      categories: [],
      categoryChosen: 'all',
      loadingImage: false,
      desktopNavLevel: 1,
      areYouSure: false,
      chosenId: '',
      src: null,
      crop: {
        unit: '%',
        width: 30,
        // aspect: 16 / 9,
        aspect: 16 / 16,
      },
      imageEditing: true
    };
  }

  componentDidMount() {
    this.getMyCard();
    this.getTemplates();
    this.getGalleries();
    this.getPackage();
  }


  getPackage = () => {
    this.setState({loading: true});
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'profile', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          if (response.data.data.card.slug === null || response.data.data.card.slug === undefined || response.data.data.card.slug === '') {
            window.location.href='/signup'
          }
          console.log(response);
          this.setState({loading: false});
          if (response.data.status === "ok") {
            this.setState({
              package: response.data.data.package
            });
          }
        }).catch(err => {
          console.log(err);
          this.setState({loading: false});
        });
      } catch (err) {
        console.log(err);
        this.setState({loading: false});
      }
    }
  }

  onChangeText = (value) => {
    this.setState({text: value});
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(
        value.toString('html')
      );
    }
  };

  searchGallery = () => {
    let gallery = this.state.gallery;
    let original_gallery = this.state.original_gallery;
    if (original_gallery.length === 0) this.setState({original_gallery: gallery});

    let query = this.state.search_gallery;

    let output = [];
    gallery.forEach((item, i) => {
      if (item.tagEnglish.includes(query) || item.name.includes(query)) output.push(item);
    });

    if (output.length >= 1) {
      this.setState({gallery: output});
    }
  }

  resetGallery = () => {
    let original_gallery = this.state.original_gallery;
    if (original_gallery.length >= 1) {
      this.setState({gallery: original_gallery});
    }
  }

  updateCoverFromGallery = () => {
    let gallerySelectedCoverImage = this.state.gallerySelectedCoverImage;

    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.put(API + 'card/cover-by-gallery?id='+this.state.gallerySelectedCoverImage, {}, {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          console.log(response);
          this.setState({
            loading: false,
            uploadLogoModal: false,
            uploadCoverModal: false,
            uploadCoverModalSuccess: true,
            coverImageShowGalleryBool: false,
            cover: response.data.data.card.cover
          });
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
          console.log(err);
      }
    }
  }

  updateLogoFromGallery = () => {
    let gallerySelectedLogoImage = this.state.gallerySelectedLogoImage;

    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.put(API + 'card/logo-by-gallery?id='+this.state.gallerySelectedLogoImage, {}, {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          console.log(response);
          this.setState({loading: false, uploadLogoModal: false, uploadCoverModal: false, uploadLogoModalSuccess: true, logoImageShowGalleryBool: false, logo: response.data.data.card.logo});
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
          console.log(err);
      }
    }
  }

  getTemplates = () => {
    this.setState({loading: true});
    axios.get(API + 'templates').then(response => {
      if (response.data.status === "ok") {
        this.setState({templates: response.data.data.templates});
      }
      this.setState({loading: false});
    }).catch(err => {
      console.log(err);
      this.setState({loading: false});
    });
  }

  checkSlug = (slug) => {
    slug = slug.replace(' ', '');
    if (slug.match("^[a-zA-Z0-9]+$") !== null) {
      this.setState({slug: slug}, () => {
        axios.get(API + 'card/slug?slug='+slug).then(response => {
          if (response.data.status === "ok") {
            this.setState({slugAvailable: response.data.available});
          }
        }).catch(err => {
          console.log(err);
        });
      });
    } else {
      this.setState({slug: slug, slugAvailable: false});
    }
  }

  is24hours = () => {
    this.setState({loading: true});
    let openingHours = this.state.openingHours;

    let a1 = new Date(openingHours['A'][0]).getHours() + ':' + new Date(openingHours['A'][0]).getMinutes();
    let a2 = new Date(openingHours['A'][1]).getHours() + ':' + new Date(openingHours['A'][1]).getMinutes();

    let b1 = new Date(openingHours['B'][0]).getHours() + ':' + new Date(openingHours['B'][0]).getMinutes();
    let b2 = new Date(openingHours['B'][1]).getHours() + ':' + new Date(openingHours['B'][1]).getMinutes();

    let c1 = new Date(openingHours['C'][0]).getHours() + ':' + new Date(openingHours['C'][0]).getMinutes();
    let c2 = new Date(openingHours['C'][1]).getHours() + ':' + new Date(openingHours['C'][1]).getMinutes();

    let d1 = new Date(openingHours['D'][0]).getHours() + ':' + new Date(openingHours['D'][0]).getMinutes();
    let d2 = new Date(openingHours['D'][1]).getHours() + ':' + new Date(openingHours['D'][1]).getMinutes();

    let e1 = new Date(openingHours['E'][0]).getHours() + ':' + new Date(openingHours['E'][0]).getMinutes();
    let e2 = new Date(openingHours['E'][1]).getHours() + ':' + new Date(openingHours['E'][1]).getMinutes();

    let f1 = new Date(openingHours['F'][0]).getHours() + ':' + new Date(openingHours['F'][0]).getMinutes();
    let f2 = new Date(openingHours['F'][1]).getHours() + ':' + new Date(openingHours['F'][1]).getMinutes();

    let g1 = new Date(openingHours['G'][0]).getHours() + ':' + new Date(openingHours['G'][0]).getMinutes();
    let g2 = new Date(openingHours['G'][1]).getHours() + ':' + new Date(openingHours['G'][1]).getMinutes();
    if (
      ((a1 === '0:0' || a1 === '00:00') && (a2 === '0:0' || a2 === '00:00')) &&
      ((b1 === '0:0' || b1 === '00:00') && (b2 === '0:0' || b2 === '00:00')) &&
      ((c1 === '0:0' || c1 === '00:00') && (c2 === '0:0' || c2 === '00:00')) &&
      ((d1 === '0:0' || d1 === '00:00') && (d2 === '0:0' || d2 === '00:00')) &&
      ((e1 === '0:0' || e1 === '00:00') && (e2 === '0:0' || e2 === '00:00')) &&
      ((f1 === '0:0' || f1 === '00:00') && (f2 === '0:0' || f2 === '00:00')) &&
      ((g1 === '0:0' || g1 === '00:00') && (g2 === '0:0' || g2 === '00:00'))
    ) {
      this.setState({open24h: true, loading: false});
    } else {
      this.setState({open24h: false, loading: false});
    }
  }

  remove_button = (btn) => {
    let index = this.state.buttonShownIndex;

    if (index === 4) this.setState({button_4: ''});
    if (index === 5) this.setState({button_5: ''});
    if (index === 6) this.setState({button_6: ''});
    if (index === 7) this.setState({button_7: ''});
    if (index === 8) this.setState({button_8: ''});
    if (index === 9) this.setState({button_9: ''});

    index -= 1;

    this.setState({buttonShownIndex: index});
  }

  setOpen24H = () => {
    if (this.state.open24h) {
      this.setState({open24h: false});
    } else {
      this.setState({
        openingHours: {
          'A': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'B': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'C': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'D': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'E': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'F': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'G': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
        },
        open24h: true
      });
    }
  }

  getOpeningHours = (day, type = 'from') => {
    let days = this.state.openingHours;

    if (days === null || days === undefined || days['A'] === null || days['A'] === undefined) {
      return new Date(Date.now());
    } else {
      if (type === 'from') {
        return new Date(days[day][0]);
      } else {
        return new Date(days[day][1]);
      }
    }
  }

  setOpeningHours = (day, type = 'from', time) => {
    let days = this.state.openingHours;
    let dayTimes = days[day];

    if (type === 'from') {
      dayTimes[0] = time;
    } else {
      dayTimes[1] = time;
    }

    days[day] = dayTimes;
    console.log(days);
    this.setState({openingHours: days});
  }

  getGalleries = () => {
    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'galleries', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          console.log(response);
          this.setState({gallery: response.data.data.gallery, original_gallery: response.data.data.gallery});
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
          console.log(err);
      }
    }
  }

  defaultHours = () => {
    return {
      'A': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
      'B': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
      'C': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
      'D': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
      'E': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
      'F': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
      'G': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
    };
  }

  getMyCard = () => {
    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'card', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          console.log(response);
          if (response.data.status === "ok") {
            this.setState({
              categories: response.data.data.categories,
              title: response.data.data.card.title,
              secondaryTitle: response.data.data.card.secondaryTitle,
              // text: RichTextEditor.createValueFromString(response.data.data.card.text, 'html'),
              text: response.data.data.card.text.replaceAll('<br />', '\n'),
              phoneNumber: response.data.data.card.phoneNumber,
              facebookLink: response.data.data.card.facebookLink,
              instagramLink: response.data.data.card.instagramLink,
              address: response.data.data.card.address,
              video: response.data.data.card.video,
              logo: response.data.data.card.logo,
              cover: response.data.data.card.cover,
              button_1: response.data.data.card.button_1,
              button_1_icon: response.data.data.card.button_1_icon,
              button_1_link: response.data.data.card.button_1_link,
              button_2: response.data.data.card.button_2,
              button_2_icon: response.data.data.card.button_2_icon,
              button_2_link: response.data.data.card.button_2_link,
              button_3: response.data.data.card.button_3,
              button_3_icon: response.data.data.card.button_3_icon,
              button_3_link: response.data.data.card.button_3_link,
              button_4: response.data.data.card.button_4,
              button_4_icon: response.data.data.card.button_4_icon,
              button_4_link: response.data.data.card.button_4_link,
              button_5: response.data.data.card.button_5,
              button_5_icon: response.data.data.card.button_5_icon,
              button_5_link: response.data.data.card.button_5_link,
              button_6: response.data.data.card.button_6,
              button_6_icon: response.data.data.card.button_6_icon,
              button_6_link: response.data.data.card.button_6_link,
              button_7: response.data.data.card.button_7,
              button_7_icon: response.data.data.card.button_7_icon,
              button_7_link: response.data.data.card.button_7_link,
              button_8: response.data.data.card.button_8,
              button_8_icon: response.data.data.card.button_8_icon,
              button_8_link: response.data.data.card.button_8_link,
              button_9: response.data.data.card.button_9,
              button_9_icon: response.data.data.card.button_9_icon,
              button_9_link: response.data.data.card.button_9_link,
              businessName: response.data.data.card.businessName,
              openingHours: response.data.data.card.openingHours === undefined ? this.defaultHours() : response.data.data.card.openingHours,
              slug: response.data.data.card.slug,
              category: response.data.data.card.category,
              slugOriginal: response.data.data.card.slug,
              templateId: response.data.data.card.templateId,
              templateChosen: response.data.data.card.templateId,
              template: response.data.data.template
            }, () => {
              this.setState({loading: false});
              let c = this.state;
              if (c.button_9 !== '' && c.button_9 !== undefined && c.button_9 !== null) this.setState({buttonShownIndex: 9});
              else if (c.button_8 !== '' && c.button_8 !== undefined && c.button_8 !== null) this.setState({buttonShownIndex: 8});
              else if (c.button_7 !== '' && c.button_7 !== undefined && c.button_7 !== null) this.setState({buttonShownIndex: 7});
              else if (c.button_6 !== '' && c.button_6 !== undefined && c.button_6 !== null) this.setState({buttonShownIndex: 6});
              else if (c.button_5 !== '' && c.button_5 !== undefined && c.button_5 !== null) this.setState({buttonShownIndex: 5});
              else if (c.button_4 !== '' && c.button_4 !== undefined && c.button_4 !== null) this.setState({buttonShownIndex: 4});
              else this.setState({buttonShownIndex: 3});

              let openingHours = this.state.openingHours;

              if (openingHours === null || openingHours === undefined || openingHours['A'] === null || openingHours['A'] === undefined) {

              } else {
                if (openingHours['A'][0] === '' || openingHours['A'][0].length === 0) this.close('A');
                if (openingHours['B'][0] === '' || openingHours['B'][0].length === 0) this.close('B');
                if (openingHours['C'][0] === '' || openingHours['C'][0].length === 0) this.close('C');
                if (openingHours['D'][0] === '' || openingHours['D'][0].length === 0) this.close('D');
                if (openingHours['E'][0] === '' || openingHours['E'][0].length === 0) this.close('E');
                if (openingHours['F'][0] === '' || openingHours['F'][0].length === 0) this.close('F');
                if (openingHours['G'][0] === '' || openingHours['G'][0].length === 0) this.close('G');

                this.is24hours()
              }
            });
          }
        }).catch(err => {
          this.setState({loading: true});
          console.log(err);
          alert('שגיאה בקבלת נתוני הכרטיס, אנא נסו שוב');
        });
      } catch (err) {
        this.setState({loading: true});
        console.log(err);
        alert('שגיאה בקבלת נתוני הכרטיס, אנא נסו שוב');
      }
    }
  }

  updateCard = () => {
    this.setState({errorAlert: false, error: ''});

    let payload = {
      title: this.state.title,
      secondaryTitle: this.state.secondaryTitle,
      // text: this.state.text.toString('html'),
      text: this.state.text.replace(/\r?\n/g, '<br />'),
      phoneNumber: this.state.phoneNumber,
      facebookLink: this.state.facebookLink,
      instagramLink: this.state.instagramLink,
      address: this.state.address,
      video: this.state.video,
      button_1: this.state.button_1,
      button_1_icon: this.state.button_1_icon,
      button_1_link: this.state.button_1_link,
      button_2: this.state.button_2,
      button_2_icon: this.state.button_2_icon,
      button_2_link: this.state.button_2_link,
      button_3: this.state.button_3,
      button_3_icon: this.state.button_3_icon,
      button_3_link: this.state.button_3_link,
      button_4: this.state.button_4,
      button_4_icon: this.state.button_4_icon,
      button_4_link: this.state.button_4_link,
      button_5: this.state.button_5,
      button_5_icon: this.state.button_5_icon,
      button_5_link: this.state.button_5_link,
      button_6: this.state.button_6,
      button_6_icon: this.state.button_6_icon,
      button_6_link: this.state.button_6_link,
      button_7: this.state.button_7,
      button_7_icon: this.state.button_7_icon,
      button_7_link: this.state.button_7_link,
      button_8: this.state.button_8,
      button_8_icon: this.state.button_8_icon,
      button_8_link: this.state.button_8_link,
      button_9: this.state.button_9,
      button_9_icon: this.state.button_9_icon,
      button_9_link: this.state.button_9_link,
      businessName: this.state.businessName,
      openingHours: this.state.openingHours,
      slug: this.state.slug,
      templateId: this.state.templateId,

      gallerySelectedCoverImage: '',
      gallerySelectedLogoImage: '',
    }
    console.log('--------');
    console.log(this.state.openingHours);
console.log('--------');
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          axios.put(API + 'card', payload, {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {
            if (response.data.status === "ok") {
              alert('הכרטיס עודכן בהצלחה');
              // window.location.reload();
            } else {
              alert('חלה שגיאה, אנא נסו שוב.')
            }
          }).catch(err => {
            console.log(err);
            alert('חלה שגיאה, אנא נסו שוב.')
          });
        } catch (err) {
          console.log(err);
          alert('חלה שגיאה, אנא נסו שוב.')
        }
      }


      // if (
      //   this.state.title !== '' &&
      //   this.state.secondaryTitle !== '' &&
      //   this.state.text !== '' &&
      //   this.state.phoneNumber !== '' &&
      //   this.state.facebookLink !== '' &&
      //   this.state.instagramLink !== '' &&
      //   this.state.address !== '' &&
      //   this.state.businessName !== '' &&
      //   this.state.templateId !== ''
      // ) {

      // if (this.state.title === '') this.setState({errortitle: true});
      // if (this.state.secondaryTitle === '') this.setState({errorsecondarytitle: true});
      // if (this.state.text === '') this.setState({errortext: true});
      // if (this.state.phoneNumber === '') this.setState({errorphoneNumber: true});
      // if (this.state.facebookLink === '') this.setState({errorfacebookLink: true});
      // if (this.state.instagramLink === '') this.setState({errorinstagramLink: true});
      // if (this.state.address === '') this.setState({erroraddress: true});
      // if (this.state.businessName === '') this.setState({errorbusinessName: true});

    // } else {
    //   this.setState({errorAlert: true, error: 'יש למלא את כל השדות על מנת ליצור את הכרטיס'});
    //
    //
    // }
  }

  addButtonIndex = () => {
    let index = this.state.buttonShownIndex;

    index += 1;

    this.setState({buttonShownIndex: index});
  }



  updateTemplate = () => {
    this.setState({areYouSure: false})
    let id = this.state.chosenId;

    if (id !== '' && id !== undefined && id !== null) {
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {

        axios.put(API + 'card/template?id='+id, {}, {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          console.log(response);
            this.getMyCard();
            this.setState({changeTemplateModal: false, areYouSure: false});
        }).catch((error) => {
          this.setState({changeTemplateModal: false, areYouSure: false});
        });
      }
    }
  }

  getMyCardTemplate = () => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      axios.get(API + 'card', {
        headers: {
           Authorization: `Bearer ${token}`
        }
      }).then(response => {
          this.setState({templateId: response.data.data.card.templateId, template: response.data.data.template});
      }).catch((error) => {
        this.setState({changeTemplateModal: false});
      });
    }
  }


  getMyCardImages = () => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      axios.get(API + 'card', {
        headers: {
           Authorization: `Bearer ${token}`
        }
      }).then(response => {
        console.log(response);
          this.setState({logo: response.data.data.card.logo, cover: response.data.data.card.cover});
      }).catch((error) => {

      });
    }
  }

  blobToFile = (theBlob, fileName) =>{
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }


  onFormSubmitCover = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    this.setState({loadingImage: true});


    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    console.log(this.state.file);

    const compressedFile = await imageCompression(this.state.file, options);

    // formData.append('file',this.state.file);
    const convertedBlobFile = new File([compressedFile], this.state.file.name, { type: this.state.file.type, lastModified: Date.now()})

    console.log(convertedBlobFile);

    formData.append('file', convertedBlobFile);

    let token = cookies.get('__dato__');
    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'card/cover',formData,config)
          .then((response) => {
            this.setState({file: null, uploadCoverModal: false, uploadCoverModalSuccess: true, loadingImage: false})
            // window.location.reload();
            this.getMyCardImages();
          }).catch((error) => {
            this.setState({file: null, uploadCoverModal: false, loadingImage: false})
      });
    } else {
      this.setState({loadingImage: false});
    }
  }

  onFormSubmit = async (e) => {
    this.setState({loadingImage: true});

    e.preventDefault();
    const formData = new FormData();

    const {blobFile} = this.state;

    const newImage = new File([blobFile], blobFile.name, { type: blobFile.type });

    //
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    console.log(this.state.file);

    const compressedFile = await imageCompression(newImage, options);

    // formData.append('file',this.state.file);
    const convertedBlobFile = new File([compressedFile], this.state.file.name, { type: this.state.file.type, lastModified: Date.now()})

    console.log(convertedBlobFile);

    formData.append('file', convertedBlobFile);
    //

    let token = cookies.get('__dato__');
    if (token !== null && token !== undefined && token !== '') {
      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'card/logo',formData,config)
          .then((response) => {
            this.setState({file: null, toBlob: null, croppedImageUrl: '', imageEditing: true, src: null, uploadLogoModal: false, uploadLogoModalSuccess: true, loadingImage: false, })
            // window.location.reload();
            this.getMyCardImages();
          }).catch((error) => {
            this.setState({file: null, toBlob: null, croppedImageUrl: '', imageEditing: true, src: null,  uploadLogoModal: false, loadingImage: false})
      });
    }
  }

  onChange = (e) => {
      this.setState({file:e.target.files[0], imageEditing: false});

      if (e.target.files && e.target.files.length > 0) {
       const reader = new FileReader();
       reader.addEventListener('load', () =>
         this.setState({ src: reader.result })
       );
       reader.readAsDataURL(e.target.files[0]);
     }
  }

  close = (t) => {
    let dict = this.state.close;

    if (dict[t]) {
      dict[t] = false
    } else {
      dict[t] = true
    }

    let openingHours = this.state.openingHours;

    try {
      openingHours[t] = ['', ''];
    } catch (err) {
      console.log(err);
      openingHours = {
        'A': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'B': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'C': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'D': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'E': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'F': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'G': [new Date('Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'), 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
      };
    }


    this.setState({close: dict, openingHours: openingHours});
  }

  areYouSure = (id) => {
    this.setState({chosenId: id, areYouSure: true});
  }

  setText = (text) => {
    this.setState({text: text})
  }

  /* Image crop */
  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
    onImageLoaded = image => {
      this.imageRef = image;
    };

    onCropComplete = crop => {
      this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
      // You could also use percentCrop:
      // this.setState({ crop: percentCrop });
      this.setState({ crop });
    };

    async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.png'
      );
      this.setState({ croppedImageUrl });
      console.log(croppedImageUrl);
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({blobFile:blob})
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/png');
    });
  }



  render() {
    const { crop, croppedImageUrl, src } = this.state;

    return (
      <div>
        {this.state.loading ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OfflineHeader package={this.state.package}/>
            </Grid>
            <Grid item xs={12}>
              <center>
                <div style={{textAlign: 'center', marginTop: '5%'}}>
                  <CircularProgress />
                  <h3 style={{direction: 'rtl'}}>..טוען</h3>
                </div>
              </center>
            </Grid>
          </Grid>
        ) : (
          <React.Fragment>
          <Dialog
           open={this.state.areYouSure}
           keepMounted
           onClose={() => this.setState({areYouSure: false})}
           aria-labelledby="alert-dialog-slide-title"
           aria-describedby="alert-dialog-slide-description"
           style={{
             direction: 'rtl',
             textAlign: 'right',
             whiteSpace: 'normal'
           }}
         >
           <DialogTitle id="alert-dialog-slide-title">{"החלפת תבנית"}</DialogTitle>
           <DialogContent>
             <DialogContentText id="alert-dialog-slide-description" style={{whiteSpace: 'normal'}}>
                האם אתם בטוחים שברצונכם להחליף תבנית לתבנית זו?
             </DialogContentText>
           </DialogContent>
           <DialogActions>
             <Button onClick={() => this.setState({areYouSure: false})} color="orange" variant={'contained'} style={{marginRight: '1%', marginLeft: '1%'}}>
               לא! חזור אחורה
             </Button>
             <Button onClick={() => this.updateTemplate(this.state.chosenId)} color="primary" variant={'contained'} style={{marginRight: '1%', marginLeft: '1%'}}>
               אני בטוח\ה
             </Button>
           </DialogActions>
         </Dialog>

            <BrowserView>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <OfflineHeader mobile={isMobile} package={this.state.package}/>
              </Grid>

              <Grid item xs={12} style={{marginBottom: '2%', maxWidth: '90%'}}>
              <div style={{marginRight: '4%', textAlign :'right'}}>
                <p style={{direction:'rtl', fontSize: '2em', fontWeight: 700, color: yellow, marginBottom: 0}}>
                  עריכת הכרטיס שלי
                </p>
              </div>
              </Grid>
              <Grid item xs={12}>
              <center><div style={{
                maxWidth: '80%',
                overflowX: 'auto',
                marginTop: '0%'
              }}>
              <Tabs value={this.state.desktopNavLevel}
              variant="fullWidth" centered>
                {/*<Tab value={5} label="תבנית" onClick={(e) => this.setState({mobileNavLevel: 5})}/>*/}
                <Tab value={5} label="תבנית" onClick={(e) => this.setState({desktopNavLevel: 5})}/>
                <Tab value={4} label="שעות" onClick={(e) => this.setState({desktopNavLevel: 4})}/>
                <Tab value={3} label="כפתורים" onClick={(e) => this.setState({desktopNavLevel: 3})}/>
                <Tab value={2} label="תמונות" onClick={(e) => this.setState({desktopNavLevel: 2})}/>
                <Tab value={1} label="פרטים" onClick={(e) => this.setState({desktopNavLevel: 1})}/>
              </Tabs>
              </div></center>
              </Grid>

              <Grid item xs={12} style={{marginBottom: '2%', maxWidth: '90%'}}>
                <div style={{marginRight: '4%', textAlign :'right'}}>
                  {this.state.desktopNavLevel === 5 && (
                    <React.Fragment>
                      <div style={{width: '100%'}}>
                        <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                          התבנית שלי
                        </p>

                        {(this.state.templateId === '' || this.state.templateId === null || this.state.templateId === undefined) ? (
                          <React.Fragment>
                            <p style={{direction:'rtl', color: yellow, fontSize: '1em'}}>אין לך תבנית עדיין, ללא תבנית לא נוכל ליצור את הכרטיס שלך!</p>
                            <button onClick={(e) => this.setState({changeTemplateModal: true})} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.3em'}}>לשינוי התבנית לחצו כאן</button>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <img src={('https://app.dibit.co.il/templates/'+ this.state.template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 150, height: 300}} />
                            <br />
                            <button onClick={(e) => this.setState({changeTemplateModal: true})} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.3em'}}>לשינוי התבנית לחצו כאן</button>
                          </React.Fragment>
                        )}





                        {/*<Select
                          value={this.state.templateId}
                          onChange={(e) => this.setState({templateId: e.target.value})}
                          style={{
                            width: '50%', textAlign: 'right', direction: 'rtl'
                          }}
                        >
                          {this.state.templates.map((index) => {
                            return (
                              <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={index._id}>{index.name}</MenuItem>
                            );
                          })}
                        </Select>*/}

                        <Modal
                          open={this.state.changeTemplateModal}
                          onClose={(e) => this.setState({changeTemplateModal: false})}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                          }}
                        >
                          <div style={{
                            backgroundColor: 'white',
                            border: '2px solid white',
                            padding: '2px 4px 3px',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            width: '100%'
                          }}>
                            <div style={{color: blue, backgroundColor: yellow}}>
                              <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0,}}>
                              &nbsp;  בחרו תבנית מהמאגר המדהים שלנו &nbsp;&nbsp;
                              </p>
                            </div><br />

                            <center>
                            <div style={{textAlign: 'center', width: '100%'}}>
                              <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                                {this.state.categories.reverse().map((c_) => {
                                  let category = c_.name;
                                  return (
                                    <Button onClick={(e) => this.setState({categoryChosen: category})} style={{backgroundColor: this.state.categoryChosen === category ? yellow : blue, color: 'white', borderRadius: 50, marginRight: '0.5%', marginLeft: '0.5%'}}>{category}</Button>
                                  )
                                })}
                                <Button onClick={(e) => this.setState({categoryChosen: 'all'})} style={{backgroundColor: this.state.categoryChosen === 'all' ? yellow : blue, color: 'white', borderRadius: 50, marginRight: '0.5%', marginLeft: '0.5%'}}>הכל</Button>

                              </div>
                            </div>
                            </center>
                            <br />
                            <center>
                            <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', overflow: 'hidden'}}>
                              <div style={{width: '100%'}}>
                                <Card style={{overflow: 'auto', width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                                  {this.state.templates.reverse().map((template, i) => {
                                      if (template.coverImage !== undefined && template.coverImage !== null && template.coverImage !== '') {
                                        if (this.state.categoryChosen === 'all' || this.state.categoryChosen === '') {
                                          return (
                                            <div onClick={(e) => this.areYouSure(template._id)} style={{textAlign: 'center', justifyContent: 'center', marginRight: '0.5%', marginLeft: '0.5%'}}>
                                              <p>{template.name}</p>
                                              <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} alt={template.name} onClick={(e) => this.areYouSure(template._id)} className={(this.state.templateId === template._id.toString()) ? 'halImg' : ''} style={{width: 150, height: 400}}/>
                                            </div>
                                          )
                                        } else {
                                          if (this.state.categoryChosen === template.category) {
                                            return (
                                              <div onClick={(e) => this.areYouSure(template._id)} style={{textAlign: 'center', justifyContent: 'center', marginRight: '0.5%', marginLeft: '0.5%'}}>
                                                <p>{template.name}</p>
                                                <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} alt={template.name} onClick={(e) => this.areYouSure(template._id)} className={(this.state.templateId === template._id.toString()) ? 'halImg' : ''} style={{width: 150, height: 400}}/>
                                              </div>
                                            )
                                          }
                                        }
                                      }
                                  })}
                                </Card>
                              </div>
                            </div>
                            </center>
                          </div>
                        </Modal>
                      </div>
                    </React.Fragment>
                  )}

                  {this.state.desktopNavLevel === 1 && (
                    <React.Fragment>
                      <div style={{width: '100%'}}>
                        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                          <div style={{width: '90%'}}>
                            <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                              שם העסק באנגלית <span style={{color: 'red'}}>*</span>
                            </p>
                            {(!this.state.slugAvailable && this.state.slugOriginal !== this.state.slug) && (
                              <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                                שם זה תפוס, אנא בחר אחד אחר או הוסף לו תווים
                              </p>
                            )}
                            <TextField
                              value={this.state.slug}
                              onChange={(e) => this.checkSlug(e.target.value)}
                              style={{
                                direction: 'rtl',
                                textAlign: 'right',
                                fontSize:'1.3em',
                                marginTop: '0.5%',
                                width: '80%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  direction: 'rtl',
                                  textAlign: 'right',
                                  fontSize:'1.3em',
                                  width: '80%',
                                  backgroundColor: 'white',
                                  color: blue,
                                  fontWeight: 400
                                }
                              }}
                            />

                            <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                              ללא רווחים, לדוגמה moshe-design
                            </p>
                          </div>
                          <div style={{width: '90%'}}>
                          <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                            שם העסק <span style={{color: 'red'}}>*</span>
                          </p>
                          {this.state.errorbusinessName && (
                            <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                              זהו שדה חובה!
                            </p>
                          )}
                          <TextField
                            value={this.state.businessName}
                            onChange={(e) => this.setState({businessName: e.target.value})}
                            style={{
                              direction: 'rtl',
                              textAlign: 'right',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              width: '80%',
                              color: blue
                            }}
                            inputProps={{
                              style:{
                                direction: 'rtl',
                                textAlign: 'right',
                                fontSize:'1.3em',
                                width: '80%',
                                backgroundColor: 'white',
                                color: blue,
                                fontWeight: 400
                              }
                            }}
                          />
                          </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <div style={{width: '90%'}}>
                          <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                            כותרת ראשית
                          </p>
                          {this.state.errortitle && (
                            <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                              זהו שדה חובה!
                            </p>
                          )}
                          <TextField
                            value={this.state.title}
                            onChange={(e) => this.setState({title: e.target.value})}
                            style={{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '50%',
                              fontSize:'1.3em',
                              width: '80%',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue
                            }}
                            inputProps={{
                              style:{
                                direction: 'rtl',
                                textAlign: 'right',
                                fontSize:'1.3em',
                                width: '80%',
                                backgroundColor: 'white',
                                color: blue,
                                fontWeight: 400
                              }
                            }}
                          />
                          </div>
                          <div style={{width: '90%'}}>
                          <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                            כותרת משנית
                          </p>
                          {this.state.errorsecondarytitle && (
                            <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                              זהו שדה חובה!
                            </p>
                          )}
                          <TextField
                            value={this.state.secondaryTitle}
                            onChange={(e) => this.setState({secondaryTitle: e.target.value})}
                            style={{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '80%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue
                            }}
                            inputProps={{
                              style:{
                                direction: 'rtl',
                                textAlign: 'right',
                                fontSize:'1.3em',
                                width: '80%',
                                backgroundColor: 'white',
                                color: blue,
                                fontWeight: 400
                              }
                            }}
                          />
                          </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <div style={{width: '90%'}}>
                          <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                            מספר הטלפון המקושר לכרטיס <span style={{color: 'red'}}>*</span>
                          </p>
                          {this.state.errorphoneNumber && (
                            <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                              זהו שדה חובה!
                            </p>
                          )}
                          <TextField
                            value={this.state.phoneNumber}
                            onChange={(e) => this.setState({phoneNumber: e.target.value})}
                            style={{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '80%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue
                            }}
                            inputProps={{
                              style:{
                                direction: 'rtl',
                                textAlign: 'right',
                                fontSize:'1.3em',
                                width: '80%',
                                backgroundColor: 'white',
                                color: blue,
                                fontWeight: 400
                              }
                            }}
                          />
                          </div>
                          <div style={{width: '90%'}}>
                          <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                            טקסט הכרטיס
                          </p>
                          {this.state.errortext && (
                            <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                              זהו שדה חובה!
                            </p>
                          )}
                          {/*<div style={{width: '100%'}}>
                            <div style={{textAlign: 'right', width: '80%', display: 'inline-block'}}>
                              <RichTextEditor
                                value={this.state.text}
                                onChange={this.onChangeText}
                                toolbarConfig={toolbarConfig}
                                placeholder={'טקסט'}
                              />
                            </div>
                          </div>*/}
                          <textarea
                            value={this.state.text}
                            onChange={(e) => this.setText(e.target.value)}
                            style={{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '80%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue,
                              whiteSpace: 'pre-wrap'
                            }}
                            inputProps={{
                              style:{
                                direction: 'rtl',
                                textAlign: 'right',
                                fontSize:'1.3em',
                                width: '80%',
                                backgroundColor: 'white',
                                color: blue,
                                fontWeight: 400,
                                whiteSpace: 'pre-wrap'
                              }
                            }}
                            rows={4}
                            placeholder={'טקסט כאן'}
                          ></textarea>
                          </div>
                        </div>
                      </div>

                      <br />


                      {/*<textarea rows="4" cols="50"
                        value={this.state.text}
                        onChange={(e) => this.setState({text: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%'
                        }}
                      ></textarea>*/}

                    </React.Fragment>
                  )}
                  {this.state.desktopNavLevel === 2 && (
                    <React.Fragment>
                    {/*<Link to='/gallery' style={{textDecoration: 'none'}}><button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.2em'}}>לעריכת הגלרייה, לחץ כאן</button></Link>*/}


                    {/*<p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                      התמונות שלי
                    </p>*/}

                    <center>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'}}>
                        {(this.state.logo !== '' && this.state.logo !== undefined && this.state.logo !== null) && (
                          <div style={{marginRight: '3%'}}>
                            <p style={{color: blue, fontSize: '1.5em'}}>לוגו</p>
                            {(this.state.logo.includes('/gallery/') || this.state.logo.includes('/templates/')) ? (
                              <img src={this.state.logo} style={{marginTop: 2, width: 150, borderRadius: '100%', padding: 0, height: 150, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', objectFit: 'cover'}}/>
                            ) : (
                              <img src={'https://app.dibit.co.il/logos/'+this.state.logo} style={{marginTop: 2, width: 150, borderRadius: '100%', padding: 0, height: 150, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', objectFit: 'cover'}}/>
                            )}
                          </div>
                        )}
                        {(this.state.cover !== '' && this.state.cover !== undefined && this.state.cover !== null) && (
                          <div>
                            <p style={{color: blue, fontSize: '1.5em'}}>תמונת רקע</p>
                            {(this.state.cover.includes('/gallery/') || this.state.cover.includes('/templates/')) ? (
                              <img src={this.state.cover} style={{marginTop: 2, width: 350, padding: 0, height: 200, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', objectFit: 'cover'}}/>
                            ) : (
                              <img src={'https://app.dibit.co.il/logos/'+this.state.cover} style={{marginTop: 2, width: 350, padding: 0, height: 200, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', objectFit: 'cover'}}/>
                            )}
                          </div>
                        )}
                      </div>
                      <br />
                    </center>

                    <button onClick={(e) => this.setState({uploadLogoModal: true})} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>{this.state.logo === '' ? 'העלאת הלוגו' : 'עדכון הלוגו'}</button>
                    <span style={{fontSize: '1.3em'}}>&nbsp; או &nbsp;</span>
                    <button onClick={(e) => this.setState({uploadCoverModal: true})} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>העלאת תמונת רקע</button>

                    <Modal
                      open={this.state.uploadLogoModal}
                      onClose={(e) => this.setState({uploadLogoModal: false})}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{
                        backgroundColor: 'white',
                        border: '2px solid white',
                        padding: '5px 8px 6px',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                      }}>
                        {this.state.logoImageShowGalleryBool ? (
                          <React.Fragment>
                          <div style={{textAlign: 'right'}}>
                            <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700,}}>
                            בחרו תמונה מהגלרייה המדהימה שלנו
                            </p>

                            <div>
                              <p>חפש תמונה על פי מילה המגדירה אותה</p>

                              <button onClick={(e) => this.resetGallery()} style={{backgroundColor: 'white', color: blue, border: '1px solid ' + blue, fontSize: '1.5em'}}>איפוס</button>
                              &nbsp;
                              <button onClick={(e) => this.searchGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>חפש</button>
                              &nbsp;
                              <TextField
                                value={this.state.search_gallery}
                                onChange={(e) => this.setState({search_gallery: e.target.value})}
                                style={{width: '70%',
                                direction: 'rtl',
                                textAlign: 'right',}}
                                inputProps={{
                                  style: {
                                    direction: 'rtl',
                                    textAlign: 'right',
                                  }
                                }}
                              />
                              <br />
                              <br />
                            </div>
                          </div>
                          <Card style={{minWidth: '100%', height: 350, overflowY: 'auto'}}>
                          {this.state.gallery.map((image, i) => {
                            if (this.state.gallerySelectedLogoImage !== '') {
                              if (i % 3 !== 0 || i === 0) {
                                if (image._id.toString() === this.state.gallerySelectedLogoImage) {
                                  return (
                                    <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                  )
                                } else {
                                  return (
                                    <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                                  )
                                }
                              } else {
                                if (image._id.toString() === this.state.gallerySelectedLogoImage) {
                                  return (
                                    <React.Fragment>
                                      <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                      <br />
                                    </React.Fragment>
                                  )
                                } else {
                                  return (
                                    <React.Fragment>
                                      <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                                      <br />
                                    </React.Fragment>
                                  )
                                }
                              }
                            } else {
                              if (i % 3 !== 0 || i === 0) {
                                if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                                  return (
                                    <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                  )
                                } else {
                                  return (
                                    <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                                  )
                                }
                              } else {
                                if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                                  return (
                                    <React.Fragment>
                                      <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                      <br />
                                    </React.Fragment>
                                  )
                                } else {
                                  return (
                                    <React.Fragment>
                                      <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                                      <br />
                                    </React.Fragment>
                                  )
                                }
                              }
                            }
                          })}
                          </Card>
                          <br />
                          <div style={{textAlign: 'right'}}>
                            <button onClick={(e) => this.updateLogoFromGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>בחר ועדכן</button>
                          </div>
                          <p style={{fontSize: '1.3em', direction: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                          <div style={{textAlign: 'right'}}>
                            <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}} onClick={(e) => this.setState({logoImageShowGalleryBool: false})}>או העלו תמונה משלכם</button>
                          </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <p style={{direction:'rtl', color: blue, fontSize: '1.7em', fontWeight: 700,}}>
                              הלוגו שלכם
                            </p>
                            <p style={{direction:'rtl', color: yellow, fontSize: '1.4em', fontWeight: 700,}}>
                              בחרו את לוגו העסק או תמונה ייצוגית אחרת
                            </p>

                            {(this.state.logo !== null && this.state.logo !== undefined && this.state.logo !== '') && (this.state.imageEditing) && (
                              <center>
                                {(this.state.logo.includes('/gallery/') || this.state.logo.includes('/templates/')) ? (
                                  <img src={this.state.logo} style={{marginTop: 2, width: 225, borderRadius: '100%', padding: 0, height: 225, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}/>
                                ) : (
                                  <img src={'https://app.dibit.co.il/logos/'+this.state.logo} style={{marginTop: 2, width: 225, borderRadius: '100%', padding: 0, height: 225, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}/>
                                )}
                              </center>
                            )}

                            {src && (
                              <center>
                              <div style={{textAlign: 'center'}}>
                              <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                                style={{
                                  width: 300,
                                  height: 'auto'
                                }}
                              />
                              </div>
                              </center>
                            )}
                            {/*croppedImageUrl && (
                              <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
                            )*/}

                            {this.state.loadingImage && (
                              <React.Fragment>
                                <center>
                                  <p>מעלה את התמונה, אנא המתן</p>
                                  <CircularProgress />
                                </center>
                              </React.Fragment>
                            )}

                            <form onSubmit={this.onFormSubmit}>
                              <br />
                              <div style={{textAlign: 'right'}}>

                              {(this.state.file !== undefined && this.state.file !== null && this.state.file !== '') && (
                                <React.Fragment>
                                <p style={{color: blue, fontSize: '1em', direction: 'rtl'}}>הקובץ שנבחר: {this.state.file.name}</p>
                                <button type="submit" style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>לסיום והעלאה לחץ/י כאן</button>
                                <br /><br />
                                </React.Fragment>
                              )}

                              <div class="upload-btn-wrapper">
                                <button class="btn">לחצו כאן לבחירת התמונה</button>
                                <input type="file" name="myImage" onChange={this.onChange} accept="image/*"/>
                              </div>




                              <p style={{fontSize: '1.3em', direction: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                              </div>
                            </form>
                            <div style={{textAlign: 'right'}}>
                              <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em', textAlign: 'right'}} onClick={(e) => this.setState({logoImageShowGalleryBool: true})}>או בחר מתוך הגלרייה שלנו</button>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </Modal>
                    <Snackbar open={this.state.uploadLogoModalSuccess} autoHideDuration={6000} onClose={(e) => this.setState({uploadLogoModalSuccess: false})}>
                      <Alert variant="filled" severity="success">!הלוגו הועלה בהצלחה ויתעדכן בהקדם בכרטיס הדיגיטלי שלך</Alert>
                    </Snackbar>

                    <Modal
                      open={this.state.uploadCoverModal}
                      onClose={(e) => this.setState({uploadCoverModal: false})}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{
                        backgroundColor: 'white',
                        border: '2px solid white',
                        padding: '2px 4px 3px',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        minWidth: isMobile ? 'auto' : 500,
                        minHeight: isMobile ? 'auto' : 400
                      }}>
                        {this.state.coverImageShowGalleryBool ? (
                          <React.Fragment>
                          <div style={{textAlign: 'right'}}>
                            <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700,}}>
                            בחרו תמונה מהגלרייה המדהימה שלנו
                            </p>

                            <div>
                              <p>חפש תמונה על פי מילה המגדירה אותה</p>

                              <button onClick={(e) => this.resetGallery()} style={{backgroundColor: 'white', color: blue, border: '1px solid ' + blue, fontSize: '1.5em'}}>איפוס</button>
                              &nbsp;
                              <button onClick={(e) => this.searchGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>חפש</button>
                              &nbsp;
                              <TextField
                                value={this.state.search_gallery}
                                onChange={(e) => this.setState({search_gallery: e.target.value})}
                                style={{width: '70%',
                                direction: 'rtl',
                                textAlign: 'right',}}
                                inputProps={{
                                  style: {
                                    direction: 'rtl',
                                    textAlign: 'right',
                                  }
                                }}
                              />
                              <br />
                              <br />
                            </div>
                          </div>
                          <Card style={{minWidth: '100%', height: 350, overflowY: 'auto'}}>
                          {this.state.gallery.map((image, i) => {
                            console.log(i);
                            console.log(image);
                            if (this.state.gallerySelectedCoverImage !== '') {
                              if (i % 3 !== 0 || i === 0) {
                                if (image._id.toString() === this.state.gallerySelectedCoverImage) {
                                  return (
                                    <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                  )
                                } else {
                                  return (
                                    <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                                  )
                                }
                              } else {
                                if (image._id.toString() === this.state.gallerySelectedCoverImage) {
                                  return (
                                    <React.Fragment>
                                      <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                      <br />
                                    </React.Fragment>
                                  )
                                } else {
                                  return (
                                    <React.Fragment>
                                      <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                                      <br />
                                    </React.Fragment>
                                  )
                                }
                              }
                            } else {
                              if (i % 3 !== 0 || i === 0) {
                                if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                                  return (
                                    <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                  )
                                } else {
                                  return (
                                    <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                                  )
                                }
                              } else {
                                if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                                  return (
                                    <React.Fragment>
                                      <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                      <br />
                                    </React.Fragment>
                                  )
                                } else {
                                  return (
                                    <React.Fragment>
                                      <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                                      <br />
                                    </React.Fragment>
                                  )
                                }
                              }
                            }
                          })}
                          </Card>
                          <br />
                          <div style={{textAlign: 'right'}}>
                            <button onClick={(e) => this.updateCoverFromGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>בחר ועדכן</button>
                          </div>
                          <p style={{fontSize: '1.3em', direction: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                          <div style={{textAlign: 'right'}}>
                            <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}} onClick={(e) => this.setState({coverImageShowGalleryBool: false})}>או העלו תמונה משלכם</button>
                          </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                          <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700,}}>
                            תמונת הרקע שלי
                          </p>
                          <p style={{direction:'rtl', color: yellow, fontSize: '1.4em', fontWeight: 700,}}>
                            זוהי התמונה שתופיע מאחורי הלוגו שלך
                          </p>
                          {(this.state.cover !== '' && this.state.cover !== undefined && this.state.cover !== null) && (
                            <center>
                            {(this.state.cover.includes('/gallery/') || this.state.cover.includes('/templates/')) ? (
                              <img src={this.state.cover} style={{maxWidth: '60vh', maxHeight: '200px'}}/>
                            ) : (
                              <img src={'https://app.dibit.co.il/logos/'+this.state.cover} style={{maxWidth: '60vh', maxHeight: '200px'}}/>
                            )}
                            </center>
                          )}

                          {this.state.loadingImage && (
                            <React.Fragment>
                              <center>
                                <p>מעלה את התמונה, אנא המתן</p>
                                <CircularProgress />
                              </center>
                            </React.Fragment>
                          )}

                          <form onSubmit={this.onFormSubmitCover}>
                            <br />
                            <div style={{textAlign: 'right'}}>
                            <div class="upload-btn-wrapper">
                              <button class="btn">לחצו כאן לבחירת התמונה</button>
                              <input type="file" name="myImage" onChange={this.onChange} accept="image/*"/>
                            </div>

                            {(this.state.file !== undefined && this.state.file !== null && this.state.file !== '') && (
                              <React.Fragment>
                              <p style={{color: blue, fontSize: '1em', direction: 'rtl'}}>הקובץ שנבחר: {this.state.file.name}</p>
                              <button type="submit" style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>להעלאה לחץ/י כאן</button>
                              </React.Fragment>
                            )}

                            <p style={{fontSize: '1.3em', direction: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                            </div>
                          </form>
                          <div style={{textAlign: 'right'}}>
                            <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em', textAlign: 'right'}} onClick={(e) => this.setState({coverImageShowGalleryBool: true})}>או בחר מתוך הגלרייה שלנו</button>
                          </div>
                          </React.Fragment>
                        )}

                      </div>
                    </Modal>
                    <Snackbar open={this.state.uploadCoverModalSuccess} autoHideDuration={6000} onClose={(e) => this.setState({uploadCoverModalSuccess: false})}>
                      <Alert variant="filled" severity="success">!תמונת הרקע שלכם התעדכנה בהצלחה ותעודכן בכרטיס הדיגיטלי שלכם בהקדם</Alert>
                    </Snackbar>
                    </React.Fragment>
                  )}
                  {this.state.desktopNavLevel === 3 && (
                    <React.Fragment>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '80%'}}>
                      <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                        לינק לפייסבוק שלי
                      </p>
                      {this.state.errorfacebookLink && (
                        <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                          זהו שדה חובה!
                        </p>
                      )}
                      <TextField
                        value={this.state.facebookLink}
                        onChange={(e) => this.setState({facebookLink: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '80%',
                          fontSize:'1.3em',
                          marginTop: '0.5%',
                          backgroundColor: 'white',
                          color: blue
                        }}
                        inputProps={{
                          style:{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '80%',
                            fontSize:'1.3em',
                            marginTop: '0.5%',
                            backgroundColor: 'white',
                            color: blue
                          }
                        }}
                      />
                      </div>
                      <div style={{width: '80%'}}>
                      <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                        לינק לאינטסגרם שלי
                      </p>
                      {this.state.errorinstagramLink && (
                        <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                          זהו שדה חובה!
                        </p>
                      )}
                      <TextField
                        value={this.state.instagramLink}
                        onChange={(e) => this.setState({instagramLink: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '80%',
                          fontSize:'1.3em',
                          marginTop: '0.5%',
                          backgroundColor: 'white',
                          color: blue
                        }}
                        inputProps={{
                          style:{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '80%',
                            fontSize:'1.3em',
                            marginTop: '0.5%',
                            backgroundColor: 'white',
                            color: blue
                          }
                        }}
                      />
                      </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '80%'}}>
                        <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                          כתובת לוויז
                        </p>
                        {this.state.erroraddress && (
                          <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                            זהו שדה חובה!
                          </p>
                        )}
                        <TextField
                          value={this.state.address}
                          onChange={(e) => this.setState({address: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '80%',
                            fontSize:'1.3em',
                            marginTop: '0.5%',
                            backgroundColor: 'white',
                            color: blue
                          }}
                          inputProps={{
                            style:{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '80%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue
                            }
                          }}
                        />
                      </div>
                      <div style={{width: '80%'}}>
                      <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                        כתובת לסרטון
                      </p>
                      <TextField
                        value={this.state.video}
                        onChange={(e) => this.setState({video: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '80%',
                          fontSize:'1.3em',
                          marginTop: '0.5%',
                          backgroundColor: 'white',
                          color: blue
                        }}
                        inputProps={{
                          style:{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '80%',
                            fontSize:'1.3em',
                            marginTop: '0.5%',
                            backgroundColor: 'white',
                            color: blue
                          }
                        }}
                      />
                      </div>
                    </div>




                    <br />

                    <br />
                    <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>
                      כפתור מספר 1
                    </p>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                      סוג הכפתור
                    </p>
                    <Select
                      value={this.state.button_1}
                      onChange={(e) => this.setState({button_1: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '50%',
                        fontSize:'1.3em',
                        marginTop: '0.5%',
                        backgroundColor: 'white',
                        color: blue
                      }}
                      inputProps={{
                        style:{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%',
                          backgroundColor: 'white',
                          color: blue
                        }
                      }}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'website'}>אתר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'shop'}>חנות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'waze'}>וויז</MenuItem>
                    </Select>
                    {(this.state.button_1 === 'website' || this.state.button_1 === 'shop' || this.state.button_1 === 'portfolio') && (
                      <React.Fragment>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                          קישור
                        </p>
                        <TextField
                          value={this.state.button_1_link}
                          onChange={(e) => this.setState({button_1_link: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '50%',
                            fontSize:'1.3em',
                            marginTop: '0.5%',
                            backgroundColor: 'white',
                            color: blue
                          }}
                          inputProps={{
                            style:{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '50%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue
                            }
                          }}
                        />
                      </React.Fragment>
                    )}
                    <br />
                    <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                      כפתור מספר 2
                    </p>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                      סוג הכפתור
                    </p>
                    <Select
                      value={this.state.button_2}
                      onChange={(e) => this.setState({button_2: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '50%',
                        fontSize:'1.3em',
                        marginTop: '0.5%',
                        backgroundColor: 'white',
                        color: blue
                      }}
                      inputProps={{
                        style:{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%',
                          backgroundColor: 'white',
                          color: blue
                        }
                      }}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'website'}>אתר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'shop'}>חנות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'waze'}>וויז</MenuItem>
                    </Select>
                    {(this.state.button_2 === 'website' || this.state.button_2 === 'shop' || this.state.button_2 === 'portfolio') && (
                      <React.Fragment>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                          קישור
                        </p>
                        <TextField
                          value={this.state.button_2_link}
                          onChange={(e) => this.setState({button_2_link: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '50%',
                            fontSize:'1.3em',
                            marginTop: '0.5%',
                            backgroundColor: 'white',
                            color: blue
                          }}
                          inputProps={{
                            style:{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '50%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue
                            }
                          }}
                        />
                      </React.Fragment>
                    )}
                    <br />
                    <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                      כפתור מספר 3
                    </p>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                      סוג הכפתור
                    </p>
                    <Select
                      value={this.state.button_3}
                      onChange={(e) => this.setState({button_3: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '50%',
                        fontSize:'1.3em',
                        marginTop: '0.5%',
                        backgroundColor: 'white',
                        color: blue
                      }}
                      inputProps={{
                        style:{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%',
                          backgroundColor: 'white',
                          color: blue
                        }
                      }}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'website'}>אתר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'shop'}>חנות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'waze'}>וויז</MenuItem>
                    </Select>
                    {(this.state.button_3 === 'website' || this.state.button_3 === 'shop' || this.state.button_3 === 'portfolio') && (
                      <React.Fragment>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                          קישור
                        </p>
                        <TextField
                          value={this.state.button_3_link}
                          onChange={(e) => this.setState({button_3_link: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '50%',
                            fontSize:'1.3em',
                            marginTop: '0.5%',
                            backgroundColor: 'white',
                            color: blue
                          }}
                          inputProps={{
                            style:{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '50%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue
                            }
                          }}
                        />
                      </React.Fragment>
                    )}
                    <br />

                    {this.state.buttonShownIndex >= 4 && (
                      <React.Fragment>
                        <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                          כפתור מספר 4
                        </p>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                          סוג הכפתור
                        </p>
                        <Select
                          value={this.state.button_4}
                          onChange={(e) => this.setState({button_4: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '50%',
                            fontSize:'1.3em',
                            marginTop: '0.5%',
                            backgroundColor: 'white',
                            color: blue
                          }}
                          inputProps={{
                            style:{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '50%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue
                            }
                          }}
                        >
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'website'}>אתר</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'shop'}>חנות</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                          <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'waze'}>וויז</MenuItem>
                        </Select>
                        {(this.state.button_4 === 'website' || this.state.button_4 === 'shop' || this.state.button_4 === 'portfolio') && (
                          <React.Fragment>
                            <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                              קישור
                            </p>
                            <TextField
                              value={this.state.button_4_link}
                              onChange={(e) => this.setState({button_4_link: e.target.value})}
                              style={{
                                direction: 'rtl',
                                textAlign: 'right',
                                width: '50%',
                                fontSize:'1.3em',
                                marginTop: '0.5%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  direction: 'rtl',
                                  textAlign: 'right',
                                  width: '50%',
                                  fontSize:'1.3em',
                                  marginTop: '0.5%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </React.Fragment>
                        )}
                        <br />
                      </React.Fragment>
                    )}


                    {this.state.buttonShownIndex >= 5 && (
                      <React.Fragment>
                      <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                        כפתור מספר 5
                      </p>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                        סוג הכפתור
                      </p>
                      <Select
                        value={this.state.button_5}
                        onChange={(e) => this.setState({button_5: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%',
                          backgroundColor: 'white',
                          color: blue
                        }}
                        inputProps={{
                          style:{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '50%',
                            fontSize:'1.3em',
                            marginTop: '0.5%',
                            backgroundColor: 'white',
                            color: blue
                          }
                        }}
                      >
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'website'}>אתר</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'shop'}>חנות</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'waze'}>וויז</MenuItem>
                      </Select>
                      {(this.state.button_5 === 'website' || this.state.button_5 === 'shop' || this.state.button_5 === 'portfolio') && (
                        <React.Fragment>
                          <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                            קישור
                          </p>
                          <TextField
                            value={this.state.button_5_link}
                            onChange={(e) => this.setState({button_5_link: e.target.value})}
                            style={{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '50%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue
                            }}
                            inputProps={{
                              style:{
                                direction: 'rtl',
                                textAlign: 'right',
                                width: '50%',
                                fontSize:'1.3em',
                                marginTop: '0.5%',
                                backgroundColor: 'white',
                                color: blue
                              }
                            }}
                          />
                        </React.Fragment>
                      )}
                      <br />
                      </React.Fragment>
                    )}

                    {this.state.buttonShownIndex >= 6 && (
                      <React.Fragment>
                      <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                        כפתור מספר 6
                      </p>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                        סוג הכפתור
                      </p>
                      <Select
                        value={this.state.button_6}
                        onChange={(e) => this.setState({button_6: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%',
                          backgroundColor: 'white',
                          color: blue
                        }}
                        inputProps={{
                          style:{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '50%',
                            fontSize:'1.3em',
                            marginTop: '0.5%',
                            backgroundColor: 'white',
                            color: blue
                          }
                        }}
                      >
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'website'}>אתר</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'shop'}>חנות</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'waze'}>וויז</MenuItem>
                      </Select>
                      {(this.state.button_6 === 'website' || this.state.button_6 === 'shop' || this.state.button_6 === 'portfolio') && (
                        <React.Fragment>
                          <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                            קישור
                          </p>
                          <TextField
                            value={this.state.button_6_link}
                            onChange={(e) => this.setState({button_6_link: e.target.value})}
                            style={{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '50%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue
                            }}
                            inputProps={{
                              style:{
                                direction: 'rtl',
                                textAlign: 'right',
                                width: '50%',
                                fontSize:'1.3em',
                                marginTop: '0.5%',
                                backgroundColor: 'white',
                                color: blue
                              }
                            }}
                          />
                        </React.Fragment>
                      )}
                      <br />
                      </React.Fragment>
                    )}


                    {this.state.buttonShownIndex >= 7 && (
                      <React.Fragment>
                      <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                        כפתור מספר 7
                      </p>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                        סוג הכפתור
                      </p>
                      <Select
                        value={this.state.button_7}
                        onChange={(e) => this.setState({button_7: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%',
                          backgroundColor: 'white',
                          color: blue
                        }}
                        inputProps={{
                          style:{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '50%',
                            fontSize:'1.3em',
                            marginTop: '0.5%',
                            backgroundColor: 'white',
                            color: blue
                          }
                        }}
                      >
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'website'}>אתר</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'shop'}>חנות</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'waze'}>וויז</MenuItem>
                      </Select>
                      {(this.state.button_7 === 'website' || this.state.button_7 === 'shop' || this.state.button_7 === 'portfolio') && (
                        <React.Fragment>
                          <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                            קישור
                          </p>
                          <TextField
                            value={this.state.button_7_link}
                            onChange={(e) => this.setState({button_7_link: e.target.value})}
                            style={{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '50%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue
                            }}
                            inputProps={{
                              style:{
                                direction: 'rtl',
                                textAlign: 'right',
                                width: '50%',
                                fontSize:'1.3em',
                                marginTop: '0.5%',
                                backgroundColor: 'white',
                                color: blue
                              }
                            }}
                          />
                        </React.Fragment>
                      )}
                      <br />
                      </React.Fragment>
                    )}

                    {this.state.buttonShownIndex >= 8 && (
                      <React.Fragment>
                      <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                        כפתור מספר 8
                      </p>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                        סוג הכפתור
                      </p>
                      <Select
                        value={this.state.button_8}
                        onChange={(e) => this.setState({button_8: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%',
                          backgroundColor: 'white',
                          color: blue
                        }}
                        inputProps={{
                          style:{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '50%',
                            fontSize:'1.3em',
                            marginTop: '0.5%',
                            backgroundColor: 'white',
                            color: blue
                          }
                        }}
                      >
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'website'}>אתר</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'shop'}>חנות</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'waze'}>וויז</MenuItem>
                      </Select>
                      {(this.state.button_8 === 'website' || this.state.button_8 === 'shop' || this.state.button_8 === 'portfolio') && (
                        <React.Fragment>
                          <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                            קישור
                          </p>
                          <TextField
                            value={this.state.button_8_link}
                            onChange={(e) => this.setState({button_8_link: e.target.value})}
                            style={{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '50%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue
                            }}
                            inputProps={{
                              style:{
                                direction: 'rtl',
                                textAlign: 'right',
                                width: '50%',
                                fontSize:'1.3em',
                                marginTop: '0.5%',
                                backgroundColor: 'white',
                                color: blue
                              }
                            }}
                          />
                        </React.Fragment>
                      )}
                      <br />
                      </React.Fragment>
                    )}

                    {this.state.buttonShownIndex === 9 && (
                      <React.Fragment>

                      <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                        כפתור מספר 9
                      </p>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                        סוג הכפתור
                      </p>
                      <Select
                        value={this.state.button_9}
                        onChange={(e) => this.setState({button_9: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%',
                          backgroundColor: 'white',
                          color: blue
                        }}
                        inputProps={{
                          style:{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '50%',
                            fontSize:'1.3em',
                            marginTop: '0.5%',
                            backgroundColor: 'white',
                            color: blue
                          }
                        }}
                      >
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'website'}>אתר</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'shop'}>חנות</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'waze'}>וויז</MenuItem>
                      </Select>
                      {(this.state.button_9 === 'website' || this.state.button_9 === 'shop' || this.state.button_9 === 'portfolio') && (
                        <React.Fragment>
                          <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                            קישור
                          </p>
                          <TextField
                            value={this.state.button_9_link}
                            onChange={(e) => this.setState({button_9_link: e.target.value})}
                            style={{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '50%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              color: blue
                            }}
                            inputProps={{
                              style:{
                                direction: 'rtl',
                                textAlign: 'right',
                                width: '50%',
                                fontSize:'1.3em',
                                marginTop: '0.5%',
                                backgroundColor: 'white',
                                color: blue
                              }
                            }}
                          />
                        </React.Fragment>
                      )}
                      </React.Fragment>
                    )}

                    {this.state.buttonShownIndex <= 8 && (
                      <React.Fragment>
                        <br />
                        <Button onClick={(e) => this.addButtonIndex()} style={{color: yellow, backgroundColor: blue, fontSize: '1.3em', marginRight: '1%'}}>
                          + הוספת כפתור נוסף
                        </Button>
                      </React.Fragment>
                    )}
                    {this.state.buttonShownIndex >= 4 && (
                      <React.Fragment>
                        <Button onClick={(e) => this.remove_button()} style={{color: yellow, backgroundColor: blue, fontSize: '1.3em'}}>
                        <DeleteForeverIcon />  מחק כפתור
                        </Button>
                      </React.Fragment>
                    )}
                    </React.Fragment>
                  )}
                  {this.state.desktopNavLevel === 4 && (
                    <React.Fragment>
                    <div>
                      <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                        שעות הפעילות של העסק <span style={{color: 'red'}}>*</span>
                      </p>
                      <Checkbox
                        value={this.state.open24h}
                        checked={this.state.open24h}
                        onChange={(e) => this.setOpen24H()}
                        color={yellow}
                      /> <span style={{fontSize: '1.3em'}}>פתוח 24/7</span>
                      <br />

                      {!this.state.open24h && (
                        <React.Fragment>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                          יום ראשון
                        </p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                              <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                                <span>סגור ביום זה <Checkbox
                                  value={this.state.close['A']}
                                  checked={this.state.close['A']}
                                  onChange={(e) => this.close('A')}
                                  color={yellow}
                                  style={{color: blue}}
                                /></span>
                                &nbsp;
                                {this.state.close['A'] && (
                                  <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                                )}
                              </div>
                              {!this.state.close['A'] && (
                                <div>
                                  <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="עד שעה"
                                    value={this.getOpeningHours('A', 'to')}
                                    onChange={(time) => this.setOpeningHours('A', 'to', time)}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                    ampm={false}
                                  />
                                  &nbsp;&nbsp;
                                  <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="משעה"
                                    value={this.getOpeningHours('A', 'from')}
                                    onChange={(time) => this.setOpeningHours('A', 'from', time)}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                    ampm={false}
                                  />
                                </div>
                              )}

                            </div>
                        </MuiPickersUtilsProvider>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                          יום שני
                        </p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                            <span>סגור ביום זה <Checkbox
                              value={this.state.close['B']}
                              checked={this.state.close['B']}
                              onChange={(e) => this.close('B')}
                              color={yellow}
                              style={{color: blue}}
                            /></span>
                            &nbsp;
                            {this.state.close['B'] && (
                              <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                            )}
                            </div>

                            {!this.state.close['B'] && (
                              <div>
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="עד שעה"
                                value={this.getOpeningHours('B', 'to')}
                                onChange={(time) => this.setOpeningHours('B', 'to', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                              &nbsp;&nbsp;
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="משעה"
                                value={this.getOpeningHours('B', 'from')}
                                onChange={(time) => this.setOpeningHours('B', 'from', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                              </div>
                            )}
                          </div>
                        </MuiPickersUtilsProvider>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                          יום שלישי
                        </p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                              <span>סגור ביום זה <Checkbox
                                value={this.state.close['C']}
                                checked={this.state.close['C']}
                                onChange={(e) => this.close('C')}
                                color={yellow}
                                style={{color: blue}}
                              /></span>
                              &nbsp;
                              {this.state.close['C'] && (
                                <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                              )}
                            </div>
                            {!this.state.close['C'] && (
                              <div>
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="עד שעה"
                                value={this.getOpeningHours('C', 'to')}
                                onChange={(time) => this.setOpeningHours('C', 'to', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                              &nbsp;&nbsp;
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="משעה"
                                value={this.getOpeningHours('C', 'from')}
                                onChange={(time) => this.setOpeningHours('C', 'from', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                              </div>
                            )}
                          </div>
                        </MuiPickersUtilsProvider>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                          יום רביעי
                        </p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                            <span>סגור ביום זה <Checkbox
                              value={this.state.close['D']}
                              onChange={(e) => this.close('D')}
                              checked={this.state.close['D']}
                              color={yellow}
                              style={{color: blue}}
                            /></span>
                            &nbsp;
                            {this.state.close['D'] && (
                              <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                            )}
                            </div>

                            {!this.state.close['D'] && (
                              <div>
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="עד שעה"
                                value={this.getOpeningHours('D', 'to')}
                                onChange={(time) => this.setOpeningHours('D', 'to', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                              &nbsp;&nbsp;
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="משעה"
                                value={this.getOpeningHours('D', 'from')}
                                onChange={(time) => this.setOpeningHours('D', 'from', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                              </div>
                            )}
                          </div>
                        </MuiPickersUtilsProvider>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                          יום חמישי
                        </p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                            <span>סגור ביום זה <Checkbox
                              value={this.state.close['E']}
                              checked={this.state.close['E']}
                              onChange={(e) => this.close('E')}
                              color={yellow}
                              style={{color: blue}}
                            /></span>
                            &nbsp;
                            {this.state.close['E'] && (
                              <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                            )}
                            </div>

                            {!this.state.close['E'] && (
                              <div>
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="עד שעה"
                                value={this.getOpeningHours('E', 'to')}
                                onChange={(time) => this.setOpeningHours('E', 'to', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                              &nbsp;&nbsp;
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="משעה"
                                value={this.getOpeningHours('E', 'from')}
                                onChange={(time) => this.setOpeningHours('E', 'from', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                              </div>
                            )}

                          </div>
                        </MuiPickersUtilsProvider>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                          יום שישי
                        </p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                            <span>סגור ביום זה <Checkbox
                              value={this.state.close['F']}
                              checked={this.state.close['F']}
                              onChange={(e) => this.close('F')}
                              color={yellow}
                              style={{color: blue}}
                            /></span>
                            &nbsp;
                            {this.state.close['F'] && (
                              <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                            )}
                            </div>

                            {!this.state.close['F'] && (
                              <div>
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="עד שעה"
                                value={this.getOpeningHours('F', 'to')}
                                onChange={(time) => this.setOpeningHours('F', 'to', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                              &nbsp;&nbsp;
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="משעה"
                                value={this.getOpeningHours('F', 'from')}
                                onChange={(time) => this.setOpeningHours('F', 'from', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                              </div>
                            )}

                          </div>
                        </MuiPickersUtilsProvider>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                          יום שבת
                        </p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                            <span>סגור ביום זה <Checkbox
                              value={this.state.close['G']}
                              checked={this.state.close['G']}
                              onChange={(e) => this.close('G')}
                              color={yellow}
                              style={{color: blue}}
                            /></span>
                            &nbsp;
                            {this.state.close['G'] && (
                              <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                            )}
                            </div>

                            {!this.state.close['G'] && (
                              <div>
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="עד שעה"
                                value={this.getOpeningHours('G', 'to')}
                                onChange={(time) => this.setOpeningHours('G', 'to', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                              &nbsp;&nbsp;
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="משעה"
                                value={this.getOpeningHours('G', 'from')}
                                onChange={(time) => this.setOpeningHours('G', 'from', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                              </div>
                            )}

                          </div>
                        </MuiPickersUtilsProvider>
                        </React.Fragment>
                      )}
                    </div>
                    </React.Fragment>
                  )}

                  {(this.state.desktopNavLevel !== 2 && this.state.desktopNavLevel !== 5) && (
                    <React.Fragment>
                    <br /><br />
                    <Button onClick={(e) => this.updateCard()} style={{color: blue, backgroundColor: yellow, fontSize: '1.3em', width: '40%'}}>
                      עדכון הכרטיס שלי
                    </Button>
                    <br /><br />
                    </React.Fragment>
                  )}


                    {this.state.errorAlert && (
                      <div style={{float: 'right', maxWidth:'50%'}}>
                        <Alert severity="error" color="error" style={{textAlign: 'center', direction: 'rtl'}}>
                          &nbsp; {this.state.error}  &nbsp;
                        </Alert>
                      </div>
                    )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <center><p style={{direction:'rtl', color: blue, fontSize: '1em', fontWeight: 100}}>
                    כל הזכויות שמורות לדיביט בע"מ, 2020
                  </p></center>
                </div>
              </Grid>
            </Grid>
            </BrowserView>
            {/*
              ████████▄     ▄████████  ▄█    █▄   ▄█   ▄████████    ▄████████         ▄████████    ▄████████    ▄███████▄    ▄████████    ▄████████
              ███   ▀███   ███    ███ ███    ███ ███  ███    ███   ███    ███        ███    ███   ███    ███   ███    ███   ███    ███   ███    ███
              ███    ███   ███    █▀  ███    ███ ███▌ ███    █▀    ███    █▀         ███    █▀    ███    █▀    ███    ███   ███    █▀    ███    ███
              ███    ███  ▄███▄▄▄     ███    ███ ███▌ ███         ▄███▄▄▄            ███         ▄███▄▄▄       ███    ███  ▄███▄▄▄      ▄███▄▄▄▄██▀
              ███    ███ ▀▀███▀▀▀     ███    ███ ███▌ ███        ▀▀███▀▀▀          ▀███████████ ▀▀███▀▀▀     ▀█████████▀  ▀▀███▀▀▀     ▀▀███▀▀▀▀▀
              ███    ███   ███    █▄  ███    ███ ███  ███    █▄    ███    █▄                ███   ███    █▄    ███          ███    █▄  ▀███████████
              ███   ▄███   ███    ███ ███    ███ ███  ███    ███   ███    ███         ▄█    ███   ███    ███   ███          ███    ███   ███    ███
              ████████▀    ██████████  ▀██████▀  █▀   ████████▀    ██████████       ▄████████▀    ██████████  ▄████▀        ██████████   ███    ███
            */}
            <MobileView>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <OfflineHeader mobile={isMobile} title={'עריכת הכרטיס שלי'} package={this.state.package}/>
              </Grid>

              <Grid item xs={12}>
              <br />
              <center><div style={{
                maxWidth: '100%',
                overflowX: 'auto',
                marginTop: '-5%'
              }}>
              <Tabs value={this.state.mobileNavLevel}
              variant="fullWidth" centered>
                {/*<Tab value={5} label="תבנית" onClick={(e) => this.setState({mobileNavLevel: 5})}/>*/}
                <Tab value={4} label={<span style={{fontSize: '0.8em'}}>שעות</span>} onClick={(e) => this.setState({mobileNavLevel: 4})}/>
                <Tab value={3} label={<span style={{fontSize: '0.8em'}}>כפתורים</span>} onClick={(e) => this.setState({mobileNavLevel: 3})}/>
                <Tab value={2} label={<span style={{fontSize: '0.8em'}}>תמונות</span>} onClick={(e) => this.setState({mobileNavLevel: 2})}/>
                <Tab value={1} label={<span style={{fontSize: '0.8em'}}>פרטים</span>} onClick={(e) => this.setState({mobileNavLevel: 1})}/>
              </Tabs>
              </div></center>
              </Grid>

              {this.state.mobileNavLevel === 1 && (
                <Grid item xs={12} style={{marginBottom: '2%'}}>
                  <div style={{marginRight: '4%', textAlign :'right'}}>
                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    שם העסק באנגלית <span style={{color: 'red'}}>*</span>
                  </p>
                  <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                    ללא רווחים, לדוגמה moshe-design
                  </p>
                  {(!this.state.slugAvailable && this.state.slugOriginal !== this.state.slug) && (
                    <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                      שם זה תפוס, אנא בחר אחד אחר או הוסף לו תווים
                    </p>
                  )}
                  <TextField
                    value={this.state.slug}
                    onChange={(e) => this.checkSlug(e.target.value)}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '95%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <br />

                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    שם העסק <span style={{color: 'red'}}>*</span>
                  </p>
                  {this.state.errorbusinessName && (
                    <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                      זהו שדה חובה!
                    </p>
                  )}
                  <TextField
                    value={this.state.businessName}
                    onChange={(e) => this.setState({businessName: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '95%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />

                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    כותרת ראשית
                  </p>
                  {this.state.errortitle && (
                    <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                      זהו שדה חובה!
                    </p>
                  )}
                  <TextField
                    value={this.state.title}
                    onChange={(e) => this.setState({title: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '95%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />

                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    כותרת משנית
                  </p>
                  {this.state.errorsecondarytitle && (
                    <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                      זהו שדה חובה!
                    </p>
                  )}
                  <TextField
                    value={this.state.secondaryTitle}
                    onChange={(e) => this.setState({secondaryTitle: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '95%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />

                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    מספר הטלפון המקושר לכרטיס <span style={{color: 'red'}}>*</span>
                  </p>
                  {this.state.errorphoneNumber && (
                    <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                      זהו שדה חובה!
                    </p>
                  )}
                  <TextField
                    value={this.state.phoneNumber}
                    onChange={(e) => this.setState({phoneNumber: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '95%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />

                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    טקסט הכרטיס
                  </p>
                  {this.state.errortext && (
                    <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                      זהו שדה חובה!
                    </p>
                  )}
                  {/*<textarea rows="4" cols="50"
                    value={this.state.text}
                    onChange={(e) => this.setState({text: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '50%',
                      fontSize:'1.3em',
                      marginTop: '0.5%'
                    }}
                  ></textarea>*/}
                  <textarea
                    value={this.state.text}
                    onChange={(e) => this.setText(e.target.value)}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '95%',
                      fontSize:'1.3em',
                      marginTop: '0.5%',
                      backgroundColor: 'white',
                      color: blue,
                      whiteSpace: 'pre-wrap'
                    }}
                    inputProps={{
                      style:{
                        direction: 'rtl',
                        textAlign: 'right',
                        fontSize:'1.3em',
                        width: '95%',
                        backgroundColor: 'white',
                        color: blue,
                        fontWeight: 400,
                        whiteSpace: 'pre-wrap'
                      }
                    }}
                    rows={4}
                    placeholder={'טקסט כאן'}
                  ></textarea>
                  </div>
                </Grid>
              )}
              {this.state.mobileNavLevel === 2 && (
                <Grid item xs={12} style={{marginBottom: '2%'}}>
                  <div style={{marginRight: '4%', textAlign :'right'}}>
                  <p style={{direction:'rtl', color: blue, fontSize: '1.7em', fontWeight: 700, marginBottom: 0}}>
                    התמונות שלי
                  </p>

                  <center>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '80%'}}>
                      {(this.state.logo !== '' && this.state.logo !== undefined && this.state.logo !== null) && (
                        <div>
                          <p style={{color: blue, fontSize: '1.5em'}}>לוגו</p>
                          {(this.state.logo.includes('/gallery/') || this.state.logo.includes('/templates/')) ? (
                            <img src={this.state.logo} style={{marginTop: 2, width: 100, borderRadius: '100%', padding: 0, height: 100, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', objectFit: 'cover'}}/>
                          ) : (
                            <img src={'https://app.dibit.co.il/logos/'+this.state.logo} style={{marginTop: 2, width: 100, borderRadius: '100%', padding: 0, height: 100, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', objectFit: 'cover'}}/>
                          )}
                        </div>
                      )}
                      {(this.state.cover !== '' && this.state.cover !== undefined && this.state.cover !== null) && (
                        <div>
                          <p style={{color: blue, fontSize: '1.5em'}}>תמונת רקע</p>
                          {(this.state.cover.includes('/gallery/') || this.state.cover.includes('/templates/')) ? (
                            <img src={this.state.cover} style={{marginTop: 2, width: 170, padding: 0, height: 100, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', objectFit: 'cover'}}/>
                          ) : (
                            <img src={'https://app.dibit.co.il/logos/'+this.state.cover} style={{marginTop: 2, width: 170, padding: 0, height: 100, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', objectFit: 'cover'}}/>
                          )}
                        </div>
                      )}
                    </div>
                  </center>
                  <br /><br />

                  <button onClick={(e) => this.setState({uploadLogoModal: true})} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.3em'}}>{this.state.logo === '' ? 'העלאת הלוגו' : 'עדכון הלוגו'}</button>
                  &nbsp; <span style={{fontSize:'1.3em'}}>או</span> &nbsp;
                  <button onClick={(e) => this.setState({uploadCoverModal: true})} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.3em'}}>העלאת תמונת רקע</button>

                  <Modal
                    open={this.state.uploadLogoModal}
                    onClose={(e) => this.setState({uploadLogoModal: false})}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      maxWidth: '90%'
                    }}
                    contentStyle={{maxWidth: '90%'}}
                  >
                      {this.state.logoImageShowGalleryBool ? (
                        <React.Fragment>
                        <div style={{textAlign: 'right'}}>
                          <p style={{direction:'rtl', color: blue, fontSize: '1.1em', fontWeight: 700, color: yellow}}>
                          בחרו תמונה מהגלרייה המדהימה שלנו
                          </p>

                          <div>
                            <p style={{fontSize:'1em', marginBottom: 0}}>חפש תמונה על פי מילה המגדירה אותה</p>

                            <TextField
                              value={this.state.search_gallery}
                              onChange={(e) => this.setState({search_gallery: e.target.value})}
                              style={{
                                width: '100%',
                                direction: 'rtl',
                                textAlign: 'right',
                                color: blue,
                                backgroundColor: 'white'
                              }}
                              inputProps={{
                                style: {
                                  direction: 'rtl',
                                  textAlign: 'right',
                                  fontSize: '1.6em',
                                  color: blue,
                                  backgroundColor: 'white'
                                }
                              }}
                            />
                            <br /><br />
                            <button onClick={(e) => this.resetGallery()} style={{width: '50%', backgroundColor: 'white', color: blue, border: '1px solid ' + blue, fontSize: '1.5em'}}>איפוס</button>
                            &nbsp;
                            <button onClick={(e) => this.searchGallery()} style={{width: '50%', backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>חפש</button>
                            &nbsp;

                            <br />
                            <br />
                          </div>
                        </div>
                        <Card style={{minWidth: '100%', height: 350, overflowY: 'auto', overflowX: 'auto'}}>
                        {this.state.gallery.map((image, i) => {
                          console.log(i);
                          console.log(image);
                          if (this.state.gallerySelectedLogoImage !== '') {
                            if (i % 2 !== 0 || i === 0) {
                              if (image._id.toString() === this.state.gallerySelectedLogoImage) {
                                return (
                                  <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px', objectFit: 'cover'}} />
                                )
                              } else {
                                return (
                                  <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, objectFit: 'cover'}} />
                                )
                              }
                            } else {
                              if (image._id.toString() === this.state.gallerySelectedLogoImage) {
                                return (
                                  <React.Fragment>
                                    <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px', objectFit: 'cover'}} />
                                    <br />
                                  </React.Fragment>
                                )
                              } else {
                                return (
                                  <React.Fragment>
                                    <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,objectFit: 'cover'}} />
                                    <br />
                                  </React.Fragment>
                                )
                              }
                            }
                          } else {
                            if (i % 2 !== 0 || i === 0) {
                              if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                                return (
                                  <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px', objectFit: 'cover'}} />
                                )
                              } else {
                                return (
                                  <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, objectFit: 'cover'}} />
                                )
                              }
                            } else {
                              if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                                return (
                                  <React.Fragment>
                                    <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px', objectFit: 'cover'}} />
                                    <br />
                                  </React.Fragment>
                                )
                              } else {
                                return (
                                  <React.Fragment>
                                    <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,objectFit: 'cover'}} />
                                    <br />
                                  </React.Fragment>
                                )
                              }
                            }
                          }
                        })}
                        </Card>
                        <br />
                        <div style={{textAlign: 'right'}}>
                          <button onClick={(e) => this.updateLogoFromGallery()} style={{width: '100%', backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>בחר ועדכן</button>
                        </div>
                        <p style={{fontSize: '1.3em', direction: 'rtl', textAlign: 'right', color: blue, textAlign: 'center', marginTop: 0, marginBottom: 0}}>- או -</p>
                        <div style={{textAlign: 'right'}}>
                          <button style={{width: '100%', backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}} onClick={(e) => this.setState({logoImageShowGalleryBool: false})}>או העלו תמונה משלכם</button>
                        </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <p style={{direction:'rtl', color: blue, fontSize: '1.7em', fontWeight: 700, marginBottom: '-5%'}}>
                            הלוגו שלכם
                          </p>
                          <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                            בחרו את לוגו העסק או תמונה ייצוגית אחרת
                          </p>
                          {((this.state.logo !== null && this.state.logo !== undefined && this.state.logo !== '') && (this.state.imageEditing)) && (
                            <center>
                              {(this.state.logo.includes('/gallery/') || this.state.logo.includes('/templates/')) ? (
                                <img src={this.state.logo} style={{marginTop: 2, width: 150, borderRadius: '100%', padding: 0, height: 150, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}/>
                              ) : (
                                <img src={'https://app.dibit.co.il/logos/'+this.state.logo} style={{marginTop: 2, width: 150, borderRadius: '100%', padding: 0, height: 150, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}/>
                              )}
                            </center>
                          )}

                          {src && (
                            <center>
                            <div style={{textAlign: 'center'}}>
                            <ReactCrop
                              src={src}
                              crop={crop}
                              ruleOfThirds
                              onImageLoaded={this.onImageLoaded}
                              onComplete={this.onCropComplete}
                              onChange={this.onCropChange}
                              style={{
                                width: 230,
                                height: 'auto'
                              }}
                            />
                            </div>
                            </center>
                          )}

                          {this.state.loadingImage && (
                            <React.Fragment>
                              <center>
                                <p>מעלה את התמונה, אנא המתן</p>
                                <CircularProgress />
                              </center>
                            </React.Fragment>
                          )}

                          <form onSubmit={this.onFormSubmit}>
                            <br />
                            <div style={{textAlign: 'right'}}>

                            {(src) && (
                              <React.Fragment>
                              <p style={{color: blue, fontSize: '1em', direction: 'rtl'}}>הקובץ שנבחר: {this.state.file.name}</p>
                              <button type="submit" style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>לסיום והעלאה לחץ/י כאן</button>
                              <br /><br />
                              </React.Fragment>
                            )}

                            <div class="upload-btn-wrapper">
                              <button class="btn">לחצו כאן לבחירת התמונה</button>
                              <input type="file" name="myImage" onChange={this.onChange} accept="image/*"/>
                            </div>



                            <p style={{fontSize: '1.3em', direction: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                            </div>
                          </form>
                          <div style={{textAlign: 'right'}}>
                            <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em', textAlign: 'right'}} onClick={(e) => this.setState({logoImageShowGalleryBool: true})}>או בחר מתוך הגלרייה שלנו</button>
                          </div>
                        </React.Fragment>
                      )}
                  </Modal>
                  <Snackbar open={this.state.uploadLogoModalSuccess} autoHideDuration={6000} onClose={(e) => this.setState({uploadLogoModalSuccess: false})}>
                    <Alert variant="filled" severity="success">!הלוגו הועלה בהצלחה ויתעדכן בהקדם בכרטיס הדיגיטלי שלך</Alert>
                  </Snackbar>

                  <Modal
                    open={this.state.uploadCoverModal}
                    onClose={(e) => this.setState({uploadCoverModal: false})}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      maxWidth: '90%'
                    }}
                  >
                      {this.state.coverImageShowGalleryBool ? (
                        <React.Fragment>
                        <div style={{textAlign: 'right'}}>
                          <p style={{direction:'rtl', color: blue, fontSize: '1.1em', fontWeight: 700, color: yellow}}>
                          בחרו תמונה מהגלרייה המדהימה שלנו
                          </p>

                          <div>
                            <p style={{fontSize:'1em', marginBottom: 0}}>חפש תמונה על פי מילה המגדירה אותה</p>

                            <TextField
                              value={this.state.search_gallery}
                              onChange={(e) => this.setState({search_gallery: e.target.value})}
                              style={{
                                width: '100%',
                                direction: 'rtl',
                                textAlign: 'right',
                                color: blue,
                                backgroundColor: 'white'
                              }}
                              inputProps={{
                                style: {
                                  direction: 'rtl',
                                  textAlign: 'right',
                                  fontSize: '1.6em',
                                  color: blue,
                                  backgroundColor: 'white'
                                }
                              }}
                            />
                            <br /><br />
                            <button onClick={(e) => this.resetGallery()} style={{width: '50%', backgroundColor: 'white', color: blue, border: '1px solid ' + blue, fontSize: '1.5em'}}>איפוס</button>
                            &nbsp;
                            <button onClick={(e) => this.searchGallery()} style={{width: '50%', backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>חפש</button>
                            &nbsp;

                            <br />
                            <br />
                          </div>
                        </div>
                        <Card style={{minWidth: '100%', height: 350, overflowY: 'auto', overflowX: 'auto'}}>
                        {this.state.gallery.map((image, i) => {
                          console.log(i);
                          console.log(image);
                          if (this.state.gallerySelectedCoverImage !== '') {
                            if (i % 2 !== 0 || i === 0) {
                              if (image._id.toString() === this.state.gallerySelectedCoverImage) {
                                return (
                                  <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px', objectFit: 'cover'}} />
                                )
                              } else {
                                return (
                                  <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, objectFit: 'cover'}} />
                                )
                              }
                            } else {
                              if (image._id.toString() === this.state.gallerySelectedCoverImage) {
                                return (
                                  <React.Fragment>
                                    <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px', objectFit: 'cover'}} />
                                    <br />
                                  </React.Fragment>
                                )
                              } else {
                                return (
                                  <React.Fragment>
                                    <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,objectFit: 'cover'}} />
                                    <br />
                                  </React.Fragment>
                                )
                              }
                            }
                          } else {
                            if (i % 2 !== 0 || i === 0) {
                              if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                                return (
                                  <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px',objectFit: 'cover'}} />
                                )
                              } else {
                                return (
                                  <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, objectFit: 'cover'}} />
                                )
                              }
                            } else {
                              if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                                return (
                                  <React.Fragment>
                                    <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px', objectFit: 'cover'}} />
                                    <br />
                                  </React.Fragment>
                                )
                              } else {
                                return (
                                  <React.Fragment>
                                    <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,objectFit: 'cover'}} />
                                    <br />
                                  </React.Fragment>
                                )
                              }
                            }
                          }
                        })}
                        </Card>
                        <br />
                        <div style={{textAlign: 'right'}}>
                          <button onClick={(e) => this.updateCoverFromGallery()} style={{width: '100%', backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>בחר ועדכן</button>
                        </div>
                        <p style={{fontSize: '1.3em', direction: 'rtl', textAlign: 'right', color: blue, textAlign: 'center', marginTop: 0, marginBottom: 0}}>- או -</p>
                        <div style={{textAlign: 'right'}}>
                          <button style={{width: '100%', backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}} onClick={(e) => this.setState({coverImageShowGalleryBool: false})}>או העלו תמונה משלכם</button>
                        </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                        <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: '-5%'}}>
                          תמונת הרקע שלי
                        </p>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                          זוהי התמונה שתופיע מאחורי הלוגו שלך
                        </p>
                        {(this.state.cover !== '' && this.state.cover !== undefined && this.state.cover !== null) && (
                          <center>
                          {(this.state.cover.includes('/gallery/') || this.state.cover.includes('/templates/')) ? (
                            <img src={this.state.cover} style={{maxWidth: '100%', maxHeight: '200px'}}/>
                          ) : (
                            <img src={'https://app.dibit.co.il/logos/'+this.state.cover} style={{maxWidth: '100%', maxHeight: '200px'}}/>
                          )}
                          </center>
                        )}

                        {this.state.loadingImage && (
                          <React.Fragment>
                            <center>
                              <p>מעלה את התמונה, אנא המתן</p>
                              <CircularProgress />
                            </center>
                          </React.Fragment>
                        )}

                        <form onSubmit={this.onFormSubmitCover}>
                          <br />
                          <div style={{textAlign: 'right'}}>
                          <div class="upload-btn-wrapper">
                            <button class="btn">לחצו כאן לבחירת התמונה</button>
                            <input type="file" name="myImage" onChange={this.onChange} accept="image/*"/>
                          </div>

                          {(this.state.file !== undefined && this.state.file !== null && this.state.file !== '') && (
                            <React.Fragment>
                            <p style={{color: blue, fontSize: '1em', direction: 'rtl'}}>הקובץ שנבחר: {this.state.file.name}</p>
                            <button type="submit" style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>להעלאה לחץ/י כאן</button>
                            </React.Fragment>
                          )}

                          <p style={{fontSize: '1.3em', direction: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                          </div>
                        </form>
                        <div style={{textAlign: 'right'}}>
                          <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em', textAlign: 'right'}} onClick={(e) => this.setState({coverImageShowGalleryBool: true})}>או בחר מתוך הגלרייה שלנו</button>
                        </div>
                        </React.Fragment>
                      )}
                  </Modal>

                  <Snackbar open={this.state.uploadCoverModalSuccess} autoHideDuration={6000} onClose={(e) => this.setState({uploadCoverModalSuccess: false})}>
                    <Alert variant="filled" severity="success">!תמונת הרקע שלכם התעדכנה בהצלחה ותעודכן בכרטיס הדיגיטלי שלכם בהקדם</Alert>
                  </Snackbar>
                  </div>
                </Grid>
              )}
              {this.state.mobileNavLevel === 3 && (
                <Grid item xs={12} style={{marginBottom: '2%'}}>
                  <div style={{marginRight: '4%', textAlign :'right'}}>
                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    לינק לפייסבוק שלי
                  </p>
                  {this.state.errorfacebookLink && (
                    <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                      זהו שדה חובה!
                    </p>
                  )}
                  <TextField
                    value={this.state.facebookLink}
                    onChange={(e) => this.setState({facebookLink: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '90%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />

                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    לינק לאינטסגרם שלי
                  </p>
                  {this.state.errorinstagramLink && (
                    <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                      זהו שדה חובה!
                    </p>
                  )}
                  <TextField
                    value={this.state.instagramLink}
                    onChange={(e) => this.setState({instagramLink: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '90%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />

                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    כתובת לוויז
                  </p>
                  {this.state.erroraddress && (
                    <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                      זהו שדה חובה!
                    </p>
                  )}
                  <TextField
                    value={this.state.address}
                    onChange={(e) => this.setState({address: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '90%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <br />
                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    כתובת לסרטון
                  </p>
                  <TextField
                    value={this.state.video}
                    onChange={(e) => this.setState({video: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '90%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    כפתור מספר 1
                  </p>
                  <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0, marginTop: 0}}>
                    סוג הכפתור
                  </p>
                  <Select
                    value={this.state.button_1}
                    onChange={(e) => this.setState({button_1: e.target.value})}
                    style={{width: '90%', fontSize: '1.5em', textAlign: 'right', direction: 'rtl', backgroundColor: 'white'}}
                  >
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'facebook'}>פייסבוק</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'instagram'}>אינסטגרם</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'website'}>אתר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'youtube'}>יוטיוב</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'shop'}>חנות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'callme'}>התקשר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'portfolio'}>תיק עבודות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'waze'}>וויז</MenuItem>
                  </Select>
                  {(this.state.button_1 === 'website' || this.state.button_1 === 'shop' || this.state.button_1 === 'portfolio') && (
                    <React.Fragment>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                        קישור
                      </p>
                      <TextField
                        value={this.state.button_1_link}
                        onChange={(e) => this.setState({button_1_link: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '90%',
                          fontSize:'1.3em',
                          marginTop: '0.5%'
                        }}
                        inputProps={{
                          style:{
                            fontSize:'1.5em',
                            backgroundColor: 'white',
                            color: blue,
                            fontWeight: 'bold'
                          }
                        }}
                      />
                    </React.Fragment>
                  )}
                  <br />
                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    כפתור מספר 2
                  </p>
                  <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0, marginTop: 0}}>
                    סוג הכפתור
                  </p>
                  <Select
                    value={this.state.button_2}
                    onChange={(e) => this.setState({button_2: e.target.value})}
                    style={{width: '90%', fontSize: '1.5em', textAlign: 'right', direction: 'rtl', backgroundColor: 'white'}}
                  >
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'facebook'}>פייסבוק</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'instagram'}>אינסטגרם</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'website'}>אתר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'youtube'}>יוטיוב</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'callme'}>התקשר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'shop'}>חנות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'portfolio'}>תיק עבודות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'waze'}>וויז</MenuItem>
                  </Select>
                  {(this.state.button_2 === 'website' || this.state.button_2 === 'shop' || this.state.button_2 === 'portfolio') && (
                    <React.Fragment>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                        קישור
                      </p>
                      <TextField
                        value={this.state.button_2_link}
                        onChange={(e) => this.setState({button_2_link: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '90%',
                          fontSize:'1.3em',
                          marginTop: '0.5%'
                        }}
                        inputProps={{
                          style:{
                            fontSize:'1.5em',
                            backgroundColor: 'white',
                            color: blue,
                            fontWeight: 'bold'
                          }
                        }}
                      />
                    </React.Fragment>
                  )}
                  <br />
                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    כפתור מספר 3
                  </p>
                  <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0, marginTop: 0}}>
                    סוג הכפתור
                  </p>
                  <Select
                    value={this.state.button_3}
                    onChange={(e) => this.setState({button_3: e.target.value})}
                    style={{width: '90%', fontSize: '1.5em', textAlign: 'right', direction: 'rtl', backgroundColor: 'white'}}
                  >
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'facebook'}>פייסבוק</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'instagram'}>אינסטגרם</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'website'}>אתר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'youtube'}>יוטיוב</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'callme'}>התקשר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'shop'}>חנות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'portfolio'}>תיק עבודות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'waze'}>וויז</MenuItem>
                  </Select>
                  {(this.state.button_3 === 'website' || this.state.button_3 === 'shop' || this.state.button_3 === 'portfolio') && (
                    <React.Fragment>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                        קישור
                      </p>
                      <TextField
                        value={this.state.button_3_link}
                        onChange={(e) => this.setState({button_3_link: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '90%',
                          fontSize:'1.3em',
                          marginTop: '0.5%'
                        }}
                        inputProps={{
                          style:{
                            fontSize:'1.5em',
                            backgroundColor: 'white',
                            color: blue,
                            fontWeight: 'bold'
                          }
                        }}
                      />
                    </React.Fragment>
                  )}
                  <br />

                  {this.state.buttonShownIndex >= 4 && (
                    <React.Fragment>
                      <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                        כפתור מספר 4
                      </p>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0, marginTop: 0}}>
                        סוג הכפתור
                      </p>
                      <Select
                        value={this.state.button_4}
                        onChange={(e) => this.setState({button_4: e.target.value})}
                        style={{width: '90%', fontSize: '1.5em', textAlign: 'right', direction: 'rtl', backgroundColor: 'white'}}
                      >
                        <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'facebook'}>פייסבוק</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'instagram'}>אינסטגרם</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'website'}>אתר</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'youtube'}>יוטיוב</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'callme'}>התקשר</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'shop'}>חנות</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'portfolio'}>תיק עבודות</MenuItem>
                        <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'waze'}>וויז</MenuItem>
                      </Select>
                      {(this.state.button_4 === 'website' || this.state.button_4 === 'shop' || this.state.button_4 === 'portfolio') && (
                        <React.Fragment>
                          <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                            קישור
                          </p>
                          <TextField
                            value={this.state.button_4_link}
                            onChange={(e) => this.setState({button_4_link: e.target.value})}
                            style={{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '90%',
                              fontSize:'1.3em',
                              marginTop: '0.5%'
                            }}
                            inputProps={{
                              style:{
                                fontSize:'1.5em',
                                backgroundColor: 'white',
                                color: blue,
                                fontWeight: 'bold'
                              }
                            }}
                          />
                        </React.Fragment>
                      )}
                      <br />
                    </React.Fragment>
                  )}


                  {this.state.buttonShownIndex >= 5 && (
                    <React.Fragment>
                    <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                      כפתור מספר 5
                    </p>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0, marginTop: 0}}>
                      סוג הכפתור
                    </p>
                    <Select
                      value={this.state.button_5}
                      onChange={(e) => this.setState({button_5: e.target.value})}
                      style={{width: '90%', fontSize: '1.5em', textAlign: 'right', direction: 'rtl', backgroundColor: 'white'}}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'facebook'}>פייסבוק</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'instagram'}>אינסטגרם</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'website'}>אתר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'youtube'}>יוטיוב</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'callme'}>התקשר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'shop'}>חנות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'portfolio'}>תיק עבודות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'waze'}>וויז</MenuItem>
                    </Select>
                    {(this.state.button_5 === 'website' || this.state.button_5 === 'shop' || this.state.button_5 === 'portfolio') && (
                      <React.Fragment>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                          קישור
                        </p>
                        <TextField
                          value={this.state.button_5_link}
                          onChange={(e) => this.setState({button_5_link: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '90%',
                            fontSize:'1.3em',
                            marginTop: '0.5%'
                          }}
                          inputProps={{
                            style:{
                              fontSize:'1.5em',
                              backgroundColor: 'white',
                              color: blue,
                              fontWeight: 'bold'
                            }
                          }}
                        />
                      </React.Fragment>
                    )}
                    <br />
                    </React.Fragment>
                  )}

                  {this.state.buttonShownIndex >= 6 && (
                    <React.Fragment>
                    <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                      כפתור מספר 6
                    </p>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0, marginTop: 0}}>
                      סוג הכפתור
                    </p>
                    <Select
                      value={this.state.button_6}
                      onChange={(e) => this.setState({button_6: e.target.value})}
                      style={{width: '90%', fontSize: '1.5em', textAlign: 'right', direction: 'rtl', backgroundColor: 'white'}}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'facebook'}>פייסבוק</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'instagram'}>אינסטגרם</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'website'}>אתר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'youtube'}>יוטיוב</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'callme'}>התקשר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'shop'}>חנות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'portfolio'}>תיק עבודות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'waze'}>וויז</MenuItem>
                    </Select>
                    {(this.state.button_6 === 'website' || this.state.button_6 === 'shop' || this.state.button_6 === 'portfolio') && (
                      <React.Fragment>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                          קישור
                        </p>
                        <TextField
                          value={this.state.button_6_link}
                          onChange={(e) => this.setState({button_6_link: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '90%',
                            fontSize:'1.3em',
                            marginTop: '0.5%'
                          }}
                          inputProps={{
                            style:{
                              fontSize:'1.5em',
                              backgroundColor: 'white',
                              color: blue,
                              fontWeight: 'bold'
                            }
                          }}
                        />
                      </React.Fragment>
                    )}
                    <br />
                    </React.Fragment>
                  )}


                  {this.state.buttonShownIndex >= 7 && (
                    <React.Fragment>
                    <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                      כפתור מספר 7
                    </p>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0, marginTop: 0}}>
                      סוג הכפתור
                    </p>
                    <Select
                      value={this.state.button_7}
                      onChange={(e) => this.setState({button_7: e.target.value})}
                      style={{width: '90%', fontSize: '1.5em', textAlign: 'right', direction: 'rtl', backgroundColor: 'white'}}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'facebook'}>פייסבוק</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'instagram'}>אינסטגרם</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'website'}>אתר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'youtube'}>יוטיוב</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'callme'}>התקשר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'shop'}>חנות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'portfolio'}>תיק עבודות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'waze'}>וויז</MenuItem>
                    </Select>
                    {(this.state.button_7 === 'website' || this.state.button_7 === 'shop' || this.state.button_7 === 'portfolio') && (
                      <React.Fragment>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                          קישור
                        </p>
                        <TextField
                          value={this.state.button_7_link}
                          onChange={(e) => this.setState({button_7_link: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '90%',
                            fontSize:'1.3em',
                            marginTop: '0.5%'
                          }}
                          inputProps={{
                            style:{
                              fontSize:'1.5em',
                              backgroundColor: 'white',
                              color: blue,
                              fontWeight: 'bold'
                            }
                          }}
                        />
                      </React.Fragment>
                    )}
                    <br />
                    </React.Fragment>
                  )}

                  {this.state.buttonShownIndex >= 8 && (
                    <React.Fragment>
                    <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                      כפתור מספר 8
                    </p>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0, marginTop: 0}}>
                      סוג הכפתור
                    </p>
                    <Select
                      value={this.state.button_8}
                      onChange={(e) => this.setState({button_8: e.target.value})}
                    style={{width: '90%', fontSize: '1.5em', textAlign: 'right', direction: 'rtl', backgroundColor: 'white'}}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'facebook'}>פייסבוק</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'instagram'}>אינסטגרם</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'website'}>אתר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'youtube'}>יוטיוב</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'callme'}>התקשר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'shop'}>חנות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'portfolio'}>תיק עבודות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'waze'}>וויז</MenuItem>
                    </Select>
                    {(this.state.button_8 === 'website' || this.state.button_8 === 'shop' || this.state.button_8 === 'portfolio') && (
                      <React.Fragment>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                          קישור
                        </p>
                        <TextField
                          value={this.state.button_8_link}
                          onChange={(e) => this.setState({button_8_link: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '90%',
                            fontSize:'1.3em',
                            marginTop: '0.5%'
                          }}
                          inputProps={{
                            style:{
                              fontSize:'1.5em',
                              backgroundColor: 'white',
                              color: blue,
                              fontWeight: 'bold'
                            }
                          }}
                        />
                      </React.Fragment>
                    )}
                    <br />
                    </React.Fragment>
                  )}

                  {this.state.buttonShownIndex === 9 && (
                    <React.Fragment>

                    <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                      כפתור מספר 9
                    </p>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0, marginTop: 0}}>
                      סוג הכפתור
                    </p>
                    <Select
                      value={this.state.button_9}
                      onChange={(e) => this.setState({button_9: e.target.value})}
                      style={{width: '90%', fontSize: '1.5em', textAlign: 'right', direction: 'rtl', backgroundColor: 'white'}}
                    >
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'facebook'}>פייסבוק</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'instagram'}>אינסטגרם</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'website'}>אתר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'youtube'}>יוטיוב</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'callme'}>התקשר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'shop'}>חנות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'portfolio'}>תיק עבודות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direction: 'rtl', fontSize: '1.5em'}} value={'waze'}>וויז</MenuItem>
                    </Select>
                    {(this.state.button_9 === 'website' || this.state.button_9 === 'shop' || this.state.button_9 === 'portfolio') && (
                      <React.Fragment>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                          קישור
                        </p>
                        <TextField
                          value={this.state.button_9_link}
                          onChange={(e) => this.setState({button_9_link: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '90%',
                            fontSize:'1.3em',
                            marginTop: '0.5%'
                          }}
                          inputProps={{
                            style:{
                              fontSize:'1.5em',
                              backgroundColor: 'white',
                              color: blue,
                              fontWeight: 'bold'
                            }
                          }}
                        />
                      </React.Fragment>
                    )}
                    </React.Fragment>
                  )}

                  {this.state.buttonShownIndex <= 8 && (
                    <React.Fragment>
                      <br />
                      <Button onClick={(e) => this.addButtonIndex()} style={{color: yellow, backgroundColor: blue, fontSize: '1.5em', marginRight: '1%'}}>
                        + הוספת כפתור נוסף
                      </Button>
                    </React.Fragment>
                  )}
                  {this.state.buttonShownIndex >= 4 && (
                    <React.Fragment>
                      <Button onClick={(e) => this.remove_button()} style={{color: yellow, backgroundColor: blue, fontSize: '1.5em'}}>
                      <DeleteForeverIcon />  מחק כפתור
                      </Button>
                    </React.Fragment>
                  )}

                  </div>
                </Grid>
              )}
              {this.state.mobileNavLevel === 4 && (
                <Grid item xs={12} style={{marginBottom: '2%'}}>
                  <div style={{marginRight: '4%', textAlign :'right'}}>
                  <div>
                    <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                      שעות הפעילות של העסק <span style={{color: 'red'}}>*</span>
                    </p>
                    <Checkbox
                      value={this.state.open24h}
                      checked={this.state.open24h}
                      onChange={(e) => this.setOpen24H()}
                      color={yellow}
                    /> <span style={{fontSize: '1.5em'}}>פתוח 24/7</span>
                    <br />

                    {!this.state.open24h && (
                      <React.Fragment>
                      <p style={{fontSize: '2em', direction:'rtl', color: blue, fontWeight: 700, marginBottom: 0}}>
                        יום ראשון
                      </p>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <React.Fragment>
                        <p style={{color: yellow, fontSize: '1.5em'}}>סגור ביום זה <Checkbox
                          value={this.state.close['A']}
                          checked={this.state.close['A']}
                          onChange={(e) => this.close('A')}
                          color={yellow}
                          style={{
                            color: blue,
                            marginTop: 0,
                            marginBottom: 0
                          }}
                        /></p>
                        {this.state.close['A'] && (
                          <p style={{color: blue, fontSize: '1.5em'}}>העסק מוגדר כסגור ביום זה</p>
                        )}
                      </React.Fragment>
                      {!this.state.close['A'] && (
                        <div>
                        <div>
                          <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>משעה</p>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            value={this.getOpeningHours('A', 'from')}
                            onChange={(time) => this.setOpeningHours('A', 'from', time)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            ampm={false}
                            style={{
                              fontSize: '1.5em',
                              width: '90%',
                              backgroundColor: 'white',
                              color: blue
                            }}
                            inputProps={{
                              style:{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }
                            }}
                          />
                        </div>
                          <div>
                            <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>עד שעה</p>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              label=""
                              value={this.getOpeningHours('A', 'to')}
                              onChange={(time) => this.setOpeningHours('A', 'to', time)}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              ampm={false}
                              style={{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  fontSize: '1.5em',
                                  width: '90%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </div>

                        </div>
                      )}
                      </MuiPickersUtilsProvider>
                      {/*שני*/}
                      <p style={{fontSize: '2em', direction:'rtl', color: blue, fontWeight: 700, marginBottom: 0}}>
                        יום שני
                      </p>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <React.Fragment>
                        <p style={{color: yellow, fontSize: '1.5em'}}>סגור ביום זה <Checkbox
                          value={this.state.close['B']}
                          checked={this.state.close['B']}
                          onChange={(e) => this.close('B')}
                          color={yellow}
                          style={{
                            color: blue,
                            marginTop: 0,
                            marginBottom: 0
                          }}
                        /></p>
                        {this.state.close['B'] && (
                          <p style={{color: blue, fontSize: '1.5em'}}>העסק מוגדר כסגור ביום זה</p>
                        )}
                      </React.Fragment>
                      {!this.state.close['B'] && (
                        <div>
                          <div>
                            <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>משעה</p>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              value={this.getOpeningHours('B', 'from')}
                              onChange={(time) => this.setOpeningHours('B', 'from', time)}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              ampm={false}
                              style={{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  fontSize: '1.5em',
                                  width: '90%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </div>
                          <div>
                            <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>עד שעה</p>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              label=""
                              value={this.getOpeningHours('B', 'to')}
                              onChange={(time) => this.setOpeningHours('B', 'to', time)}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              ampm={false}
                              style={{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  fontSize: '1.5em',
                                  width: '90%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </div>

                        </div>
                      )}
                      </MuiPickersUtilsProvider>
                      {/*שני*/}
                      {/*שלישי*/}
                      <p style={{fontSize: '2em', direction:'rtl', color: blue, fontWeight: 700, marginBottom: 0}}>
                        יום שלישי
                      </p>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <React.Fragment>
                        <p style={{color: yellow, fontSize: '1.5em'}}>סגור ביום זה <Checkbox
                          value={this.state.close['C']}
                          checked={this.state.close['C']}
                          onChange={(e) => this.close('C')}
                          color={yellow}
                          style={{
                            color: blue,
                            marginTop: 0,
                            marginBottom: 0
                          }}
                        /></p>
                        {this.state.close['C'] && (
                          <p style={{color: blue, fontSize: '1.5em'}}>העסק מוגדר כסגור ביום זה</p>
                        )}
                      </React.Fragment>
                      {!this.state.close['C'] && (
                        <div>
                          <div>
                            <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>משעה</p>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              value={this.getOpeningHours('C', 'from')}
                              onChange={(time) => this.setOpeningHours('C', 'from', time)}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              ampm={false}
                              style={{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  fontSize: '1.5em',
                                  width: '90%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </div>
                          <div>
                            <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>עד שעה</p>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              label=""
                              value={this.getOpeningHours('C', 'to')}
                              onChange={(time) => this.setOpeningHours('C', 'to', time)}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              ampm={false}
                              style={{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  fontSize: '1.5em',
                                  width: '90%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      </MuiPickersUtilsProvider>
                      {/*שלישי*/}
                      {/*רביעי*/}
                      <p style={{fontSize: '2em', direction:'rtl', color: blue, fontWeight: 700, marginBottom: 0}}>
                        יום רביעי
                      </p>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <React.Fragment>
                        <p style={{color: yellow, fontSize: '1.5em'}}>סגור ביום זה <Checkbox
                          value={this.state.close['D']}
                          checked={this.state.close['D']}
                          onChange={(e) => this.close('D')}
                          color={yellow}
                          style={{
                            color: blue,
                            marginTop: 0,
                            marginBottom: 0
                          }}
                        /></p>
                        {this.state.close['D'] && (
                          <p style={{color: blue, fontSize: '1.5em'}}>העסק מוגדר כסגור ביום זה</p>
                        )}
                      </React.Fragment>
                      {!this.state.close['D'] && (
                        <div>
                          <div>
                            <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>משעה</p>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              value={this.getOpeningHours('D', 'from')}
                              onChange={(time) => this.setOpeningHours('D', 'from', time)}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              ampm={false}
                              style={{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  fontSize: '1.5em',
                                  width: '90%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </div>
                          <div>
                            <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>עד שעה</p>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              label=""
                              value={this.getOpeningHours('D', 'to')}
                              onChange={(time) => this.setOpeningHours('D', 'to', time)}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              ampm={false}
                              style={{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  fontSize: '1.5em',
                                  width: '90%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      </MuiPickersUtilsProvider>
                      {/*רביעי*/}
                      {/*חמישי*/}
                      <p style={{fontSize: '2em', direction:'rtl', color: blue, fontWeight: 700, marginBottom: 0}}>
                        יום חמישי
                      </p>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <React.Fragment>
                        <p style={{color: yellow, fontSize: '1.5em'}}>סגור ביום זה <Checkbox
                          value={this.state.close['E']}
                          checked={this.state.close['E']}
                          onChange={(e) => this.close('E')}
                          color={yellow}
                          style={{
                            color: blue,
                            marginTop: 0,
                            marginBottom: 0
                          }}
                        /></p>
                        {this.state.close['E'] && (
                          <p style={{color: blue, fontSize: '1.5em'}}>העסק מוגדר כסגור ביום זה</p>
                        )}
                      </React.Fragment>
                      {!this.state.close['E'] && (
                        <div>
                          <div>
                            <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>משעה</p>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              value={this.getOpeningHours('E', 'from')}
                              onChange={(time) => this.setOpeningHours('E', 'from', time)}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              ampm={false}
                              style={{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  fontSize: '1.5em',
                                  width: '90%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </div>
                          <div>
                            <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>עד שעה</p>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              label=""
                              value={this.getOpeningHours('E', 'to')}
                              onChange={(time) => this.setOpeningHours('E', 'to', time)}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              ampm={false}
                              style={{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  fontSize: '1.5em',
                                  width: '90%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      </MuiPickersUtilsProvider>
                      {/*חמישי*/}
                      {/*שישי*/}
                      <p style={{fontSize: '2em', direction:'rtl', color: blue, fontWeight: 700, marginBottom: 0}}>
                        יום שישי
                      </p>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <React.Fragment>
                        <p style={{color: yellow, fontSize: '1.5em'}}>סגור ביום זה <Checkbox
                          value={this.state.close['F']}
                          checked={this.state.close['F']}
                          onChange={(e) => this.close('F')}
                          color={yellow}
                          style={{
                            color: blue,
                            marginTop: 0,
                            marginBottom: 0
                          }}
                        /></p>
                        {this.state.close['F'] && (
                          <p style={{color: blue, fontSize: '1.5em'}}>העסק מוגדר כסגור ביום זה</p>
                        )}
                      </React.Fragment>
                      {!this.state.close['F'] && (
                        <div>
                          <div>
                            <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>משעה</p>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              value={this.getOpeningHours('F', 'from')}
                              onChange={(time) => this.setOpeningHours('F', 'from', time)}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              ampm={false}
                              style={{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  fontSize: '1.5em',
                                  width: '90%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </div>
                          <div>
                            <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>עד שעה</p>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              label=""
                              value={this.getOpeningHours('F', 'to')}
                              onChange={(time) => this.setOpeningHours('F', 'to', time)}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              ampm={false}
                              style={{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  fontSize: '1.5em',
                                  width: '90%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      </MuiPickersUtilsProvider>
                      {/*שישי*/}
                      {/*שבת*/}
                      <p style={{fontSize: '2em', direction:'rtl', color: blue, fontWeight: 700, marginBottom: 0}}>
                        יום שבת
                      </p>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <React.Fragment>
                        <p style={{color: yellow, fontSize: '1.5em'}}>סגור ביום זה <Checkbox
                          value={this.state.close['G']}
                          checked={this.state.close['G']}
                          onChange={(e) => this.close('G')}
                          color={yellow}
                          style={{
                            color: blue,
                            marginTop: 0,
                            marginBottom: 0
                          }}
                        /></p>
                        {this.state.close['G'] && (
                          <p style={{color: blue, fontSize: '1.5em'}}>העסק מוגדר כסגור ביום זה</p>
                        )}
                      </React.Fragment>
                      {!this.state.close['G'] && (
                        <div>
                          <div>
                            <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>משעה</p>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              value={this.getOpeningHours('G', 'from')}
                              onChange={(time) => this.setOpeningHours('G', 'from', time)}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              ampm={false}
                              style={{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  fontSize: '1.5em',
                                  width: '90%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </div>
                          <div>
                            <p style={{color: blue, fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>עד שעה</p>
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              label=""
                              value={this.getOpeningHours('G', 'to')}
                              onChange={(time) => this.setOpeningHours('G', 'to', time)}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              ampm={false}
                              style={{
                                fontSize: '1.5em',
                                width: '90%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  fontSize: '1.5em',
                                  width: '90%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      </MuiPickersUtilsProvider>
                      {/*שבת*/}
                      </React.Fragment>
                    )}
                  </div>
                  </div>
                </Grid>
              )}
              {this.state.mobileNavLevel === 5 && (
                <React.Fragment>
                <Grid item xs={12} style={{marginBottom: '2%'}}>
                  <div style={{marginRight: '4%', textAlign :'right'}}>
                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    תבנית הכרטיס שלי <span style={{color: 'red'}}>*</span>
                  </p>

                  {(this.state.templateId === '' || this.state.templateId === null || this.state.templateId === undefined) ? (
                    <React.Fragment>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1em'}}>אין לך תבנית עדיין, ללא תבנית לא נוכל ליצור את הכרטיס שלך!</p>
                      <button onClick={(e) => this.setState({changeTemplateModal: true})} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.2em'}}>לבחירת תבנית לחצו כאן</button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1em', marginBottom: -15}}>התבנית שלך:</p>
                      <br />
                      <img src={('https://app.dibit.co.il/templates/'+ this.state.template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 150, height: 300}} />
                      <br />
                      <button onClick={(e) => this.setState({changeTemplateModal: true})} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.2em'}}>לבחירת תבנית לחצו כאן</button>
                    </React.Fragment>
                  )}

                  {/*<Select
                    value={this.state.templateId}
                    onChange={(e) => this.setState({templateId: e.target.value})}
                    style={{
                      width: '50%', textAlign: 'right', direction: 'rtl'
                    }}
                  >
                    {this.state.templates.map((index) => {
                      return (
                        <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={index._id}>{index.name}</MenuItem>
                      );
                    })}
                  </Select>*/}

                  </div>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'right', marginRight: '5%'}}>
                  <h2>בחר תבנית ממאגר התבניות של דיביט</h2>
                </Grid>

                {this.state.templates.map((template) => {
                  if (this.state.templateChosen === template._id.toString()) {
                    return (
                      <Grid item xs={6} style={{textAlign: 'center'}}>
                        <p>{template.name}</p>
                        <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={6} style={{textAlign: 'center'}} onClick={(e) => this.areYouSure(template._id)}>
                        <p>{template.name}</p>
                        <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', }} />
                      </Grid>
                    );
                  }
                })}

                </React.Fragment>
              )}

              <Grid item xs={12} style={{marginBottom: '2%'}}>
                <div style={{textAlign :'right'}}>
                  <center><Button onClick={(e) => this.updateCard()} style={{color: blue, backgroundColor: yellow, fontSize: '1.6em', width: '90%'}}>
                    עדכון הכרטיס שלי
                  </Button></center>
                  <br /><br />

                    {this.state.errorAlert && (
                      <div style={{float: 'right', maxWidth:'50%'}}>
                        <Alert severity="error" color="error" style={{textAlign: 'center', direction: 'rtl'}}>
                          &nbsp; {this.state.error}  &nbsp;
                        </Alert>
                      </div>
                    )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <center><p style={{direction:'rtl', color: blue, fontSize: '1em', fontWeight: 100}}>
                    כל הזכויות שמורות לדיביט בע"מ, 2020
                  </p></center>
                </div>
              </Grid>
            </Grid>
            </MobileView>
          </React.Fragment>
        )}

      </div>
    );
  }
}

export default EditCard;
