import React, { Component } from 'react';
import '../../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';
import LinkIcon from '@material-ui/icons/Link';
import OfflineHeader from '../../components/OfflineHeader';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import TimelineIcon from '@material-ui/icons/Timeline';
import GoogleLogin from 'react-google-login';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Chart } from 'react-charts'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CancelIcon from '@material-ui/icons/Cancel';
import {Helmet} from 'react-helmet';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import {
  BrowserView,
  MobileView,
  isMobile
} from "react-device-detect";
import {
 Link,
 Redirect
} from "react-router-dom";

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';


class Messages extends Component {
  componentDidMount () {
    this.readAllMessage();
    this.getMessages();
    this.getPackage();
  }

  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      reply: {},
      loading: false,
      messageReadSnackBarIndication: false,
      package: {},
      trialEnded: false,
      trialUntil: 0,
      slug: ''
    };
  }

  getPackage = () => {
    this.setState({loading: true});
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'profile', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          if (response.data.data.card.slug === null || response.data.data.card.slug === undefined || response.data.data.card.slug === '') {
            window.location.href='/signup'
          }
          //console.log(response);
          this.setState({loading: false});
          if (response.data.status === "ok") {
            this.setState({
              package: response.data.data.package,
              trialEnded: response.data.data.trialEnded,
              trialUntil: response.data.data.trialUntil,
              slug: response.data.data.card.slug
            });
          }
        }).catch(err => {
          console.log(err);
          this.setState({loading: false});
        });
      } catch (err) {
        console.log(err);
        this.setState({loading: false});
      }
    }
  }

  getMessages = () => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'messages', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          if (response.data.status === "ok") {
            let messages = response.data.data.messages;
            let output = [];
            messages.forEach((item, i) => {
              if (
                (item.reply === '' || item.reply === undefined || item.reply === null)
              ) output.push(item);
            });

            this.setState({messages: output});
          }
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  readAllMessage = () => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.put(API + 'messages/read', {
        }, {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {

        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  readMessage = (id) => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.put(API + 'messages/read-one?id='+id, {
        }, {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          this.setState({messageReadSnackBarIndication: true});
          this.getMessages();
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  replyMessage = (id) => {
    let token = cookies.get('__dato__');
    console.log(id);
    console.log(token);
    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.put(API + 'message/reply?id='+id, {
          reply: this.state.reply[id]
        }, {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          if (response.data.status === "ok") {
            alert('התשובה נרשמה בהצלחה!');
            this.getMessages();
          }
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
        console.log(err);
      }
    }
  }
  setReply = (id, text) => {
    let replys = this.state.reply;
    replys[id] = text;

    this.setState({reply: replys});
  }


    helmet = () => {
      const description = 'דיביט | ההודעות שלי';

      return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>דיביט | ההודעות שלי</title>
            <link rel="canonical" href={window.location.href} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta property="og:description" content={description} />
            <meta property="og:title" content={description} />
            <meta property="og:site_name" content="דיביט" />
            <meta property="og:see_also" content="דיביט" />
            <meta itemprop="name" content={description} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta name="description" content={description}/>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
      );
    }


  render() {
    return (
      <div>
        {this.state.trialEnded && (<Redirect to='/' />)}

        <BrowserView>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OfflineHeader slug={this.state.slug} mobile={isMobile} title={'ההודעות שלי'} package={this.state.package} trialUntil={this.state.trialUntil}/>
          </Grid>
          <Grid item xs={12} style={{marginBottom: '0%', marginTop: '-2%'}}>
            <div style={{marginRight: '4%',  marginBottom: -30}}>
              <p style={{direction:'rtl', fontSize: '2em', fontWeight: 700, color: yellow,  marginBottom: -0}}>
                ההודעות שלי
              </p>
              <Link to='/messages/history' style={{textDecoration: 'none'}}><p style={{direction:'rtl', fontSize: '1.2em', fontWeight: 700, color: blue,}}>
                להיסטוריית ההודעות לחצו כאן
              </p></Link>
              <br />
            </div>
          </Grid>

          <Grid item xs={12} style={{marginBottom: '2%'}}>
            <div style={{marginRight: '4%', textAlign:'right'}}>
              {this.state.messages.length === 0 && (
                <p style={{direction:'rtl', fontSize: '1.3em', fontWeight: 700, color: yellow,  marginBottom: -0}}>
                  אין הודעות חדשות
                </p>
              )}

              {this.state.messages.reverse().map((item) => {
                return (
                  <center>
                  <Card style={{padding: '1%', width: isMobile ? '90%' : '50%', float: 'right', textAlign: 'right', marginTop: '2%'}} raised={true}>
                    <div style={{width: '100%', textAlign: 'right'}}>
                      <CancelIcon style={{width: 40, height: 40, textAlign: 'right', marginTop: 0, marginBottom: 0}} onClick={(e) => this.readMessage(item._id)}/>
                    </div>

                    <p style={{direction:'rtl', color: blue, fontSize: isMobile ? '1.5em' : '1.3em', fontWeight: 700, marginBottom: 10}}>
                      "{item.message}"
                    </p>
                    <p style={{direction:'rtl', fontSize: '1.1em', fontWeight: 700, marginBottom: 10}}>
                      <span style={{color: blue}}>{new Date(item.createdAt).getDate() + '/' + (new Date(item.createdAt).getMonth() + 1) + '/' + new Date(item.createdAt).getFullYear()}</span>
                    </p>

                    <Button onClick={(e) => this.replyMessage(item._id)} style={{color: blue, backgroundColor: yellow, fontSize: '1.3em'}}>
                      הגב
                    </Button>
                    &nbsp;&nbsp;
                    <TextField
                      value={this.state.reply[item._id] === undefined ? '' : this.state.reply[item._id]}
                      onChange={(e) => this.setReply(item._id, e.target.value)}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '50%',
                        fontSize:'1.3em',
                        marginTop: '0.5%',
                        backgroundColor: 'white',
                        color: blue
                      }}
                      inputProps={{
                        style:{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%',
                          backgroundColor: 'white',
                          color: blue
                        }
                      }}
                    />

                    <p style={{marginRight: '2%', fontSize: '1.3em', color: blue}}>- או לחילופין -</p>
                    <Button style={{width: '50%', color: yellow, backgroundColor: blue, fontSize: '1.6em'}} onClick={(e) => window.open('tel:'+item.guestPhoneNumber, '_blank')}>
                      התקשרו אל הלקוח
                    </Button>
                    <br /><br />
                    <Button style={{width: '50%', color: 'white', backgroundColor: '#25d366', fontSize: '1.6em'}} onClick={(e) => window.open('https://wa.me/+972'+item.guestPhoneNumber, '_blank')}>
                      <WhatsAppIcon style={{width: 32, height: 32, color: 'white'}}/> שלח ווטצאפ ללקוח
                    </Button>
                    <br /><br />

                  </Card>
                  <br /><br />
                  </center>
                )
              })}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <center><p style={{direction:'rtl', color: blue, fontSize: '1.1em', fontWeight: 100}}>
                כל הזכויות שמורות לדיביט בע"מ, 2020
              </p></center>
            </div>
          </Grid>
        </Grid>
        </BrowserView>

        <MobileView>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OfflineHeader slug={this.state.slug} mobile={isMobile} title={'ההודעות שלי'} package={this.state.package} trialUntil={this.state.trialUntil}/>
          </Grid>
          <Grid item xs={12} style={{marginBottom: '0%', marginTop: '-2%'}}>
            <div style={{marginRight: '4%',  marginBottom: -30}}>
              <Link to='/messages/history' style={{textDecoration: 'none'}}>
              <p style={{direction:'rtl', fontSize: '1.5em', fontWeight: 700, color: blue,}}>
                להיסטוריית ההודעות לחצו כאן
              </p>
              </Link>
              <br />
            </div>
          </Grid>

          <Grid item xs={12} style={{marginBottom: '2%'}}>
            <div>
              {this.state.messages.reverse().map((item) => {
                console.log(item);
                return (
                  <center>
                  <Card style={{width: '95%'}} raised={true}>
                    <div style={{width: '100%', textAlign: 'right'}}>
                      <CancelIcon style={{width: 40, height: 40, textAlign: 'right', marginTop: 0, marginBottom: 0}} onClick={(e) => this.readMessage(item._id)}/>
                    </div>

                    <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 10}}>
                      "{item.message}"
                    </p>
                    <p style={{direction:'rtl', fontSize: '1.1em', fontWeight: 700, marginBottom: 10}}>
                      <span style={{color: blue}}>{new Date(item.createdAt).getDate() + '/' + (new Date(item.createdAt).getMonth() + 1) + '/' + new Date(item.createdAt).getFullYear()}</span>
                    </p>

                    <TextField
                      value={this.state.reply[item._id] === undefined ? '' : this.state.reply[item._id]}
                      onChange={(e) => this.setReply(item._id, e.target.value)}
                      placeholder={'אנא כתבו את התגובה להודעה כאן...'}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '99%',
                        marginTop: '0.5%',
                        backgroundColor: 'white',
                        color: blue
                      }}
                      inputProps={{
                        style:{
                          fontSize:'1.4em',
                          backgroundColor: 'white',
                          color: blue,
                          fontWeight: 'bold'
                        }
                      }}
                    />
                    <br /><br />
                    <Button onClick={(e) => this.replyMessage(item._id)} style={{width: '99%', color: blue, backgroundColor: yellow, fontSize: '1.6em'}}>
                      הגב
                    </Button>

                    <p style={{marginRight: '2%', fontSize: '1.3em', color: blue}}>- או לחילופין -</p>
                    <Button style={{width: '99%', color: yellow, backgroundColor: blue, fontSize: '1.6em'}} onClick={(e) => window.open('tel:'+item.guestPhoneNumber, '_blank')}>
                      התקשרו אל הלקוח
                    </Button>
                    <br /><br />
                    <Button style={{width: '99%', color: 'white', backgroundColor: '#25d366', fontSize: '1.6em'}} onClick={(e) => window.open('https://wa.me/+972'+item.guestPhoneNumber, '_blank')}>
                      <WhatsAppIcon style={{width: 32, height: 32, color: 'white'}}/> שלח ווטצאפ ללקוח
                    </Button>
                    <br /><br />
                  </Card>
                  <br /><br />
                  </center>
                )
              })}
            </div>
          </Grid>
          <Snackbar open={this.state.messageReadSnackBarIndication} autoHideDuration={6000} onClose={(e) => this.setState({messageReadSnackBarIndication: false})} style={{fontSize: '1.2em'}}>
            <Alert onClose={(e) => this.setState({messageReadSnackBarIndication: false})} severity="success" style={{fontSize: '1.2em'}}>
              <span style={{fontSize: '1.2em'}}>ההודעה סומנה כנקראה</span>
            </Alert>
          </Snackbar>
          <Grid item xs={12}>
            <div>
              <center><p style={{direction:'rtl', color: blue, fontSize: '1.4em', fontWeight: 100}}>
                כל הזכויות שמורות לדיביט בע"מ, 2020
              </p></center>
            </div>
          </Grid>
        </Grid>
        </MobileView>
      </div>
    );
  }
}

export default Messages;
