import React, { Component } from 'react';
import '../../App.css';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import CircularProgress from '@material-ui/core/CircularProgress';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { SliderPicker, ChromePicker } from 'react-color'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';

import {
 Link,
 Redirect
} from "react-router-dom";

import {
  BrowserView,
  MobileView,
} from "react-device-detect";

import Switch from '@material-ui/core/Switch';
import LinkIcon from '@material-ui/icons/Link';
import OfflineHeader from '../../components/OfflineHeader';
import OfflineHeaderLogin from '../../components/OfflineHeaderLogin';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import TimelineIcon from '@material-ui/icons/Timeline';
import GoogleLogin from 'react-google-login';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CreateIcon from '@material-ui/icons/Create';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';

import FBLogo from '../../resources/fb_logo.png';
// import InstagramLogo from '../../resources/instagram_logo.png';
// import WhatsappLogo from '../../resources/whatsapp_logo.png';
// import WazeLogo from '../../resources/waze_logo.png';
// import WhatsappSVG from '../../resources/whatsapp.svg';
// import Paperplane from '../../resources/paperplane.png';
// import LinkIconI from '../../resources/broken-link.png';
// import Phone from '../../resources/phone.png';
import InstagramLogo from '../../resources/icons/instagram.svg';
import WhatsappLogo from '../../resources/icons/whatsapp.svg';
import WazeLogo from '../../resources/icons/waze.svg';
import WhatsappSVG from '../../resources/icons/whatsapp.svg';
import Paperplane from '../../resources/icons/send.svg';
import LinkIconI from '../../resources/broken-link.png';
import CallIcon from '../../resources/icons/call.svg';
import WatchIcon from '../../resources/icons/view.svg';
import Trend from '../../resources/icons/trend.svg';
import EditIcon from '../../resources/icons/edit.svg';
import MessageIcon from '../../resources/icons/message.svg';
import Temp from '../../resources/temp.png';
import CheckIcon from '@material-ui/icons/Check';

import {Helmet} from 'react-helmet';

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import CloseIcon from '@material-ui/icons/Close';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';


class Packages extends Component {
    constructor(props) {
      super(props);
      this.state = {
        link: '',
        name: '',
        description: '',
        package: {},
        trialEnded: false,
        trialUntil: 0,
        slug: '',
        payment: false,

        ccno: '',
        expdate: '',
        cvv: '',
        idNumber: '',
        paymentSuccess: '',
        expdateMonth: '01',
        expdateYear: '21'
      };
    }

    componentDidMount() {
      this.getData();
      this.getPackage();
    }

    processPayment = () => {
      this.setState({loadingPayment: true});

      let payload = {
        ccno: this.state.ccno,
        expdate: this.state.expdateMonth + '/' + this.state.expdateYear,
        cvv: this.state.cvv,
        idNumber: this.state.idNumber,
      }

      console.log(payload);
      console.log(API + 'payment/create-payment?query='+this.state.packageId);

      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          return axios.post(API + 'payment/create-payment?query='+this.state.packageId, payload, {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {
            console.log(response);
            this.setState({loadingPayment: false}, () => {
              if (response.data.status === 'ok') {
                this.setState({paymentSuccess: 'yes'});
              } else {
                this.setState({paymentSuccess: 'no'});
              }
            })
          }).catch(err => {
            console.log(err);
            this.setState({loading: false, paymentSuccess: 'no', loadingPayment: false});
          });
        } catch (err) {
          console.log(err);
          this.setState({loading: false, paymentSuccess: 'no', loadingPayment: false});
        }
      }
    }



    getPackage = () => {
      this.setState({loading: true});
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          return axios.get(API + 'profile', {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {
            if (response.data.data.card.slug === null || response.data.data.card.slug === undefined || response.data.data.card.slug === '') {
              window.location.href='/signup'
            }
            //console.log(response);
            this.setState({loading: false});
            if (response.data.status === "ok") {
              this.setState({
                package: response.data.data.package,
                trialEnded: response.data.data.trialEnded,
                trialUntil: response.data.data.trialUntil,
                slug: response.data.data.card.slug
              });
            }
          }).catch(err => {
            console.log(err);
            this.setState({loading: false});
          });
        } catch (err) {
          console.log(err);
          this.setState({loading: false});
        }
      }
    }

    getData = () => {
      this.setState({loading: true});
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          return axios.get(API + 'profile', {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {
            if (response.data.data.card.slug === null || response.data.data.card.slug === undefined || response.data.data.card.slug === '') {
              window.location.href='/signup'
            }
            //console.log(response);
            this.setState({loading: false});
            if (response.data.status === "ok") {
              this.setState({
                firstName: response.data.data.profile.firstName,
                lastName: response.data.data.profile.lastName,
                card: response.data.data.card,
                package: response.data.data.package,
              });
            }
          }).catch(err => {
            console.log(err);
            this.setState({loading: false});
          });
        } catch (err) {
          console.log(err);
          this.setState({loading: false});
        }
      }
    }

    getPayment = (id) => {
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          return axios.get(API + 'payment/info?query='+id, {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {
            console.log(response);
            this.setState({
              payment: true,
              name: response.data.data.name,
              description: response.data.data.description,
              packageId: id,
              price: response.data.data.price,
            });
          }).catch(err => {
            console.log(err);
            this.setState({loading: false});
          });
        } catch (err) {
          console.log(err);
          this.setState({loading: false});
        }
      }
    }


      helmet = () => {
        const description = 'דיביט | חבילות';

        return (
          <Helmet>
              <meta charSet="utf-8" />
              <title>דיביט | חבילות</title>
              <link rel="canonical" href={window.location.href} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
              <meta property="og:description" content={description} />
              <meta property="og:title" content={description} />
              <meta property="og:site_name" content="דיביט" />
              <meta property="og:see_also" content="דיביט" />
              <meta itemprop="name" content={description} />
              <meta itemprop="description" content={description} />
              <meta itemprop="image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
              <meta name="description" content={description}/>
              <link rel="canonical" href={window.location.href} />
          </Helmet>
        );
      }

      setExpdate = (text) => {
        let text_ = text.replace('/', '').split('');

        if (text_.length === 4) {
          text = text_[0] + '' + text_[1] + '/' + text_[2] + '' + text_[3];
        }

        if (text_.length <= 4) this.setState({expdate: text})
      }


  render() {
    return (
      <div>
      {this.helmet()}

        <BrowserView>
          {this.state.loading ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {this.state.trialEnded ? (
                  <OfflineHeaderLogin package={this.state.package} trialUntil={this.state.trialUntil}/>
                ) : (
                  <OfflineHeader slug={this.state.slug} package={this.state.package} trialUntil={this.state.trialUntil}/>
                )}
              </Grid>
              <Grid item xs={12}>
                <center>
                  <div style={{textAlign: 'center', marginTop: '5%'}}>
                    <CircularProgress />
                    <h3 style={{direciton: 'rtl'}}>..טוען</h3>
                  </div>
                </center>
              </Grid>
            </Grid>
          ) : (
            <React.Fragment>
                {this.state.trialEnded ? (
                  <OfflineHeaderLogin package={this.state.package} trialUntil={this.state.trialUntil}/>
                ) : (
                  <OfflineHeader slug={this.state.slug} package={this.state.package} trialUntil={this.state.trialUntil}/>
                )}
                <Grid container spacing={2}>

                {!this.state.payment ? (
                  <React.Fragment>
                  <Grid item xs={12} style={{marginTop: '1%', marginBottom: '1%'}}>
                  </Grid>
                  <Grid item xs={4}>
                  <center><div style={{maxWidth: '85%'}}>
                    <Card style={{backgroundColor: '#f7f7f7', borderRadius: 0}}>
                      <div style={{backgroundColor: blue, padding: '1%'}}>
                        <h2 style={{color: 'white', marginBottom: 0, marginTop: 0}}>חבילת פרימיום</h2>
                        <h3 style={{color: 'white', marginTop: 0}}>חבילה מותאמת אישית</h3>
                      </div>
                      <div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <span style={{marginTop: '2%'}}>₪</span>
                          <span style={{fontSize: '2.3em'}}>35</span>
                          <span style={{marginTop: '2%'}}>00</span>
                        </div>
                        <p style={{textAlign: 'center', fontWeight: 100, marginTop: '-3%'}}>חודשי</p>
                        <br />
                        <div style={{marginTop: '-5%'}}>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>ייחודית URL כתובת</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>כפתורי שיתוף</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>אודות העסק</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שעות פעילות</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שמירה באנשי קשר</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>גלריית תמונות ללא הגבלה</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>עיצוב מותאם אישית</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שליחה וקבלת הודעות</span>
                            <CheckCircleOutlineIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>עריכת תבנית</span>
                            <CheckCircleOutlineIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        </div>

                        <center>
                        {this.state.package._id !== '5f788a78e276cbd65a7e6543' ? (
                          <React.Fragment>
                          <Button style={{
                            backgroundColor: yellow,
                            color: 'white',
                            fontSize: '1.5em',
                            borderRadius: 0,
                            width: 100
                          }} onClick={(e) => this.getPayment('5f788a78e276cbd65a7e6543')}>לרכישה</Button>
                          <br />
                          <p style={{textAlign: 'center', fontWeight: 100, marginTop: '0%'}}>ללא התחייבות</p>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                          <p style={{textAlign: 'center', fontWeight: 100, marginTop: '0%', fontSize: '1.5em'}}>זוהי החבילה אליה אתה מנוי</p>
                          </React.Fragment>
                        )}
                        </center>
                      </div>
                    </Card></div></center>
                  </Grid>
                  <Grid item xs={4}>
                  <center><div style={{maxWidth: '85%'}}>
                    <Card style={{backgroundColor: '#f7f7f7', borderRadius: 0}}>
                    <div style={{backgroundColor: blue, padding: '1%'}}>
                      <h2 style={{color: 'white', marginBottom: 0, marginTop: 0}}>חבילת מתקדמת</h2>
                      <h3 style={{color: 'white', marginTop: 0}}>החבילה הכי משתלמת</h3>
                    </div>
                    <div>
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <span style={{marginTop: '6%', color: '#717171'}}><del>₪39</del></span>
                        <span style={{marginTop: '2%'}}>₪</span>
                        <span style={{fontSize: '2.3em'}}>29</span>
                        <span style={{marginTop: '2%'}}>90</span>
                      </div>
                      <p style={{textAlign: 'center', fontWeight: 100, marginTop: '-3%'}}>חודשי</p>
                      <br />
                      <div style={{marginTop: '-5%'}}>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>ייחודית URL כתובת</span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>כפתורי שיתוף</span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>אודות העסק</span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שעות פעילות</span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שמירה באנשי קשר</span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>גלריית תמונות ללא הגבלה</span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>עיצוב מותאם אישית</span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שליחה וקבלת הודעות</span>
                          <CheckCircleOutlineIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>עריכת תבנית</span>
                          <CheckCircleOutlineIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                      </div>

                      <center>
                      {this.state.package._id !== '5f788a61e276cbd65a7e6542' ? (
                        <React.Fragment>
                        <Button style={{
                          backgroundColor: yellow,
                          color: 'white',
                          fontSize: '1.5em',
                          borderRadius: 0,
                          width: 100
                        }} onClick={(e) => this.getPayment('5f788a61e276cbd65a7e6542')}>לרכישה</Button>
                        <br />
                        <p style={{textAlign: 'center', fontWeight: 100, marginTop: '0%'}}>ללא התחייבות</p>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                        <p style={{textAlign: 'center', fontWeight: 100, marginTop: '0%', fontSize: '1.5em'}}>זוהי החבילה אליה אתה מנוי</p>
                        </React.Fragment>
                      )}
                      </center>
                    </div>
                    </Card></div></center>
                  </Grid>
                  <Grid item xs={4}>
                  <center><div style={{maxWidth: '85%'}}>
                    <Card style={{backgroundColor: '#f7f7f7', borderRadius: 0}}>
                    <div style={{backgroundColor: blue, padding: '1%'}}>
                      <h2 style={{color: 'white', marginBottom: 0, marginTop: 0}}>חבילת הבסיס</h2>
                      <h3 style={{color: 'white', marginTop: 0}}>החבילה הקטנה ביותר</h3>
                    </div>
                    <div>
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <span style={{marginTop: '2%'}}>₪</span>
                        <span style={{fontSize: '2.3em'}}>19</span>
                        <span style={{marginTop: '2%'}}>90</span>
                      </div>
                      <p style={{textAlign: 'center', fontWeight: 100, marginTop: '-3%'}}>חודשי</p>
                      <br />
                      <div style={{marginTop: '-5%'}}>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>ייחודית URL כתובת</span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>כפתורי שיתוף</span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>אודות העסק</span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שעות פעילות</span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שמירה באנשי קשר</span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>עד 10 תמונות לבחירה</span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'ltr'}}>
                          עד 10 תבניות לבחירה
                          </span>
                          <CheckIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שליחה וקבלת הודעות</span>
                          <CloseIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                          <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>עריכת תבנית</span>
                          <CloseIcon style={{width: 25, height: 25}} />
                        </div>
                        <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                      </div>

                      <center>
                      {this.state.package._id !== '5f43f7d4b23816b7e984b6e1' ? (
                        <React.Fragment>
                        <Button style={{
                          backgroundColor: yellow,
                          color: 'white',
                          fontSize: '1.5em',
                          borderRadius: 0,
                          width: 100
                        }} onClick={(e) => this.getPayment('5f43f7d4b23816b7e984b6e1')}>לרכישה</Button>
                        <br />
                        <p style={{textAlign: 'center', fontWeight: 100, marginTop: '0%'}}>ללא התחייבות</p>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                        <p style={{textAlign: 'center', fontWeight: 100, marginTop: '0%', fontSize: '1.5em'}}>זוהי החבילה אליה אתה מנוי</p>
                        </React.Fragment>
                      )}
                      </center>
                    </div>
                    </Card></div></center>
                  </Grid>
                  </React.Fragment>
                ) : (
                  <Grid item xs={12} style={{marginTop: '1%', marginBottom: '1%'}}>
                    <center>
                      <p style={{fontSize: '2em', direction: 'rtl', marginBottom: 0}}>רכישת {this.state.name}</p>
                      <p style={{fontSize: '1.5em', marginTop: 0, marginBottom: 0}}>{this.state.description}</p>
                      <p style={{fontSize: '1.7em', marginBottom: 0, marginTop: 0, direciton: 'rtl'}}>
                      ₪{this.state.price}
                      </p>
                      <p style={{fontSize: '1.2em', marginBottom: 0, marginTop: 0, direciton: 'rtl'}}>
                      לחודש (לא כולל מע"מ)
                      </p>

                      {this.state.loadingPayment ? (
                        <React.Fragment>
                          <p style={{fontSize: '1.5em', marginBottom: 0, marginTop: 0, direction: 'rtl'}}>אנא המתינו בזמן שאנו מעבדים את התשלום..</p>
                          <CircularProgress />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                        {this.state.paymentSuccess === '' ? (
                          <React.Fragment>
                          <div style={{textAlign: 'right', marginRight: '30%'}}>
                            <p style={{direction:'rtl', color: blue, fontSize: '1.3em', fontWeight: 700, marginBottom: 0}}>
                              מספר כרטיס אשראי
                            </p>
                            <TextField
                              value={this.state.ccno}
                              onChange={(e) => this.setState({ccno: e.target.value})}
                              style={{
                                direction: 'rtl',
                                textAlign: 'right',
                                width: '50%',
                                fontSize:'1.3em',
                                marginTop: '0.5%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  direction: 'rtl',
                                  textAlign: 'right',
                                  width: '50%',
                                  fontSize:'1.3em',
                                  marginTop: '0.5%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                            <p style={{direction:'rtl', color: blue, fontSize: '1.3em', fontWeight: 700, marginBottom: 0}}>
                              תוקף הכרטיס
                            </p>
                            {/*<TextField
                              value={this.state.expdate}
                              onChange={(e) => this.setExpdate(e.target.value)}
                              style={{
                                direction: 'rtl',
                                textAlign: 'right',
                                width: '50%',
                                fontSize:'1.3em',
                                marginTop: '0.5%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  direction: 'rtl',
                                  textAlign: 'right',
                                  width: '50%',
                                  fontSize:'1.3em',
                                  marginTop: '0.5%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />*/}
                            <Select value={this.state.expdateMonth}
                               onChange={(e) => this.setState({expdateMonth: e.target.value})}
                             style={{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '24%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              marginRight: '0.5%',
                              marginLeft: '0.5%',
                              color: blue
                            }}
                            inputProps={{
                              style:{
                                direction: 'rtl',
                                textAlign: 'right',
                                width: '24%',
                                fontSize:'1.3em',
                                marginTop: '0.5%',
                                backgroundColor: 'white',
                                marginRight: '0.5%',
                                marginLeft: '0.5%',
                                color: blue
                              }
                            }}>
                              <MenuItem value={'01'} style={{fontWeight: 'bold', textAlign: 'right'}}>01</MenuItem>
                              <MenuItem value={'02'} style={{fontWeight: 'bold', textAlign: 'right'}}>02</MenuItem>
                              <MenuItem value={'03'} style={{fontWeight: 'bold', textAlign: 'right'}}>03</MenuItem>
                              <MenuItem value={'04'} style={{fontWeight: 'bold', textAlign: 'right'}}>04</MenuItem>
                              <MenuItem value={'05'} style={{fontWeight: 'bold', textAlign: 'right'}}>05</MenuItem>
                              <MenuItem value={'06'} style={{fontWeight: 'bold', textAlign: 'right'}}>06</MenuItem>
                              <MenuItem value={'07'} style={{fontWeight: 'bold', textAlign: 'right'}}>07</MenuItem>
                              <MenuItem value={'08'} style={{fontWeight: 'bold', textAlign: 'right'}}>08</MenuItem>
                              <MenuItem value={'09'} style={{fontWeight: 'bold', textAlign: 'right'}}>09</MenuItem>
                              <MenuItem value={'10'} style={{fontWeight: 'bold', textAlign: 'right'}}>10</MenuItem>
                              <MenuItem value={'11'} style={{fontWeight: 'bold', textAlign: 'right'}}>11</MenuItem>
                              <MenuItem value={'12'} style={{fontWeight: 'bold', textAlign: 'right'}}>12</MenuItem>
                            </Select>
                            <Select value={this.state.expdateYear}
                            onChange={(e) => this.setState({expdateYear: e.target.value})}
                             style={{
                              direction: 'rtl',
                              textAlign: 'right',
                              width: '24%',
                              fontSize:'1.3em',
                              marginTop: '0.5%',
                              backgroundColor: 'white',
                              marginRight: '0.5%',
                              marginLeft: '0.5%',
                              color: blue
                            }}
                            inputProps={{
                              style:{
                                direction: 'rtl',
                                textAlign: 'right',
                                width: '24%',
                                fontSize:'1.3em',
                                marginTop: '0.5%',
                                backgroundColor: 'white',
                                marginRight: '0.5%',
                                marginLeft: '0.5%',
                                color: blue
                              }
                            }}>
                              <MenuItem value={'21'} style={{fontWeight: 'bold', textAlign: 'right'}}>2021</MenuItem>
                              <MenuItem value={'22'} style={{fontWeight: 'bold', textAlign: 'right'}}>2022</MenuItem>
                              <MenuItem value={'23'} style={{fontWeight: 'bold', textAlign: 'right'}}>2023</MenuItem>
                              <MenuItem value={'24'} style={{fontWeight: 'bold', textAlign: 'right'}}>2024</MenuItem>
                              <MenuItem value={'25'} style={{fontWeight: 'bold', textAlign: 'right'}}>2025</MenuItem>
                              <MenuItem value={'26'} style={{fontWeight: 'bold', textAlign: 'right'}}>2026</MenuItem>
                              <MenuItem value={'27'} style={{fontWeight: 'bold', textAlign: 'right'}}>2027</MenuItem>
                              <MenuItem value={'28'} style={{fontWeight: 'bold', textAlign: 'right'}}>2028</MenuItem>
                              <MenuItem value={'29'} style={{fontWeight: 'bold', textAlign: 'right'}}>2029</MenuItem>
                              <MenuItem value={'30'} style={{fontWeight: 'bold', textAlign: 'right'}}>2030</MenuItem>
                              <MenuItem value={'31'} style={{fontWeight: 'bold', textAlign: 'right'}}>2031</MenuItem>
                            </Select>
                            <p style={{direction:'rtl', color: blue, fontSize: '1.3em', fontWeight: 700, marginBottom: 0}}>
                              3 ספרות בגב הכרטיס (CVV/CVC)
                            </p>
                            <TextField
                              value={this.state.cvv}
                              onChange={(e) => this.setState({cvv: e.target.value})}
                              style={{
                                direction: 'rtl',
                                textAlign: 'right',
                                width: '50%',
                                fontSize:'1.3em',
                                marginTop: '0.5%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  direction: 'rtl',
                                  textAlign: 'right',
                                  width: '50%',
                                  fontSize:'1.3em',
                                  marginTop: '0.5%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                            <p style={{direction:'rtl', color: blue, fontSize: '1.3em', fontWeight: 700, marginBottom: 0}}>
                              מספר תעודת זהות
                            </p>
                            <TextField
                              value={this.state.idNumber}
                              onChange={(e) => this.setState({idNumber: e.target.value})}
                              style={{
                                direction: 'rtl',
                                textAlign: 'right',
                                width: '50%',
                                fontSize:'1.3em',
                                marginTop: '0.5%',
                                backgroundColor: 'white',
                                color: blue
                              }}
                              inputProps={{
                                style:{
                                  direction: 'rtl',
                                  textAlign: 'right',
                                  width: '50%',
                                  fontSize:'1.3em',
                                  marginTop: '0.5%',
                                  backgroundColor: 'white',
                                  color: blue
                                }
                              }}
                            />
                            <br /><br />

                          </div>
                          <Button style={{backgroundColor: yellow, border: '0px solid' + yellow, color: 'white', fontSize:'1.5em'}} onClick={(e) => this.processPayment()}>
                            סיום ורכישה
                          </Button>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                          {this.state.paymentSuccess === 'yes' ? (
                            <p style={{fontSize: '1.6em', color: 'green', textAlign: 'center', direction: 'rtl'}}>התשלום בוצע בהצלחה!</p>
                          ) : (
                            <p style={{fontSize: '1.6em', color: 'red', textAlign: 'center', direction: 'rtl'}}>התשלום נכשל, אנא נסו בשנית</p>
                          )}
                          </React.Fragment>
                        )}
                        </React.Fragment>
                      )}
                    </center>
                  </Grid>
                )}
                </Grid>
            </React.Fragment>
          )}
        </BrowserView>

        <MobileView>
          <Grid container spaceing={2}>
            <Grid item xs={12}>
              <OfflineHeader slug={this.state.slug} mobile={true} package={this.state.package} trialUntil={this.state.trialUntil}/>
            </Grid>
            <Grid item xs={12} style={{marginTop: '5%'}}>

            </Grid>

             <Grid item xs={12} style={{marginTop: '5%'}}></Grid>

             {!this.state.payment ? (
               <React.Fragment>
               {/*----*/}
               <Grid item xs={12} style={{marginBottom: '0%'}}>
                   <div style={{textAlign: 'right', direciton: 'rtl', marginRight: '5%'}}>
                     <h1>החבילות שלנו</h1>
                   </div>
                  <center><div style={{maxWidth: '85%'}}>
                    <Card style={{backgroundColor: '#f7f7f7', borderRadius: 0}}>
                      <div style={{backgroundColor: blue, padding: '1%'}}>
                        <h2 style={{color: 'white', marginBottom: 0, marginTop: 0}}>חבילת פרימיום</h2>
                        <h3 style={{color: 'white', marginTop: 0}}>חבילה מותאמת אישית</h3>
                      </div>
                      <div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <span style={{marginTop: '2%'}}>₪</span>
                          <span style={{fontSize: '2.3em'}}>35</span>
                          <span style={{marginTop: '2%'}}>00</span>
                        </div>
                        <p style={{textAlign: 'center', fontWeight: 100, marginTop: '-3%'}}>חודשי</p>
                        <br />
                        <div style={{marginTop: '-5%'}}>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>ייחודית URL כתובת</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>כפתורי שיתוף</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>אודות העסק</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שעות פעילות</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שמירה באנשי קשר</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>גלריית תמונות ללא הגבלה</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>עיצוב מותאם אישית</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שליחה וקבלת הודעות</span>
                            <CheckCircleOutlineIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>עריכת תבנית</span>
                            <CheckCircleOutlineIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        </div>

                        <center>
                        {this.state.package._id !== '5f788a78e276cbd65a7e6543' ? (
                          <React.Fragment>
                          <Button style={{
                            backgroundColor: yellow,
                            color: 'white',
                            fontSize: '1.5em',
                            borderRadius: 0,
                            width: 100
                          }} onClick={(e) => this.getPayment('5f788a78e276cbd65a7e6543')}>לרכישה</Button>
                          <br />
                          <p style={{textAlign: 'center', fontWeight: 100, marginTop: '0%'}}>ללא התחייבות</p>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                          <p style={{textAlign: 'center', fontWeight: 100, marginTop: '0%', fontSize: '1.5em'}}>זוהי החבילה אליה אתה מנוי</p>
                          </React.Fragment>
                        )}
                        </center>
                      </div>
                    </Card>
                    <br /><br />
                    <Card style={{backgroundColor: '#f7f7f7', borderRadius: 0}}>
                      <div style={{backgroundColor: blue, padding: '1%'}}>
                        <h2 style={{color: 'white', marginBottom: 0, marginTop: 0}}>חבילת מתקדמת</h2>
                        <h3 style={{color: 'white', marginTop: 0}}>החבילה הכי משתלמת</h3>
                      </div>
                      <div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <span style={{marginTop: '6%', color: '#717171'}}><del>₪39</del></span>
                          <span style={{marginTop: '2%'}}>₪</span>
                          <span style={{fontSize: '2.3em'}}>29</span>
                          <span style={{marginTop: '2%'}}>90</span>
                        </div>
                        <p style={{textAlign: 'center', fontWeight: 100, marginTop: '-3%'}}>חודשי</p>
                        <br />
                        <div style={{marginTop: '-5%'}}>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>ייחודית URL כתובת</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>כפתורי שיתוף</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>אודות העסק</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שעות פעילות</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שמירה באנשי קשר</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>גלריית תמונות ללא הגבלה</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>עיצוב מותאם אישית</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שליחה וקבלת הודעות</span>
                            <CheckCircleOutlineIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>עריכת תבנית</span>
                            <CheckCircleOutlineIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        </div>

                        <center>
                        {this.state.package._id !== '5f788a61e276cbd65a7e6542' ? (
                          <React.Fragment>
                          <Button style={{
                            backgroundColor: yellow,
                            color: 'white',
                            fontSize: '1.5em',
                            borderRadius: 0,
                            width: 100
                          }} onClick={(e) => this.getPayment('5f788a61e276cbd65a7e6542')}>לרכישה</Button>
                          <br />
                          <p style={{textAlign: 'center', fontWeight: 100, marginTop: '0%'}}>ללא התחייבות</p>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                          <p style={{textAlign: 'center', fontWeight: 100, marginTop: '0%', fontSize: '1.5em'}}>זוהי החבילה אליה אתה מנוי</p>
                          </React.Fragment>
                        )}
                        </center>
                      </div>
                    </Card>
                    <br /><br />
                    <Card style={{backgroundColor: '#f7f7f7', borderRadius: 0}}>
                      <div style={{backgroundColor: blue, padding: '1%'}}>
                        <h2 style={{color: 'white', marginBottom: 0, marginTop: 0}}>חבילת הבסיס</h2>
                        <h3 style={{color: 'white', marginTop: 0}}>החבילה הקטנה ביותר</h3>
                      </div>
                      <div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <span style={{marginTop: '2%'}}>₪</span>
                          <span style={{fontSize: '2.3em'}}>19</span>
                          <span style={{marginTop: '2%'}}>90</span>
                        </div>
                        <p style={{textAlign: 'center', fontWeight: 100, marginTop: '-3%'}}>חודשי</p>
                        <br />
                        <div style={{marginTop: '-5%'}}>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>ייחודית URL כתובת</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>כפתורי שיתוף</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>אודות העסק</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שעות פעילות</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שמירה באנשי קשר</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>עד 10 תמונות בגלרייה</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>עד 10 תבניות לבחירה</span>
                            <CheckIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>שליחה וקבלת הודעות</span>
                            <CloseIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                          <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5%'}}>
                            <span style={{fontSize: '1.2em', marginRight: '2%', direciton: 'rtl'}}>עריכת תבנית</span>
                            <CloseIcon style={{width: 25, height: 25}} />
                          </div>
                          <hr style={{backgroundColor: '#E8E8E8', color: '#E8E8E8', maxWidth: '70%'}}/>
                        </div>

                        <center>
                        {this.state.package._id !== '5f43f7d4b23816b7e984b6e1' ? (
                          <React.Fragment>
                          <Button style={{
                            backgroundColor: yellow,
                            color: 'white',
                            fontSize: '1.5em',
                            borderRadius: 0,
                            width: 100
                          }} onClick={(e) => this.getPayment('5f43f7d4b23816b7e984b6e1')}>לרכישה</Button>
                          <br />
                          <p style={{textAlign: 'center', fontWeight: 100, marginTop: '0%'}}>ללא התחייבות</p>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                          <p style={{textAlign: 'center', fontWeight: 100, marginTop: '0%', fontSize: '1.5em'}}>זוהי החבילה אליה אתה מנוי</p>
                          </React.Fragment>
                        )}
                        </center>
                      </div>
                    </Card>
                  </div></center>
               </Grid>
               {/*----*/}

               </React.Fragment>
             ) : (
               <Grid item xs={12} style={{marginTop: '1%', marginBottom: '1%'}}>
                 <center>
                 <p style={{fontSize: '2em', direction: 'rtl', marginBottom: '-2%'}}>רכישת {this.state.name}</p>
                 <p style={{fontSize: '1.5em', marginBottom: 0, marginTop: 0}}>{this.state.description}</p>
                 <p style={{fontSize: '1.7em', marginBottom: 0, marginTop: 0, direciton: 'rtl'}}>
                 ₪{this.state.price}
                 </p>
                 <p style={{fontSize: '1.2em', marginBottom: 0, marginTop: '-1%', direciton: 'rtl'}}>
                 לחודש (לא כולל מע"מ)
                 </p>

                 {this.state.loadingPayment ? (
                   <React.Fragment>
                     <p style={{fontSize: '1em', marginBottom: 0, marginTop: 0, direction: 'rtl'}}>אנא המתינו בזמן שאנו מעבדים את התשלום..</p>
                     <CircularProgress />
                   </React.Fragment>
                 ) : (
                   <React.Fragment>
                   {this.state.paymentSuccess === '' ? (
                     <React.Fragment>
                     <div style={{textAlign: 'right', marginRight: '5%'}}>
                       <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                         מספר כרטיס אשראי
                       </p>
                       <TextField
                         value={this.state.ccno}
                         onChange={(e) => this.setState({ccno: e.target.value})}
                         style={{
                           direction: 'rtl',
                           textAlign: 'right',
                           width: '90%',
                           fontSize:'1.5em',
                           marginTop: '0.5%',
                           backgroundColor: 'white',
                           color: blue
                         }}
                         inputProps={{
                           style:{
                             direction: 'rtl',
                             textAlign: 'right',
                             width: '90%',
                             fontSize:'1.5em',
                             marginTop: '0.5%',
                             backgroundColor: 'white',
                             color: blue
                           }
                         }}
                       />
                       <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                         תוקף הכרטיס
                       </p>
                       {/*<TextField
                         value={this.state.expdate}
                         onChange={(e) => this.setExpdate(e.target.value)}
                         style={{
                           direction: 'rtl',
                           textAlign: 'right',
                           width: '90%',
                           fontSize:'1.5em',
                           marginTop: '0.5%',
                           backgroundColor: 'white',
                           color: blue
                         }}
                         inputProps={{
                           style:{
                             direction: 'rtl',
                             textAlign: 'right',
                             width: '90%',
                             fontSize:'1.5em',
                             marginTop: '0.5%',
                             backgroundColor: 'white',
                             color: blue
                           }
                         }}
                       />*/}
                       <Select value={this.state.expdateMonth}
                          onChange={(e) => this.setState({expdateMonth: e.target.value})}
                        style={{
                         direction: 'rtl',
                         textAlign: 'right',
                         width: '44%',
                         fontSize:'1.3em',
                         marginTop: '0.5%',
                         backgroundColor: 'white',
                         marginRight: '0.5%',
                         marginLeft: '0.5%',
                         color: blue
                       }}
                       inputProps={{
                         style:{
                           direction: 'rtl',
                           textAlign: 'right',
                           width: '44%',
                           fontSize:'1.3em',
                           marginTop: '0.5%',
                           backgroundColor: 'white',
                           marginRight: '0.5%',
                           marginLeft: '0.5%',
                           color: blue
                         }
                       }}>
                         <MenuItem value={'01'} style={{fontWeight: 'bold', textAlign: 'right'}}>01</MenuItem>
                         <MenuItem value={'02'} style={{fontWeight: 'bold', textAlign: 'right'}}>02</MenuItem>
                         <MenuItem value={'03'} style={{fontWeight: 'bold', textAlign: 'right'}}>03</MenuItem>
                         <MenuItem value={'04'} style={{fontWeight: 'bold', textAlign: 'right'}}>04</MenuItem>
                         <MenuItem value={'05'} style={{fontWeight: 'bold', textAlign: 'right'}}>05</MenuItem>
                         <MenuItem value={'06'} style={{fontWeight: 'bold', textAlign: 'right'}}>06</MenuItem>
                         <MenuItem value={'07'} style={{fontWeight: 'bold', textAlign: 'right'}}>07</MenuItem>
                         <MenuItem value={'08'} style={{fontWeight: 'bold', textAlign: 'right'}}>08</MenuItem>
                         <MenuItem value={'09'} style={{fontWeight: 'bold', textAlign: 'right'}}>09</MenuItem>
                         <MenuItem value={'10'} style={{fontWeight: 'bold', textAlign: 'right'}}>10</MenuItem>
                         <MenuItem value={'11'} style={{fontWeight: 'bold', textAlign: 'right'}}>11</MenuItem>
                         <MenuItem value={'12'} style={{fontWeight: 'bold', textAlign: 'right'}}>12</MenuItem>
                       </Select>
                       <Select value={this.state.expdateYear}
                       onChange={(e) => this.setState({expdateYear: e.target.value})}
                        style={{
                         direction: 'rtl',
                         textAlign: 'right',
                         width: '44%',
                         fontSize:'1.3em',
                         marginTop: '0.5%',
                         backgroundColor: 'white',
                         marginRight: '0.5%',
                         marginLeft: '0.5%',
                         color: blue
                       }}
                       inputProps={{
                         style:{
                           direction: 'rtl',
                           textAlign: 'right',
                           width: '44%',
                           fontSize:'1.3em',
                           marginTop: '0.5%',
                           backgroundColor: 'white',
                           marginRight: '0.5%',
                           marginLeft: '0.5%',
                           color: blue
                         }
                       }}>
                         <MenuItem value={'21'} style={{fontWeight: 'bold', textAlign: 'right'}}>2021</MenuItem>
                         <MenuItem value={'22'} style={{fontWeight: 'bold', textAlign: 'right'}}>2022</MenuItem>
                         <MenuItem value={'23'} style={{fontWeight: 'bold', textAlign: 'right'}}>2023</MenuItem>
                         <MenuItem value={'24'} style={{fontWeight: 'bold', textAlign: 'right'}}>2024</MenuItem>
                         <MenuItem value={'25'} style={{fontWeight: 'bold', textAlign: 'right'}}>2025</MenuItem>
                         <MenuItem value={'26'} style={{fontWeight: 'bold', textAlign: 'right'}}>2026</MenuItem>
                         <MenuItem value={'27'} style={{fontWeight: 'bold', textAlign: 'right'}}>2027</MenuItem>
                         <MenuItem value={'28'} style={{fontWeight: 'bold', textAlign: 'right'}}>2028</MenuItem>
                         <MenuItem value={'29'} style={{fontWeight: 'bold', textAlign: 'right'}}>2029</MenuItem>
                         <MenuItem value={'30'} style={{fontWeight: 'bold', textAlign: 'right'}}>2030</MenuItem>
                         <MenuItem value={'31'} style={{fontWeight: 'bold', textAlign: 'right'}}>2031</MenuItem>
                       </Select>
                       <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                         3 ספרות בגב הכרטיס (CVV/CVC)
                       </p>
                       <TextField
                         value={this.state.cvv}
                         onChange={(e) => this.setState({cvv: e.target.value})}
                         style={{
                           direction: 'rtl',
                           textAlign: 'right',
                           width: '90%',
                           fontSize:'1.5em',
                           marginTop: '0.5%',
                           backgroundColor: 'white',
                           color: blue
                         }}
                         inputProps={{
                           style:{
                             direction: 'rtl',
                             textAlign: 'right',
                             width: '90%',
                             fontSize:'1.5em',
                             marginTop: '0.5%',
                             backgroundColor: 'white',
                             color: blue
                           }
                         }}
                       />
                       <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                         מספר תעודת זהות
                       </p>
                       <TextField
                         value={this.state.idNumber}
                         onChange={(e) => this.setState({idNumber: e.target.value})}
                         style={{
                           direction: 'rtl',
                           textAlign: 'right',
                           width: '90%',
                           fontSize:'1.5em',
                           marginTop: '0.5%',
                           backgroundColor: 'white',
                           color: blue
                         }}
                         inputProps={{
                           style:{
                             direction: 'rtl',
                             textAlign: 'right',
                             width: '90%',
                             fontSize:'1.5em',
                             marginTop: '0.5%',
                             backgroundColor: 'white',
                             color: blue
                           }
                         }}
                       />
                       <br /><br />

                     </div>
                     <Button style={{backgroundColor: yellow, border: '0px solid' + yellow, color: 'white', fontSize:'1.5em'}} onClick={(e) => this.processPayment()}>
                       סיום ורכישה
                     </Button>
                     </React.Fragment>
                   ) : (
                     <React.Fragment>
                     {this.state.paymentSuccess === 'yes' ? (
                       <p style={{fontSize: '1.6em', color: 'green', textAlign: 'center', direction: 'rtl'}}>התשלום בוצע בהצלחה!</p>
                     ) : (
                       <p style={{fontSize: '1.6em', color: 'red', textAlign: 'center', direction: 'rtl'}}>התשלום נכשל, אנא נסו בשנית</p>
                     )}
                     </React.Fragment>
                   )}
                   </React.Fragment>
                 )}
                 </center>
               </Grid>
             )}


             <Grid item xs={12} style={{marginTop: '5%'}}></Grid>
          </Grid>
        </MobileView>
      </div>
    );
  }
}

export default Packages;
