import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import {
  BrowserView,
  MobileView,
  isMobile
} from "react-device-detect";

import { Link } from 'react-router-dom';

import Switch from '@material-ui/core/Switch';

import OfflineHeader from '../components/OfflineHeader';
import OfflineHeaderLogin from '../components/OfflineHeaderLogin';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';

import GoogleLogin from 'react-google-login';

import axios from 'axios';
import Cookies from 'universal-cookie';

import { Helmet } from 'react-helmet';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';


class LoginPWD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      email: '',
      password: ''
    };
  }

  login = () => {
    axios.post(API+'login', {
      email: this.state.email,
      password: this.state.password
    }).then(res => {
      console.log(res.data.status);
      if (res.data.status === "ok") {
        cookies.set('__dato__', res.data.data.token);
        cookies.set('__dato__', res.data.data.token, { path: '/'});
        window.location.href='/';
      } else {
        alert('השם או הסיסמה אינם נכונים');
        this.setState({loading: false});
      }
    }).catch(err => {console.log(err);
      alert('הייתה שגיאה בהתחברות');
       this.setState({loading: false});
    });

  }

  helmet = () => {
    const description = 'דיביט | התחברות';

    return (
      <Helmet>
          <meta charSet="utf-8" />
          <title>דיביט | התחברות</title>
          <link rel="canonical" href={window.location.href} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
          <meta property="og:description" content={description} />
          <meta property="og:title" content={description} />
          <meta property="og:site_name" content="דיביט" />
          <meta property="og:see_also" content="דיביט" />
          <meta itemprop="name" content={description} />
          <meta itemprop="description" content={description} />
          <meta itemprop="image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
          <meta name="description" content={description}/>
          <link rel="canonical" href={window.location.href} />
      </Helmet>
    );
  }


    responseFacebook = (response) => {
      let accessToken = response.accessToken;
      if (accessToken !== '' && accessToken !== undefined && accessToken !== null) {
        axios.post(API+'login-facebook', {
          facebookToken: accessToken,
          email: response.email,
          name: response.name
        }).then(res => {
          console.log(res.data.status);
          if (res.data.status === "ok") {
            cookies.set('__dato__', res.data.data.token);
            cookies.set('__dato__', res.data.data.token, { path: '/'});
            window.location.href='/';
          } else {
            alert('הייתה שגיאה בהתחברות');
            this.setState({loading: false});
          }
        }).catch(err => {console.log(err);
          alert('הייתה שגיאה בהתחברות');
           this.setState({loading: false});
        });
      }
    }

    responseGoogle = (response) => {
      let googleToken = response.tokenObj.id_token;

      if (googleToken !== null && googleToken !== undefined && googleToken !== '') {
        axios.post(API+'login-google', {
          googleToken: googleToken
        }).then(res => {
          console.log(res.data.status);
          if (res.data.status === "ok") {
            cookies.set('__dato__', res.data.data.token);
            cookies.set('__dato__', res.data.data.token, { path: '/'});
            window.location.reload();
          } else {
            alert('הייתה שגיאה בהתחברות');
            this.setState({loading: false});
          }
        }).catch(err => {console.log(err);
          alert('הייתה שגיאה בהתחברות');
           this.setState({loading: false});
        });
      }
    }


  render() {
    return (
      <div>
      {this.helmet()}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OfflineHeaderLogin logged={false}/>
          </Grid>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12}>
            <center>
            <p style={{direction:'rtl', fontSize: '2.2em', fontWeight: 700, color: blue}}>
              התחברות לדיביט
            </p>
            {isMobile ? (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}>
                <GoogleLogin
                  autoLoad={false}
                  clientId="83209058373-aiif2qkqf3oect4udcggf8a9vqv3ebrm.apps.googleusercontent.com"
                  buttonText="התחבר לדיביט באמצעות גוגל"
                  onSuccess={(res) => this.responseGoogle(res)}
                  onFailure={(res) => this.responseGoogle(res)}
                  cookiePolicy={'single_host_origin'}
                  render={renderProps => (
                    <Button autoLoad={false} onClick={(e) => renderProps.onClick(e)} variant="contained" style={{backgroundColor: yellow, color: blue, fontSize: '1em', whiteSpace: 'nowrap', marginLeft: '1%',
                    marginRight: '1%'}}>
                        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path><path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path><path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path><path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path><path fill="none" d="M0 0h18v18H0z"></path></g></svg> &nbsp; התחבר עם גוגל
                    </Button>
                  )}
                />
                <FacebookLogin
                  appId="1287273654938817"
                  autoLoad={false}
                  fields="name,email,picture"
                  onClick={(res) => this.responseFacebook(res)}
                  callback={(res) => this.responseFacebook(res)}
                  render={renderProps => (
                    <Button autoLoad={false} onClick={(res) => renderProps.onClick(res)} variant="contained" style={{backgroundColor: '#3676fe', color: 'white', fontSize: '1em', whiteSpace: 'nowrap', marginLeft: '1%',
                    marginRight: '1%'}}>
                        <FacebookIcon style={{color: 'white'}}/> &nbsp; התחבר עם פייסבוק
                    </Button>
                  )}
                />
              </div>
            ) : (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}>
                <GoogleLogin
                  autoLoad={false}
                  clientId="83209058373-aiif2qkqf3oect4udcggf8a9vqv3ebrm.apps.googleusercontent.com"
                  buttonText="התחבר לדיביט באמצעות גוגל"
                  onSuccess={(res) => this.responseGoogle(res)}
                  onFailure={(res) => this.responseGoogle(res)}
                  cookiePolicy={'single_host_origin'}
                  render={renderProps => (
                    <Button autoLoad={false} onClick={(e) => renderProps.onClick(e)} variant="contained" style={{backgroundColor: yellow, color: blue, fontSize: '1.2em', whiteSpace: 'nowrap', marginLeft: '1%',
                    marginRight: '1%'}}>
                        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path><path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path><path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path><path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path><path fill="none" d="M0 0h18v18H0z"></path></g></svg> &nbsp; התחבר עם גוגל
                    </Button>
                  )}
                />
                <FacebookLogin
                  appId="1287273654938817"
                  autoLoad={false}
                  fields="name,email,picture"
                  onClick={(res) => this.responseFacebook(res)}
                  callback={(res) => this.responseFacebook(res)}
                  render={renderProps => (
                    <Button autoLoad={false} onClick={(res) => renderProps.onClick(res)} variant="contained" style={{backgroundColor: '#3676fe', color: 'white', fontSize: '1.2em', whiteSpace: 'nowrap', marginLeft: '1%',
                    marginRight: '1%'}}>
                        <FacebookIcon style={{color: 'white'}}/> &nbsp; התחבר עם פייסבוק
                    </Button>
                  )}
                />
              </div>
            )}


            <p style={{fontSize: '1.3em', color: blue}}>- או -</p>

            <TextField
              value={this.state.email}
              onChange={(e) => this.setState({email: e.target.value})}
              placeholder={'כתובת המייל שלך'}
              type='email'
              style={{
                direction: 'rtl',
                textAlign: 'right',
                width: isMobile ? '90%' : '50%',
                fontSize: '1.6em',
                color: blue,
                backgroundColor: 'white'
              }}
              inputProps={{
                style: {
                  fontSize: '1.4em',
                  color: blue,
                  backgroundColor: 'white',
                  fontWeight: 'bold'
                }
              }}
            />
            <br /><br />
            <TextField
              value={this.state.password}
              onChange={(e) => this.setState({password: e.target.value})}
              placeholder={'סיסמה'}
              type='password'
              style={{
                direction: 'rtl',
                textAlign: 'right',
                width: isMobile ? '90%' : '50%',
                fontSize: '1.6em',
                color: blue,
                backgroundColor: 'white'
              }}
              inputProps={{
                style: {
                  fontSize: '1.4em',
                  color: blue,
                  backgroundColor: 'white',
                  fontWeight: 'bold'
                }
              }}
            />

            <br /><br />

            <Button variant={'contained'} style={{fontSize: '1.2em'}} className="login__email_password" style={{width: isMobile ? '50%' : '20%', fontSize: '1.5em'}} onClick={(e) => this.login()}>
              התחבר
            </Button>

            <footer>
            <Link to='/signup' style={{textDecoration: 'none'}}>
              <p style={{direction:'rtl', color: blue, fontSize: '1.4em', fontWeight: 500, color: yellow, marginBottom: 0}}>
                אין לך חשבון? לחץ כאן להרשמה
              </p>
            </Link>
            <Link to='/forgot-password' style={{textDecoration: 'none'}}>
              <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 500, color: blue, marginTop: 0}}>
                שכחת סיסמה? לחצו כאן
              </p>
            </Link>
            </footer>

            </center>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default LoginPWD;
