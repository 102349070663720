import React, { Component } from 'react';
import '../../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';
import LinkIcon from '@material-ui/icons/Link';
import OfflineHeader from '../../components/OfflineHeader';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import TimelineIcon from '@material-ui/icons/Timeline';
import GoogleLogin from 'react-google-login';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Chart } from 'react-charts'

import {
  BrowserView,
  MobileView,
} from "react-device-detect";

import {Helmet} from 'react-helmet';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';


class Settings extends Component {
  componentDidMount() {
    this.getData();
    this.getPackage();
  }

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      loading: false,
      package: {}
    };
  }

  getPackage = () => {
    this.setState({loading: true});
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'profile', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          if (response.data.data.card.slug === null || response.data.data.card.slug === undefined || response.data.data.card.slug === '') {
            window.location.href='/signup'
          }
          //console.log(response);
          this.setState({loading: false});
          if (response.data.status === "ok") {
            this.setState({
              package: response.data.data.package
            });
          }
        }).catch(err => {
          console.log(err);
          this.setState({loading: false});
        });
      } catch (err) {
        console.log(err);
        this.setState({loading: false});
      }
    }
  }

      getData = () => {
        let token = cookies.get('__dato__');

        if (token !== null && token !== undefined && token !== '') {
          try {
            return axios.get(API + 'user', {
              headers: {
                 Authorization: `Bearer ${token}`
              }
            }).then(response => {
              //console.log(response);
              if (response.data.status === "ok") {
                this.setState({
                  firstName: response.data.data.user.firstName,
                  lastName: response.data.data.user.lastName,
                  phoneNumber: response.data.data.user.phoneNumber,
                  email: response.data.data.user.email,
                });
              }
            }).catch(err => {
              console.log(err);
              this.setConnectionStatus();
            });
          } catch (err) {
            console.log(err);
            this.setConnectionStatus();
          }
        }
      }

      updateData = () => {
        let token = cookies.get('__dato__');

        if (token !== null && token !== undefined && token !== '') {
          try {
            return axios.put(API + 'user', {
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              phoneNumber: this.state.phoneNumber,
            } ,{
              headers: {
                 Authorization: `Bearer ${token}`
              }
            }).then(response => {
              //console.log(response);
              if (response.data.status === "ok") {
                alert('ההגדרות עודכנו בהצלחה');
                window.location.reload();
              }
            }).catch(err => {
              console.log(err);
              alert('שגיאה בעדכון ההגדרות');
            });
          } catch (err) {
            console.log(err);
            alert('שגיאה בעדכון ההגדרות');
          }
        }
      }

        helmet = () => {
          const description = 'דיביט | הגדרות';

          return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>דיביט | הגדרות</title>
                <link rel="canonical" href={window.location.href} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
                <meta property="og:description" content={description} />
                <meta property="og:title" content={description} />
                <meta property="og:site_name" content="דיביט" />
                <meta property="og:see_also" content="דיביט" />
                <meta itemprop="name" content={description} />
                <meta itemprop="description" content={description} />
                <meta itemprop="image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
                <meta name="description" content={description}/>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
          );
        }

  render() {
    return (
      <div>
        {this.helmet()}
        <BrowserView>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OfflineHeader package={this.state.package}/>
          </Grid>
          <Grid item xs={12} style={{marginBottom: '2%'}}>
            <div style={{marginRight: '4%'}}>
              <p style={{direction:'rtl', fontSize: '3em', fontWeight: 700, color: yellow, marginBottom: -50}}>
                ההגדרות שלי
              </p>
            </div>
          </Grid>
          <Grid item xs={12} style={{marginBottom: '2%'}}>
            <div style={{marginRight: '4%', textAlign :'right'}}>
              <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                שם פרטי
              </p>
              <TextField
                value={this.state.firstName}
                onChange={(e) => this.setState({firstName: e.target.value})}
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '50%',
                  fontSize:'1.3em',
                  marginTop: '0.5%'
                }}
              />

              <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                שם משפחה
              </p>
              <TextField
                value={this.state.lastName}
                onChange={(e) => this.setState({lastName: e.target.value})}
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '50%',
                  fontSize:'1.3em',
                  marginTop: '0.5%'
                }}
              />

              <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                מספר טלפון
              </p>
              <TextField
                value={this.state.phoneNumber}
                onChange={(e) => this.setState({phoneNumber: e.target.value})}
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '50%',
                  fontSize:'1.3em',
                  marginTop: '0.5%'
                }}
              />

              <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                אימייל
              </p>
              <TextField
                value={this.state.email}
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '50%',
                  fontSize:'1.3em',
                  marginTop: '0.5%'
                }}
                disabled={true}
              />


              <br /><br />
              <Button onClick={(e) => this.updateData()} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em'}}>
                עדכון
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <center><p style={{direction:'rtl', color: blue, fontSize: '1em', fontWeight: 100}}>
                כל הזכויות שמורות לדיביט בע"מ, 2020
              </p></center>
            </div>
          </Grid>
        </Grid>
        </BrowserView>
        <MobileView>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OfflineHeader package={this.state.package} />
          </Grid>
          <Grid item xs={12} style={{marginBottom: '2%'}}>
            <div style={{marginRight: '4%'}}>
              <p style={{direction:'rtl', fontSize: '2em', fontWeight: 700, color: yellow, marginBottom: -50}}>
                ההגדרות שלי
              </p>
            </div><br />
          </Grid>
          <Grid item xs={12} style={{marginBottom: '2%'}}>
            <div style={{marginRight: '4%', textAlign :'right'}}>
              <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                שם פרטי
              </p>
              <TextField
                value={this.state.firstName}
                onChange={(e) => this.setState({firstName: e.target.value})}
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '90%',
                  fontSize:'1.3em',
                  marginTop: '0.5%'
                }}
              />

              <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                שם משפחה
              </p>
              <TextField
                value={this.state.lastName}
                onChange={(e) => this.setState({lastName: e.target.value})}
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '90%',
                  fontSize:'1.3em',
                  marginTop: '0.5%'
                }}
              />

              <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                מספר טלפון
              </p>
              <TextField
                value={this.state.phoneNumber}
                onChange={(e) => this.setState({phoneNumber: e.target.value})}
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '90%',
                  fontSize:'1.3em',
                  marginTop: '0.5%'
                }}
              />

              <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                אימייל
              </p>
              <TextField
                value={this.state.email}
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: '90%',
                  fontSize:'1.3em',
                  marginTop: '0.5%'
                }}
                disabled={true}
              />


              <br /><br />
              <Button onClick={(e) => this.updateData()} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em'}}>
                עדכון
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <center><p style={{direction:'rtl', color: blue, fontSize: '1em', fontWeight: 100}}>
                כל הזכויות שמורות לדיביט בע"מ, 2020
              </p></center>
            </div>
          </Grid>
        </Grid>
        </MobileView>
      </div>
    );
  }
}

export default Settings;
