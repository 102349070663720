import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import {
 Link
} from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import Cookies from 'universal-cookie';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import {
  isMobile,
  MobileView,
  BrowserView
} from "react-device-detect";

// Drawer
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
// Drawer
import CloseIcon from '@material-ui/icons/Close';

const cookies = new Cookies();

const blue = '#07132D';
const yellow = '#FEBE36';

class OfflineHeaderLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerIsOpen: false
    };
  }

  logout = () => {
    cookies.remove('__dato__');
    cookies.remove('__dato__', { path: '/' });

    alert('התנתק בהצלחה, מקווים לראותכם שוב בקרוב!');

    window.location.reload();
  }

  handleDrawerOpen = () => {
    this.setState({ drawerIsOpen: true });
  };

  closeDrawer = () => {
    this.setState({ drawerIsOpen: false });
  };

  render() {
    return (
      <React.Fragment>
      <MobileView>
      <AppBar position="static" style={{backgroundColor: blue}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>


          {((!this.props.mobile || this.props.mobile === undefined || this.props.mobile === null) && (this.props.logged !== false)) ? (
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}>


            </div>
          ) : (
            <div>
              <Link to='/'><Button raised color="accent">
                <img src={'https://app.dibit.co.il/icon.png'} style={{width: 50}}/>
              </Button></Link>
            </div>
          )}

          {(this.props.title !== '' && this.props.title !== undefined && this.props.title !== null) && (
            <span style={{fontSize: '1.6em'}}>{this.props.title}</span>
          )}

          {(this.props.logged !== false && this.props.mobile) && (
            <div>
                <React.Fragment>
                  <Link to='/messages'><IconButton>
                    <NotificationsNoneIcon style={{width: 22, height: 22, color: 'white'}}/>
                  </IconButton></Link>
                  <IconButton>
                    <MenuIcon style={{width: 22, height: 22, color: 'white'}} onClick={(e) => this.handleDrawerOpen()}/>
                  </IconButton>
                </React.Fragment>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <React.Fragment>

      </React.Fragment>
      </MobileView>
      <BrowserView>
      <AppBar position="static" style={{backgroundColor: blue,}}>
        <Toolbar style={{textAlign: 'right',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>

          </div>
          <Link to='/'><Button raised color="accent">
            <img src={'https://app.dibit.co.il/icon.png'} style={{width: 50}}/>
          </Button></Link>
        </Toolbar>
      </AppBar>
      </BrowserView>
      </React.Fragment>
    );
  }
}

export default OfflineHeaderLogin;
