import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import {
  BrowserView,
  MobileView,
  isMobile
} from "react-device-detect";

import { Link } from 'react-router-dom';

import Switch from '@material-ui/core/Switch';

import OfflineHeader from '../components/OfflineHeader';
import OfflineHeaderLogin from '../components/OfflineHeaderLogin';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';

import GoogleLogin from 'react-google-login';

import axios from 'axios';
import Cookies from 'universal-cookie';

import { Helmet} from 'react-helmet';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';


class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: '',
      code: '',
      token: '',
      showCode: false,
      changePassword: false,
      password: ''
    };
  }

  requestCode = () => {
    axios.post(API+'request-restore-code', {
      email: this.state.email,
    }).then(res => {
      // console.log(res.data.status);
      if (res.data.status === "ok") {
        this.setState({showCode: true, code: ''})
      } else {
        this.setState({loading: false});
      }
    }).catch(err => {console.log(err);
       this.setState({loading: false});
    });
  }

  verifyCode = () => {
    axios.put(API+'verify-code', {
      email: this.state.email,
      code: this.state.code
    }).then(res => {
      // console.log(res.data.status);
      if (res.data.status === "ok") {
        cookies.set('__dato__', res.data.data.token);
        cookies.set('__dato__', res.data.data.token, { path: '/'});
        this.setState({token: res.data.data.token, changePassword: true});
      } else {
        alert('הקוד שגוי');
        this.setState({loading: false});
      }
    }).catch(err => {console.log(err);
      alert('הקוד שגוי');
       this.setState({loading: false});
    });
  }

  changePassword = () => {
    let token = cookies.get('__dato__');

    axios.put(API+'change-password', {
      password: this.state.password,
    }, {
      headers: {
         Authorization: `Bearer ${token}`
      }
    }).then(res => {
      console.log(res.data.status);
      if (res.data.status === "ok") {
        alert('הסיסמה שונתה בהצלחה, ברוך הבא');
        window.location.href='/';
      } else {
        alert('שגיאה');
        this.setState({loading: false});
      }
    }).catch(err => {console.log(err);
      alert('שגיאה');
       this.setState({loading: false});
    });
  }


    helmet = () => {
      const description = 'דיביט | שכחתי סיסמה';

      return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>דיביט | שכחתי סיסמה</title>
            <link rel="canonical" href={window.location.href} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta property="og:description" content={description} />
            <meta property="og:title" content={description} />
            <meta property="og:site_name" content="דיביט" />
            <meta property="og:see_also" content="דיביט" />
            <meta itemprop="name" content={description} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta name="description" content={description}/>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
      );
    }

  render() {
    return (
      <div>
        {this.helmet()}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OfflineHeaderLogin logged={false}/>
          </Grid>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12}>
            <center>
            <p style={{direction:'rtl', fontSize: '2.2em', fontWeight: 700, color: blue}}>
              שכחתי סיסמה
            </p>

            {!this.state.changePassword ? (
              <React.Fragment>
              {this.state.showCode ? (
                <React.Fragment>
                <center>
                  <p style={{direction: 'rtl', fontSize: isMobile ? '1.1em' : '1.2em', maxWidth: '98%', whiteSpace: 'normal'}}>אנא הקישו את הקוד הסודי שקיבלתם במסרון</p>
                </center>

                <TextField
                  value={this.state.code}
                  onChange={(e) => this.setState({code: e.target.value})}
                  placeholder={'הקוד'}
                  type='text'
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: isMobile ? '90%' : '50%',
                    fontSize: '1.6em',
                    color: blue,
                    backgroundColor: 'white'
                  }}
                  inputProps={{
                    style: {
                      fontSize: '1.4em',
                      color: blue,
                      backgroundColor: 'white',
                      fontWeight: 'bold'
                    }
                  }}
                />
                <br/><br />

                <Button variant={'contained'} style={{fontSize: '1.2em'}} className="login__email_password" style={{width: '50%', fontSize: '1.5em'}} onClick={(e) => this.verifyCode()}>
                  אישור
                </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                <center>
                  <p style={{direction: 'rtl', fontSize: isMobile ? '1.1em' : '1.2em', maxWidth: '98%', whiteSpace: 'normal'}}>קוד סודי ישלח למספר הטלפון המקושר לחשבון של המייל שתרשמו</p>
                </center>

                <TextField
                  value={this.state.email}
                  onChange={(e) => this.setState({email: e.target.value})}
                  placeholder={'כתובת המייל שלך'}
                  type='email'
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: isMobile ? '90%' : '50%',
                    fontSize: '1.6em',
                    color: blue,
                    backgroundColor: 'white'
                  }}
                  inputProps={{
                    style: {
                      fontSize: '1.4em',
                      color: blue,
                      backgroundColor: 'white',
                      fontWeight: 'bold'
                    }
                  }}
                />
                <br/><br />

                <Button variant={'contained'} className="login__email_password" style={{width: '50%', fontSize: '1.2em'}} onClick={(e) => this.requestCode()}>
                  לחץ לקבלת קוד סודי
                </Button>
                </React.Fragment>
              )}
              </React.Fragment>
            ) : (
              <React.Fragment>
              <center>
                <p style={{direction: 'rtl', fontSize: '1.2em'}}>אנא הקש את הסיסמה החדשה שלך</p>
              </center>

              <TextField
                value={this.state.password}
                onChange={(e) => this.setState({password: e.target.value})}
                placeholder={'סיסמה חדשה'}
                type='password'
                style={{
                  direction: 'rtl',
                  textAlign: 'right',
                  width: isMobile ? '90%' : '50%',
                  fontSize: '1.6em',
                  color: blue,
                  backgroundColor: 'white'
                }}
                inputProps={{
                  style: {
                    fontSize: '1.4em',
                    color: blue,
                    backgroundColor: 'white',
                    fontWeight: 'bold'
                  }
                }}
              />
              <br/><br />

              <Button variant={'contained'} style={{fontSize: '1.2em'}} className="login__email_password" style={{width: '50%', fontSize: '1.3em'}} onClick={(e) => this.changePassword()}>
                אישור
              </Button>
              </React.Fragment>
            )}




            <Link to='/e-sign' style={{textDecoration: 'none'}}><footer>
              <p style={{direction:'rtl', color: blue, fontSize: '1.4em', fontWeight: 500, color: blue}}>
                נזכרתי בסיסמה שלי! חזרה להתחברות
              </p>
            </footer></Link>

            </center>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ForgotPassword;
