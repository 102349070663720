import React, { Component } from 'react';
import '../../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';
import LinkIcon from '@material-ui/icons/Link';
import OfflineHeader from '../../components/OfflineHeader';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import TimelineIcon from '@material-ui/icons/Timeline';
import GoogleLogin from 'react-google-login';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Chart } from 'react-charts'

import {
  BrowserView,
  MobileView,
  isMobile
} from "react-device-detect";

import { Redirect } from 'react-router-dom';
import {Helmet} from 'react-helmet';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';


class Messages extends Component {
  componentDidMount () {
    this.getMessages();
    this.getPackage();
  }

  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      loading: false,
      package: {},
      trialEnded: false,
      trialUntil: 0,
      slug: ''
    };
  }

  getPackage = () => {
    this.setState({loading: true});
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'profile', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          if (response.data.data.card.slug === null || response.data.data.card.slug === undefined || response.data.data.card.slug === '') {
            window.location.href='/signup'
          }
          //console.log(response);
          this.setState({loading: false});
          if (response.data.status === "ok") {
            this.setState({
              package: response.data.data.package,
              trialUntil: response.data.data.trialUntil,
              trialEnded: response.data.data.trialEnded,
              slug: response.data.data.card.slug
            });
          }
        }).catch(err => {
          console.log(err);
          this.setState({loading: false});
        });
      } catch (err) {
        console.log(err);
        this.setState({loading: false});
      }
    }
  }

  getMessages = () => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'messages', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          if (response.data.status === "ok") {
            let messages = response.data.data.messages;
            let output = [];
            messages.forEach((item, i) => {
              if (item.reply !== '' && item.reply !== undefined && item.reply !== null) output.push(item);
            });

            this.setState({messages: output});
          }
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
        console.log(err);
      }
    }
  }


    helmet = () => {
      const description = 'דיביט | ההודעות שנקראו';

      return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>דיביט | ההודעות שנקראו</title>
            <link rel="canonical" href={window.location.href} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta property="og:description" content={description} />
            <meta property="og:title" content={description} />
            <meta property="og:site_name" content="דיביט" />
            <meta property="og:see_also" content="דיביט" />
            <meta itemprop="name" content={description} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta name="description" content={description}/>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
      );
    }


  render() {
    return (
      <div>
        {this.helmet()}

        {this.state.trialEnded && (<Redirect to='/' />)}

        <BrowserView>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OfflineHeader slug={this.state.slug} mobile={isMobile} title={'ההודעות שנשלחו'} package={this.state.package} trialUntil={this.state.trialUntil}/>
          </Grid>

          <Grid item xs={12} style={{marginBottom: '0%', marginTop: '-2%'}}>
            <div style={{marginRight: '4%'}}>
              <p style={{direction:'rtl', fontSize: '2em', fontWeight: 700, color: yellow, marginBottom: -30}}>
                ההודעות שנשלחו
              </p>
              <br />
            </div>
          </Grid>

          <Grid item xs={12} style={{marginBottom: '2%'}}>
            <div style={{marginRight: '4%', textAlign :'right'}}>
              {this.state.messages.reverse().map((item) => {
                return (
                  <center>
                  <Card style={{padding: '1%', width: '50%', float: 'right', textAlign: 'right', marginTop: '1%', marginBottom: '1%'}} raised={true}>
                    <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 10}}>
                      "{item.message}"
                    </p>

                    {item.reply !== 'direct' && (
                      <p style={{direction:'rtl', fontSize: '1.3em', fontWeight: 700, marginBottom: 10}}>
                        <span style={{color: yellow}}>תגובה:</span> <span style={{color: blue}}>{item.reply}</span>
                      </p>
                    )}

                    <p style={{direction:'rtl', fontSize: '1em', fontWeight: 700, marginBottom: 10}}>
                      <span style={{color: blue}}>{new Date(item.createdAt).getDate() + '/' + (new Date(item.createdAt).getMonth() + 1) + '/' + new Date(item.createdAt).getFullYear()}</span>
                    </p>
                  </Card>
                  </center>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <center><p style={{direction:'rtl', color: blue, fontSize: '1em', fontWeight: 100}}>
                כל הזכויות שמורות לדיביט בע"מ, 2020
              </p></center>
            </div>
          </Grid>
        </Grid>
        </BrowserView>

        <MobileView>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OfflineHeader slug={this.state.slug} mobile={isMobile} title={'ההודעות שנשלחו'} package={this.state.package} trialUntil={this.state.trialUntil}/>
          </Grid>
          <Grid item xs={12} style={{marginBottom: 0, marginTop: 0}}>
            <div style={{marginRight: '4%', marginTop: 0, marginBottom: 0}}>
              <p style={{direction:'rtl', fontSize: '2em', fontWeight: 700, color: yellow, marginBottom: '-5%', marginTop: '-4%'}}>
                ההודעות שנשלחו
              </p>
              <br />
            </div>
          </Grid>
          <Grid item xs={12} style={{marginBottom: '2%'}}>
            <div>
              {this.state.messages.reverse().map((item) => {
                return (
                  <center>
                  <Card style={{width: '95%', marginTop: '2%'}} raised={true}>
                    <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 10}}>
                      "{item.message}"
                    </p>

                    {item.reply !== 'direct' && (
                      <p style={{direction:'rtl', fontSize: '1.3em', fontWeight: 700, marginBottom: 10}}>
                        <span style={{color: yellow}}>תגובה:</span> <span style={{color: blue}}>{item.reply}</span>
                      </p>
                    )}

                    <p style={{direction:'rtl', fontSize: '1em', fontWeight: 700, marginBottom: 10}}>
                      <span style={{color: blue}}>{new Date(item.createdAt).getDate() + '/' + (new Date(item.createdAt).getMonth() + 1) + '/' + new Date(item.createdAt).getFullYear()}</span>
                    </p>
                  </Card>
                  </center>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <center><p style={{direction:'rtl', color: blue, fontSize: '1.4em', fontWeight: 100}}>
                כל הזכויות שמורות לדיביט בע"מ, 2020
              </p></center>
            </div>
          </Grid>
        </Grid>
        </MobileView>
      </div>
    );
  }
}

export default Messages;
