import React, { Component, useMemo } from 'react';
import '../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import Alert from '@material-ui/lab/Alert';
// import Modal from '@material-ui/core/Modal';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';
import LinkIcon from '@material-ui/icons/Link';
import OfflineHeaderLogin from '../components/OfflineHeaderLogin';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import TimelineIcon from '@material-ui/icons/Timeline';
import GoogleLogin from 'react-google-login';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Chart } from 'react-charts'
import Snackbar from '@material-ui/core/Snackbar';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Helmet} from 'react-helmet';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import RichTextEditor from 'react-rte';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import {
  isMobile,
  BrowserView,
  MobileView
} from "react-device-detect";

import { Redirect } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';


const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

    helmet = () => {
      const description = 'דיביט | צור קשר';

      return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>דיביט | צור קשר</title>
            <link rel="canonical" href={window.location.href} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta property="og:description" content={description} />
            <meta property="og:title" content={description} />
            <meta property="og:site_name" content="דיביט" />
            <meta property="og:see_also" content="דיביט" />
            <meta itemprop="name" content={description} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta name="description" content={description}/>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
      );
    }


  render() {
    return (
      <div>
        {this.helmet()}
        {this.state.trialEnded && (<Redirect to='/' />)}

        {this.state.loading ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OfflineHeaderLogin />
            </Grid>
            <Grid item xs={12}>
              <center>
                <div style={{textAlign: 'center', marginTop: '5%'}}>
                  <CircularProgress />
                  <h3 style={{direciton: 'rtl'}}>..טוען</h3>
                </div>
              </center>
            </Grid>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <OfflineHeaderLogin mobile={isMobile} />

                {isMobile ? (
                  <iframe src="https://dibit.co.il/contact-us-iframe/" style={{
                    width: '100vw',
                    marginTop: '0%',
                    marginLeft: '0%',
                    height: '80vh'
                  }}>
                  </iframe>
                ) : (
                  <iframe src="https://dibit.co.il/contact-us-iframe/" style={{
                    width: '100vw',
                    marginTop: '0%',
                    marginLeft: '0%'
                  }}>
                  </iframe>
                )}

              </Grid>

              <Grid item xs={12}>
                <div>
                  <center><p style={{direction:'rtl', color: blue, fontSize: '1em', fontWeight: 100}}>
                    כל הזכויות שמורות לדיביט בע"מ, 2020
                  </p></center>
                </div>
              </Grid>
            </Grid>

          </React.Fragment>
        )}

      </div>
    );
  }
}

export default ContactUs;
