import React, { Component } from 'react';
import '../../App.css';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import CircularProgress from '@material-ui/core/CircularProgress';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { SliderPicker, ChromePicker } from 'react-color'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';

import {
 Link,
 Redirect
} from "react-router-dom";

import {
  BrowserView,
  MobileView,
  isMobile
} from "react-device-detect";

import Switch from '@material-ui/core/Switch';
import LinkIcon from '@material-ui/icons/Link';
import OfflineHeader from '../../components/OfflineHeader';
import OfflineHeaderLogin from '../../components/OfflineHeaderLogin';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import TimelineIcon from '@material-ui/icons/Timeline';
import GoogleLogin from 'react-google-login';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CreateIcon from '@material-ui/icons/Create';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';

import FBLogo from '../../resources/fb_logo.png';
// import InstagramLogo from '../../resources/instagram_logo.png';
// import WhatsappLogo from '../../resources/whatsapp_logo.png';
// import WazeLogo from '../../resources/waze_logo.png';
// import WhatsappSVG from '../../resources/whatsapp.svg';
// import Paperplane from '../../resources/paperplane.png';
// import LinkIconI from '../../resources/broken-link.png';
// import Phone from '../../resources/phone.png';
import InstagramLogo from '../../resources/icons/instagram.svg';
import WhatsappLogo from '../../resources/icons/whatsapp.svg';
import WazeLogo from '../../resources/icons/waze.svg';
import WhatsappSVG from '../../resources/icons/whatsapp.svg';
import Paperplane from '../../resources/icons/send.svg';
import LinkIconI from '../../resources/broken-link.png';
import CallIcon from '../../resources/icons/call.svg';
import WatchIcon from '../../resources/icons/view.svg';
import Trend from '../../resources/icons/trend.svg';
import EditIcon from '../../resources/icons/edit.svg';
import MessageIcon from '../../resources/icons/message.svg';
import Temp from '../../resources/temp.png';
import CheckIcon from '@material-ui/icons/Check';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {Helmet} from 'react-helmet';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';


class Payments extends Component {
    constructor(props) {
      super(props);
      this.state = {
        link: '',
        name: '',
        description: '',
        package: {},
        trialEnded: false,
        trialUntil: 0,
        slug: '',
        sto: {
          date: '',
          nextPaymentAt: ''
        },
        invoices: [],
        payments: [],
      };
    }

    componentDidMount() {
      this.getData();
      this.getPayments();
    }

    getData = () => {
      this.setState({loading: true});
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          return axios.get(API + 'profile', {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {
            if (response.data.data.card.slug === null || response.data.data.card.slug === undefined || response.data.data.card.slug === '') {
              window.location.href='/signup'
            }
            //console.log(response);
            this.setState({loading: false});
            if (response.data.status === "ok") {
              this.setState({
                firstName: response.data.data.profile.firstName,
                lastName: response.data.data.profile.lastName,
                card: response.data.data.card,
                package: response.data.data.package
              });
            }
          }).catch(err => {
            console.log(err);
            this.setState({loading: false});
          });
        } catch (err) {
          console.log(err);
          this.setState({loading: false});
        }
      }
    }
    getPayments = () => {
      this.setState({loading: true});
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          return axios.get(API + 'payments', {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {
            this.setState({loading: false});
            if (response.data.status === "ok") {
              this.setState({
                sto: response.data.data.sto,
                payments: response.data.data.payments,
                invoices: response.data.data.invoices,
              });
            }
          }).catch(err => {
            console.log(err);
            this.setState({loading: false});
          });
        } catch (err) {
          console.log(err);
          this.setState({loading: false});
        }
      }
    }



      helmet = () => {
        const description = 'דיביט | הגדרות תשלום';

        return (
          <Helmet>
              <meta charSet="utf-8" />
              <title>דיביט | חבילות</title>
              <link rel="canonical" href={window.location.href} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
              <meta property="og:description" content={description} />
              <meta property="og:title" content={description} />
              <meta property="og:site_name" content="דיביט" />
              <meta property="og:see_also" content="דיביט" />
              <meta itemprop="name" content={description} />
              <meta itemprop="description" content={description} />
              <meta itemprop="image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
              <meta name="description" content={description}/>
              <link rel="canonical" href={window.location.href} />
          </Helmet>
        );
      }

      modifyDate = (date) => {
    let d = new Date(date);
    try {
      let day = d.getDate().toString();
      if (day.length === 1) day = '0' + day;
      let month = (d.getMonth() + 1).toString();
      if (month.length === 1) month = '0' + month;

      return day + '/' + month + '/' + d.getFullYear();
    } catch (err) {
      return '-';
    }

    return '-';
  }

  cancelSubscription = () => {
    let toDeleteQ = window.confirm('האם אתם בטוחים שברצנוכם לבטל את השירות ואת הכרטיס הדיגיטלי שלכם? שימו לב - פעולה זאת לא ניתנת לשחזור ותצטרכו לשלם על השירות מחדש על מנת להפעיל את הכרטיס בשנית');

    if (toDeleteQ || toDeleteQ === true) {
      this.setState({loading: true});
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          return axios.delete(API + 'payment/cancel-serivce', {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {
            this.setState({loading: false});
            alert('השירות בוטל בהצלחה');
            window.location.href='/';
          }).catch(err => {
            console.log(err);
            this.setState({loading: false});
          });
        } catch (err) {
          console.log(err);
          this.setState({loading: false});
        }
      }
    }
  }

  render() {
    return (
      <React.Fragment>
      {this.state.loading ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OfflineHeaderLogin slug={this.state.slug}  package={this.state.package} trialUntil={'0'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{textAlign: 'center', marginTop: '5%'}}>
                <CircularProgress />
                <h3 style={{direction: 'rtl'}}>..טוען</h3>
              </div>
            </center>
          </Grid>
        </Grid>
      ) : (
        <div>
          <BrowserView>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OfflineHeader slug={this.state.slug} mobile={isMobile} title={'הגדרות תשלום'} package={this.state.package} trialUntil={this.state.trialUntil}/>
            </Grid>
            <Grid item xs={12} style={{marginBottom: '2%'}}>
              <div style={{marginRight: '4%'}}>
                <p style={{direction:'rtl', fontSize: '3em', fontWeight: 700, color: yellow, marginBottom: -50}}>
                  {this.state.package.name}
                </p>
                <p style={{direction:'rtl', fontSize: '2em', fontWeight: 700, color: blue, marginBottom: 0}}>
                  ₪{this.state.package.price} לחודש ללא מע"מ
                </p>
                {this.state.sto && (
                  <p style={{direction:'rtl', fontSize: '1.5em', fontWeight: 700, color: blue, marginBottom: 0, marginTop: 0}}>
                    התשלום הבא יבוצע ב{this.state.sto.nextPaymentAt}
                  </p>
                )}

                <br />

                <div style={{textAlign: 'right'}}>
                  <Button variant={'contained'} style={{
                    border: '1px solid red',
                    backgroundColor: 'red',
                    color: 'white',
                    fontSize: '1em'
                  }} onClick={(e) => this.cancelSubscription()}>ביטול השירות והכרטיס הדיגיטלי שלי</Button>
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div>
                <center>
                <p style={{marginRight: '4%', textAlign: 'right', direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0, textDecoration: 'underline'}}>
                  היסטוריית החשבוניות שלך
                </p>
                <TableContainer component={Paper} style={{maxWidth: '80%'}}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" style={{fontSize: '1.5em'}}>צפייה</TableCell>
                      <TableCell align="right" style={{fontSize: '1.5em'}}>תאריך</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.invoices.reverse().map((item, i) => {
                      return (
                        <TableRow key={i+'_a_'}>
                          <TableCell align="right" style={{fontSize: '1.2em', textDecoration: 'underline'}} onClick={(e) => window.open(item.link, '_blank')}>לחצו לצפייה</TableCell>
                          <TableCell align="right" style={{fontSize: '1.2em'}}>{this.modifyDate(item.createdAt)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
                <p style={{marginRight: '4%', textAlign: 'right', direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0, textDecoration: 'underline'}}>
                  היסטוריית החיובים שלך
                </p>
                <TableContainer component={Paper} style={{maxWidth: '80%'}}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" style={{fontSize: '1.5em'}}>סה"כ</TableCell>
                      <TableCell align="right" style={{fontSize: '1.5em'}}>תאריך</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.payments.reverse().map((item, i) => {
                      return (
                        <TableRow key={i+'_b_'}>
                          <TableCell align="right" style={{fontSize: '1.2em'}}>₪{parseFloat(item.amount).toFixed(2)}</TableCell>
                          <TableCell align="right" style={{fontSize: '1.2em'}}>{item.date}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
                </center>
              </div>
            </Grid>
            <Grid item xs={12}>
              <footer>
                <center><p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 100}}>
                  כל הזכויות שמורות לדיביט בע"מ, 2020
                </p></center>
              </footer>
            </Grid>
          </Grid>
          </BrowserView>
          <MobileView>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OfflineHeader slug={this.state.slug}  mobile={isMobile} title={'הגדרות תשלום'} package={this.state.package} trialUntil={this.state.trialUntil}/>
            </Grid>
            <Grid item xs={12} style={{marginBottom: '2%'}}>
              <div style={{marginRight: '4%'}}>
                <p style={{direction:'rtl', fontSize: '3em', fontWeight: 700, color: yellow, marginBottom: -50}}>
                  {this.state.package.name}
                </p>
                <p style={{direction:'rtl', fontSize: '2em', fontWeight: 700, color: blue, marginBottom: 0}}>
                  ₪{this.state.package.price} לחודש ללא מע"מ
                </p>
                {this.state.sto && (
                  <p style={{direction:'rtl', fontSize: '1.5em', fontWeight: 700, color: blue, marginBottom: 0, marginTop: 0}}>
                    התשלום הבא יבוצע ב{this.state.sto.nextPaymentAt}
                  </p>
                )}

                <div style={{textAlign: 'right'}}>
                  <Button variant={'contained'} style={{
                    border: '1px solid red',
                    backgroundColor: 'red',
                    color: 'white',
                    fontSize: '1em'
                  }} onClick={(e) => this.cancelSubscription()}>ביטול השירות והכרטיס הדיגיטלי שלי</Button>
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div>
                <center>
                <p style={{marginRight: '4%', textAlign: 'right', direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0, textDecoration: 'underline'}}>
                  היסטוריית החשבוניות שלך
                </p>
                <TableContainer component={Paper} style={{maxWidth: '80%'}}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" style={{fontSize: '1.5em'}}>צפייה</TableCell>
                      <TableCell align="right" style={{fontSize: '1.5em'}}>תאריך</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.invoices.reverse().map((item, i) => {
                      return (
                        <TableRow key={i+'_a_'}>
                          <TableCell align="right" style={{fontSize: '1.2em', textDecoration: 'underline'}} onClick={(e) => window.open(item.link, '_blank')}>לחצו לצפייה</TableCell>
                          <TableCell align="right" style={{fontSize: '1.2em'}}>{this.modifyDate(item.createdAt)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
                <p style={{marginRight: '4%', textAlign: 'right', direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0, textDecoration: 'underline'}}>
                  היסטוריית החיובים שלך
                </p>
                <TableContainer component={Paper} style={{maxWidth: '80%'}}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" style={{fontSize: '1.5em'}}>סה"כ</TableCell>
                      <TableCell align="right" style={{fontSize: '1.5em'}}>תאריך</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.payments.reverse().map((item, i) => {
                      return (
                        <TableRow key={i+'_b_'}>
                          <TableCell align="right" style={{fontSize: '1.2em'}}>₪{parseFloat(item.amount).toFixed(2)}</TableCell>
                          <TableCell align="right" style={{fontSize: '1.2em'}}>{item.date}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
                </center>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <center><p style={{direction:'rtl', color: blue, fontSize: '1em', fontWeight: 100}}>
                  כל הזכויות שמורות לדיביט בע"מ, 2020
                </p></center>
              </div>
            </Grid>
          </Grid>
          </MobileView>
        </div>
      )}

      </React.Fragment>
    );
  }
}

export default Payments;
