import React, { Component } from 'react';
import '../../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import Alert from '@material-ui/lab/Alert';
// import Modal from '@material-ui/core/Modal';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';
import LinkIcon from '@material-ui/icons/Link';
import OfflineHeader from '../../components/OfflineHeader';
import OfflineHeaderLogin from '../../components/OfflineHeaderLogin';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import TimelineIcon from '@material-ui/icons/Timeline';
import GoogleLogin from 'react-google-login';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Chart } from 'react-charts'
import Snackbar from '@material-ui/core/Snackbar';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import {Helmet} from 'react-helmet';
import {
  isMobile,
  BrowserView,
  MobileView
} from "react-device-detect";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import RichTextEditor from 'react-rte';

import imageCompression from 'browser-image-compression';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';
const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ]
  };

  const toolbarConfigMobile = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: [],
      INLINE_STYLE_BUTTONS: [

      ],
      BLOCK_TYPE_DROPDOWN: [

      ],
      BLOCK_TYPE_BUTTONS: [

      ]
    };
const theme = createMuiTheme({
  overrides: {
    MuiGridListTile: {
      // Name of the rule
      size: {
        // Some CSS
        width: 130,
        height: '100%'
      },
    },
  },
});

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      secondaryTitle: '',
      // text: RichTextEditor.createEmptyValue(),
      text: '',
      phoneNumber: '',
      facebookLink: '',
      instagramLink: '',
      address: '',
      businessName: '',
      loading: false,
      button_1: '',
      button_1_icon: '',
      button_1_link: '',
      button_2: '',
      button_2_icon: '',
      button_2_link: '',
      button_3: '',
      button_3_icon: '',
      button_3_link: '',
      button_4: '',
      button_4_icon: '',
      button_4_link: '',
      button_5: '',
      button_5_icon: '',
      button_5_link: '',
      button_6: '',
      button_6_icon: '',
      button_6_link: '',
      button_7: '',
      button_7_icon: '',
      button_7_link: '',
      button_8: '',
      button_8_icon: '',
      button_8_link: '',
      button_9: '',
      button_9_icon: '',
      button_9_link: '',
      buttonShownIndex: 3,
      openingHours: {
        'A': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'B': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'C': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'D': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'E': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'F': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'G': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
      },
      close: {
        'A': false,
        'B': false,
        'C': false,
        'D': false,
        'E': false,
        'F': false,
        'G': false,
      },
      open24h: false,
      slug: '',
      slugOriginal: '',
      slugAvailable: true,
      templateId: '',
      templates: [],
      uploadLogoModal: false,
      uploadLogoModalSuccess: false,
      uploadCoverModal: false,
      uploadCoverModalSuccess: false,
      changeTemplateModal: false,
      uploadGalleryModal: false,
      uploadGalleryModalSuccess: false,
      template: {
        name: '',
        _id: '',
        description: '',
        coverImage: ''
      },
      step: 1,
      templateChosen: '',

      gallery: [],
      original_gallery: [],
      search_gallery: '',
      gallerySelectedCoverImage: '',
      gallerySelectedLogoImage: '',
      loadingImage: false,
      categories: [],
      categoryChosen: 'all',
      code: '',
      codeWrong: false,
      logo: '',
      resentVerificationCode: false,
      cover: '',
      src: null,
      crop: {
        unit: '%',
        width: 30,
        // aspect: 16 / 9,
        aspect: 16 / 16,
      },
      imageEditing: true
    };
  }

  componentDidMount() {
    console.log(this.state.text);
    this.getMyCard();
    this.getTemplates();
    this.getGalleries();
  }

  onChangeText = (value) => {
    this.setState({text: value});
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(
        value.toString('html')
      );
    }
  };

  getGalleries = () => {
    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'galleries', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          this.setState({gallery: response.data.data.gallery, original_gallery: response.data.data.gallery});
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
          console.log(err);
      }
    }
  }

  searchGallery = () => {
    let gallery = this.state.gallery;
    let original_gallery = this.state.original_gallery;
    if (original_gallery.length === 0) this.setState({original_gallery: gallery});

    let query = this.state.search_gallery;

    let output = [];
    gallery.forEach((item, i) => {
      if (item.tagEnglish.includes(query) || item.name.includes(query)) output.push(item);
    });

    if (output.length >= 1) {
      this.setState({gallery: output});
    }
  }

  resetGallery = () => {
    let original_gallery = this.state.original_gallery;
    if (original_gallery.length >= 1) {
      this.setState({gallery: original_gallery});
    }
  }

  updateCoverFromGallery = () => {
    let gallerySelectedCoverImage = this.state.gallerySelectedCoverImage;

    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.put(API + 'card/cover-by-gallery?id='+this.state.gallerySelectedCoverImage, {}, {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          this.setState({loading: false, uploadLogoModal: false, uploadCoverModal: false, cover: response.data.data.card.cover, uploadCoverModalSuccess: true, coverImageShowGalleryBool: false, cover: response.data.data.card.cover});
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
          console.log(err);
      }
    }
  }

  updateLogoFromGallery = () => {
    let gallerySelectedLogoImage = this.state.gallerySelectedLogoImage;

    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.put(API + 'card/logo-by-gallery?id='+this.state.gallerySelectedLogoImage, {}, {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          this.setState({loading: false, uploadLogoModal: false, uploadCoverModal: false, logo: response.data.data.card.logo, uploadLogoModalSuccess: true, logoImageShowGalleryBool: false, logo: response.data.data.card.logo});
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
          console.log(err);
      }
    }
  }

  getTemplates = () => {
    this.setState({loading: true});
    axios.get(API + 'templates').then(response => {
      if (response.data.status === "ok") {
        this.setState({templates: response.data.data.templates});
      }
      this.setState({loading: false});
    }).catch(err => {
      console.log(err);
      this.setState({loading: false});
    });
  }

  checkSlug = (slug) => {
    slug = slug.replace(' ', '');
    let slugCheck = slug.replace('-', '');
    if (slugCheck.match("^[a-zA-Z0-9]+$") !== null) {
      this.setState({slug: slug}, () => {
        axios.get(API + 'card/slug?slug='+slug.toLowerCase()).then(response => {
          console.log(response.data.available);

            this.setState({slugAvailable: response.data.available});

        }).catch(err => {
          console.log(err);
        });
      });
    } else {
      this.setState({slug: slug, slugAvailable: false});
    }
  }

  is24hours = () => {
    this.setState({loading: true});
    let openingHours = this.state.openingHours;

    let a1 = new Date(openingHours['A'][0]).getHours() + ':' + new Date(openingHours['A'][0]).getMinutes();
    let a2 = new Date(openingHours['A'][1]).getHours() + ':' + new Date(openingHours['A'][1]).getMinutes();

    let b1 = new Date(openingHours['B'][0]).getHours() + ':' + new Date(openingHours['B'][0]).getMinutes();
    let b2 = new Date(openingHours['B'][1]).getHours() + ':' + new Date(openingHours['B'][1]).getMinutes();

    let c1 = new Date(openingHours['C'][0]).getHours() + ':' + new Date(openingHours['C'][0]).getMinutes();
    let c2 = new Date(openingHours['C'][1]).getHours() + ':' + new Date(openingHours['C'][1]).getMinutes();

    let d1 = new Date(openingHours['D'][0]).getHours() + ':' + new Date(openingHours['D'][0]).getMinutes();
    let d2 = new Date(openingHours['D'][1]).getHours() + ':' + new Date(openingHours['D'][1]).getMinutes();

    let e1 = new Date(openingHours['E'][0]).getHours() + ':' + new Date(openingHours['E'][0]).getMinutes();
    let e2 = new Date(openingHours['E'][1]).getHours() + ':' + new Date(openingHours['E'][1]).getMinutes();

    let f1 = new Date(openingHours['F'][0]).getHours() + ':' + new Date(openingHours['F'][0]).getMinutes();
    let f2 = new Date(openingHours['F'][1]).getHours() + ':' + new Date(openingHours['F'][1]).getMinutes();

    let g1 = new Date(openingHours['G'][0]).getHours() + ':' + new Date(openingHours['G'][0]).getMinutes();
    let g2 = new Date(openingHours['G'][1]).getHours() + ':' + new Date(openingHours['G'][1]).getMinutes();
    if (
      ((a1 === '0:0' || a1 === '00:00') && (a2 === '0:0' || a2 === '00:00')) &&
      ((b1 === '0:0' || b1 === '00:00') && (b2 === '0:0' || b2 === '00:00')) &&
      ((c1 === '0:0' || c1 === '00:00') && (c2 === '0:0' || c2 === '00:00')) &&
      ((d1 === '0:0' || d1 === '00:00') && (d2 === '0:0' || d2 === '00:00')) &&
      ((e1 === '0:0' || e1 === '00:00') && (e2 === '0:0' || e2 === '00:00')) &&
      ((f1 === '0:0' || f1 === '00:00') && (f2 === '0:0' || f2 === '00:00')) &&
      ((g1 === '0:0' || g1 === '00:00') && (g2 === '0:0' || g2 === '00:00'))
    ) {
      this.setState({open24h: true, loading: false});
    } else {
      this.setState({open24h: false, loading: false});
    }
  }

  remove_button = (btn) => {
    let index = this.state.buttonShownIndex;

    if (index === 4) this.setState({button_4: ''});
    if (index === 5) this.setState({button_5: ''});
    if (index === 6) this.setState({button_6: ''});
    if (index === 7) this.setState({button_7: ''});
    if (index === 8) this.setState({button_8: ''});
    if (index === 9) this.setState({button_9: ''});

    index -= 1;

    this.setState({buttonShownIndex: index});
  }

  setOpen24H = () => {
    if (this.state.open24h) {
      this.setState({open24h: false});
    } else {
      this.setState({
        openingHours: {
          'A': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'B': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'C': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'D': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'E': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'F': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'G': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
        },
        open24h: true
      });
    }
  }

  getOpeningHours = (day, type = 'from') => {
    let days = this.state.openingHours;

    if (days[day] === undefined) {
      days[day] = ['Sat Sep 12 2020 09:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 20:00:00 GMT+0300 (Israel Daylight Time)']

      this.setState({openingHours: days}, () => {
        if (type === 'from') {
          return new Date(days[day][0]);
        } else {
          return new Date(days[day][1]);
        }
      })
    } else {
      if (type === 'from') {
        return new Date(days[day][0]);
      } else {
        return new Date(days[day][1]);
      }
    }
  }

  setOpeningHours = (day, type = 'from', time) => {
    let days = this.state.openingHours;
    let dayTimes = days[day];

    if (type === 'from') {
      dayTimes[0] = time;
    } else {
      dayTimes[1] = time;
    }

    days[day] = dayTimes;

    this.setState({openingHours: days});
  }

  getMyCard = () => {
    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'card', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          if (response.data.status === "ok") {
            this.setState({
              categories: response.data.data.categories,
              slug: response.data.data.card.slug,
              logo: response.data.data.card.logo,
              cover: response.data.data.card.cover,
              phoneNumber: response.data.data.card.phoneNumber,
              title: response.data.data.card.title,
              secondaryTitle: response.data.data.card.secondaryTitle,
              // text: RichTextEditor.createValueFromString(response.data.data.card.text, 'html'),
              text: response.data.data.card.text.replaceAll('<br />', '\n'),
              businessName: response.data.data.card.businessName
            });
          }
        }).catch(err => {
          this.setState({loading: true});
          console.log(err);
          window.location.reload();
        });
      } catch (err) {
        this.setState({loading: true});
        console.log(err);
        window.location.reload();
      }
    }
  }

  verifySMS = () => {

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        axios.put(API + 'verify-code-sms', {
          code: this.state.code
        }, {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          if (response.data.status === "ok") {
            this.setState({step: 2});
          } else {
            this.setState({codeWrong: 'קוד שגוי'});
          }
        }).catch(err => {
          console.log(err);
          this.setState({codeWrong: 'קוד שגוי'});
        });
      } catch (err) {
        console.log(err);
        this.setState({codeWrong: 'קוד שגוי'});
      }
    }
  }

  resendVerificationCode = () => {
    let token = cookies.get('__dato__');

    axios.post(API + 'resend-verficaiton-code', {}, {
      headers: {
         Authorization: `Bearer ${token}`
      }
    }).then(response => {
      if (response.data.status === "ok") {
        this.setState({resentVerificationCode: true});
      } else {
        alert('חלה שגיאה, אנא נסו שוב.')
      }
    }).catch(err => {
      console.log(err);
      alert('חלה שגיאה, אנא נסו שוב.')
    });
  }

  step1 = () => {
    this.setState({errorAlert: false, error: ''});

    let payload = {
      slug: this.state.slug,
      firstTime: true
    }

    if (this.state.title !== '') payload['title'] = this.state.title;
    if (this.state.secondaryTitle !== '') payload['secondaryTitle'] = this.state.secondaryTitle;
    if (this.state.text !== '') payload['text'] = this.state.text.replace(/\r?\n/g, '<br />');
    if (this.state.phoneNumber !== '') payload['phoneNumber'] = this.state.phoneNumber;
    if (this.state.businessName !== '') payload['businessName'] = this.state.businessName;

    if (
      this.state.slug !== ''
    ) {
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          axios.put(API + 'card', payload, {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {
            if (response.data.status === "ok") {
              this.setState({step: 15}, () => {
                this.resendVerificationCode();
              });
            } else {
              alert('חלה שגיאה, אנא נסו שוב.')
            }
          }).catch(err => {
            console.log(err);
            alert('חלה שגיאה, אנא נסו שוב.')
          });
        } catch (err) {
          console.log(err);
          alert('חלה שגיאה, אנא נסו שוב.')
        }
      }
    } else {
      this.setState({errorAlert: true, error: 'יש למלא את כל השדות על מנת ליצור את הכרטיס'});

      if (this.state.title === '') this.setState({errortitle: true});
      if (this.state.secondaryTitle === '') this.setState({errorsecondarytitle: true});
      if (this.state.text === '') this.setState({errortext: true});
      if (this.state.phoneNumber === '') this.setState({errorphoneNumber: true});
      if (this.state.facebookLink === '') this.setState({errorfacebookLink: true});
      if (this.state.instagramLink === '') this.setState({errorinstagramLink: true});
      if (this.state.address === '') this.setState({erroraddress: true});
      if (this.state.businessName === '') this.setState({errorbusinessName: true});

    }
  }

  addButtonIndex = () => {
    let index = this.state.buttonShownIndex;

    index += 1;

    this.setState({buttonShownIndex: index});
  }



  updateTemplate = (id) => {

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      axios.put(API + 'card/template?id='+id, {}, {
        headers: {
           Authorization: `Bearer ${token}`
        }
      }).then(response => {
          this.getMyCardImages();
          this.setState({templateChosen: id});
          this.setState({changeTemplateModal: false});
      }).catch((error) => {
        this.setState({changeTemplateModal: false});
      });
    }
  }

  getMyCardTemplate = () => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      axios.get(API + 'card', {
        headers: {
           Authorization: `Bearer ${token}`
        }
      }).then(response => {
          this.setState({templateId: response.data.data.card.templateId, template: response.data.data.template});
      }).catch((error) => {
        this.setState({changeTemplateModal: false});
      });
    }
  }


  getMyCardImages = () => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      axios.get(API + 'card', {
        headers: {
           Authorization: `Bearer ${token}`
        }
      }).then(response => {
        //console.log(response);
          this.setState({logo: response.data.data.card.logo, cover: response.data.data.card.cover});
      }).catch((error) => {

      });
    }
  }



  onFormSubmitCover = async (e) => {
    this.setState({loadingImage: true});


    e.preventDefault();
    const formData = new FormData();

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    console.log(this.state.file);

    const compressedFile = await imageCompression(this.state.file, options);

    const convertedBlobFile = new File([compressedFile], this.state.file.name, { type: this.state.file.type, lastModified: Date.now()})

    console.log(convertedBlobFile);

    formData.append('file', convertedBlobFile);

    let token = cookies.get('__dato__');
    if (token !== null && token !== undefined && token !== '') {
      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'card/cover',formData,config)
          .then((response) => {
            this.setState({fileCover: null, uploadCoverModal: false, uploadCoverModalSuccess: true, cover: 'yes', loadingImage: false})
            // window.location.reload();
            this.getMyCardImages();
          }).catch((error) => {
            this.setState({fileCover: null, uploadCoverModal: false, loadingImage: false})
          });
    }
  }

  onFormSubmit = async (e) => {
    this.setState({loadingImage: true})

    e.preventDefault();
    const formData = new FormData();

    const {blobFile} = this.state;

    const newImage = new File([blobFile], blobFile.name, { type: blobFile.type });

    //
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    console.log(this.state.file);

    const compressedFile = await imageCompression(newImage, options);

    // formData.append('file',this.state.file);
    const convertedBlobFile = new File([compressedFile], this.state.file.name, { type: this.state.file.type, lastModified: Date.now()})

    console.log(convertedBlobFile);

    formData.append('file', convertedBlobFile);
    // const formData = new FormData();
    //
    // //
    // const options = {
    //   maxSizeMB: 1,
    //   maxWidthOrHeight: 1920,
    //   useWebWorker: true
    // }
    // console.log(this.state.file);
    //
    // const compressedFile = await imageCompression(this.state.file, options);
    //
    // const convertedBlobFile = new File([compressedFile], this.state.file.name, { type: this.state.file.type, lastModified: Date.now()})
    //
    // console.log(convertedBlobFile);
    //
    // formData.append('file', convertedBlobFile);
    //

    let token = cookies.get('__dato__');
    if (token !== null && token !== undefined && token !== '') {
      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'card/logo',formData,config)
          .then((response) => {
            this.setState({file: null, toBlob: null, croppedImageUrl: '', imageEditing: true, src: null, uploadLogoModal: false, uploadLogoModalSuccess: true, logo: 'yes', loadingImage: false})
            // window.location.reload();
            this.getMyCardImages();
          }).catch((error) => {
            this.setState({file: null, toBlob: null, croppedImageUrl: '', imageEditing: true, src: null, uploadLogoModal: false, loadingImage: false})
      });
    }
  }

  onChange = (e) => {
      // this.setState({file:e.target.files[0]});
      this.setState({file:e.target.files[0], imageEditing: false});

      if (e.target.files && e.target.files.length > 0) {
       const reader = new FileReader();
       reader.addEventListener('load', () =>
         this.setState({ src: reader.result })
       );
       reader.readAsDataURL(e.target.files[0]);
     }
  }

  onChangeCover = (e) => {
      this.setState({fileCover:e.target.files[0]});
  }

  close = (t) => {
    let dict = this.state.close;

    if (dict[t]) {
      dict[t] = false
    } else {
      dict[t] = true
    }

    let openingHours = this.state.openingHours;

    openingHours[t] = ['', ''];

    this.setState({close: dict, openingHours: openingHours});
  }

  validatePhoneNumber = () => {
    let phoneNumber = this.state.phoneNumber;

    var regex = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/;

    if (
      regex.test(phoneNumber) &&
      (
        phoneNumber[2] === '0' ||
        phoneNumber[2] === '2' ||
        phoneNumber[2] === '5' ||
        phoneNumber[2] === '7' ||
        phoneNumber[2] === '8' ||
        phoneNumber[2] === '3' ||
        phoneNumber[2] === '4'
      )
    ) {
      return true
    }

    return false
  }

  headerComponent = () => {
    if (this.state.step === 1 || this.state.step === 15) {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>4</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>3</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>2</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: blue,
            color: 'white',
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>1</p>
        </div>
      );
    } else if (this.state.step === 2) {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>4</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>3</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: blue,
            color: 'white',
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>2</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>1</p>
        </div>
      )
    } else if (this.state.step === 3) {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>4</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: blue,
            color: 'white',
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>3</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>2</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>1</p>
        </div>
      )
    } else if (this.state.step === 4) {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <p style={{
            borderRadius: '50%',
            backgroundColor: blue,
            color: 'white',
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>4</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>3</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>2</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>1</p>
        </div>
      )
    } else {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>4</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>3</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>2</p>
          <p style={{marginTop: '6%', fontSize: '3em', color: yellow, marginRight: '1.5%', marginLeft: '1.5%'}}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: blue,
            color: 'white',
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>1</p>
        </div>
      )
    }
  }

  headerComponentBrowser = () => {
    if (this.state.step === 1 || this.state.step === 15) {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>4</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>3</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>2</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: blue,
            color: 'white',
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>1</p>
        </div>
      );
    } else if (this.state.step === 2) {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>4</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>3</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: blue,
            color: 'white',
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>2</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>1</p>
        </div>
      )
    } else if (this.state.step === 3) {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>4</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: blue,
            color: 'white',
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>3</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>2</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>1</p>
        </div>
      )
    } else if (this.state.step === 4) {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <p style={{
            borderRadius: '50%',
            backgroundColor: blue,
            color: 'white',
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>4</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>3</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>2</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>1</p>
        </div>
      )
    } else {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>4</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>3</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: yellow,
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>2</p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: 'transparent',
            color: blue,
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}> {'<'} </p>
          <p style={{
            borderRadius: '50%',
            backgroundColor: blue,
            color: 'white',
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontSize: '2em', marginRight: '1.5%', marginLeft: '1.5%'
          }}>1</p>
        </div>
      )
    }
  }

  finalStep = () => {
    this.setState({step: 4}, () => {
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          axios.get(API + 'final-step', {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {

          }).catch(err => {

          });
        } catch (err) {

        }
      }
    });
  }


    helmet = () => {
      const description = 'דיביט | הרשמה';

      return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>דיביט | הרשמה</title>
            <link rel="canonical" href={window.location.href} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta property="og:description" content={description} />
            <meta property="og:title" content={description} />
            <meta property="og:site_name" content="דיביט" />
            <meta property="og:see_also" content="דיביט" />
            <meta itemprop="name" content={description} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content="https://dibit.co.il/wp-content/uploads/2020/09/cropped-logo-2-180x180.jpg" />
            <meta name="description" content={description}/>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
      );
    }

    /* Image crop */
    onSelectFile = e => {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          this.setState({ src: reader.result })
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    };

    onImageLoaded = image => {
      this.imageRef = image;
    };

    onCropComplete = crop => {
      this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
      // You could also use percentCrop:
      // this.setState({ crop: percentCrop });
      this.setState({ crop });
    };

    async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.png'
      );
      this.setState({ croppedImageUrl });
      console.log(croppedImageUrl);
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({blobFile:blob})
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/png');
    });
  }


  render() {
    const { crop, croppedImageUrl, src } = this.state;

    return (
      <div>
        {this.helmet()}

        {this.state.loading ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OfflineHeader logged={false} package={{messages: true, editCard: true}} />
            </Grid>
            <Grid item xs={12}>
              <center>
                <div style={{textAlign: 'center', marginTop: '5%'}}>
                  <CircularProgress />
                  <h3 style={{direciton: 'rtl'}}>..טוען</h3>
                </div>
              </center>
            </Grid>
          </Grid>
        ) : (
          <React.Fragment>
          <MobileView>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OfflineHeader logged={false} mobile={isMobile} package={{messages: true, editCard: true}}/>
            </Grid>
            {this.state.step === 1 && (
              <Grid item xs={12}>
                <div style={{marginTop: '-2%'}}>
                  {this.headerComponent()}
                  <center><div style={{height: 35, width: '90%', backgroundColor: 'white', marginTop: '-22%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',}}></div></center>
                </div>
                <center>
                  <p style={{textAlign: 'center', direction:'rtl', fontSize: '2em', fontWeight: 700, color: blue, marginTop: '2%', marginBottom: '-2%'}}>
                    שלב 1
                  </p>
                </center>
                <div style={{marginRight: '4%', textAlign :'right'}}>
                  {!this.state.slugAvailable && (
                    <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                      שם זה אינו זמין, אנא בחר אחד אחר או הוסף תווים נוספים
                    </p>
                  )}
                  <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                    {this.state.slug === '' ? (
                      <span style={{whiteSpace: 'break-spaces'}}>זאת תהיה כתובת האינטרנט שלכם, לדוגמא: https://dibit.co.il/your-card</span>
                    ) : (
                      <span style={{whiteSpace: 'break-spaces'}}>זאת תהיה כתובת האינטרנט שלכם, לדוגמא: https://dibit.co.il/{this.state.slug}</span>
                    )}
                  </p>
                  <TextField
                    value={this.state.slug}
                    onChange={(e) => this.checkSlug(e.target.value)}
                    placeholder={'שם העסק באנגלית'}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '95%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <br />
                  <TextField
                    value={this.state.businessName}
                    onChange={(e) => this.setState({businessName: e.target.value})}
                    placeholder={'שם העסק בעברית'}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '95%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <br />
                  <TextField
                    value={this.state.phoneNumber}
                    onChange={(e) => this.setState({phoneNumber: e.target.value})}
                    placeholder={'מספר הטלפון המקושר לכרטיס'}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '95%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  {!this.validatePhoneNumber() && (
                    <p style={{color: 'red', marginBottom: '-2%', fontSize: '1em'}}>יש להזין מספר טלפון תקני</p>
                  )}
                  <br />
                  <TextField
                    value={this.state.title}
                    onChange={(e) => this.setState({title: e.target.value})}
                    placeholder={'כותרת ראשית'}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '95%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <br />
                  <TextField
                    value={this.state.secondaryTitle}
                    onChange={(e) => this.setState({secondaryTitle: e.target.value})}
                    placeholder={'כותרת משנית'}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '95%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <br />
                  <center>
                  <br />
                  {/*<div style={{maxWidth: '90%', marginLeft: '10%'}}>
                  <RichTextEditor
                    value={this.state.text}
                    onChange={this.onChangeText}
                    toolbarConfig={toolbarConfigMobile}
                    style={{
                      maxWidth: '80%'
                    }}
                    editorClassName="editor-mobile"
                    placeholder={'טקסט'}
                  />
                  </div>*/}
                  <textarea
                    value={this.state.text}
                    onChange={(e) => this.setState({text: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '95%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white',
                      marginLeft: '5%'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold',
                        marginLeft: '5%'
                      }
                    }}
                    rows={4}
                    placeholder={'טקסט כאן'}
                  ></textarea>
                  </center>


                  <br /><br />
                  {(this.state.slugAvailable && this.state.slug !== '' && this.state.businessName !== '' && this.state.phoneNumber !== '') ? (
                    <React.Fragment>
                      {this.validatePhoneNumber() ? (
                        <Button onClick={(e) => this.step1()} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%'}}>
                          {'<'} לשלב הבא
                        </Button>
                      ) : (
                        <Button onClick={(e) => alert('עלייך להזין מספר טלפון תיקני')} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%'}}>
                          {'<'} לשלב הבא
                        </Button>
                      )}
                    </React.Fragment>
                  ) : (
                    <Button onClick={(e) => alert('אנא השלימו את כל הפרטים וודאו ששם העסק באנגלית אינו תפוס')} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%'}}>
                      {'<'} לשלב הבא
                    </Button>
                  )}

                  <br /><br />
                </div>
              </Grid>
            )}

            {/* SMS validation */}
            {this.state.step === 15 && (
              <Grid item xs={12} style={{overflowY: 'visiable'}}>
                <div style={{marginTop: '-2%'}}>
                  {this.headerComponent()}
                  <center><div style={{height: 35, width: '90%', backgroundColor: 'white', marginTop: '-22%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',}}></div></center>
                </div>
                <center>
                  <p style={{textAlign: 'center', direction:'rtl', fontSize: '2em', fontWeight: 700, color: blue, marginTop: '2%', marginBottom: '-2%'}}>
                    שלב 1
                  </p>
                </center>
                <div style={{textAlign :'center'}}>
                  <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                    על מנת להמשיך, אנא הקש את הקוד שקיבלת בSMS
                  </p>
                  {this.state.codeWrong && (
                    <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0, color: 'red'}}>
                      קוד שגוי
                    </p>
                  )}
                  <TextField
                    value={this.state.code}
                    onChange={(e) => this.setState({code: e.target.value})}
                    placeholder={'הקוד'}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      width: '95%',
                      fontSize:'1.5em',
                      marginTop: '0.5%',
                      color: blue,
                      backgroundColor: 'white'
                    }}
                    inputProps={{
                      style:{
                        fontSize:'1.5em',
                        color: blue,
                        backgroundColor: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                  />

                  <Snackbar open={this.state.resentVerificationCode} autoHideDuration={6000} onClose={(e) => this.setState({resentVerificationCode: false})}>
                    <Alert variant="filled" severity="success">קוד נשלח אליכם</Alert>
                  </Snackbar>



                  <br /><br />
                    <Button onClick={(e) => this.verifySMS()} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%'}}>
                      {'<'} לשלב הבא
                    </Button>
                  <br /><br />

                  <Button onClick={(e) => this.resendVerificationCode()} style={{color: yellow, backgroundColor: blue, fontSize: '1em', width: '70%'}}>
                    לא קיבלתי קוד - שלחו לי שוב
                  </Button>
                </div>
              </Grid>
            )}

            {this.state.step === 2 && (
              <Grid item container xs={12}>
              <Grid item xs={12}>

                <div style={{marginTop: '-2%'}}>
                  {this.headerComponent()}
                  <center><div style={{height: 35, width: '90%', backgroundColor: 'white', marginTop: '-22%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',}}></div></center>
                </div>
                <center>
                  <p style={{textAlign: 'center', direction:'rtl', fontSize: '2em', fontWeight: 700, color: blue, marginTop: '2%', marginBottom: '0%'}}>
                    שלב 2
                  </p>
                  <div style={{justifyContent: 'space-between', display: 'flex', padding: '1%'}}>
                  <Button onClick={(e) => this.setState({step: 3})} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%', marginLeft: '0%'}}>
                    {'<'} לשלב הבא
                  </Button>
                  <Button onClick={(e) => this.setState({step: 1})} style={{color: blue, backgroundColor: 'white', fontSize: '1.2em', width: '40%'}}>
                    לשלב הקודם {'>'}
                  </Button>
                  </div>
                </center>

                <center><div style={{backgroundColor: yellow, maxWidth: '92%'}}>
                  <center><p style={{color: blue, padding: '5%', fontSize: '1.2em'}}>בחר תבנית מהמאגר המדהים שלנו</p></center>
                </div></center>

                <br />
                <div style={{textAlign: 'center', width: '100%', marginTop: '-5%', marginBottom: 2}}>
                  <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {this.state.categories.reverse().map((c_) => {
                      let category = c_.name;
                      return (
                        <Button onClick={(e) => this.setState({categoryChosen: category})} style={{fontSize: '1em', backgroundColor: this.state.categoryChosen === category ? yellow : blue, color: 'white', borderRadius: 50, marginRight: '0.5%', marginLeft: '0.5%', marginBottom: '0.5%'}}>{category}</Button>
                      )
                    })}
                    <Button onClick={(e) => this.setState({categoryChosen: 'all'})} style={{fontSize: '1em', backgroundColor: this.state.categoryChosen === 'all' ? yellow : blue, color: 'white', borderRadius: 50, marginRight: '0.5%', marginLeft: '0.5%', marginBottom: '0.5%'}}>הכל</Button>

                  </div>
                </div>
                <br />

                </Grid>
                {/*this.state.templates.map((template) => {
                  if (this.state.templateChosen === template._id.toString()) {
                    return (
                      <Grid item xs={6} style={{textAlign: 'center'}}>
                        <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={6} style={{textAlign: 'center'}} onClick={(e) => this.updateTemplate(template._id)}>
                        <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', }} />
                      </Grid>
                    );
                  }
                })*/}
                {this.state.templates.map((template) => {
                  if (this.state.categoryChosen === 'all' || this.state.categoryChosen === '') {
                    if (this.state.templateChosen === template._id.toString()) {
                      return (
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                          <p>{template.name}</p>
                          <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid item xs={6} style={{textAlign: 'center'}} onClick={(e) => this.updateTemplate(template._id)}>
                          <p>{template.name}</p>
                          <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', }} />
                        </Grid>
                      );
                    }
                  } else {
                    if (this.state.categoryChosen === template.category) {
                      if (this.state.templateChosen === template._id.toString()) {
                        return (
                          <Grid item xs={6} style={{textAlign: 'center'}}>
                            <p>{template.name}</p>
                            <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid item xs={6} style={{textAlign: 'center'}} onClick={(e) => this.updateTemplate(template._id)}>
                            <p>{template.name}</p>
                            <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', }} />
                          </Grid>
                        );
                      }
                    }
                  }

                })}

                <Grid item xs={12}>
                <div style={{marginRight: '4%', textAlign :'right'}}>


                  <br /><br />
                  <div style={{justifyContent: 'space-between', display: 'flex'}}>
                  <Button onClick={(e) => this.setState({step: 3})} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%', marginLeft: '5%'}}>
                    {'<'} לשלב הבא
                  </Button>
                  <Button onClick={(e) => this.setState({step: 1})} style={{color: blue, backgroundColor: 'white', fontSize: '1.2em', width: '40%'}}>
                    לשלב הקודם {'>'}
                  </Button>
                  </div>
                  <br /><br />
                </div>
                </Grid>

              </Grid>
            )}

            {this.state.step === 3 && (
              <Grid item container xs={12}>
              <Grid item xs={12}>

                <div style={{marginTop: '-2%'}}>
                  {this.headerComponent()}
                  <center><div style={{height: 35, width: '90%', backgroundColor: 'white', marginTop: '-22%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',}}></div></center>
                </div>
                <center>
                  <p style={{textAlign: 'center', direction:'rtl', fontSize: '1.3em', fontWeight: 700, color: blue, marginTop: '2%', marginBottom: '2%'}}>
                    שלב 3
                  </p>
                </center>

                </Grid>


                <Grid item xs={12}>
                <div style={{marginRight: '4%', textAlign :'right', marginTop: '-10%'}}>
                <p style={{direction:'rtl', color: blue, fontSize: '2.3em', fontWeight: 700, marginBottom: '0%'}}>
                  בחר את התמונות שלך
                </p>
                <div>
                  {(this.state.logo !== undefined && this.state.logo !== null && this.state.logo === '') ? (
                    <button onClick={(e) => this.setState({uploadLogoModal: true})} style={{
                      backgroundColor: yellow,
                      color: blue,
                      border: '1px solid ' + yellow,
                      fontSize: '1.5em',
                    }}>לחצו להעלאת הלוגו</button>
                  ) : (
                    <React.Fragment>
                      <p style={{color: 'green', fontSize: '1.3em'}}>!הלוגו הועלה בהצלחה ✅</p>
                      <button onClick={(e) => this.setState({uploadLogoModal: true})} style={{
                        backgroundColor: yellow,
                        color: blue,
                        border: '1px solid ' + yellow,
                        fontSize: '1.5em',
                      }}>לחצו להחלפת הלוגו</button>
                      <br /><br />
                      {(this.state.logo.includes('/gallery/') || this.state.logo.includes('/logos/') || this.state.logo.includes('/templates/') || this.state.logo.includes('app.dibit'))  ? (
                        <img src={this.state.logo} className="signup__mobile__image__logo"/>
                      ) : (
                        <img src={'https://app.dibit.co.il/logos/'+this.state.logo} className="signup__mobile__image__logo"/>
                      )}
                    </React.Fragment>
                  )}
                  {(
                    (this.state.logo !== undefined && this.state.logo !== null && this.state.logo === '')
                    && (this.state.logo !== undefined && this.state.logo !== null && this.state.logo === '')
                  ) && (
                    <React.Fragment><br /><br /></React.Fragment>
                  )}
                  {(this.state.cover === undefined || this.state.cover === null || this.state.cover === '') ? (
                    <button onClick={(e) => this.setState({uploadCoverModal: true})} style={{
                      backgroundColor: blue,
                      color: yellow,
                      border: '1px solid ' + yellow,
                      fontSize: '1.5em',
                    }}>לחצו להעלאת הקאבר</button>
                  ) : (
                    <React.Fragment>
                      <p style={{color: 'green', fontSize: '1.3em'}}>!תמונת הקאבר הועלתה בהצלחה ✅</p>
                      <button onClick={(e) => this.setState({uploadCoverModal: true})} style={{
                        backgroundColor: blue,
                        color: yellow,
                        border: '1px solid ' + yellow,
                        fontSize: '1.5em',
                      }}>לחצו להחלפת הקאבר</button>
                      <br /><br />
                      {(this.state.cover.includes('/gallery/') || this.state.cover.includes('/logos/') || this.state.cover.includes('/templates/') || this.state.cover.includes('app.dibit'))  ? (
                        <img src={this.state.cover} className="signup__mobile__image__cover"/>
                      ) : (
                        <img src={'https://app.dibit.co.il/logos/'+this.state.cover} className="signup__mobile__image__cover"/>
                      )}
                    </React.Fragment>
                  )}
                </div>
                <Modal
                  open={this.state.uploadLogoModal}
                  onClose={(e) => this.setState({uploadLogoModal: false})}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{
                    backgroundColor: 'white',
                    border: '2px solid white',
                    padding: '5px 8px 6px',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                  }}>
                    {this.state.logoImageShowGalleryBool ? (
                      <React.Fragment>
                      <div style={{textAlign: 'right'}}>
                        <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, whiteSpace: 'break-spaces'}}>
                        בחרו תמונה מהגלרייה המדהימה שלנו
                        </p>

                        <div>
                          <p>חפש תמונה על פי מילה המגדירה אותה</p>

                          <TextField
                            value={this.state.search_gallery}
                            onChange={(e) => this.setState({search_gallery: e.target.value})}
                            style={{width: '70%',
                            direction: 'rtl',
                            textAlign: 'right',}}
                            inputProps={{
                              style: {
                                direction: 'rtl',
                                textAlign: 'right',
                              }
                            }}
                          />
                          <br />
                          <br />
                          <button onClick={(e) => this.resetGallery()} style={{backgroundColor: 'white', color: blue, border: '1px solid ' + blue, fontSize: '1.5em'}}>איפוס</button>
                          &nbsp;
                          <button onClick={(e) => this.searchGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>חפש</button>
                          &nbsp;

                        </div>
                      </div>
                      <Card style={{minWidth: '100%', height: 350, overflowY: 'auto'}}>
                      {this.state.gallery.map((image, i) => {
                        // console.log(i);
                        // console.log(image);
                        if (this.state.gallerySelectedLogoImage !== '') {
                          if (i % 2 !== 0 || i === 0) {
                            if (image._id.toString() === this.state.gallerySelectedLogoImage) {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                              )
                            } else {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                              )
                            }
                          } else {
                            if (image._id.toString() === this.state.gallerySelectedLogoImage) {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                  <br />
                                </React.Fragment>
                              )
                            } else {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                                  <br />
                                </React.Fragment>
                              )
                            }
                          }
                        } else {
                          if (i % 2 !== 0 || i === 0) {
                            if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                              )
                            } else {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                              )
                            }
                          } else {
                            if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                  <br />
                                </React.Fragment>
                              )
                            } else {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                                  <br />
                                </React.Fragment>
                              )
                            }
                          }
                        }
                      })}
                      </Card>
                      <br />
                      <div style={{textAlign: 'right'}}>
                        <button onClick={(e) => this.updateLogoFromGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>בחר ועדכן</button>
                      </div>
                      <p style={{fontSize: '1.3em', direciton: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                      <div style={{textAlign: 'right'}}>
                        <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}} onClick={(e) => this.setState({logoImageShowGalleryBool: false})}>או העלו תמונה משלכם</button>
                      </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <p style={{direction:'rtl', color: blue, fontSize: '1.7em', fontWeight: 700, marginBottom: '-10%'}}>
                          הלוגו שלכם
                        </p>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1.2em', fontWeight: 700, marginBottom: 0, whiteSpace: 'break-spaces'}}>
                          בחרו את לוגו העסק או תמונה ייצוגית אחרת
                        </p>
                        {(this.state.logo !== null && this.state.logo !== undefined && this.state.logo !== '') && (this.state.imageEditing) && (
                          <center>
                            {(this.state.logo.includes('/gallery/') || this.state.logo.includes('/templates/')) ? (
                              <img src={this.state.logo} style={{maxWidth: '235px', height: 'auto', borderRadius: '50%', width: 190, height: 190}}/>
                            ) : (
                              <img src={'https://app.dibit.co.il/logos/'+this.state.logo} style={{maxWidth: '235px', height: 'auto', borderRadius: '50%', width: 190, height: 190}}/>
                            )}
                          </center>
                        )}

                        {src && (
                          <center>
                          <div style={{textAlign: 'center'}}>
                          <ReactCrop
                            src={src}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                            style={{
                              width: 300,
                              height: 'auto'
                            }}
                          />
                          </div>
                          </center>
                        )}

                        {this.state.loadingImage && (
                          <React.Fragment>
                            <center>
                              <p>מעלה את התמונה, אנא המתן</p>
                              <CircularProgress />
                            </center>
                          </React.Fragment>
                        )}

                        <form onSubmit={this.onFormSubmit}>
                          <br />
                          <div style={{textAlign: 'right'}}>


                          {(this.state.file !== undefined && this.state.file !== null && this.state.file !== '') && (
                            <React.Fragment>
                            <p style={{color: blue, fontSize: '1em', direction: 'rtl', whiteSpace:'break-spaces'}}>הקובץ שנבחר: {this.state.file.name}</p>
                            <button type="submit" style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>להעלאה לחץ/י כאן</button>
                            <br /><br />
                            </React.Fragment>
                          )}


                          <div class="upload-btn-wrapper">
                            <button class="btn">לחצו כאן לבחירת התמונה</button>
                            <input type="file" name="myImage" onChange={this.onChange} accept="image/*"/>
                          </div>

                          <p style={{fontSize: '1.3em', direciton: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                          </div>
                        </form>
                        <div style={{textAlign: 'right'}}>
                          <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em', textAlign: 'right'}} onClick={(e) => this.setState({logoImageShowGalleryBool: true})}>או בחר מתוך הגלרייה שלנו</button>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </Modal>
                <Snackbar open={this.state.uploadLogoModalSuccess} autoHideDuration={6000} onClose={(e) => this.setState({uploadLogoModalSuccess: false})}>
                  <Alert variant="filled" severity="success">!הלוגו הועלה בהצלחה ויתעדכן בהקדם בכרטיס הדיגיטלי שלך</Alert>
                </Snackbar>

                <Modal
                  open={this.state.uploadCoverModal}
                  onClose={(e) => this.setState({uploadCoverModal: false})}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{
                    backgroundColor: 'white',
                    border: '2px solid white',
                    padding: '2px 4px 3px',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    minWidth: isMobile ? 'auto' : 500,
                    minHeight: isMobile ? 'auto' : 400
                  }}>
                    {this.state.coverImageShowGalleryBool ? (
                      <React.Fragment>
                      <div style={{textAlign: 'right'}}>
                        <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700,}}>
                        בחרו תמונה מהגלרייה המדהימה שלנו
                        </p>

                        <div>
                          <p>חפש תמונה על פי מילה המגדירה אותה</p>

                          <TextField
                            value={this.state.search_gallery}
                            onChange={(e) => this.setState({search_gallery: e.target.value})}
                            style={{width: '70%',
                            direction: 'rtl',
                            textAlign: 'right',}}
                            inputProps={{
                              style: {
                                direction: 'rtl',
                                textAlign: 'right',
                              }
                            }}
                          />
                          <br />
                          <br />
                          <button onClick={(e) => this.resetGallery()} style={{backgroundColor: 'white', color: blue, border: '1px solid ' + blue, fontSize: '1.5em'}}>איפוס</button>
                          &nbsp;
                          <button onClick={(e) => this.searchGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>חפש</button>
                          &nbsp;

                        </div>
                      </div>
                      <Card style={{minWidth: '100%', height: 350, overflowY: 'auto'}}>
                      {this.state.gallery.map((image, i) => {
                        // console.log(i);
                        // console.log(image);
                        if (this.state.gallerySelectedCoverImage !== '') {
                          if (i % 2 !== 0 || i === 0) {
                            if (image._id.toString() === this.state.gallerySelectedCoverImage) {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                              )
                            } else {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                              )
                            }
                          } else {
                            if (image._id.toString() === this.state.gallerySelectedCoverImage) {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                  <br />
                                </React.Fragment>
                              )
                            } else {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                                  <br />
                                </React.Fragment>
                              )
                            }
                          }
                        } else {
                          if (i % 2 !== 0 || i === 0) {
                            if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                              )
                            } else {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                              )
                            }
                          } else {
                            if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                  <br />
                                </React.Fragment>
                              )
                            } else {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                                  <br />
                                </React.Fragment>
                              )
                            }
                          }
                        }
                      })}
                      </Card>
                      <br />
                      <div style={{textAlign: 'right'}}>
                        <button onClick={(e) => this.updateCoverFromGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>בחר ועדכן</button>
                      </div>
                      <p style={{fontSize: '1.3em', direciton: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                      <div style={{textAlign: 'right'}}>
                        <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}} onClick={(e) => this.setState({coverImageShowGalleryBool: false})}>או העלו תמונה משלכם</button>
                      </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                      <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: '-5%'}}>
                        תמונת הרקע שלי
                      </p>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.2em', fontWeight: 700, marginBottom: 0, whiteSpace: 'break-spaces'}}>
                        זוהי התמונה שתופיע מאחורי הלוגו שלך
                      </p>
                      {(this.state.cover !== '' && this.state.cover !== undefined && this.state.cover !== null) && (
                        <center>
                        {(this.state.cover.includes('/gallery/') || this.state.cover.includes('/templates/')) ? (
                          <img src={this.state.cover} style={{maxWidth: '35vh', maxHeight: '200px'}}/>
                        ) : (
                          <img src={'https://app.dibit.co.il/logos/'+this.state.cover} style={{maxWidth: '35vh', maxHeight: '200px'}}/>
                        )}
                        </center>
                      )}

                      {this.state.loadingImage && (
                        <React.Fragment>
                          <center>
                            <p>מעלה את התמונה, אנא המתן</p>
                            <CircularProgress />
                          </center>
                        </React.Fragment>
                      )}
                      <form onSubmit={this.onFormSubmitCover}>
                        <br />
                        <div style={{textAlign: 'right'}}>
                        <div class="upload-btn-wrapper">
                          <button class="btn">לחצו כאן לבחירת התמונה</button>
                          <input type="file" name="myImage" onChange={this.onChange} accept="image/*"/>
                        </div>

                        {(this.state.file !== undefined && this.state.file !== null && this.state.file !== '') && (
                          <React.Fragment>
                          <p style={{color: blue, fontSize: '1em', direction: 'rtl', whiteSpace:'break-spaces'}}>הקובץ שנבחר: {this.state.file.name}</p>
                          <button type="submit" style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>להעלאה לחץ/י כאן</button>
                          </React.Fragment>
                        )}

                        <p style={{fontSize: '1.3em', direciton: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                        </div>
                      </form>
                      <div style={{textAlign: 'right'}}>
                        <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em', textAlign: 'right'}} onClick={(e) => this.setState({coverImageShowGalleryBool: true})}>או בחר מתוך הגלרייה שלנו</button>
                      </div>
                      </React.Fragment>
                    )}

                  </div>
                </Modal>
                <Snackbar open={this.state.uploadCoverModalSuccess} autoHideDuration={6000} onClose={(e) => this.setState({uploadCoverModalSuccess: false})}>
                  <Alert variant="filled" severity="success">!תמונת הרקע שלכם התעדכנה בהצלחה ותעודכן בכרטיס הדיגיטלי שלכם בהקדם</Alert>
                </Snackbar>

                  <br /><br />
                  <div style={{justifyContent: 'space-between', display: 'flex'}}>
                  <Button onClick={(e) => this.finalStep()} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%', marginLeft: '5%'}}>
                    {'<'} לשלב הבא
                  </Button>
                  <Button onClick={(e) => this.setState({step: 2})} style={{color: blue, backgroundColor: 'white', fontSize: '1.2em', width: '40%'}}>
                    לשלב הקודם {'>'}
                  </Button>
                  </div>
                  <br /><br />
                </div>
                </Grid>

              </Grid>
            )}


            {this.state.step === 4 && (
              <Grid item container xs={12}>
              <Grid item xs={12}>

                <div style={{marginTop: '-2%'}}>
                  {this.headerComponent()}
                  <center><div style={{height: 35, width: '90%', backgroundColor: 'white', marginTop: '-22%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',}}></div></center>
                </div>
                <center>
                  <p style={{textAlign: 'center', direction:'rtl', fontSize: '1.5em', fontWeight: 700, color: blue, marginTop: '2%', marginBottom: '2%', whiteSpace: 'break-spaces'}}>
                    סיימנו!
                  </p>
                  <p style={{textAlign: 'center', direction:'rtl', fontSize: '1.2em', fontWeight: 700, color: blue, marginTop: '2%', marginBottom: '2%'}}>
                    מוכנים? תתחדשו יש לכם <span style={{color: yellow}}>דיביט!</span>
                  </p>

                  <Button onClick={(e) => window.open('https://dibit.co.il/'+this.state.slug)} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '90%'}}>
                    !לכניסה לכרטיס שלכם לחצו כאן
                  </Button>
                  <br />
                  <center><ArrowDownwardIcon style={{color: blue, fontSize: '5em', textAlign: 'center'}}/></center>
                </center>
                <div style={{justifyContent: 'space-between', display: 'flex'}}>
                <Button onClick={(e) => window.location.href='/card/edit'} style={{color: blue, backgroundColor: yellow, fontSize: '1.5em', width: '40%', marginLeft: '7%', whiteSpace: 'break-spaces', lineHeight: 'noraml'}}>
                  <span style={{ whiteSpace: 'break-spaces', lineHeight: 'noraml'}}>
                  לעריכת הכרטיס
                  לחצו כאן
                  </span>
                </Button>
                <Button onClick={(e) => window.open('https://wa.me/?text='+encodeURI('https://dibit.co.il/'+this.state.slug))} style={{color: blue, backgroundColor: yellow, fontSize: '1.5em', width: '40%', marginRight: '7%'}}>
                  <span style={{ whiteSpace: 'break-spaces', lineHeight: 'noraml'}}>
                  רוצים להשוויץ?
                  תשלחו לחברים
                  </span>
                </Button>
                </div>

                </Grid>

                <Grid item xs={12}>
                <div style={{marginRight: '4%', textAlign :'right'}}>


                  <br /><br />
                  <div style={{justifyContent: 'space-between', display: 'flex'}}>
                  <Button onClick={(e) => window.location.href='/'} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%', marginLeft: '5%'}}>
                    {'<'} לסיום
                  </Button>
                  <Button onClick={(e) => this.setState({step: 3})} style={{color: blue, backgroundColor: 'white', fontSize: '1.2em', width: '40%'}}>
                    לשלב הקודם {'>'}
                  </Button>
                  </div>
                  <br /><br />
                </div>
                </Grid>

              </Grid>
            )}
            <Grid item xs={12}>
              <div>
                <center><p style={{direction:'rtl', color: blue, fontSize: '1em', fontWeight: 100}}>
                  כל הזכויות שמורות לדיביט בע"מ, 2020
                </p></center>
              </div>
            </Grid>
          </Grid>
          </MobileView>

          <BrowserView>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OfflineHeaderLogin />
            </Grid>
            {this.state.step === 1 && (
              <Grid item xs={12} style={{overflowY: 'visiable'}}>
                <div style={{marginTop: '0%'}}>
                  {this.headerComponentBrowser()}
                </div>
                <center>
                  <p style={{textAlign: 'center', direction:'rtl', fontSize: '3em', fontWeight: 700, color: blue, marginTop: '-2%', marginBottom: 0}}>
                    שלב 1
                  </p>
                  {(this.state.slugAvailable && this.state.slug !== '' && this.state.businessName !== '' && this.state.phoneNumber !== '') ? (
                    <React.Fragment>
                      {this.validatePhoneNumber() ? (
                        <Button onClick={(e) => this.step1()} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%'}}>
                          {'<'} לשלב הבא
                        </Button>
                      ) : (
                        <Button onClick={(e) => alert('עלייך להזין מספר טלפון תקני')} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%'}}>
                          {'<'} לשלב הבא
                        </Button>
                      )}

                    </React.Fragment>
                  ) : (
                    <Button onClick={(e) => alert('אנא השלימו את כל הפרטים וודאו ששם העסק באנגלית אינו תפוס')} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%'}}>
                      {'<'} לשלב הבא
                    </Button>
                  )}
                </center>
                <div style={{textAlign :'center'}}>

                  {!this.state.slugAvailable && (
                    <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                      שם זה אינו זמין, אנא בחר אחד אחר או הוסף תווים נוספים
                    </p>
                  )}
                  <p style={{direction:'rtl', color: yellow, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    {this.state.slug === '' ? (
                      <span>זאת תהיה כתובת האינטרנט שלכם, לדוגמא: https://dibit.co.il/your-card</span>
                    ) : (
                      <span>זאת תהיה כתובת האינטרנט שלכם, לדוגמא: https://dibit.co.il/{this.state.slug}</span>
                    )}
                  </p>
                  <TextField
                    value={this.state.slug}
                    onChange={(e) => this.checkSlug(e.target.value)}
                    placeholder={'שם העסק באנגלית'}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      fontSize:'1.3em',
                      marginTop: '0.5%',
                      width: '45%',
                      backgroundColor: 'white',
                      color: blue
                    }}
                    inputProps={{
                      style:{
                        direction: 'rtl',
                        textAlign: 'right',
                        fontSize:'1.3em',
                        width: '45%',
                        backgroundColor: 'white',
                        color: blue,
                        fontWeight: 400,
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <br />
                  <TextField
                    value={this.state.businessName}
                    onChange={(e) => this.setState({businessName: e.target.value})}
                    placeholder={'שם העסק בעברית'}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      fontSize:'1.3em',
                      marginTop: '0.5%',
                      width: '45%',
                      backgroundColor: 'white',
                      color: blue
                    }}
                    inputProps={{
                      style:{
                        direction: 'rtl',
                        textAlign: 'right',
                        fontSize:'1.3em',
                        width: '45%',
                        backgroundColor: 'white',
                        color: blue,
                        fontWeight: 400,
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <br />

                  {!this.validatePhoneNumber() && (
                    <p style={{color: 'red', marginBottom: '0%', fontSize: '1.5em'}}>יש להזין מספר טלפון תקני</p>
                  )}
                  <TextField
                    value={this.state.phoneNumber}
                    onChange={(e) => this.setState({phoneNumber: e.target.value})}
                    placeholder={'מספר הטלפון המקושר לכרטיס'}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      fontSize:'1.3em',
                      marginTop: '0.5%',
                      width: '45%',
                      backgroundColor: 'white',
                      color: blue
                    }}
                    inputProps={{
                      style:{
                        direction: 'rtl',
                        textAlign: 'right',
                        fontSize:'1.3em',
                        width: '45%',
                        backgroundColor: 'white',
                        color: blue,
                        fontWeight: 400,
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <br />
                  <TextField
                    value={this.state.title}
                    onChange={(e) => this.setState({title: e.target.value})}
                    placeholder={'כותרת ראשית'}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      fontSize:'1.3em',
                      marginTop: '0.5%',
                      width: '45%',
                      backgroundColor: 'white',
                      color: blue
                    }}
                    inputProps={{
                      style:{
                        direction: 'rtl',
                        textAlign: 'right',
                        fontSize:'1.3em',
                        width: '45%',
                        backgroundColor: 'white',
                        color: blue,
                        fontWeight: 400,
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <br />
                  <TextField
                    value={this.state.secondaryTitle}
                    onChange={(e) => this.setState({secondaryTitle: e.target.value})}
                    placeholder={'כותרת משנית'}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      fontSize:'1.3em',
                      marginTop: '0.5%',
                      width: '45%',
                      backgroundColor: 'white',
                      color: blue
                    }}
                    inputProps={{
                      style:{
                        direction: 'rtl',
                        textAlign: 'right',
                        fontSize:'1.3em',
                        width: '45%',
                        backgroundColor: 'white',
                        color: blue,
                        fontWeight: 400,
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <br /><br />
                  {/*<div style={{maxWidth: '60%'}}>
                  <RichTextEditor
                    value={this.state.text}
                    onChange={this.onChangeText}
                    toolbarConfig={toolbarConfig}
                    placeholder={'טקסט'}
                  /></div>*/}
                  <textarea
                    value={this.state.text}
                    onChange={(e) => this.setState({text: e.target.value})}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      fontSize:'1.3em',
                      marginTop: '0.5%',
                      width: '45%',
                      backgroundColor: 'white',
                      color: blue
                    }}
                    inputProps={{
                      style:{
                        direction: 'rtl',
                        textAlign: 'right',
                        fontSize:'1.3em',
                        width: '45%',
                        backgroundColor: 'white',
                        color: blue,
                        fontWeight: 400,
                        fontWeight: 'bold'
                      }
                    }}
                    rows={4}
                    placeholder={'טקסט כאן'}
                  ></textarea>

                  <br /><br />
                  {(this.state.slugAvailable && this.state.slug !== '' && this.state.businessName !== '' && this.state.phoneNumber !== '') ? (
                    <React.Fragment>
                      {this.validatePhoneNumber() ? (
                        <Button onClick={(e) => this.step1()} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%'}}>
                          {'<'} לשלב הבא
                        </Button>
                      ) : (
                        <Button onClick={(e) => alert('עלייך להזין מספר טלפון תקני')} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%'}}>
                          {'<'} לשלב הבא
                        </Button>
                      )}

                    </React.Fragment>
                  ) : (
                    <Button onClick={(e) => alert('אנא השלימו את כל הפרטים וודאו ששם העסק באנגלית אינו תפוס')} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%'}}>
                      {'<'} לשלב הבא
                    </Button>
                  )}

                  <br /><br />
                </div>
              </Grid>
            )}

            {/* SMS validation */}
            {this.state.step === 15 && (
              <Grid item xs={12} style={{overflowY: 'visiable'}}>
                <div style={{marginTop: '0%'}}>
                  {this.headerComponentBrowser()}
                </div>
                <center>
                  <p style={{textAlign: 'center', direction:'rtl', fontSize: '3em', fontWeight: 700, color: blue, marginTop: '-2%', marginBottom: 0}}>
                    שלב 1
                  </p>
                  <Button onClick={(e) => this.verifySMS()} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%'}}>
                    {'<'} לשלב הבא
                  </Button>
                </center>
                <div style={{textAlign :'center'}}>
                  <p style={{direction:'rtl', color: yellow, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    על מנת להמשיך, אנא הקש את הקוד שקיבלת בSMS
                  </p>
                  {this.state.codeWrong && (
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.3em', fontWeight: 700, marginBottom: 0, color: 'red'}}>
                      קוד שגוי
                    </p>
                  )}
                  <TextField
                    value={this.state.code}
                    onChange={(e) => this.setState({code: e.target.value})}
                    placeholder={'הקוד'}
                    style={{
                      direction: 'rtl',
                      textAlign: 'right',
                      fontSize:'1.3em',
                      marginTop: '0.5%',
                      width: '45%',
                      backgroundColor: 'white',
                      color: blue
                    }}
                    inputProps={{
                      style:{
                        direction: 'rtl',
                        textAlign: 'right',
                        fontSize:'1.3em',
                        width: '45%',
                        backgroundColor: 'white',
                        color: blue,
                        fontWeight: 400,
                        fontWeight: 'bold'
                      }
                    }}
                  />


                  <br /><br />
                    <Button onClick={(e) => this.verifySMS()} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%'}}>
                      {'<'} לשלב הבא
                    </Button>
                  <br /><br />

                  <Snackbar open={this.state.resentVerificationCode} autoHideDuration={6000} onClose={(e) => this.setState({resentVerificationCode: false})}>
                    <Alert variant="filled" severity="success">קוד נשלח אליכם</Alert>
                  </Snackbar>

                  <Button onClick={(e) => this.resendVerificationCode()} style={{color: yellow, backgroundColor: blue, fontSize: '1em', width: '20%'}}>
                    לא קיבלתי קוד - שלחו לי שוב
                  </Button>
                </div>
              </Grid>
            )}

            {this.state.step === 2 && (
              <Grid item container xs={12}>
              <Grid item xs={12}>

                <div style={{marginTop: '0%'}}>
                  {this.headerComponentBrowser()}
                </div>
                <center>
                  <br />
                  <br />
                  <p style={{textAlign: 'center', direction:'rtl', fontSize: '3em', fontWeight: 700, color: blue, marginTop: '-4%', marginBottom: '0%'}}>
                    שלב 2
                  </p>
                  <center>
                  <div style={{justifyContent: 'space-between', display: 'flex', padding: '1%'}}>
                  <Button onClick={(e) => this.setState({step: 3})} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%', marginLeft: '0%'}}>
                    {'<'} לשלב הבא
                  </Button>
                  <Button onClick={(e) => this.setState({step: 1})} style={{color: blue, backgroundColor: 'white', fontSize: '1.2em', width: '40%'}}>
                    לשלב הקודם {'>'}
                  </Button>
                  </div>
                  </center>
                </center>

                <center>
                  <div style={{backgroundColor: yellow, maxWidth: '50%'}}>
                    <p style={{color: blue, padding: '3%', fontSize: '2em'}}>בחר תבנית מהמאגר המדהים שלנו</p>
                  </div>
                </center>

                <br />

                <div style={{textAlign: 'center', width: '100%',}}>
                  <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {this.state.categories.reverse().map((c_) => {
                      let category = c_.name;
                      return (
                        <Button onClick={(e) => this.setState({categoryChosen: category})} style={{fontSize: '1em', backgroundColor: this.state.categoryChosen === category ? yellow : blue, color: 'white', borderRadius: 50, marginRight: '0.5%', marginLeft: '0.5%', marginBottom: '0.5%'}}>{category}</Button>
                      )
                    })}
                    <Button onClick={(e) => this.setState({categoryChosen: 'all'})} style={{fontSize: '1em', backgroundColor: this.state.categoryChosen === 'all' ? yellow : blue, color: 'white', borderRadius: 50, marginRight: '0.5%', marginLeft: '0.5%', marginBottom: '0.5%'}}>הכל</Button>

                  </div>
                </div>
                <br />


                </Grid>
                {this.state.templates.map((template) => {
                  if (this.state.categoryChosen === 'all' || this.state.categoryChosen === '') {
                    if (this.state.templateChosen === template._id.toString()) {
                      return (
                        <Grid item xs={4} style={{textAlign: 'center'}}>
                          <p>{template.name}</p>
                          <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', width: 200, height: 400, borderRadius: '3%', boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid item xs={4} style={{textAlign: 'center'}} onClick={(e) => this.updateTemplate(template._id)}>
                          <p>{template.name}</p>
                          <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', width: 200, height: 400, borderRadius: '3%', }} />
                        </Grid>
                      );
                    }
                  } else {
                    if (this.state.categoryChosen === template.category) {
                      if (this.state.templateChosen === template._id.toString()) {
                        return (
                          <Grid item xs={4} style={{textAlign: 'center'}}>
                            <p>{template.name}</p>
                            <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', width: 200, height: 400, borderRadius: '3%', boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid item xs={4} style={{textAlign: 'center'}} onClick={(e) => this.updateTemplate(template._id)}>
                            <p>{template.name}</p>
                            <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', width: 200, height: 400, borderRadius: '3%', }} />
                          </Grid>
                        );
                      }
                    }
                  }

                })}

                {/*this.state.templates.map((template) => {
                  if (this.state.templateChosen === template._id.toString()) {
                    return (
                      <Grid item xs={4} style={{textAlign: 'center'}}>
                        <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={4} style={{textAlign: 'center'}} onClick={(e) => this.updateTemplate(template._id)}>
                        <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 'auto', maxWidth: '90%', height: 400, borderRadius: '3%', }} />
                      </Grid>
                    );
                  }
                })*/}

                <Grid item xs={12}>
                <div style={{marginRight: '4%', textAlign :'right'}}>


                  <br /><br />
                  <div style={{justifyContent: 'space-between', display: 'flex'}}>
                  <Button onClick={(e) => this.setState({step: 3})} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%', marginLeft: '5%'}}>
                    {'<'} לשלב הבא
                  </Button>
                  <Button onClick={(e) => this.setState({step: 1})} style={{color: blue, backgroundColor: 'white', fontSize: '1.2em', width: '40%'}}>
                    לשלב הקודם {'>'}
                  </Button>
                  </div>
                  <br /><br />
                </div>
                </Grid>

              </Grid>
            )}

            {this.state.step === 3 && (
              <Grid item container xs={12}>
              <Grid item xs={12}>

                <div style={{marginTop: '0%'}}>
                  {this.headerComponentBrowser()}
                </div>
                <center>
                  <p style={{textAlign: 'center', direction:'rtl', fontSize: '3em', fontWeight: 700, color: blue, marginTop: '-2%'}}>
                    שלב 3
                  </p>
                </center>

                </Grid>


                <Grid item xs={12}>
                <div style={{marginRight: '4%', textAlign :'right', marginTop: '-2%'}}>
                <p style={{direction:'rtl', color: blue, fontSize: '2.3em', fontWeight: 700, marginBottom: '0%'}}>
                  בחר את התמונות שלך
                </p>
                <div>
                  {(this.state.logo !== undefined && this.state.logo !== null && this.state.logo === '') ? (
                    <button onClick={(e) => this.setState({uploadLogoModal: true})} style={{
                      backgroundColor: yellow,
                      color: blue,
                      border: '1px solid ' + yellow,
                      fontSize: '1.8em',
                    }}>לחצו להעלאת הלוגו</button>
                  ) : (
                    <React.Fragment>
                      <p style={{color: 'green', fontSize: '1.7em'}}>!הלוגו הועלה בהצלחה ✅</p>
                      <button onClick={(e) => this.setState({uploadLogoModal: true})} style={{
                        backgroundColor: yellow,
                        color: blue,
                        border: '1px solid ' + yellow,
                        fontSize: '1.8em',
                      }}>לחצו להחלפת הלוגו</button>
                      <br />
                      <br />
                      {(this.state.logo.includes('/gallery/') || this.state.logo.includes('/logos/') || this.state.logo.includes('/templates/') || this.state.logo.includes('app.dibit'))  ? (
                        <img src={this.state.cover} className="signup__mobile__image__logo"/>
                      ) : (
                        <img src={'https://app.dibit.co.il/logos/'+this.state.logo} className="signup__mobile__image__logo"/>
                      )}
                    </React.Fragment>
                  )}
                  {(
                    (this.state.logo !== undefined && this.state.logo !== null && this.state.logo === '')
                    && (this.state.logo !== undefined && this.state.logo !== null && this.state.logo === '')
                  ) && (
                    <React.Fragment><br /><br /></React.Fragment>
                  )}
                  {(this.state.cover === undefined || this.state.cover === null || this.state.cover === '') ? (
                    <button onClick={(e) => this.setState({uploadCoverModal: true})} style={{
                      backgroundColor: blue,
                      color: yellow,
                      border: '1px solid ' + yellow,
                      fontSize: '1.8em',
                    }}>לחצו להעלאת הקאבר</button>
                  ) : (
                    <React.Fragment>
                      <p style={{color: 'green', fontSize: '1.7em'}}>!תמונת הקאבר הועלתה בהצלחה ✅</p>
                      <button onClick={(e) => this.setState({uploadCoverModal: true})} style={{
                        backgroundColor: blue,
                        color: yellow,
                        border: '1px solid ' + yellow,
                        fontSize: '1.8em',
                      }}>לחצו להחלפת הקאבר</button>
                      <br />
                      <br />
                      {(this.state.cover.includes('/gallery/') || this.state.cover.includes('/logos/') || this.state.cover.includes('/templates/') || this.state.cover.includes('app.dibit'))  ? (
                        <img src={this.state.cover} className="signup__mobile__image__cover"/>
                      ) : (
                        <img src={'https://app.dibit.co.il/logos/'+this.state.cover} className="signup__mobile__image__cover"/>
                      )}
                    </React.Fragment>
                  )}
                </div>
                <Modal
                  open={this.state.uploadLogoModal}
                  onClose={(e) => this.setState({uploadLogoModal: false})}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{
                    backgroundColor: 'white',
                    border: '2px solid white',
                    padding: '5px 8px 6px',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                  }}>
                    {this.state.logoImageShowGalleryBool ? (
                      <React.Fragment>
                      <div style={{textAlign: 'right'}}>
                        <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700,}}>
                        בחרו תמונה מהגלרייה המדהימה שלנו
                        </p>

                        <div>
                          <p>חפש תמונה על פי מילה המגדירה אותה</p>

                          <button onClick={(e) => this.resetGallery()} style={{backgroundColor: 'white', color: blue, border: '1px solid ' + blue, fontSize: '1.5em'}}>איפוס</button>
                          &nbsp;
                          <button onClick={(e) => this.searchGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>חפש</button>
                          &nbsp;
                          <TextField
                            value={this.state.search_gallery}
                            onChange={(e) => this.setState({search_gallery: e.target.value})}
                            style={{width: '70%',
                            direction: 'rtl',
                            textAlign: 'right',}}
                            inputProps={{
                              style: {
                                direction: 'rtl',
                                textAlign: 'right',
                              }
                            }}
                          />
                          <br />
                          <br />
                        </div>
                      </div>
                      <Card style={{minWidth: '100%', height: 350, overflowY: 'auto'}}>
                      {this.state.gallery.map((image, i) => {
                        // console.log(i);
                        // console.log(image);
                        if (this.state.gallerySelectedLogoImage !== '') {
                          if (i % 3 !== 0 || i === 0) {
                            if (image._id.toString() === this.state.gallerySelectedLogoImage) {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                              )
                            } else {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                              )
                            }
                          } else {
                            if (image._id.toString() === this.state.gallerySelectedLogoImage) {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                  <br />
                                </React.Fragment>
                              )
                            } else {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                                  <br />
                                </React.Fragment>
                              )
                            }
                          }
                        } else {
                          if (i % 3 !== 0 || i === 0) {
                            if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                              )
                            } else {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                              )
                            }
                          } else {
                            if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                  <br />
                                </React.Fragment>
                              )
                            } else {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedLogoImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                                  <br />
                                </React.Fragment>
                              )
                            }
                          }
                        }
                      })}
                      </Card>
                      <br />
                      <div style={{textAlign: 'right'}}>
                        <button onClick={(e) => this.updateLogoFromGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>בחר ועדכן</button>
                      </div>
                      <p style={{fontSize: '1.3em', direciton: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                      <div style={{textAlign: 'right'}}>
                        <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}} onClick={(e) => this.setState({logoImageShowGalleryBool: false})}>או העלו תמונה משלכם</button>
                      </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <p style={{direction:'rtl', color: blue, fontSize: '1.7em', fontWeight: 700}}>
                          הלוגו שלכם
                        </p>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1.4em', fontWeight: 700}}>
                          בחרו את לוגו העסק או תמונה ייצוגית אחרת
                        </p>
                        {(this.state.logo !== null && this.state.logo !== undefined && this.state.logo !== '') && (this.state.imageEditing) && (
                          <center>
                            {(this.state.logo.includes('/gallery/') || this.state.logo.includes('/templates/')) ? (
                              <img src={this.state.logo} style={{maxWidth: '235px', height: 'auto', borderRadius: '50%', width: 190, height: 190}}/>
                            ) : (
                              <img src={'https://app.dibit.co.il/logos/'+this.state.logo} style={{maxWidth: '235px', height: 'auto', borderRadius: '50%', width: 190, height: 190}}/>
                            )}
                          </center>
                        )}

                        {src && (
                          <center>
                          <div style={{textAlign: 'center'}}>
                          <ReactCrop
                            src={src}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                            style={{
                              width: 300,
                              height: 'auto'
                            }}
                          />
                          </div>
                          </center>
                        )}

                        {this.state.loadingImage && (
                          <React.Fragment>
                            <center>
                              <p>מעלה את התמונה, אנא המתן</p>
                              <CircularProgress />
                            </center>
                          </React.Fragment>
                        )}

                        <form onSubmit={this.onFormSubmit}>
                          <br />
                          <div style={{textAlign: 'right'}}>
                          {(this.state.file !== undefined && this.state.file !== null && this.state.file !== '') && (
                            <React.Fragment>
                            <p style={{color: blue, fontSize: '1em', direction: 'rtl'}}>הקובץ שנבחר: {this.state.file.name}</p>
                            <button type="submit" style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>לסיום והעלאה לחץ/י כאן</button>
                            <br /><br />
                            </React.Fragment>
                          )}

                          <div class="upload-btn-wrapper">
                            <button class="btn">לחצו כאן לבחירת התמונה</button>
                            <input type="file" name="myImage" onChange={this.onChange} accept="image/*"/>
                          </div>

                          <p style={{fontSize: '1.3em', direciton: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                          </div>
                        </form>
                        <div style={{textAlign: 'right'}}>
                          <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em', textAlign: 'right'}} onClick={(e) => this.setState({logoImageShowGalleryBool: true})}>או בחר מתוך הגלרייה שלנו</button>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </Modal>
                <Snackbar open={this.state.uploadLogoModalSuccess} autoHideDuration={6000} onClose={(e) => this.setState({uploadLogoModalSuccess: false})}>
                  <Alert variant="filled" severity="success">!הלוגו הועלה בהצלחה ויתעדכן בהקדם בכרטיס הדיגיטלי שלך</Alert>
                </Snackbar>

                <Modal
                  open={this.state.uploadCoverModal}
                  onClose={(e) => this.setState({uploadCoverModal: false})}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{
                    backgroundColor: 'white',
                    border: '2px solid white',
                    padding: '2px 4px 3px',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    minWidth: isMobile ? 'auto' : 500,
                    minHeight: isMobile ? 'auto' : 400
                  }}>
                    {this.state.coverImageShowGalleryBool ? (
                      <React.Fragment>
                      <div style={{textAlign: 'right'}}>
                        <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700,}}>
                        בחרו תמונה מהגלרייה המדהימה שלנו
                        </p>

                        <div>
                          <p>חפש תמונה על פי מילה המגדירה אותה</p>

                          <button onClick={(e) => this.resetGallery()} style={{backgroundColor: 'white', color: blue, border: '1px solid ' + blue, fontSize: '1.5em'}}>איפוס</button>
                          &nbsp;
                          <button onClick={(e) => this.searchGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>חפש</button>
                          &nbsp;
                          <TextField
                            value={this.state.search_gallery}
                            onChange={(e) => this.setState({search_gallery: e.target.value})}
                            style={{width: '70%',
                            direction: 'rtl',
                            textAlign: 'right',}}
                            inputProps={{
                              style: {
                                direction: 'rtl',
                                textAlign: 'right',
                              }
                            }}
                          />
                          <br />
                          <br />
                        </div>
                      </div>
                      <Card style={{minWidth: '100%', height: 350, overflowY: 'auto'}}>
                      {this.state.gallery.map((image, i) => {
                        // console.log(i);
                        // console.log(image);
                        if (this.state.gallerySelectedCoverImage !== '') {
                          if (i % 3 !== 0 || i === 0) {
                            if (image._id.toString() === this.state.gallerySelectedCoverImage) {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                              )
                            } else {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                              )
                            }
                          } else {
                            if (image._id.toString() === this.state.gallerySelectedCoverImage) {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                  <br />
                                </React.Fragment>
                              )
                            } else {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                                  <br />
                                </React.Fragment>
                              )
                            }
                          }
                        } else {
                          if (i % 3 !== 0 || i === 0) {
                            if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                              )
                            } else {
                              return (
                                <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, }} />
                              )
                            }
                          } else {
                            if (image.selected || 'https://app.dibit.co.il/gallery/'+image.image === this.state.cover) {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5, boxShadow: 'rgb(65, 131, 215) 2px 4px 3px 0px, rgb(65, 131, 215) 0px 1px 2px 3px'}} />
                                  <br />
                                </React.Fragment>
                              )
                            } else {
                              return (
                                <React.Fragment>
                                  <img onClick={(e) => this.setState({gallerySelectedCoverImage: image._id})} src={'https://app.dibit.co.il/gallery/' + image.image} style={{width: 150, height: 150, marginRight: 5, marginLeft: 5,}} />
                                  <br />
                                </React.Fragment>
                              )
                            }
                          }
                        }
                      })}
                      </Card>
                      <br />
                      <div style={{textAlign: 'right'}}>
                        <button onClick={(e) => this.updateCoverFromGallery()} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>בחר ועדכן</button>
                      </div>
                      <p style={{fontSize: '1.3em', direciton: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                      <div style={{textAlign: 'right'}}>
                        <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}} onClick={(e) => this.setState({coverImageShowGalleryBool: false})}>או העלו תמונה משלכם</button>
                      </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                      <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700}}>
                        תמונת הרקע שלי
                      </p>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.4em', fontWeight: 700}}>
                        זוהי התמונה שתופיע מאחורי הלוגו שלך
                      </p>
                      {(this.state.cover !== '' && this.state.cover !== undefined && this.state.cover !== null) && (
                        <center>
                        {(this.state.cover.includes('/gallery/') || this.state.cover.includes('/templates/')) ? (
                          <img src={this.state.cover} style={{maxWidth: '60vh', maxHeight: '200px'}}/>
                        ) : (
                          <img src={'https://app.dibit.co.il/logos/'+this.state.cover} style={{maxWidth: '60vh', maxHeight: '200px'}}/>
                        )}
                        </center>
                      )}

                      {this.state.loadingImage && (
                        <React.Fragment>
                          <center>
                            <p>מעלה את התמונה, אנא המתן</p>
                            <CircularProgress />
                          </center>
                        </React.Fragment>
                      )}

                      <form onSubmit={this.onFormSubmitCover}>
                        <br />
                        <div style={{textAlign: 'right'}}>
                        <div class="upload-btn-wrapper">
                          <button class="btn">לחצו כאן לבחירת התמונה</button>
                          <input type="file" name="myImage" onChange={this.onChange} accept="image/*"/>
                        </div>

                        {(this.state.file !== undefined && this.state.file !== null && this.state.file !== '') && (
                          <React.Fragment>
                          <p style={{color: blue, fontSize: '1em', direction: 'rtl'}}>הקובץ שנבחר: {this.state.file.name}</p>
                          <button type="submit" style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>להעלאה לחץ/י כאן</button>
                          </React.Fragment>
                        )}

                        <p style={{fontSize: '1.3em', direciton: 'rtl', textAlign: 'right', color: blue}}>- או -</p>
                        </div>
                      </form>
                      <div style={{textAlign: 'right'}}>
                        <button style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em', textAlign: 'right'}} onClick={(e) => this.setState({coverImageShowGalleryBool: true})}>או בחר מתוך הגלרייה שלנו</button>
                      </div>
                      </React.Fragment>
                    )}

                  </div>
                </Modal>
                <Snackbar open={this.state.uploadCoverModalSuccess} autoHideDuration={6000} onClose={(e) => this.setState({uploadCoverModalSuccess: false})}>
                  <Alert variant="filled" severity="success">!תמונת הרקע שלכם התעדכנה בהצלחה ותעודכן בכרטיס הדיגיטלי שלכם בהקדם</Alert>
                </Snackbar>

                  <br /><br />
                  <div style={{justifyContent: 'space-between', display: 'flex'}}>
                  <Button onClick={(e) => this.finalStep()} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%', marginLeft: '5%'}}>
                    {'<'} לשלב הבא
                  </Button>
                  <Button onClick={(e) => this.setState({step: 2})} style={{color: blue, backgroundColor: 'white', fontSize: '1.2em', width: '40%'}}>
                    לשלב הקודם {'>'}
                  </Button>
                  </div>
                  <br /><br />
                </div>
                </Grid>

              </Grid>
            )}


            {this.state.step === 4 && (
              <Grid item container xs={12}>
              <Grid item xs={12}>

                <div style={{marginTop: '0%'}}>
                  {this.headerComponentBrowser()}
                </div>
                <center>
                  <p style={{textAlign: 'center', direction:'rtl', fontSize: '3em', fontWeight: 700, color: blue, marginTop: '-2%'}}>
                    סיימנו!
                  </p>
                  <p style={{textAlign: 'center', direction:'rtl', fontSize: '2em', fontWeight: 700, color: blue, marginTop: '2%', marginBottom: '1%'}}>
                    מוכנים? תתחדשו יש לכם <span style={{color: yellow}}>דיביט!</span>
                  </p>
                  <center>
                  <div style={{justifyContent: 'space-between', display: 'flex', marginBottom: '1%', padding: '1%'}}>
                  <Button onClick={(e) => window.location.href='/'} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%', marginLeft: '0%'}}>
                    {'<'} לסיום
                  </Button>
                  <Button onClick={(e) => this.setState({step: 3})} style={{color: blue, backgroundColor: 'white', fontSize: '1.2em', width: '40%'}}>
                    לשלב הקודם {'>'}
                  </Button>
                  </div>
                  </center>

                  <Button onClick={(e) => window.open('https://dibit.co.il/'+this.state.slug)} style={{color: blue, backgroundColor: yellow, fontSize: '1.6em', width: '90%'}}>
                    !לכניסה לכרטיס שלכם לחצו כאן
                  </Button>
                  <br />
                  <center><ArrowDownwardIcon style={{color: blue, fontSize: '5em', textAlign: 'center'}}/></center>
                </center>
                <div style={{justifyContent: 'space-between', display: 'flex'}}>
                <Button onClick={(e) => window.location.href='/card/edit'} style={{color: blue, backgroundColor: yellow, fontSize: '1.7em', width: '40%', marginLeft: '7%'}}>
                  לעריכת הכרטיס
                  לחצו כאן
                </Button>
                <Button onClick={(e) => window.open('https://wa.me/?text='+encodeURI('https://dibit.co.il/'+this.state.slug))} style={{color: blue, backgroundColor: yellow, fontSize: '1.7em', width: '40%', marginRight: '7%'}}>
                  רוצים להשוויץ?
                  תשלחו לחברים
                </Button>
                </div>

                </Grid>

                <Grid item xs={12}>
                <div style={{marginRight: '4%', textAlign :'right'}}>


                  <br /><br />
                  <div style={{justifyContent: 'space-between', display: 'flex'}}>
                  <Button onClick={(e) => window.location.href='/'} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%', marginLeft: '5%'}}>
                    {'<'} לסיום
                  </Button>
                  <Button onClick={(e) => this.setState({step: 3})} style={{color: blue, backgroundColor: 'white', fontSize: '1.2em', width: '40%'}}>
                    לשלב הקודם {'>'}
                  </Button>
                  </div>
                  <br /><br />
                </div>
                </Grid>

              </Grid>
            )}
            <Grid item xs={12}>
              <div>
                <center><p style={{direction:'rtl', color: blue, fontSize: '1em', fontWeight: 100}}>
                  כל הזכויות שמורות לדיביט בע"מ, 2020
                </p></center>
              </div>
            </Grid>
          </Grid>
          </BrowserView>
          </React.Fragment>
        )}

      </div>
    );
  }
}

export default Signup;
