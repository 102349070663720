import React, { Component } from 'react';
import '../../App.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import Alert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import { SliderPicker, ChromePicker } from 'react-color'

import Switch from '@material-ui/core/Switch';
import LinkIcon from '@material-ui/icons/Link';
import OfflineHeader from '../../components/OfflineHeader';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook';
import TimelineIcon from '@material-ui/icons/Timeline';
import GoogleLogin from 'react-google-login';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Chart } from 'react-charts'
import Snackbar from '@material-ui/core/Snackbar';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {
  isMobile
} from "react-device-detect";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';


const cookies = new Cookies();
const API = 'https://api.dibit.co.il/v1/';
const blue = '#07132D';
const yellow = '#FEBE36';

const theme = createMuiTheme({
  overrides: {
    MuiGridListTile: {
      // Name of the rule
      size: {
        // Some CSS
        width: 130,
        height: '100%'
      },
    },
  },
});

class NewCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      secondaryTitle: '',
      text: '',
      phoneNumber: '',
      facebookLink: '',
      instagramLink: '',
      address: '',
      businessName: '',
      loading: false,
      button_1: '',
      button_1_icon: '',
      button_1_link: '',
      button_2: '',
      button_2_icon: '',
      button_2_link: '',
      button_3: '',
      button_3_icon: '',
      button_3_link: '',
      button_4: '',
      button_4_icon: '',
      button_4_link: '',
      button_5: '',
      button_5_icon: '',
      button_5_link: '',
      button_6: '',
      button_6_icon: '',
      button_6_link: '',
      button_7: '',
      button_7_icon: '',
      button_7_link: '',
      button_8: '',
      button_8_icon: '',
      button_8_link: '',
      button_9: '',
      button_9_icon: '',
      button_9_link: '',
      buttonShownIndex: 3,
      openingHours: {
        'A': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'B': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'C': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'D': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'E': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'F': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
        'G': ['Sat Sep 12 2020 20:35:13 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 14:27:31 GMT+0300 (Israel Daylight Time)'],
      },
      close: {
        'A': false,
        'B': false,
        'C': false,
        'D': false,
        'E': false,
        'F': false,
        'G': false,
      },
      open24h: false,
      slug: '',
      slugOriginal: '',
      slugAvailable: true,
      templateId: '',
      templates: [],
      uploadLogoModal: false,
      uploadLogoModalSuccess: false,
      uploadCoverModal: false,
      uploadCoverModalSuccess: false,
      changeTemplateModal: false,
      uploadGalleryModal: false,
      uploadGalleryModalSuccess: false,
      template: {
        name: '',
        _id: '',
        description: '',
        coverImage: ''
      },

    };
  }

  componentDidMount() {
    this.getMyCard();
    this.getTemplates();
  }

  getTemplates = () => {
    this.setState({loading: true});
    axios.get(API + 'templates').then(response => {
      if (response.data.status === "ok") {
        this.setState({templates: response.data.data.templates});
      }
      this.setState({loading: false});
    }).catch(err => {
      console.log(err);
      this.setState({loading: false});
    });
  }

  checkSlug = (slug) => {
    slug = slug.replace(' ', '');
    if (slug.match("^[a-zA-Z0-9]+$") !== null) {
      this.setState({slug: slug}, () => {
        axios.get(API + 'card/slug?slug='+slug).then(response => {
          if (response.data.status === "ok") {
            this.setState({slugAvailable: response.data.available});
          }
        }).catch(err => {
          console.log(err);
        });
      });
    } else {
      this.setState({slug: slug, slugAvailable: false});
    }
  }

  is24hours = () => {
    this.setState({loading: true});
    let openingHours = this.state.openingHours;

    let a1 = new Date(openingHours['A'][0]).getHours() + ':' + new Date(openingHours['A'][0]).getMinutes();
    let a2 = new Date(openingHours['A'][1]).getHours() + ':' + new Date(openingHours['A'][1]).getMinutes();

    let b1 = new Date(openingHours['B'][0]).getHours() + ':' + new Date(openingHours['B'][0]).getMinutes();
    let b2 = new Date(openingHours['B'][1]).getHours() + ':' + new Date(openingHours['B'][1]).getMinutes();

    let c1 = new Date(openingHours['C'][0]).getHours() + ':' + new Date(openingHours['C'][0]).getMinutes();
    let c2 = new Date(openingHours['C'][1]).getHours() + ':' + new Date(openingHours['C'][1]).getMinutes();

    let d1 = new Date(openingHours['D'][0]).getHours() + ':' + new Date(openingHours['D'][0]).getMinutes();
    let d2 = new Date(openingHours['D'][1]).getHours() + ':' + new Date(openingHours['D'][1]).getMinutes();

    let e1 = new Date(openingHours['E'][0]).getHours() + ':' + new Date(openingHours['E'][0]).getMinutes();
    let e2 = new Date(openingHours['E'][1]).getHours() + ':' + new Date(openingHours['E'][1]).getMinutes();

    let f1 = new Date(openingHours['F'][0]).getHours() + ':' + new Date(openingHours['F'][0]).getMinutes();
    let f2 = new Date(openingHours['F'][1]).getHours() + ':' + new Date(openingHours['F'][1]).getMinutes();

    let g1 = new Date(openingHours['G'][0]).getHours() + ':' + new Date(openingHours['G'][0]).getMinutes();
    let g2 = new Date(openingHours['G'][1]).getHours() + ':' + new Date(openingHours['G'][1]).getMinutes();
    if (
      ((a1 === '0:0' || a1 === '00:00') && (a2 === '0:0' || a2 === '00:00')) &&
      ((b1 === '0:0' || b1 === '00:00') && (b2 === '0:0' || b2 === '00:00')) &&
      ((c1 === '0:0' || c1 === '00:00') && (c2 === '0:0' || c2 === '00:00')) &&
      ((d1 === '0:0' || d1 === '00:00') && (d2 === '0:0' || d2 === '00:00')) &&
      ((e1 === '0:0' || e1 === '00:00') && (e2 === '0:0' || e2 === '00:00')) &&
      ((f1 === '0:0' || f1 === '00:00') && (f2 === '0:0' || f2 === '00:00')) &&
      ((g1 === '0:0' || g1 === '00:00') && (g2 === '0:0' || g2 === '00:00'))
    ) {
      this.setState({open24h: true, loading: false});
    } else {
      this.setState({open24h: false, loading: false});
    }
  }

  remove_button = (btn) => {
    let index = this.state.buttonShownIndex;

    if (index === 4) this.setState({button_4: ''});
    if (index === 5) this.setState({button_5: ''});
    if (index === 6) this.setState({button_6: ''});
    if (index === 7) this.setState({button_7: ''});
    if (index === 8) this.setState({button_8: ''});
    if (index === 9) this.setState({button_9: ''});

    index -= 1;

    this.setState({buttonShownIndex: index});
  }

  setOpen24H = () => {
    if (this.state.open24h) {
      this.setState({open24h: false});
    } else {
      this.setState({
        openingHours: {
          'A': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'B': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'C': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'D': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'E': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'F': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
          'G': ['Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 00:00:00 GMT+0300 (Israel Daylight Time)'],
        },
        open24h: true
      });
    }
  }

  getOpeningHours = (day, type = 'from') => {
    let days = this.state.openingHours;

    if (days[day] === undefined) {
      days[day] = ['Sat Sep 12 2020 09:00:00 GMT+0300 (Israel Daylight Time)', 'Sat Sep 12 2020 20:00:00 GMT+0300 (Israel Daylight Time)']

      this.setState({openingHours: days}, () => {
        if (type === 'from') {
          return new Date(days[day][0]);
        } else {
          return new Date(days[day][1]);
        }
      })
    } else {
      if (type === 'from') {
        return new Date(days[day][0]);
      } else {
        return new Date(days[day][1]);
      }
    }
  }

  setOpeningHours = (day, type = 'from', time) => {
    let days = this.state.openingHours;
    let dayTimes = days[day];

    if (type === 'from') {
      dayTimes[0] = time;
    } else {
      dayTimes[1] = time;
    }

    days[day] = dayTimes;

    this.setState({openingHours: days});
  }

  getMyCard = () => {
    this.setState({loading: true});

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      try {
        return axios.get(API + 'card', {
          headers: {
             Authorization: `Bearer ${token}`
          }
        }).then(response => {
          //console.log(response);
          if (response.data.status === "ok") {
            this.setState({
              slug: response.data.data.card.slug,
              logo: response.data.data.card.logo,
              cover: response.data.data.card.cover,
            });
          }
        }).catch(err => {
          this.setState({loading: true});
          console.log(err);
          window.location.reload();
        });
      } catch (err) {
        this.setState({loading: true});
        console.log(err);
        window.location.reload();
      }
    }
  }

  updateCard = () => {
    this.setState({errorAlert: false, error: ''});

    let payload = {
      title: this.state.title,
      secondaryTitle: this.state.secondaryTitle,
      text: this.state.text,
      phoneNumber: this.state.phoneNumber,
      facebookLink: this.state.facebookLink,
      instagramLink: this.state.instagramLink,
      address: this.state.address,
      video: this.state.video,
      button_1: this.state.button_1,
      button_1_icon: this.state.button_1_icon,
      button_1_link: this.state.button_1_link,
      button_2: this.state.button_2,
      button_2_icon: this.state.button_2_icon,
      button_2_link: this.state.button_2_link,
      button_3: this.state.button_3,
      button_3_icon: this.state.button_3_icon,
      button_3_link: this.state.button_3_link,
      button_4: this.state.button_4,
      button_4_icon: this.state.button_4_icon,
      button_4_link: this.state.button_4_link,
      button_5: this.state.button_5,
      button_5_icon: this.state.button_5_icon,
      button_5_link: this.state.button_5_link,
      button_6: this.state.button_6,
      button_6_icon: this.state.button_6_icon,
      button_6_link: this.state.button_6_link,
      button_7: this.state.button_7,
      button_7_icon: this.state.button_7_icon,
      button_7_link: this.state.button_7_link,
      button_8: this.state.button_8,
      button_8_icon: this.state.button_8_icon,
      button_8_link: this.state.button_8_link,
      button_9: this.state.button_9,
      button_9_icon: this.state.button_9_icon,
      button_9_link: this.state.button_9_link,
      businessName: this.state.businessName,
      openingHours: this.state.openingHours,
      slug: this.state.slug,
      templateId: this.state.templateId
    }

    if (
      this.state.slug !== ''
    ) {
      let token = cookies.get('__dato__');

      if (token !== null && token !== undefined && token !== '') {
        try {
          axios.put(API + 'card', payload, {
            headers: {
               Authorization: `Bearer ${token}`
            }
          }).then(response => {
            if (response.data.status === "ok") {
              alert('הכרטיס עודכן בהצלחה');
              window.location.href='/';
            } else {
              alert('חלה שגיאה, אנא נסו שוב.')
            }
          }).catch(err => {
            console.log(err);
            alert('חלה שגיאה, אנא נסו שוב.')
          });
        } catch (err) {
          console.log(err);
          alert('חלה שגיאה, אנא נסו שוב.')
        }
      }
    } else {
      this.setState({errorAlert: true, error: 'יש למלא את כל השדות על מנת ליצור את הכרטיס'});

      if (this.state.title === '') this.setState({errortitle: true});
      if (this.state.secondaryTitle === '') this.setState({errorsecondarytitle: true});
      if (this.state.text === '') this.setState({errortext: true});
      if (this.state.phoneNumber === '') this.setState({errorphoneNumber: true});
      if (this.state.facebookLink === '') this.setState({errorfacebookLink: true});
      if (this.state.instagramLink === '') this.setState({errorinstagramLink: true});
      if (this.state.address === '') this.setState({erroraddress: true});
      if (this.state.businessName === '') this.setState({errorbusinessName: true});

    }
  }

  addButtonIndex = () => {
    let index = this.state.buttonShownIndex;

    index += 1;

    this.setState({buttonShownIndex: index});
  }



  updateTemplate = (id) => {

    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      axios.put(API + 'card/template?id='+id, {}, {
        headers: {
           Authorization: `Bearer ${token}`
        }
      }).then(response => {
          this.getMyCardTemplate();
          this.setState({changeTemplateModal: false});
      }).catch((error) => {
        this.setState({changeTemplateModal: false});
      });
    }
  }

  getMyCardTemplate = () => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      axios.get(API + 'card', {
        headers: {
           Authorization: `Bearer ${token}`
        }
      }).then(response => {
          this.setState({templateId: response.data.data.card.templateId, template: response.data.data.template});
      }).catch((error) => {
        this.setState({changeTemplateModal: false});
      });
    }
  }


  getMyCardImages = () => {
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {
      axios.get(API + 'card', {
        headers: {
           Authorization: `Bearer ${token}`
        }
      }).then(response => {
        //console.log(response);
          this.setState({logo: response.data.data.card.logo, cover: response.data.data.cover});
      }).catch((error) => {

      });
    }
  }



  onFormSubmitCover = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.file);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'card/cover',formData,config)
          .then((response) => {
            this.setState({file: null, uploadCoverModal: false, uploadCoverModalSuccess: true})
            // window.location.reload();
            this.getMyCardImages();
          }).catch((error) => {
            this.setState({file: null, uploadCoverModal: false})
      });
    }
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.file);
    let token = cookies.get('__dato__');

    if (token !== null && token !== undefined && token !== '') {

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
          }
      };
      axios.put(API + 'card/logo',formData,config)
          .then((response) => {
            this.setState({file: null, uploadLogoModal: false, uploadLogoModalSuccess: true})
            // window.location.reload();
            this.getMyCardImages();
          }).catch((error) => {
            this.setState({file: null, uploadLogoModal: false})
      });
    }
  }

  onChange = (e) => {
      this.setState({file:e.target.files[0]});
  }

  close = (t) => {
    let dict = this.state.close;

    if (dict[t]) {
      dict[t] = false
    } else {
      dict[t] = true
    }

    let openingHours = this.state.openingHours;

    openingHours[t] = ['', ''];

    this.setState({close: dict, openingHours: openingHours});
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OfflineHeader />
            </Grid>
            <Grid item xs={12}>
              <center>
                <div style={{textAlign: 'center', marginTop: '5%'}}>
                  <CircularProgress />
                  <h3 style={{direciton: 'rtl'}}>..טוען</h3>
                </div>
              </center>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OfflineHeader mobile={isMobile}/>
            </Grid>
            <Grid item xs={12} style={{marginBottom: '2%'}}>
              <div style={{marginRight: '4%'}}>
                <p style={{direction:'rtl', fontSize: '3em', fontWeight: 700, color: yellow, marginBottom: -50}}>
                  יצירת הכרטיס שלי
                </p>
              </div>
            </Grid>
            <Grid item xs={12} style={{marginBottom: '2%'}}>
              <div style={{marginRight: '4%', textAlign :'right'}}>
                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  התמונות שלי
                </p>
                <button onClick={(e) => this.setState({uploadLogoModal: true})} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.2em'}}>{this.state.logo === '' ? 'העלאת הלוגו' : 'עדכון הלוגו'}</button>
                &nbsp; או &nbsp;
                <button onClick={(e) => this.setState({uploadCoverModal: true})} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.2em'}}>העלאת תמונת רקע</button>

                <Modal
                  open={this.state.uploadLogoModal}
                  onClose={(e) => this.setState({uploadLogoModal: false})}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{
                    backgroundColor: 'white',
                    border: '2px solid white',
                    padding: '5px 8px 6px',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                  }}>
                    <p style={{direction:'rtl', color: blue, fontSize: '1.7em', fontWeight: 700, marginBottom: '-10%'}}>
                      הלוגו שלכם
                    </p>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.4em', fontWeight: 700, marginBottom: 0}}>
                      בחרו את לוגו העסק או תמונה ייצוגית אחרת
                    </p>
                    {(this.state.logo !== null && this.state.logo !== undefined && this.state.logo !== '') ? (
                      <center><img src={'https://app.dibit.co.il/logos/'+this.state.logo} style={{maxWidth: '235px', height: 'auto', borderRadius: '50%'}}/></center>
                    ) : (
                      <center>
                        <p style={{color: 'red'}}>* שימו לב: אם כבר העלתם תמונה והיא אינה מופיעה כאן, אנא נסו בשנית בעוד מספר דק', לעיתים לדפדפן לוקח זמן לעדכן תמונה</p>
                        <button onClick={(e) => window.location.reload()} style={{backgroundColor: yellow, color: blue, border: '1px solid' + yellow, fontSize: '1.1em'}}>לביצוע ריפרש וטעינה מחדש של התמונה, אנא לחצו כאן</button>
                      </center>
                    )}

                    <form onSubmit={this.onFormSubmit}>
                      <br />
                      <div style={{textAlign: 'right'}}>
                      <div class="upload-btn-wrapper">
                        <button class="btn">לחצו כאן לבחירת התמונה</button>
                        <input type="file" name="myImage" onChange={this.onChange} accept="image/*"/>
                      </div>

                      {(this.state.file !== undefined && this.state.file !== null && this.state.file !== '') && (
                        <React.Fragment>
                        <p style={{color: blue, fontSize: '1em', direction: 'rtl'}}>הקובץ שנבחר: {this.state.file.name}</p>
                        <button type="submit" style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>להעלאה לחץ/י כאן</button>
                        </React.Fragment>
                      )}

                      </div>
                    </form>
                  </div>
                </Modal>
                <Snackbar open={this.state.uploadLogoModalSuccess} autoHideDuration={6000} onClose={(e) => this.setState({uploadLogoModalSuccess: false})}>
                  <Alert variant="filled" severity="success">!הלוגו הועלה בהצלחה ויתעדכן בהקדם בכרטיס הדיגיטלי שלך</Alert>
                </Snackbar>

                <Modal
                  open={this.state.uploadCoverModal}
                  onClose={(e) => this.setState({uploadCoverModal: false})}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{
                    backgroundColor: 'white',
                    border: '2px solid white',
                    padding: '2px 4px 3px',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                  }}>
                    <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: '-5%'}}>
                      תמונת הרקע שלי
                    </p>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.4em', fontWeight: 700, marginBottom: 0}}>
                      זוהי התמונה שתופיע מאחורי הלוגו שלך
                    </p>
                    {(this.state.cover !== '' && this.state.cover !== undefined && this.state.cover !== null) ? (
                      <center>
                      <img src={'https://app.dibit.co.il/logos/'+this.state.cover} style={{maxWidth: '60vh', maxHeight: '200px'}}/>
                      </center>
                    ) : (
                      <center>
                        <p style={{color: 'red'}}>* שימו לב: אם כבר העלתם תמונה והיא אינה מופיעה כאן, אנא נסו בשנית בעוד מספר דק', לעיתים לדפדפן לוקח זמן לעדכן תמונה</p>
                        <button onClick={(e) => window.location.reload()} style={{backgroundColor: yellow, color: blue, border: '1px solid' + yellow, fontSize: '1.1em'}}>לביצוע ריפרש וטעינה מחדש של התמונה, אנא לחצו כאן</button>
                      </center>
                    )}
                    <form onSubmit={this.onFormSubmitCover}>
                      <br />
                      <div style={{textAlign: 'right'}}>
                      <div class="upload-btn-wrapper">
                        <button class="btn">לחצו כאן לבחירת התמונה</button>
                        <input type="file" name="myImage" onChange={this.onChange} accept="image/*"/>
                      </div>

                      {(this.state.file !== undefined && this.state.file !== null && this.state.file !== '') && (
                        <React.Fragment>
                        <p style={{color: blue, fontSize: '1em', direction: 'rtl'}}>הקובץ שנבחר: {this.state.file.name}</p>
                        <button type="submit" style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.5em'}}>להעלאה לחץ/י כאן</button>
                        </React.Fragment>
                      )}
                      </div>
                    </form>
                  </div>
                </Modal>
                <Snackbar open={this.state.uploadCoverModalSuccess} autoHideDuration={6000} onClose={(e) => this.setState({uploadCoverModalSuccess: false})}>
                  <Alert variant="filled" severity="success">!תמונת הרקע שלכם התעדכנה בהצלחה ותעודכן בכרטיס הדיגיטלי שלכם בהקדם</Alert>
                </Snackbar>


                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  תבנית הכרטיס שלי <span style={{color: 'red'}}>*</span>
                </p>

                {(this.state.templateId === '' || this.state.templateId === null || this.state.templateId === undefined) ? (
                  <React.Fragment>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1em'}}>אין לך תבנית עדיין, ללא תבנית לא נוכל ליצור את הכרטיס שלך!</p>
                    <button onClick={(e) => this.setState({changeTemplateModal: true})} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.2em'}}>לבחירת תבנית לחצו כאן</button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1em', marginBottom: -15}}>התבנית שלך:</p>
                    <br />
                    <img src={('https://app.dibit.co.il/templates/'+ this.state.template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} style={{width: 150, height: 300}} />
                    <br />
                    <button onClick={(e) => this.setState({changeTemplateModal: true})} style={{backgroundColor: yellow, color: blue, border: '1px solid ' + yellow, fontSize: '1.2em'}}>לבחירת תבנית לחצו כאן</button>
                  </React.Fragment>
                )}

                {/*<Select
                  value={this.state.templateId}
                  onChange={(e) => this.setState({templateId: e.target.value})}
                  style={{
                    width: '50%', textAlign: 'right', direciton: 'rtl'
                  }}
                >
                  {this.state.templates.map((index) => {
                    return (
                      <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={index._id}>{index.name}</MenuItem>
                    );
                  })}
                </Select>*/}

                <Modal
                  open={this.state.changeTemplateModal}
                  onClose={(e) => this.setState({changeTemplateModal: false})}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{
                    backgroundColor: 'white',
                    border: '2px solid white',
                    padding: '2px 4px 3px',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                  }}>
                    <div style={{color: blue, backgroundColor: yellow}}>
                      <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0,}}>
                      &nbsp;  בחרו תבנית מהמאגר המדהים שלנו &nbsp;&nbsp;
                      </p>
                    </div><br />
                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', overflow: 'hidden'}}>
                      {/*<GridList style={{flexWrap: 'nowrap', transform: 'translateZ(0)'}} cols={2.5}>

                        {this.state.templates.map((template) => (
                            <GridListTile key={template._id} onClick={(e) => this.updateTemplate(template._id)}>
                            {template.coverImage.includes('http') ? (
                              <img src={template.coverImage.replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} alt={template.name} onClick={(e) => this.updateTemplate(template._id)} className={(this.state.templateId === template._id.toString()) ? 'halImg' : ''}/>
                            ) : (
                              <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} alt={template.name} onClick={(e) => this.updateTemplate(template._id)} className={(this.state.templateId === template._id.toString()) ? 'halImg' : ''}/>
                            )}
                            </GridListTile>
                        ))}
                      </GridList>*/}
                      <div className="carousel-outer">
                        <Carousel showThumbs={false} style={{backgroundColor: 'transparent'}}>
                        {this.state.templates.map((template) => {
                          return (
                            <div onClick={(e) => this.updateTemplate(template._id)}>
                                {template.coverImage.includes('http') ? (
                                  <React.Fragment>
                                    <p>{template.name}</p>
                                    <img src={template.coverImage.replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} alt={template.name} onClick={(e) => this.updateTemplate(template._id)} className={(this.state.templateId === template._id.toString()) ? 'halImg' : ''} style={{width: 150, height: 400}}/>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <p>{template.name}</p>
                                    <img src={('https://app.dibit.co.il/templates/'+template.coverImage).replace('https://app.dibit.co.il/templates/https://app.dibit.co.il/templates/', 'https://app.dibit.co.il/templates/')} alt={template.name} onClick={(e) => this.updateTemplate(template._id)} className={(this.state.templateId === template._id.toString()) ? 'halImg' : ''} style={{width: 150, height: 400}}/>
                                  </React.Fragment>
                                )}
                            </div>
                          )
                        })}
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </Modal>

                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  שם העסק באנגלית <span style={{color: 'red'}}>*</span>
                </p>
                <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                  ללא רווחים, לדוגמה moshe-design
                </p>
                {(!this.state.slugAvailable && this.state.slugOriginal !== this.state.slug) && (
                  <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                    שם זה תפוס, אנא בחר אחד אחר או הוסף לו תווים
                  </p>
                )}
                <TextField
                  value={this.state.slug}
                  onChange={(e) => this.checkSlug(e.target.value)}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '50%',
                    fontSize:'1.3em',
                    marginTop: '0.5%'
                  }}
                />
                <br />

                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  שם העסק <span style={{color: 'red'}}>*</span>
                </p>
                {this.state.errorbusinessName && (
                  <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                    זהו שדה חובה!
                  </p>
                )}
                <TextField
                  value={this.state.businessName}
                  onChange={(e) => this.setState({businessName: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '50%',
                    fontSize:'1.3em',
                    marginTop: '0.5%'
                  }}
                />

                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  כותרת ראשית
                </p>
                {this.state.errortitle && (
                  <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                    זהו שדה חובה!
                  </p>
                )}
                <TextField
                  value={this.state.title}
                  onChange={(e) => this.setState({title: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '50%',
                    fontSize:'1.3em',
                    marginTop: '0.5%'
                  }}
                />

                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  כותרת משנית
                </p>
                {this.state.errorsecondarytitle && (
                  <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                    זהו שדה חובה!
                  </p>
                )}
                <TextField
                  value={this.state.secondaryTitle}
                  onChange={(e) => this.setState({secondaryTitle: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '50%',
                    fontSize:'1.3em',
                    marginTop: '0.5%'
                  }}
                />

                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  טקסט הכרטיס
                </p>
                {this.state.errortext && (
                  <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                    זהו שדה חובה!
                  </p>
                )}
                <TextField
                  value={this.state.text}
                  onChange={(e) => this.setState({text: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '50%',
                    fontSize:'1.3em',
                    marginTop: '0.5%'
                  }}
                />

                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  מספר הטלפון המקושר לכרטיס <span style={{color: 'red'}}>*</span>
                </p>
                {this.state.errorphoneNumber && (
                  <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                    זהו שדה חובה!
                  </p>
                )}
                <TextField
                  value={this.state.phoneNumber}
                  onChange={(e) => this.setState({phoneNumber: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '50%',
                    fontSize:'1.3em',
                    marginTop: '0.5%'
                  }}
                />

                <div>
                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    שעות הפעילות של העסק <span style={{color: 'red'}}>*</span>
                  </p>
                  <Checkbox
                    value={this.state.open24h}
                    checked={this.state.open24h}
                    onChange={(e) => this.setOpen24H()}
                    color={yellow}
                  /> פתוח 24/7
                  <br />

                  {!this.state.open24h && (
                    <React.Fragment>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                      יום ראשון
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                          <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                            <span>סגור ביום זה <Checkbox
                              value={this.state.close['A']}
                              checked={this.state.close['A']}
                              onChange={(e) => this.close('A')}
                              color={yellow}
                              style={{color: blue}}
                            /></span>
                            &nbsp;
                            {this.state.close['A'] && (
                              <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                            )}
                          </div>
                          {!this.state.close['A'] && (
                            <div>
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="עד שעה"
                                value={this.getOpeningHours('A', 'to')}
                                onChange={(time) => this.setOpeningHours('A', 'to', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                              &nbsp;&nbsp;
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="משעה"
                                value={this.getOpeningHours('A', 'from')}
                                onChange={(time) => this.setOpeningHours('A', 'from', time)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                ampm={false}
                              />
                            </div>
                          )}

                        </div>
                    </MuiPickersUtilsProvider>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                      יום שני
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                        <span>סגור ביום זה <Checkbox
                          value={this.state.close['B']}
                          checked={this.state.close['B']}
                          onChange={(e) => this.close('B')}
                          color={yellow}
                          style={{color: blue}}
                        /></span>
                        &nbsp;
                        {this.state.close['B'] && (
                          <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                        )}
                        </div>

                        {!this.state.close['B'] && (
                          <div>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="עד שעה"
                            value={this.getOpeningHours('B', 'to')}
                            onChange={(time) => this.setOpeningHours('B', 'to', time)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            ampm={false}
                          />
                          &nbsp;&nbsp;
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="משעה"
                            value={this.getOpeningHours('B', 'from')}
                            onChange={(time) => this.setOpeningHours('B', 'from', time)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            ampm={false}
                          />
                          </div>
                        )}
                      </div>
                    </MuiPickersUtilsProvider>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                      יום שלישי
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                          <span>סגור ביום זה <Checkbox
                            value={this.state.close['C']}
                            checked={this.state.close['C']}
                            onChange={(e) => this.close('C')}
                            color={yellow}
                            style={{color: blue}}
                          /></span>
                          &nbsp;
                          {this.state.close['C'] && (
                            <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                          )}
                        </div>
                        {!this.state.close['C'] && (
                          <div>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="עד שעה"
                            value={this.getOpeningHours('C', 'to')}
                            onChange={(time) => this.setOpeningHours('C', 'to', time)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            ampm={false}
                          />
                          &nbsp;&nbsp;
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="משעה"
                            value={this.getOpeningHours('C', 'from')}
                            onChange={(time) => this.setOpeningHours('C', 'from', time)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            ampm={false}
                          />
                          </div>
                        )}
                      </div>
                    </MuiPickersUtilsProvider>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                      יום רביעי
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                        <span>סגור ביום זה <Checkbox
                          value={this.state.close['D']}
                          onChange={(e) => this.close('D')}
                          checked={this.state.close['D']}
                          color={yellow}
                          style={{color: blue}}
                        /></span>
                        &nbsp;
                        {this.state.close['D'] && (
                          <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                        )}
                        </div>

                        {!this.state.close['D'] && (
                          <div>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="עד שעה"
                            value={this.getOpeningHours('D', 'to')}
                            onChange={(time) => this.setOpeningHours('D', 'to', time)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            ampm={false}
                          />
                          &nbsp;&nbsp;
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="משעה"
                            value={this.getOpeningHours('D', 'from')}
                            onChange={(time) => this.setOpeningHours('D', 'from', time)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            ampm={false}
                          />
                          </div>
                        )}
                      </div>
                    </MuiPickersUtilsProvider>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                      יום חמישי
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                        <span>סגור ביום זה <Checkbox
                          value={this.state.close['E']}
                          checked={this.state.close['E']}
                          onChange={(e) => this.close('E')}
                          color={yellow}
                          style={{color: blue}}
                        /></span>
                        &nbsp;
                        {this.state.close['E'] && (
                          <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                        )}
                        </div>

                        {!this.state.close['E'] && (
                          <div>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="עד שעה"
                            value={this.getOpeningHours('E', 'to')}
                            onChange={(time) => this.setOpeningHours('E', 'to', time)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            ampm={false}
                          />
                          &nbsp;&nbsp;
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="משעה"
                            value={this.getOpeningHours('E', 'from')}
                            onChange={(time) => this.setOpeningHours('E', 'from', time)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            ampm={false}
                          />
                          </div>
                        )}

                      </div>
                    </MuiPickersUtilsProvider>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                      יום שישי
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                        <span>סגור ביום זה <Checkbox
                          value={this.state.close['F']}
                          checked={this.state.close['F']}
                          onChange={(e) => this.close('F')}
                          color={yellow}
                          style={{color: blue}}
                        /></span>
                        &nbsp;
                        {this.state.close['F'] && (
                          <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                        )}
                        </div>

                        {!this.state.close['F'] && (
                          <div>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="עד שעה"
                            value={this.getOpeningHours('F', 'to')}
                            onChange={(time) => this.setOpeningHours('F', 'to', time)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            ampm={false}
                          />
                          &nbsp;&nbsp;
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="משעה"
                            value={this.getOpeningHours('F', 'from')}
                            onChange={(time) => this.setOpeningHours('F', 'from', time)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            ampm={false}
                          />
                          </div>
                        )}

                      </div>
                    </MuiPickersUtilsProvider>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1em', fontWeight: 700, marginBottom: 0}}>
                      יום שבת
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <div style={{marginTop: '2%', marginRight: '2%', fontSize: '1.2em', color: yellow}}>
                        <span>סגור ביום זה <Checkbox
                          value={this.state.close['G']}
                          checked={this.state.close['G']}
                          onChange={(e) => this.close('G')}
                          color={yellow}
                          style={{color: blue}}
                        /></span>
                        &nbsp;
                        {this.state.close['G'] && (
                          <span style={{color: blue}}>העסק מוגדר כסגור ביום זה</span>
                        )}
                        </div>

                        {!this.state.close['G'] && (
                          <div>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="עד שעה"
                            value={this.getOpeningHours('G', 'to')}
                            onChange={(time) => this.setOpeningHours('G', 'to', time)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            ampm={false}
                          />
                          &nbsp;&nbsp;
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="משעה"
                            value={this.getOpeningHours('G', 'from')}
                            onChange={(time) => this.setOpeningHours('G', 'from', time)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            ampm={false}
                          />
                          </div>
                        )}

                      </div>
                    </MuiPickersUtilsProvider>
                    </React.Fragment>
                  )}
                </div>

                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  לינק לפייסבוק שלי
                </p>
                {this.state.errorfacebookLink && (
                  <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                    זהו שדה חובה!
                  </p>
                )}
                <TextField
                  value={this.state.facebookLink}
                  onChange={(e) => this.setState({facebookLink: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '50%',
                    fontSize:'1.3em',
                    marginTop: '0.5%'
                  }}
                />

                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  לינק לאינטסגרם שלי
                </p>
                {this.state.errorinstagramLink && (
                  <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                    זהו שדה חובה!
                  </p>
                )}
                <TextField
                  value={this.state.instagramLink}
                  onChange={(e) => this.setState({instagramLink: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '50%',
                    fontSize:'1.3em',
                    marginTop: '0.5%'
                  }}
                />

                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  כתובת לוויז
                </p>
                {this.state.erroraddress && (
                  <p style={{direction:'rtl', color: 'red', fontSize: '1em', fontWeight: 100, marginBottom: 0}}>
                    זהו שדה חובה!
                  </p>
                )}
                <TextField
                  value={this.state.address}
                  onChange={(e) => this.setState({address: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '50%',
                    fontSize:'1.3em',
                    marginTop: '0.5%'
                  }}
                />
                <br />
                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  כתובת לסרטון
                </p>
                <TextField
                  value={this.state.video}
                  onChange={(e) => this.setState({video: e.target.value})}
                  style={{
                    direction: 'rtl',
                    textAlign: 'right',
                    width: '50%',
                    fontSize:'1.3em',
                    marginTop: '0.5%'
                  }}
                />
                <br />
                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  כפתור מספר 1
                </p>
                <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                  סוג הכפתור
                </p>
                <Select
                  value={this.state.button_1}
                  onChange={(e) => this.setState({button_1: e.target.value})}
                  style={{width: '50%', fontSize: '1.3em', textAlign: 'right', direciton: 'rtl'}}
                >
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'website'}>אתר</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'shop'}>חנות</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'waze'}>וויז</MenuItem>
                </Select>
                {(this.state.button_1 === 'website' || this.state.button_1 === 'shop' || this.state.button_1 === 'portfolio') && (
                  <React.Fragment>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                      קישור
                    </p>
                    <TextField
                      value={this.state.button_1_link}
                      onChange={(e) => this.setState({button_1_link: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '50%',
                        fontSize:'1.3em',
                        marginTop: '0.5%'
                      }}
                    />
                  </React.Fragment>
                )}
                <br />
                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  כפתור מספר 2
                </p>
                <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                  סוג הכפתור
                </p>
                <Select
                  value={this.state.button_2}
                  onChange={(e) => this.setState({button_2: e.target.value})}
                  style={{width: '50%', fontSize: '1.3em', textAlign: 'right', direciton: 'rtl'}}
                >
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'website'}>אתר</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'shop'}>חנות</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'waze'}>וויז</MenuItem>
                </Select>
                {(this.state.button_2 === 'website' || this.state.button_2 === 'shop' || this.state.button_2 === 'portfolio') && (
                  <React.Fragment>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                      קישור
                    </p>
                    <TextField
                      value={this.state.button_2_link}
                      onChange={(e) => this.setState({button_2_link: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '50%',
                        fontSize:'1.3em',
                        marginTop: '0.5%'
                      }}
                    />
                  </React.Fragment>
                )}
                <br />
                <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                  כפתור מספר 3
                </p>
                <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                  סוג הכפתור
                </p>
                <Select
                  value={this.state.button_3}
                  onChange={(e) => this.setState({button_3: e.target.value})}
                  style={{width: '50%', fontSize: '1.3em', textAlign: 'right', direciton: 'rtl'}}
                >
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'website'}>אתר</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'shop'}>חנות</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                  <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'waze'}>וויז</MenuItem>
                </Select>
                {(this.state.button_3 === 'website' || this.state.button_3 === 'shop' || this.state.button_3 === 'portfolio') && (
                  <React.Fragment>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                      קישור
                    </p>
                    <TextField
                      value={this.state.button_3_link}
                      onChange={(e) => this.setState({button_3_link: e.target.value})}
                      style={{
                        direction: 'rtl',
                        textAlign: 'right',
                        width: '50%',
                        fontSize:'1.3em',
                        marginTop: '0.5%'
                      }}
                    />
                  </React.Fragment>
                )}
                <br />

                {this.state.buttonShownIndex >= 4 && (
                  <React.Fragment>
                    <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                      כפתור מספר 4
                    </p>
                    <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                      סוג הכפתור
                    </p>
                    <Select
                      value={this.state.button_4}
                      onChange={(e) => this.setState({button_4: e.target.value})}
                      style={{width: '50%', fontSize: '1.3em', textAlign: 'right', direciton: 'rtl'}}
                    >
                      <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                      <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                      <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'website'}>אתר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                      <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                      <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                      <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'shop'}>חנות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                      <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'waze'}>וויז</MenuItem>
                    </Select>
                    {(this.state.button_4 === 'website' || this.state.button_4 === 'shop' || this.state.button_4 === 'portfolio') && (
                      <React.Fragment>
                        <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                          קישור
                        </p>
                        <TextField
                          value={this.state.button_4_link}
                          onChange={(e) => this.setState({button_4_link: e.target.value})}
                          style={{
                            direction: 'rtl',
                            textAlign: 'right',
                            width: '50%',
                            fontSize:'1.3em',
                            marginTop: '0.5%'
                          }}
                        />
                      </React.Fragment>
                    )}
                    <br />
                  </React.Fragment>
                )}


                {this.state.buttonShownIndex >= 5 && (
                  <React.Fragment>
                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    כפתור מספר 5
                  </p>
                  <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                    סוג הכפתור
                  </p>
                  <Select
                    value={this.state.button_5}
                    onChange={(e) => this.setState({button_5: e.target.value})}
                    style={{width: '50%', fontSize: '1.3em', textAlign: 'right', direciton: 'rtl'}}
                  >
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'website'}>אתר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'shop'}>חנות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'waze'}>וויז</MenuItem>
                  </Select>
                  {(this.state.button_5 === 'website' || this.state.button_5 === 'shop' || this.state.button_5 === 'portfolio') && (
                    <React.Fragment>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                        קישור
                      </p>
                      <TextField
                        value={this.state.button_5_link}
                        onChange={(e) => this.setState({button_5_link: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%'
                        }}
                      />
                    </React.Fragment>
                  )}
                  <br />
                  </React.Fragment>
                )}

                {this.state.buttonShownIndex >= 6 && (
                  <React.Fragment>
                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    כפתור מספר 6
                  </p>
                  <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                    סוג הכפתור
                  </p>
                  <Select
                    value={this.state.button_6}
                    onChange={(e) => this.setState({button_6: e.target.value})}
                    style={{width: '50%', fontSize: '1.3em', textAlign: 'right', direciton: 'rtl'}}
                  >
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'website'}>אתר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'shop'}>חנות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'waze'}>וויז</MenuItem>
                  </Select>
                  {(this.state.button_6 === 'website' || this.state.button_6 === 'shop' || this.state.button_6 === 'portfolio') && (
                    <React.Fragment>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                        קישור
                      </p>
                      <TextField
                        value={this.state.button_6_link}
                        onChange={(e) => this.setState({button_6_link: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%'
                        }}
                      />
                    </React.Fragment>
                  )}
                  <br />
                  </React.Fragment>
                )}


                {this.state.buttonShownIndex >= 7 && (
                  <React.Fragment>
                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    כפתור מספר 7
                  </p>
                  <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                    סוג הכפתור
                  </p>
                  <Select
                    value={this.state.button_7}
                    onChange={(e) => this.setState({button_7: e.target.value})}
                    style={{width: '50%', fontSize: '1.3em', textAlign: 'right', direciton: 'rtl'}}
                  >
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'website'}>אתר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'shop'}>חנות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'waze'}>וויז</MenuItem>
                  </Select>
                  {(this.state.button_7 === 'website' || this.state.button_7 === 'shop' || this.state.button_7 === 'portfolio') && (
                    <React.Fragment>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                        קישור
                      </p>
                      <TextField
                        value={this.state.button_7_link}
                        onChange={(e) => this.setState({button_7_link: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%'
                        }}
                      />
                    </React.Fragment>
                  )}
                  <br />
                  </React.Fragment>
                )}

                {this.state.buttonShownIndex >= 8 && (
                  <React.Fragment>
                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    כפתור מספר 8
                  </p>
                  <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                    סוג הכפתור
                  </p>
                  <Select
                    value={this.state.button_8}
                    onChange={(e) => this.setState({button_8: e.target.value})}
                    style={{width: '50%', fontSize: '1.3em', textAlign: 'right', direciton: 'rtl'}}
                  >
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'website'}>אתר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'shop'}>חנות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'waze'}>וויז</MenuItem>
                  </Select>
                  {(this.state.button_8 === 'website' || this.state.button_8 === 'shop' || this.state.button_8 === 'portfolio') && (
                    <React.Fragment>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                        קישור
                      </p>
                      <TextField
                        value={this.state.button_8_link}
                        onChange={(e) => this.setState({button_8_link: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%'
                        }}
                      />
                    </React.Fragment>
                  )}
                  <br />
                  </React.Fragment>
                )}

                {this.state.buttonShownIndex === 9 && (
                  <React.Fragment>

                  <p style={{direction:'rtl', color: blue, fontSize: '1.5em', fontWeight: 700, marginBottom: 0}}>
                    כפתור מספר 9
                  </p>
                  <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                    סוג הכפתור
                  </p>
                  <Select
                    value={this.state.button_9}
                    onChange={(e) => this.setState({button_9: e.target.value})}
                    style={{width: '50%', fontSize: '1.3em', textAlign: 'right', direciton: 'rtl'}}
                  >
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'facebook'}>פייסבוק</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'instagram'}>אינסטגרם</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'website'}>אתר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'youtube'}>יוטיוב</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'callme'}>התקשר</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'whatsapp'}>ווטצאפ</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'shop'}>חנות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'portfolio'}>תיק עבודות</MenuItem>
                    <MenuItem style={{textAlign: 'right', direciton: 'rtl'}} value={'waze'}>וויז</MenuItem>
                  </Select>
                  {(this.state.button_9 === 'website' || this.state.button_9 === 'shop' || this.state.button_9 === 'portfolio') && (
                    <React.Fragment>
                      <p style={{direction:'rtl', color: yellow, fontSize: '1.1em', fontWeight: 700, marginBottom: 0}}>
                        קישור
                      </p>
                      <TextField
                        value={this.state.button_9_link}
                        onChange={(e) => this.setState({button_9_link: e.target.value})}
                        style={{
                          direction: 'rtl',
                          textAlign: 'right',
                          width: '50%',
                          fontSize:'1.3em',
                          marginTop: '0.5%'
                        }}
                      />
                    </React.Fragment>
                  )}
                  </React.Fragment>
                )}

                {this.state.buttonShownIndex <= 8 && (
                  <React.Fragment>
                    <br />
                    <Button onClick={(e) => this.addButtonIndex()} style={{color: yellow, backgroundColor: blue, fontSize: '1em', marginRight: '1%'}}>
                      + הוספת כפתור נוסף
                    </Button>
                  </React.Fragment>
                )}
                {this.state.buttonShownIndex >= 4 && (
                  <React.Fragment>
                    <Button onClick={(e) => this.remove_button()} style={{color: yellow, backgroundColor: blue, fontSize: '1em'}}>
                    <DeleteForeverIcon />  מחק כפתור
                    </Button>
                  </React.Fragment>
                )}


                <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>גלריית התמונות שלי</p>
                <p style={{direction:'rtl', color: blue, fontSize: '1.2em', fontWeight: 700, marginBottom: 0}}>יעודכן בהקדם</p>


                <br /><br />
                <Button onClick={(e) => this.updateCard()} style={{color: blue, backgroundColor: yellow, fontSize: '1.2em', width: '40%'}}>
                  עדכון הכרטיס שלי
                </Button>
                <br /><br />

                  {this.state.errorAlert && (
                    <div style={{float: 'right', maxWidth:'50%'}}>
                      <Alert severity="error" color="error" style={{textAlign: 'center', direction: 'rtl'}}>
                        &nbsp; {this.state.error}  &nbsp;
                      </Alert>
                    </div>
                  )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <center><p style={{direction:'rtl', color: blue, fontSize: '1em', fontWeight: 100}}>
                  כל הזכויות שמורות לדיביט בע"מ, 2020
                </p></center>
              </div>
            </Grid>
          </Grid>
        )}

      </div>
    );
  }
}

export default NewCard;
